  import React from "react";
  // import { useLocation, useNavigate } from "react-router-dom";
  // import AppBar from "@mui/material/AppBar";
  // import Box from "@mui/material/Box";
  // import Toolbar from "@mui/material/Toolbar";
  // import IconButton from "@mui/material/IconButton";
  // import Typography from "@mui/material/Typography";
  // import Menu from "@mui/material/Menu";
  // import MenuIcon from "@mui/icons-material/Menu";
  // import Container from "@mui/material/Container";
  // import Avatar from "@mui/material/Avatar";
  // import Stack from "@mui/material/Stack";
  // import Button from "@mui/material/Button";
  // import Tooltip from "@mui/material/Tooltip";
  // import { List, ListItem, ListItemText } from "@mui/material";
  // import { ListItemIcon, Divider } from "@mui/material";
  // import MenuItem from "@mui/material/MenuItem";
  // import { useMediaQuery, useTheme } from "@mui/material";
  // import VacanziLogo from "../../../assets/icons/logos/vacanzi.png";
  // import { ThemeProvider, createTheme } from "@mui/material/styles";
  // // import avatarMen from "../../../assets/images/avatarwomen.jpg";
  // import AccountCircleIcon from "@mui/icons-material/AccountCircle";
  // import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
  // import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
  // import LogoutIcon from "@mui/icons-material/Logout";
  // import { getTopOrgs, getTopIndustries } from "../../../api/common";
  // import { useScroll } from "../../../context/ScrollContext";
  // import { getUserApi } from "../../../api/jobseeker";
  import NewNavBar from "./NewNavbar";

  // import {
  //   Popper,
  //   Paper,
  //   Grow,
  //   ClickAwayListener,
  //   MenuList,
  // } from "@mui/material";
  // import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";

  // const lightTheme = createTheme({
  //   palette: {
  //     mode: "light",
  //     background: {
  //       default: "#EDEDFC",
  //       paper: "#EDEDFC",
  //     },
  //     primary: {
  //       main: "#000000",
  //     },
  //   },
  //   typography: {
  //     button: {
  //       textTransform: "none",
  //       fontWeight: 500,
  //     },
  //   },
  //   components: {
  //     MuiAppBar: {
  //       styleOverrides: {
  //         root: {
  //           backgroundColor: "#EDEDFC",
  //           boxShadow: "none",
  //         },
  //       },
  //     },
  //     MuiButton: {
  //       styleOverrides: {
  //         root: {
  //           borderRadius: "6px",
  //           padding: "6px 12px",
  //         },
  //       },
  //     },
  //   },
  // });

  // const NavButton = ({ children, hasDropdown, items }) => {
  //   const [open, setOpen] = useState(false);
  //   const anchorRef = React.useRef(null);

  //   const handleToggle = () => {
  //     setOpen((prevOpen) => !prevOpen);
  //   };

  //   const handleClose = (event) => {
  //     if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //       return;
  //     }
  //     setOpen(false);
  //   };

  //   if (!hasDropdown) {
  //     return (
  //       <Button
  //         color="primary"
  //         sx={{
  //           color: "white",
  //           "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
  //         }}
  //       >
  //         {children}
  //       </Button>
  //     );
  //   }

    

  //   return (
  //     <>
  //   <Button
  //     ref={anchorRef}
  //     color="primary"
  //     onMouseOver={handleToggle} // Open dropdown on mouse over
  //     onMouseLeave={handleClose} // Close dropdown on mouse leave
  //     endIcon={<KeyboardArrowDownIcon />}
  //     sx={{
  //       color: "white",
  //       "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
  //     }}
  //   >
  //     {children}
  //   </Button>
  //   <Popper
  //     open={open}
  //     anchorEl={anchorRef.current}
  //     placement="bottom-start"
  //     transition
  //     disablePortal
  //     onMouseOver={handleToggle} // Keep dropdown open when hovering over it
  //     onMouseLeave={handleClose} // Close dropdown when mouse leaves
  //   >
  //     {({ TransitionProps }) => (
  //       <Grow {...TransitionProps}>
  //         <Paper
  //           elevation={0}
  //           sx={{
  //             backgroundColor: "#dadaef",
  //             border: "1px solid rgba(255, 255, 255, 0.1)",
  //             mt: 1,
  //             borderRadius: "13px",
  //           }}
  //         >
  //           <ClickAwayListener onClickAway={handleClose}>
  //             <MenuList autoFocusItem={open}>
  //               {items?.map((item, index) => (
  //                 <MenuItem
  //                   key={index}
  //                   onClick={handleClose}
  //                   sx={{ minWidth: "160px" }}
  //                 >
  //                   {item}
  //                 </MenuItem>
  //               ))}
  //             </MenuList>
  //           </ClickAwayListener>
  //         </Paper>
  //       </Grow>
  //     )}
  //   </Popper>
  // </>
  //   );
  // };


  function ResponsiveAppBar() {
    // const [anchorElNav, setAnchorElNav] = useState(null);
    // const [anchorElUser, setAnchorElUser] = useState(null);
    // const [anchorEl, setAnchorEl] = useState(null);
    // const [openSubMenu, setOpenSubMenu] = useState(null);
    // const [companies, setCompanies] = useState();
    // const [industries, setIndustries] = useState();
    // const [userData, setUserData] = useState();
    // const [whyVacanziActive, setWhyVacanziActive] = useState(false);
    // // const [isScrolled, setIsScrolled] = useState(false); // State to track scroll position

    // const theme = useTheme();
    // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    // const navigate = useNavigate();
    // const location = useLocation();
    // const { scrollToWhyVacanzi } = useScroll();

    // const handleWhyVacanziClick = () => {
    //   if (location.pathname === "/") {
    //     scrollToWhyVacanzi();
    //     setWhyVacanziActive(true); // Set active state
    //   } else {
    //     navigate("/", { state: { scrollToWhyVacanzi: true } });
    //     setWhyVacanziActive(true); // Set active state
    //   }
    // };

    // Check location.state to determine if "Why Vacanzi" should be active
    // useEffect(() => {
    //   if (location.state?.scrollToWhyVacanzi) {
    //     setTimeout(() => {
    //       scrollToWhyVacanzi();
    //       setWhyVacanziActive(true); // Set active state
    //     }, 500);
    //   }
    // }, [location.state, scrollToWhyVacanzi]);

    // const navigate = useNavigate();

    // Add scroll event listener
    // useEffect(() => {
    //   const handleScroll = () => {
    //     if (window.scrollY > 0) {
    //       setIsScrolled(true); // Add shadow if scrolled down
    //     } else {
    //       setIsScrolled(false); // Remove shadow if at the top
    //     }
    //   };

    //   window.addEventListener('scroll', handleScroll); // Attach scroll listener
    //   return () => {
    //     window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    //   };
    // }, []);

    // Handles closing the menu
    // const handleMenuClose = () => {
    //   setAnchorEl(null);
    //   setOpenSubMenu(false);
    // };

    // useEffect(() => {
    //   const fetchOrgDetails = async () => {
    //     try {
    //       const response = await getTopOrgs();
    //       setCompanies(response?.data);
    //     } catch (error) {
    //       console.log(error.message);
    //     }
    //   };
    //   fetchOrgDetails();
    // }, []);

    // useEffect(() => {
    //   const fetchIndustryDetails = async () => {
    //     try {
    //       const response = await getTopIndustries();
    //       setIndustries(response);
    //     } catch (error) {
    //       console.log(error.message);
    //     }
    //   };
    //   fetchIndustryDetails();
    // }, []);

    // const handleOpenNavMenu = (event) => {
    //   setAnchorElNav(event.currentTarget);
    // };

    // const handleOpenUserMenu = (event) => {
    //   setAnchorElUser(event.currentTarget);
    // };

    // const handleCloseNavMenu = () => {
    //   setAnchorElNav(null);
    // };

    // const handleCloseUserMenu = () => {
    //   setAnchorElUser(null);
    // };

    // const lightTheme = createTheme({
    //   palette: {
    //     mode: "light",
    //     primary: {
    //       main: "#EDEDFC",
    //     },
    //   },
    // });

    // const handleNavigation = (path) => {
    //   navigate(path);
    //   handleMenuClose();
    // };

    // const token = localStorage.getItem("authToken");

    // const handleLogout = () => {
    //   localStorage.removeItem("authToken");
    //   localStorage.removeItem("userId");
    //   navigate("/");
    // };
    // const userId = localStorage.getItem("userId");
    // useEffect(() => {
    //   if (token) {
    //     const getUserDetails = async () => {
    //       try {
    //         const response = await getUserApi(userId, token);

    //         setUserData(response);
    //       } catch (error) {
    //         console.log(error.message);
    //       }
    //     };
    //     getUserDetails();
    //   }
    // }, [token, userId]);

    // const isActiveRoute = (path) => {
    //   return location.pathname === path;
    // };




    return (
      <>
        {/* <ThemeProvider theme={lightTheme}> */}
          {/* <AppBar
            position="sticky"
          
          > */}
            {/* <Container maxWidth="xl"> */}
              {/* <Toolbar
                disableGutters
                sx={{
                  // boxShadow: "none",
                  // display: "flex",
                  // alignItems: "center", // Center align vertically
                  // justifyContent: "space-between", // Space between logo, content, and profile
                  // width: "100%", // Ensure full width
                  minHeight:'64px'
                }}
              > */}
                {/* <Typography
                  variant="h6" // Add this prop
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    fontWeight: 700,
                    color: "inherit",
                    textDecoration: "none",
                    gap: "8px",
                  }}
                >
                  <Avatar
                    src={"/vacanziLogo.png"}
                    alt="Vacanzi Logo"
                    sx={{
                      width: 50,
                      height: 50,
                      display: { xs: "none", md: "flex" },
                    }}
                  />
                  <span style={{ color: "#423bfa", fontSize: "30px" }}>
                    Vacanzi
                  </span>
                </Typography> */}

                {/* <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex", md: "none", mr: "20px" },
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                      ml: 4,
                    }}
                  >
                    <MenuItem
                      onClick={() => handleNavigation("/findJobs")}
                      sx={{ mb: 1, position: "re" }}
                    >
                      <Typography
                        sx={{ color: "#423BFA", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        Find Jobs
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleNavigation("/companies")}
                      sx={{ mb: 1 }}
                    >
                      <Typography
                        sx={{ color: "#423BFA", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        Companies
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={scrollToWhyVacanzi} sx={{ mb: 1 }}>
                      <Typography
                        sx={{ color: "#423BFA", fontWeight: "bold" }}
                        textAlign="center"
                      >
                        Why Vacanzi
                      </Typography>
                    </MenuItem>

                    {token && (
                      <>
                        <MenuItem
                          onClick={() =>
                            handleNavigation("/jobSeekerAppliedJobs")
                          }
                          sx={{ mb: 1 }}
                        >
                          <Typography
                            sx={{ color: "#423BFA", fontWeight: "bold" }}
                            textAlign="center"
                          >
                            Applied Jobs
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleNavigation("/jobSeekerSavedJobs")}
                        >
                          <Typography
                            sx={{ color: "#423BFA", fontWeight: "bold" }}
                            textAlign="center"
                          >
                            Saved Jobs
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </Box> */}

                {/* <Avatar
                  src={VacanziLogo}
                  alt="Vacanzi Logo"
                  sx={{
                    width: 46,
                    height: 46,
                    display: { xs: "block", md: "none" },
                  }}
                /> */}

                {/* <Typography
                  variant="body2"
                  noWrap
                  component="a"
                  href="#app-bar-with-responsive-menu"
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexGrow: 1,
                    fontFamily: "GlanceSansBold",
                    fontWeight: 700,
                    color: "#423BFA",
                    textDecoration: "none",
                  }}
                >
                  Vacanzi
                </Typography> */}

                {/* <Box
                  sx={{
                    flexGrow: 2,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "flex-start",
                    gap: 5,
                  }}
                > */}
                  {/* Find Jobs Button */}
                  {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      position: "relative",
                      width: "fit-content",
                    }}
                  >
                    <Button
                      onClick={() => handleNavigation("/findJobs")}
                      sx={{
                        color: isActiveRoute("/findJobs") ? "#423BFA" : "#423BFA",
                        fontSize: isSmallScreen ? "14px" : "16px",
                        fontWeight: "bold",
                        fontFamily: "GlanceSansRegular",
                        textTransform: "none",
                      }}
                    >
                      Find Jobs
                    </Button>
                    {isActiveRoute("/findJobs") && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: -8,
                          left: 0,
                          right: 0,
                          height: 3,
                          backgroundColor: "#423BFA",
                          borderRadius: 2,
                        }}
                      />
                    )}
                  </Box> */}

                  {/* Companies Button with Submenu */}
                  {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      position: "relative",
                      width: "fit-content",
                    }}
                  >
                    <Button
                      sx={{
                        color: isActiveRoute("/companies")
                          ? "#423BFA"
                          : "#423BFA",
                        fontSize: "16px",
                        fontWeight: "bold",
                        fontFamily: "GlanceSansRegular",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(event) => {
                        setAnchorEl(event.currentTarget);
                        setOpenSubMenu("companies");
                      }}
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setOpenSubMenu("companies");
                      }}
                    >
                      Companies
                    </Button>
                    {isActiveRoute(
                      "/top-hiring-companies" ||
                        "organizationOverview" ||
                        "organizations-by-industry"
                    ) && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: -8,
                          left: 0,
                          right: 0,
                          height: 3,
                          backgroundColor: "#423BFA",
                          borderRadius: 2,
                        }}
                      />
                    )}
                  </Box> */}

                  {/* Companies Submenu */}
                  {/* <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && openSubMenu === "companies"}
                    onClose={() => {
                      setAnchorEl(null);
                      setOpenSubMenu(null);
                    }}
                    onMouseLeave={() => {
                      setAnchorEl(null);
                      setOpenSubMenu(null);
                    }}
                    MenuListProps={{
                      onMouseLeave: () => {
                        setAnchorEl(null);
                        setOpenSubMenu(null);
                      },
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        elevation: 0,
                        borderRadius: 4,
                        padding: 0.5,
                        minWidth: 300,
                        typography: "body2",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Box sx={{ flex: 1 }}>
                        <MenuItem sx={{ paddingY: 0.5 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold", fontSize: "0.875rem" }}
                          >
                            Industries
                          </Typography>
                        </MenuItem>
                        <List sx={{ padding: 0 }}>
                          {industries
                            ?.map((data) => data.industry)
                            .filter(
                              (value, index, self) =>
                                self.indexOf(value) === index
                            )
                            .map((industry, index) => (
                              <ListItem
                                key={index}
                                button
                                sx={{ paddingY: 0.25, paddingX: 2 }}
                                onClick={() =>
                                  handleNavigation(
                                    `/organizations-by-industry?industry=${industry}`
                                  )
                                }
                              >
                                <ListItemText
                                  primaryTypographyProps={{
                                    variant: "body2",
                                    fontSize: "0.875rem",
                                  }}
                                  primary={industry}
                                />
                                {isActiveRoute(
                                  `/organizations-by-industry?industry=${industry}`
                                ) && (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      bottom: 0,
                                      left: 0,
                                      right: 0,
                                      height: 2,
                                      backgroundColor: "#423BFA",
                                      borderRadius: 2,
                                    }}
                                  />
                                )}
                              </ListItem>
                            ))}
                        </List>
                      </Box>
                      <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
                      <Box sx={{ flex: 1 }}>
                        <MenuItem sx={{ paddingY: 0.5 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold", fontSize: "0.875rem" }}
                          >
                            Explore Companies
                          </Typography>
                        </MenuItem>
                        <List sx={{ padding: 0 }}>
                          {companies?.slice(0, 5).map((company, index) => (
                            <ListItem
                              key={index}
                              button
                              sx={{ paddingY: 0.25, paddingX: 2 }}
                              onClick={() =>
                                handleNavigation(
                                  `/organizationOverview?companyId=${company._id}`
                                )
                              }
                            >
                              <ListItemText
                                primaryTypographyProps={{
                                  variant: "body2",
                                  fontSize: "0.875rem",
                                }}
                                primary={company.organizationName}
                              />
                              {isActiveRoute(
                                `/organizationOverview?companyId=${company._id}`
                              ) && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    height: 2,
                                    backgroundColor: "#423BFA",
                                    borderRadius: 2,
                                  }}
                                />
                              )}
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <MenuItem sx={{ paddingY: 0.5 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold", fontSize: "0.875rem" }}
                          >
                            Top Companies
                          </Typography>
                        </MenuItem>
                        <List sx={{ padding: 0 }}>
                          <ListItem
                            button
                            sx={{ paddingY: 0.75, paddingX: 2 }}
                            onClick={() =>
                              handleNavigation(`/top-hiring-companies`)
                            }
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                variant: "body2",
                                fontSize: "0.875rem",
                              }}
                              primary="Top IT Companies"
                            />
                            {isActiveRoute(`/top-hiring-companies`) && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  height: 2,
                                  backgroundColor: "#423BFA",
                                  borderRadius: 2,
                                }}
                              />
                            )}
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                  </Menu> */}

                  {/* Why Vacanzi Button */}
                  {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      position: "relative",
                      width: "fit-content",
                    }}
                  >
                    <Button
                      onClick={handleWhyVacanziClick}
                      sx={{
                        color: isActiveRoute("/whyVacanzi")
                          ? "#423BFA"
                          : "#423BFA",
                        fontSize: isSmallScreen ? "14px" : "16px",
                        fontWeight: "bold",
                        fontFamily: "GlanceSansRegular",
                        textTransform: "none",
                      }}
                    >
                      Why Vacanzi
                    </Button>
                    {whyVacanziActive && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: -8,
                          left: 0,
                          right: 0,
                          height: 3,
                          backgroundColor: "#423BFA",
                          borderRadius: 2,
                        }}
                      />
                    )}
                  </Box> */}

                  {/* Applied Jobs Button */}
                  {/* {token && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "relative",
                        width: "fit-content",
                      }}
                    >
                      <Button
                        onClick={() => handleNavigation("/jobSeekerAppliedJobs")}
                        sx={{
                          color: isActiveRoute("/jobSeekerAppliedJobs")
                            ? "#423BFA"
                            : "#423BFA",
                          fontSize: isSmallScreen ? "14px" : "16px",
                          fontWeight: "bold",
                          fontFamily: "GlanceSansRegular",
                          textTransform: "none",
                        }}
                      >
                        Applied Jobs
                      </Button>
                      {isActiveRoute("/jobSeekerAppliedJobs") && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: -8,
                            left: 0,
                            right: 0,
                            height: 3,
                            backgroundColor: "#423BFA",
                            borderRadius: 2,
                          }}
                        />
                      )}
                    </Box>
                  )} */}

                  {/* Saved Jobs Button */}
                  {/* {token && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "relative",
                        width: "fit-content",
                      }}
                    >
                      <Button
                        onClick={() => handleNavigation("/jobSeekerSavedJobs")}
                        sx={{
                          color: isActiveRoute("/jobSeekerSavedJobs")
                            ? "#423BFA"
                            : "#423BFA",
                          fontSize: isSmallScreen ? "14px" : "16px",
                          fontWeight: "bold",
                          fontFamily: "GlanceSansRegular",
                          textTransform: "none",
                        }}
                      >
                        Saved Jobs
                      </Button>
                      {isActiveRoute("/jobSeekerSavedJobs") && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: -8,
                            left: 0,
                            right: 0,
                            height: 3,
                            backgroundColor: "#423BFA",
                            borderRadius: 2,
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box> */}

                {/* {token ? (
                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar
                          alt="User Avatar"
                          src={
                            userData?.user?.profileImage
                              ? userData?.user?.profileImage
                              : ""
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{
                        mt: "45px",
                        boxShadow: 3,
                        borderRadius: 2,
                        "& .MuiMenu-paper": {
                          minWidth: "200px",
                        },
                      }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem
                        onClick={() => handleNavigation("/jobSeeker-Profile")}
                      >
                        <ListItemIcon>
                          <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography textAlign="center">Profile</Typography>
                      </MenuItem>

                      <MenuItem
                        onClick={() => handleNavigation("/jobSeeker/support")}
                      >
                        <ListItemIcon>
                          <HelpOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography textAlign="center">Support</Typography>
                      </MenuItem>

                      <Divider sx={{ my: 1 }} />

                      <MenuItem
                        onClick={() =>
                          handleNavigation("/jobSeekeerSubscription")
                        }
                      >
                        <ListItemIcon>
                          <SubscriptionsIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography textAlign="center">
                          Subscription Plans
                        </Typography>
                      </MenuItem>

                      <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                          <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography textAlign="center">Logout</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                ) : (
                  <Stack
                    spacing={isSmallScreen ? 0 : 2}
                    direction={isSmallScreen ? "column" : "row"}
                  >
                    <Button
                      onClick={() => handleNavigation("/signinsignup")}
                      variant="contained"
                      sx={{
                        backgroundColor: "#423BFA",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: isSmallScreen ? "10px" : "20px",
                        borderRadius: "2rem",
                        fontFamily: "GlanceSansBold",
                        "&:hover": {
                          backgroundColor: "#423BFA",
                        },
                      }}
                    >
                      <Typography
                        sx={{ textTransform: "none", fontWeight: "bold" }}
                      >
                        Job Seekers
                      </Typography>
                    </Button>

                    <Button
                      onClick={() => handleNavigation("/employerSignIn")}
                      variant="text"
                      sx={{
                        color: "#423BFA",
                        fontSize: isSmallScreen ? "14px" : "20px",
                        fontWeight: "bold",
                        fontFamily: "GlanceSansRegular",
                        textTransform: "none",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        For Employers
                      </Typography>
                    </Button>
                  </Stack>
                )} */}
              {/* </Toolbar> */}
            {/* </Container> */}
          {/* </AppBar> */}
        {/* </ThemeProvider> */}
        <NewNavBar />
      </>
    );
  }

  export default ResponsiveAppBar;
