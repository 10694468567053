import React from "react";
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const SearchBar = ({
  title,
  location,
  onTitleChange,
  onLocationChange,
  onSearch,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };
  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? "column" : "row"}
      alignItems="center"
      backgroundColor="#ffffff"
      borderRadius="0.7rem"
      padding={isSmallScreen ? "10px" : "5px 20px"}
      gap={isSmallScreen ? 2 : 1}
      boxShadow="0 6px 10px -1px rgba(0, 0, 0, 0.2), 0 4px 6px -1px rgba(0, 0, 0, 0.08)" // Shadow at the bottom
    >
      {/* Skill Search Input */}
      <TextField
        variant="standard"
        placeholder="Search Designation / Skills / Companies"
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "#a3a3a3" }} />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        sx={{
          flex: 1,
          backgroundColor: "#ffffff",
          borderRadius: "50px",
          padding: "8px 20px",
          "& .MuiInputBase-input": { padding: 0 },
        }}
      />

      {/* Location Search Input */}
      <TextField
        variant="standard"
        placeholder="Search for an Area or City"
        value={location}
        onChange={(e) => onLocationChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box
                sx={{
                  borderLeft: "2px solid #e0e0e0", // Add border before the icon
                  paddingLeft: "8px", // Add padding to separate the border from the icon
                }}
              >
                <LocationOnOutlinedIcon style={{ color: "#a3a3a3" }} />
              </Box>
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        sx={{
          flex: 1,
          backgroundColor: "#ffffff",
          borderRadius: "50px",
          padding: "8px 20px",
          "& .MuiInputBase-input": { padding: 0 },
        }}
      />

      {/* Search Button */}
      <Button
        variant="contained"
        onClick={onSearch}
        sx={{
          backgroundColor: "#18181b",
          color: "#ffffff",
          borderRadius: "0.7rem",
          textTransform: "none",
          padding: "0.375rem 0.75rem",
          marginLeft: isSmallScreen ? 0 : "8px",
          "&:hover": { backgroundColor: "#21867a" },
        }}
      >
        Search jobs
      </Button>
    </Box>
  );
};

export default SearchBar;
