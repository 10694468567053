import React, { useState } from "react";
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Grid,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import googlePlay from "../../../assets/images/googlePlay.png";
import appStore from "../../../assets/images/appStore.png";
import LandingVideo from "../../../assets/videos/homevideo.mp4";
import SearchBar from "../LandingSearchBar/SearchBar";
import JobSeekerStats from "../JobSeekerHomePageBanners/JobSeekerStats";
// import LandingMain from "../../../assets/images/landinmain.png";
// import LandingOne from "../../../assets/images/landing1.png";
// import LandingTwo from "../../../assets/images/landing2.png";
// import LandingThree from "../../../assets/images/landing3.png";
// import LandingFour from "../../../assets/images/landing4.png";
// import LandingGiffy from "../../../assets/images/landinggiffy.gif";
import Landing1 from "../../../assets/images/imageconvo1.png";
import Landing2 from "../../../assets/images/imageconvo2.png";

const JobSeekerJobSearch = () => {
  const [titleSearch, setTitleSearch] = useState("");
  const [locationSearch, setLocationSearch] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate("/findJobs", {
      state: {
        keyword: titleSearch,
        jobCity: locationSearch,
      },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5faf5",
        padding: { xs: "2rem", md: "3rem" },
        minHeight: "90vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Center-align children vertically
          textAlign: "center", // Center-align text within children
          width: "100%", // Use full width to ensure centering works
          maxWidth: "1100px", // Limit the maximum width
          margin: "0 auto", // Center the Box itself horizontally
          gap: 2,
          mt: 12,
          mb: 4,
        }}
      >
        <Typography
          variant={isSmallScreen ? "h6" : "h2"}
          component="h1"
          sx={{
            color: "#000",
            fontWeight: "bold",
            fontFamily: "IBM plex sans",
          }}
        >
          Find Jobs Faster, Connect Instantly with Recruiters
        </Typography>

        <SearchBar
          title={titleSearch}
          location={locationSearch}
          onTitleChange={setTitleSearch}
          onLocationChange={setLocationSearch}
          onSearch={handleSearch}
          sx={{
            width: { xs: "100%", sm: "80%", md: "70%" },
          }}
        />
      </Box>
        <Box sx={{ mt: 10 }}>
          <Grid container spacing={6} alignItems="flex-start" sx={{ mb: 10 }}>
            {/* Left Section */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 2,
                  padding: { xs: "2rem", md: "4rem" },
                  borderRadius: "12px",
                }}
              >
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    color: "#1b1919",
                    fontWeight: 600,
                    marginBottom: { xs: 2, md: 3 },
                    maxWidth: "500px",
                    fontSize: { xs: "2rem", md: "3.8rem" },
                    fontFamily: "IBM Plex Sans",
                    lineHeight: 1.3,
                    textShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  Experience the Future
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "IBM Plex Sans",
                    mt: 0,
                    color: "#423bfa",
                    maxWidth: "600px",
                    fontSize: { xs: "2rem", md: "3rem" },
                  }}
                >
                  Live Chat, Fast-Track Your Hiring
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate("/about-vacanzi")}
                  sx={{
                    backgroundColor: "#18181b",
                    color: "#ffffff",
                    borderRadius: "0.7rem",
                    textTransform: "none",
                    padding: "0.375rem 0.75rem",
                    "&:hover": { backgroundColor: "#21867a" },
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Grid>

            {/* Right Section */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 2, // Controls the spacing between video and images
                  alignItems: "center",
                }}
              >
                {/* Video Section */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1, // Slight gap between the video and app download buttons
                  }}
                >
                  <video
                    src={LandingVideo}
                    autoPlay
                    muted
                    loop
                    style={{
                      width: "100%",
                      maxWidth: "320px",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "12px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <img
                      src={appStore}
                      alt="App Store"
                      style={{ width: "150px" }}
                    />
                    <img
                      src={googlePlay}
                      alt="Google Play"
                      style={{ width: "150px" }}
                    />
                  </Box>
                </Box>

                {/* Image Section */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1, // Reduced gap between images
                  }}
                >
                  <img
                    src={Landing1}
                    alt="Landing 1"
                    style={{
                      width: "100%",
                      maxWidth: "320px",
                      borderRadius: "12px",
                    }}
                  />
                  <img
                    src={Landing2}
                    alt="Landing 2"
                    style={{
                      width: "100%",
                      maxWidth: "320px",
                      borderRadius: "12px",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* Stats Section */}
          <Box sx={{ marginTop: "1rem", position: "relative", bottom: -90 }}>
            <JobSeekerStats />
          </Box>
        </Box>
    </Box>
  );
};

export default JobSeekerJobSearch;
