
const searchCandidates = {
  fontStyles: {
    fontSize: 13,
    fontWeight: "regular",
  },
  button: {
    backgroundColor: "#423bfa",
    borderRadius:'2rem',
    color: "white",
  },
  parentBox: {
    
    display: "flex",
    justifyContent:'center',
    alignItems: "flex-start",
    height: "auto",
    backgroundColor: "#f5f5f5",
    gap:1,
  },
  paper: {
    padding: 3,
    height: "auto",
    borderRadius: 2,
    backgroundColor: "#ffffff",
    mb: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexboxCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexboxColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  flexboxend: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  flexboxStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  searchParentBox: {
    p: 2,
    mt: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "sticky",
    bottom: 0,
    zIndex: 10,
    backgroundColor: "#fff",
  },
  searchButton: {
    backgroundColor: "#423bfa",
    borderRadius:'2rem',
    "&:hover": {
      backgroundColor: "#423bfa",
    },
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  activeFilter: {
    fontSize: 14,
    cursor: "pointer",
    border: "none",
    outline: "none",
    boxShadow: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:focus": {
      border: "none",
    },
    "& .MuiSelect-icon": {
      color: "black",
    },
  },
  addItskills: {
    fontSize: 13,
    mt:1,
    fontWeight: "bold",
    color: "#0B59BEFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":hover": {
      color: "#4A88DAFF",
    },
    cursor: "pointer",
    marginLeft: 1,
  },
};

export default searchCandidates;
