import baseURL from "./baseUrl";

export const allocateQuota = async (token, formValues) => {
    try {
      const response = await fetch(`${baseURL}/allot`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });

    
      if (!response) {
        throw new Error("network response was not ok");
      }
      return response.json()
    } catch (error) {
      console.log(error.message);
    }
  };
export const UnAllocateQuota = async (token, formValues) => {
    try {
      const response = await fetch(`${baseURL}/unAllot`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      if (!response.ok) {
        throw new Error("network response was not ok");
      }
      return response.json()
    } catch (error) {
      console.log(error.message);
    }
  };

  export const requestQuotaApi = async (token, formValues) => {
    try {
      const response = await fetch(`${baseURL}/requestQuotaAllocation`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      if (!response.ok) {
        throw new Error("network response was not ok");
      }
      return response.json()
    } catch (error) {
      console.log(error.message);
    }
  };

  

  