const subscribeList = {
  mainTitleBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "GlanceSansRegular",
    pt: 1,
    mb: 1,
  },
  parentBox: {
    backgroundColor: "#f5f5f5",
    fontFamily: "GlanceSansRegular",
  },
  cardParentBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 2,
    minHeight: "100vh",
    backgroundColor: "#f5f5f5",
    fontFamily: "GlanceSansRegular",
  },
  card: {
    minWidth: 275,
    borderRadius: 5,
  },
  title: {
    color: "#0E61E7FF",
    mb: 0.5,
    fontSize: 30,
    fontFamily: "GlanceSansRegular",
    fontWeight:'bold'
  },
  priceBox: {
    display: "flex",
    fontWeight: "regular",
    justifyContent: "start",
    fontFamily: "GlanceSansRegular",
  },
  keyfeatures: {
    mb: 1.5,
    fontWeight: "bold",
    fontSize: 13,
    ml: 1,
    opacity: 0.7,
    fontFamily: "GlanceSansRegular",
  },
  circleIcon: {
    color: "green",
    mr: 1,
    fontSize: 19,
  },
  closeIcon: {
    mr: 1,
    opacity: 0.5,
    fontSize: 19,
  },
  discountBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px dotted gray",
    borderRadius: 2,
    padding: 1.5,
    mt: 0.3,
  },
  buyButton: {
    width: 180,
    height: 48,
    fontFamily: "GlanceSansRegular",
    mb: 2,
  },
  contentBox: {
    display: "flex",
    mb: 0.3,
    fontFamily: "GlanceSansRegular",
  },
  contentText: {
    fontFamily: "poppins",
    fontSize: 14,
  },
};

export default subscribeList;
