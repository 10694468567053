import { Container, Box } from "@mui/system";
import React from "react";
// import JobSeekerSubscriptionHeader from "../../../components/JobSeekerSubscription/Header";
import SubscriptionBenifitTable from "../../../components/JobSeekerSubscription/SubscriptoinBenifitTable";
// import PricingCards from "../../../components/JobSeekerSubscription/PricingCardscompoents";
import PaidServices from "../../../components/JobSeekerSubscription/PaidServieJobSeeker";
// import AdditionalDetails from "../../../components/AdvancedComponents/AdditionalDetails";
// import NavBar from '../../../components/JobSeekerComponents/NavBar/NavBar';
import AdditionalInfo from "../../../components/JobSeekerSubscription/AdditionalInfo";
import SubscriptionPlanJobSeeker from "../../../components/JobSeekerSubscription/SubscriptionPlanJobSeeker";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";


const JobSeekerSubscription = () => {
    return (
        <>
          <NewNavBar/>
        <Container maxWidth="90%" sx={{marginTop:5,}} >
          
            
            <Box >
                <SubscriptionPlanJobSeeker/>
                <SubscriptionBenifitTable/>
                <PaidServices/>
                <AdditionalInfo/>
             
                
            </Box>
        </Container>
        </>
    );
};

export default JobSeekerSubscription;
