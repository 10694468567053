import React from "react";
import { Box, Typography, Grid, Container, Button } from "@mui/material";
// import girlMobile from "../../../assets/images/VacanziMobile.png";
import googlePlay from "../../../assets/images/googlePlay.png";
import appStore from "../../../assets/images/appStore.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Basic Swiper styles
import "swiper/css/autoplay"; // Autoplay module styles
import "swiper/css/pagination"; // Pagination styles
import { Autoplay, Pagination } from "swiper/modules"; // Corrected import
import slider1 from "../../../assets/images/slider1.png";
import slider2 from "../../../assets/images/slider2.png";
import slider3 from "../../../assets/images/slider3.png";
import slider4 from "../../../assets/images/slider4.png";
import slider5 from "../../../assets/images/slider5.png";

const images = [slider1, slider2, slider3, slider4, slider5]; // Add your image imports here

const JobSeekerBottom = () => {
  return (
    <Box sx={{ backgroundColor: "#fff", py: 4 }}>
      <Container maxWidth="xl">
        <Box mt={4}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  textAlign: "left",
                }}
              >
                <Typography
                  variant="h3"
                  component="h1"
                  gutterBottom
                  fontWeight="bold"
                >
                  Short Video Profiles
                </Typography>
                <Typography variant="h4" component="h1" gutterBottom>
                  Get Hired Faster on Vacanzi
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Vacanzi streamlines the process, allowing employers to assess
                  your skills and personality instantly, leading to faster job
                  matches.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 4,
                    borderRadius: "1rem",
                    backgroundColor: "#000",
                    "&:hover": {
                      backgroundColor: "#000",
                    },
                  }}
                >
                  Download The App Now
                </Button>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <img
                    src={appStore}
                    alt="App Store"
                    style={{ width: "150px" }}
                  />
                  <img
                    src={googlePlay}
                    alt="Google Play"
                    style={{ width: "150px" }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  textAlign: "center",
                  backgroundColor: "#000", // Black background
                  padding: "0rem", // Padding to give some space inside the black background
                  borderRadius: "1rem", // Rounded corners for the background
                }}
              >
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  autoplay={{ delay: 3000 }}
                  pagination={{ clickable: true }}
                  loop={true}
                  modules={[Autoplay, Pagination]} // Pass modules here
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={image}
                        alt={`slider-img-${index}`}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          borderRadius: "1rem",
                        }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default JobSeekerBottom;
