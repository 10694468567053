import { Box, Container, Typography, Button, styled } from "@mui/material";
// import BlockIcon from "@mui/icons-material/Block"; // Block icon for Ad-Free
// import VideocamIcon from "@mui/icons-material/Videocam"; // Videocam icon for Unlimited Videos
// import CameraAltIcon from "@mui/icons-material/CameraAlt"; // Camera icon for HD Quality
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
// import { useNavigate } from "react-router-dom";

const StyledContainer = styled(Container)(({ theme }) => ({
  color: "black ",
  padding: theme.spacing(8, 2),
  textAlign: "center",
}));

const PriceText = styled("span")({
  color: "#9F7AEA",
  fontWeight: "bold",
});

const FeaturesGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: theme.spacing(4),
  margin: theme.spacing(6, 0),
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
    gap: theme.spacing(3),
  },
}));

const FeatureItem = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "12px",
});

const features = [
  { icon: RadioButtonCheckedIcon, text: "Point 1" },
  { icon: RadioButtonCheckedIcon, text: "Point 2" },
  { icon: RadioButtonCheckedIcon, text: "Point 3" },
];

export default function AvailableFeatures({handleScrollTop}) {
 
  return (
    <StyledContainer maxWidth={false}>
      {/* <PlusBadge>
        <span className="lightning">⚡</span>
        <Typography variant="body2" sx={{ letterSpacing: 1 }}>
          
        </Typography>
      </PlusBadge> */}

      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: "2.5rem", md: "3.5rem" },
          fontWeight: "bold",
          mb: 4,
          fontFamily:"IBM plex sans"
        }}
      >
        Why Vacanzi <PriceText></PriceText>
      </Typography>

      <FeaturesGrid>
        {features.map((feature, index) => (
          <FeatureItem key={index}>
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,0.1)",
                borderRadius: "50%",
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <feature.icon size={24} />
            </Box>
            <Typography variant="h6" sx={{ fontFamily: "IBM plex sans" }}>
              {feature.text}
            </Typography>
          </FeatureItem>
        ))}
      </FeaturesGrid>

      <Button
        variant="contained"
        size="large"
        sx={{
          backgroundColor: "#18181b",
          color: "#ffffff",
          textTransform: "none",
          borderRadius: "0.7rem",
          fontFamily: "IBM plex sans",
          padding: "0.375rem 0.75rem",
          "&:hover": { backgroundColor: "#21867a" },
        }}
        onClick={() => handleScrollTop()}
      >
        Login Now
      </Button>
    </StyledContainer>
  );
}
