import React from "react";
import { Box, Typography, Container, Paper, Button } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { useNavigate } from "react-router-dom";

// Keyframes for the checkmark animation
const checkmarkAnimation = keyframes`
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const scaleAnimation = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

// Styled component for the animated checkmark
const AnimatedCheckmark = styled("svg")(({ theme }) => ({
  width: 100,
  height: 100,
  borderRadius: "50%",
  display: "block",
  strokeWidth: 2,
  stroke: theme.palette.success.main,
  strokeMiterlimit: 10,
  boxShadow: `inset 0px 0px 0px ${theme.palette.success.main}`,
  animation: `${scaleAnimation} 0.3s ease-in-out 0.9s both`,
  "& .checkmark__circle": {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: 2,
    strokeMiterlimit: 10,
    stroke: theme.palette.success.main,
    fill: "none",
    animation: `${checkmarkAnimation} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards`,
  },
  "& .checkmark__check": {
    transformOrigin: "50% 50%",
    strokeDasharray: 48,
    strokeDashoffset: 48,
    animation: `${checkmarkAnimation} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards`,
  },
}));

const JobSeekerPaymentSuccessful = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "background.paper",
          }}
        >
          <AnimatedCheckmark viewBox="0 0 52 52">
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </AnimatedCheckmark>

          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ mt: 2, color: "success.main" }}
          >
            Payment Successful!
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 2, mb: 3, textAlign: "center" }}
          >
            Thank you for your payment. Your transaction has been completed
            successfully.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="/"
            sx={{ mt: 2 }}
            onClick={() => navigate("/")}
          >
            Go to Homepage
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default JobSeekerPaymentSuccessful;
