import React from 'react';
import { Box, Typography, Grid, Container, useTheme } from '@mui/material';
import girlMobile from '../../../assets/images/VacanziMobile.png';

// const employers = [
//     { name: "Charlie Mark", role: "CEO", company: "ABC Corp" },
//     { name: "Chris Park", role: "HR Manager", company: "XYZ Ltd" },
// ];

const FeaturedEmployers = () => {

    return (
        <Box sx={{ backgroundColor: '#fff', py: 4 }}>
            <Container maxWidth="xl">
                <Box mt={4}>
                   
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Box sx={{ textAlign: 'center' }}>
                                <img
                                    src={girlMobile}
                                    alt="mobile girl "
                                    style={{
                                        maxWidth: '100%',
                                        height: 'auto'
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4"  sx={{ fontFamily:'IBM plex sans'  }} component="h1" gutterBottom>
                            Thousands of Short Videos Posted by Recruiters                                   </Typography>
                            <Typography variant="h5">Keep Scrolling for Jobs, Download the App Now!</Typography>
                           
                          
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default FeaturedEmployers;
