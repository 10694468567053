import React from "react";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@mui/material";
import { Instagram, Facebook, Twitter, YouTube } from "@mui/icons-material";
import VacanziLogo from "../../../assets/icons/logos/vacanzi.png";

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        color: "#423bfa",
        width: "100%",
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          sx={{ marginRight:20}}
          alignItems="flex-start" // Align items to the top
        >
          {/* About Us Section */}
          <Grid item xs={12} md={2.5}>
            <Link
              href="/about-vacanzi"
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                textDecoration:'none',
                color: "#000",
                height: "100%", // Ensure consistent height
                display: "flex",
                alignItems: "center", // Vertically center the text
              }}
            >
              About Us
            </Link>
            <Link
              href="/carerers"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Careers
            </Link>
            <Link
              href="/trust&safety"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Trust & safety
            </Link>
            <Link
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Fraud alert
            </Link>
            <Link
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Report issue
            </Link>
          </Grid>

          {/* For Job Seekers Section */}
          <Grid item xs={12} md={2.5}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                color: "#000",
                height: "100%", // Ensure consistent height
                display: "flex",
                alignItems: "center", // Vertically center the text
              }}
            >
              For Job Seekers
            </Typography>
            <Link
              href="/signinsignup"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              JobSeeker Login
            </Link>
            <Link
              href="/job-seeker-faq"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              FAQ
            </Link>
            <Link
              href="/privacy-policy"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Privacy Policy
            </Link>
            {/* <Link
              href="/payment-policy"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Payment Policy
            </Link> */}
            <Link
              href="/terms-&-cons"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Terms & Conditions
            </Link>
          </Grid>

          {/* For Job Seekers Section */}
          <Grid item xs={12} md={2}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                color: "#000",
                height: "100%", // Ensure consistent height
                display: "flex",
                alignItems: "center", // Vertically center the text
              }}
            >
              For Employers
            </Typography>
            <Link
              href="/employerSignIn"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Employer Login
            </Link>

            <Link
              href="/job-seeker-faq"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              FAQ
            </Link>
            <Link
              href="/privacy-policy"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Privacy Policy
            </Link>
            {/* <Link
              href="/payment-policy"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Payment Policy
            </Link> */}
            <Link
              href="/terms-&-cons"
              sx={{
                display: "block",
                mb: 1,
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Terms & Conditions
            </Link>
          </Grid>
          {/* Contact Us Section */}
          <Grid item xs={12} md={2}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                color: "#000",

                height: "100%", // Ensure consistent height
                display: "flex",
                alignItems: "center", // Vertically center the text
              }}
            >
              Contact Us
            </Typography>

            <Typography
              variant="body2"
              sx={{
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
                color: "#000",
              }}
            >
              sales@vacanzi.com
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#000",

                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
              }}
            >
              support@vacanzi.com
            </Typography>
          </Grid>
          
          {/* Logo and Social Media Section */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                mt: 2,
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: "center",
                mb: isSmallScreen ? 2 : 2,
              }}
            >
              <Avatar
                src={VacanziLogo}
                alt="Vacanzi Logo"
                sx={{
                  width: 66,
                  height: 66,
                  mr: isSmallScreen ? 0 : -1,
                  mb: isSmallScreen ? 1 : 0,
                }}
              />
              <Typography
                variant="h2"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 1,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "GlanceSansBold",
                  color: "#423bfa",
                  textDecoration: "none",
                  fontSize: "2.125rem",
                }}
              >
                Vacanzi
              </Typography>
            </Box>
            <Typography variant="h6" color="#000" gutterBottom>
              Let's Connect
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: 2, ml: 0 }}
            >
              <Link href="#" color="inherit" aria-label="Instagram">
                <Instagram sx={{ color: "#000", fontSize: "50px" }} />
              </Link>
              <Link href="#" color="inherit" aria-label="Facebook">
                <Facebook sx={{ color: "#000", fontSize: "50px" }} />
              </Link>
              <Link href="#" color="inherit" aria-label="Twitter">
                <Twitter sx={{ color: "#000", fontSize: "50px" }} />
              </Link>
              <Link href="#" color="inherit" aria-label="YouTube">
                <YouTube sx={{ color: "#000", fontSize: "50px" }} />
              </Link>
            </Box>
          </Grid>
        </Grid>

        {/* Footer Copyright Section */}
        <Box sx={{ pt: 2 }}>
          <Typography
            variant="body2"
            align="center"
            sx={{ fontFamily: "IBM Plex Sans", color: "#000" }}
          >
            ©2025 Vacanzi Rights Reserved
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
