import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { debounce } from "lodash"; // For debouncing the search API call
import { getTopCitiesApi } from "../../api/common";

const CompanySidebar = ({
  companyTypeFilter,
  handleCompanyTypeFilter,
  locationFilter,
  handleLocationFilter,
  selectedIndustry,
  handleIndustryFilter,
}) => {
  // State to manage visibility of additional options
  const [showMoreCompanyType, setShowMoreCompanyType] = useState(false);

  // State for city search
  const [cityQuery, setCityQuery] = useState("");
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [errorCities, setErrorCities] = useState(null);

  // Additional options for each filter with separate values and labels
  const companyTypeOptions = [
    { value: "company", label: "Company" },
    { value: "consultant", label: "Consultant" },
    { value: "mnc", label: "MNC" },
    { value: "soleProprietorship", label: "Sole Proprietorship" },
    { value: "partnerShip", label: "Partnership" },
    { value: "limitedLiabilityPartnership", label: "Limited Liability Partnership" },
    { value: "privateLimitedCompany", label: "Private Limited Company" },
    { value: "publicLimitedCompany", label: "Public Limited Company" },
    { value: "cooperativeSociety", label: "Cooperative Society" },
    { value: "ngo", label: "NGO" },
  ];


  const fetchCities = debounce(async (query) => {
    setLoadingCities(true);
    setErrorCities(null);

    try {
      const response = await getTopCitiesApi(query);
      setCities(response.cities); // Assuming the API returns an array of cities
    } catch (error) {
      console.error("Error fetching cities:", error.message);
      setErrorCities("Failed to fetch cities. Please try again.");
    } finally {
      setLoadingCities(false);
    }
  }, 300);

  // Cancel the debounced function when the component unmounts
  useEffect(() => {
    return () => {
      fetchCities.cancel();
    };
  }, [fetchCities]); // Cleanup on unmount

  // Fetch cities when the search query changes (only when it's not empty)
  useEffect(() => {
  if (cityQuery.trim()) {
      fetchCities(cityQuery);
    } else {
      setCities([]); // Clear cities if the query is empty
    }
  }, [cityQuery]); // Trigger effect when cityQuery changes

  // Handle city selection
  const handleCitySelection = (cityName) => {
    setCityQuery(cityName); // Update the TextField with the selected city name
    handleLocationFilter(cityName); // Update the location filter state
    setCities([]); // Clear the cities list after selection
  };

  return (
    <Box sx={{ backgroundColor: "#fff", p: 2, borderRadius: 2, boxShadow: 1 }}>
      {/* City Search Bar */}
      <Accordion
        defaultExpanded
        sx={{
          boxShadow: "none",
          backgroundColor: "transparent",
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
          sx={{
            padding: 0,
            minHeight: "40px",
            "& .MuiAccordionSummary-content": {
              margin: "0",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans, sans-serif",
              color: "#333",
            }}
          >
            Location
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0 0 0" }}>
          <TextField
            fullWidth
            placeholder="Search for a city..."
            value={cityQuery}
            onChange={(e) => setCityQuery(e.target.value)}
            sx={{ mb: 2 }}
          />
          {loadingCities && <Typography variant="body2">Loading cities...</Typography>}
          {errorCities && (
            <Typography variant="body2" color="error">
              {errorCities}
            </Typography>
          )}
          <List>
            {cities.map((city, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  backgroundColor:
                    locationFilter === city.name ? "#f0f0f0" : "transparent",
                  borderRadius: 1,
                }}
              >
                <ListItemButton onClick={() => handleCitySelection(city.name)}>
                  <ListItemText primary={city.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{ my: 2, backgroundColor: "#ddd" }} />

      {/* Company Type Filter */}
      <Accordion
        defaultExpanded
        sx={{
          boxShadow: "none",
          backgroundColor: "transparent",
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
          sx={{
            padding: 0,
            minHeight: "40px",
            "& .MuiAccordionSummary-content": {
              margin: "0",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              fontFamily: "IBM Plex Sans, sans-serif",
              color: "#333",
            }}
          >
            Company Type
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0 0 0" }}>
          <RadioGroup
            value={companyTypeFilter} // Ensure this matches the selected value
            onChange={(e) => handleCompanyTypeFilter(e.target.value)} // Use the updated handler
          >
            {(showMoreCompanyType ? companyTypeOptions : companyTypeOptions.slice(0, 3)).map(
              (option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value} // Use the value for filtering
                  control={<Radio />}
                  label={option.label} // Display the label
                />
              )
            )}
          </RadioGroup>
          {companyTypeOptions.length > 3 && (
            <Button
              size="small"
              sx={{ textTransform: "none", color: "#007bff", p: 0 }}
              onClick={() => setShowMoreCompanyType(!showMoreCompanyType)}
            >
              {showMoreCompanyType ? "View Less" : "View More"}
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CompanySidebar;
