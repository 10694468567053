import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography, Box, Container } from "@mui/material";
import { VerifyPayementApi } from "../../api/payment";

const PaymentCallback = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const verifyPayment = async () => {
      const merchantTransactionId = localStorage.getItem(
        "merchantTransactionId"
      );

      if (!merchantTransactionId) {
        console.error("No transaction ID found in local storage");
        navigate("/paymentFailure");
        return;
      }

      try {
        const response = await VerifyPayementApi(token, merchantTransactionId);
        if (response) {
          console.log("response", response?.payment);
        }
        setTimeout(() => {
          if (response?.payment?.status === "success") {
            navigate("/paymentSuccess");
          } else {
            navigate("/paymentFailure", {
              state: { errorMessage: response?.message || "Payment failed" },
            });
          }
        }, 3000);
      } catch (error) {
        console.error("Error verifying payment:", error);
        navigate("/paymentFailure", {
          state: { errorMessage: error.message },
        });
      } finally {
        localStorage.removeItem("merchantTransactionId");
      }
    };

    verifyPayment();
  }, [navigate, token]);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress size={60} sx={{ mb: 2 }} />
        <Typography variant="h5" component="h1" gutterBottom>
          Verifying your payment
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Please wait while we process your transaction...
        </Typography>
      </Box>
    </Container>
  );
};

export default PaymentCallback;
