import React from "react";
import {
  Paper,
  Avatar,
  Typography,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import avatarmen from "../../../assets/images/avatarmen.jpg";
import SendIcon from "@mui/icons-material/Send";

const JobSeekerChatDetails = ({
  chatMessages,
  supportUser,
  messageData,
  handleInputChange,
  handleSend,
  handleStatusChange,
  statusMessage,
}) => {
  console.log("supportUser", supportUser);

  return (
    <>
      {supportUser ? (
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "80vh", // Full viewport height
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          {/* Fixed Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 2,
              borderBottom: "1px solid #ddd",
              background: "#eaeaea",
            }}
          >
            {/* Avatar */}
            <Avatar sx={{ marginRight: 2 }} src={supportUser?.profileImage}>
              P
            </Avatar>

            {/* Organization Details */}
            <Box>
              <Typography variant="body1" fontWeight="bold">
                {supportUser?.fullName || "Unknown Organization"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Last seen{" "}
                {chatMessages?.[0]?.updatedAt
                  ? new Date(chatMessages[0].updatedAt).toLocaleString()
                  : "unknown"}
              </Typography>
            </Box>
          </Box>

          {/* Scrollable Chat Content */}
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              padding: 2,
              background: "#f2f2f2",
            }}
          >
            {chatMessages && chatMessages.length > 0 ? (
              chatMessages.map((chat, index) => {
                const sender = chat.sender || {}; // Ensure sender is not undefined
                const isSenderUser = ["jobseeker"].includes(chat.senderRole);
                return (
                  <Box
                    key={index}
                    sx={{
                      marginBottom: 2,
                      display: "flex",
                      flexDirection: isSenderUser ? "row-reverse" : "row", // Sender's side based on role
                      alignItems: "center",
                    }}
                  >
                    {/* Avatar */}
                    <Avatar
                      sx={{
                        margin: isSenderUser ? "0 12px 0 0" : "0 0 0 12px",
                      }}
                      src={
                        sender.rcEmail
                          ? `https://www.gravatar.com/avatar/${sender.rcEmail}`
                          : avatarmen
                      }
                    >
                      {sender.rcName?.charAt(0) || "P"}
                    </Avatar>

                    {/* Message Content */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "70%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          backgroundColor: isSenderUser ? "#f0f0f0" : "#d1e7dd",
                          padding: "8px 12px",
                          borderRadius: "8px",
                          wordBreak: "break-word",
                        }}
                      >
                        {chat.messageContent}
                      </Typography>

                      {/* Message Timestamp */}
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          marginTop: "4px",
                          alignSelf: isSenderUser ? "flex-start" : "flex-end",
                        }}
                      >
                        {new Date(chat.createdAt).toLocaleTimeString()}
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  No conversation found
                </Typography>
              </Box>
            )}
          </Box>

          {/* Fixed Message Input */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0.5rem",
              borderTop: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              position: "sticky", // Keeps it fixed at the bottom of the container
              bottom: 0,
            }}
          >
            <TextField
              fullWidth
              placeholder="Enter message here..."
              variant="outlined"
              value={messageData}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevents the default form submission behavior
                  handleSend(); // Calls your send function
                }
              }}
              slotProps={{
                input: {
                  sx: {
                    borderRadius: "2rem",
                    backgroundColor: "white",
                  },
                },
              }}
            />
            {statusMessage !== "closed" && (
              <IconButton onClick={handleSend} sx={{ marginLeft: "0.5rem" }}>
                <SendIcon color="primary" />
              </IconButton>
            )}
          </Box>
        </Paper>
      ) : (
        <Typography>Select the Chat to Start conversation</Typography>
      )}
    </>
  );
};

export default JobSeekerChatDetails;
