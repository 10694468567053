import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Sample banner images
const bannerImages = [
  { id: 1, url: "/vbanner2.avif", alt: "Banner 1" },
  { id: 2, url: "/vbanner2.avif", alt: "Banner 2" },
  { id: 3, url: "/vbanner2.avif", alt: "Banner 3" },
  // { id: 4, url: "/vbanner2.webp", alt: "Banner 4" },
  // { id: 5, url: "/vbanner2.webp", alt: "Banner 5" },
  // { id: 6, url: "/vbanner2.webp", alt: "Banner 6" },
];

const WhyVacanzi = () => {
  const [selectedButton, setSelectedButton] = useState("jobSeekers");

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  // Slider settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <ArrowForwardIcon
        sx={{
          fontSize: "2rem",
          color: "#052341FF",
          cursor: "pointer",
          position: "absolute",
          ":hover": {
            color: "#59A3EDFF",
          },
          top: "50%",
          right: 20,
          transform: "translateY(-50%)",
          zIndex: 1,
        }}
      />
    ),
    prevArrow: (
      <ArrowBackIcon
        sx={{
          fontSize: "2rem",
          color: "#052341FF",
          cursor: "pointer",
          position: "absolute",
          ":hover": {
            color: "#59A3EDFF",
          },
          top: "50%",
          left: 20,
          transform: "translateY(-50%)",
          zIndex: 1,
        }}
      />
    ),
    appendDots: (dots) => (
      <Box style={{ bottom: "20px" }}>
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </Box>
    ),
    customPaging: (i) => (
      <Box
        style={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          background: "#59A3EDFF",
        }}
      />
    ),
  };

  return (
    <Box
      sx={{
        maxWidth: "100vw",
        width: "100vw",
        backgroundColor: "#ffffff",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
   

    
      {/* Carousel for Banners */}
      <Box sx={{ width: "90vw", marginTop: 4 }}>
        <Slider {...settings}>
          {bannerImages.map((banner) => (
            <Box key={banner.id} sx={{ padding: "10px" }}>
              <Box
                sx={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  height: "auto",
                  position: "relative",
                  cursor: "pointer",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                  ":hover": {
                    transform: "scale(1.01)",
                    // boxShadow: "1px 1px 5px 3px #8f8697;",
                  },
                }}
              >
                <img
                  src={banner.url}
                  alt={banner.alt}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default WhyVacanzi;