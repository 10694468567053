import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem, Chip } from "@mui/material";

const JobSeekerCaseTwo = ({
  formData,
  handleCurrentlyWorkingChange,
  // handleJobRoleChange,
  handleLanguageChange,
  handleIndustryChange,
  handleSkillsChange,
  setFormData,
}) => {
  // const jobRoles = ["Developer", "Manager", "Designer"];
  const industries = ["Tech", "Finance", "Education"];
  const skills = ["JavaScript", "React", "Node.js", "Python", "Java"];

  const [servingNoticePeriod, setServingNoticePeriod] = useState(
    formData.servingNoticePeriod || false
  );

  const [newLanguage, setNewLanguage] = useState("");
  const handleTotalExpChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      totalExp: e.target.value,
    }));
  };

  const knownLanguages = formData.knownLanguage || [];

  const handleTotalExpInMonthsChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      totalExpInMonths: e.target.value,
    }));
  };

  const handleDesignationChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      currentDesignation: e.target.value,
    }));
  };

  const handleCompanyChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      currentCompany: e.target.value,
    }));
  };

  const handleStartDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      startDate: e.target.value,
    }));
  };

  const handleEndDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      endDate: e.target.value,
    }));
  };

  const handleCurrentCtcChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      currentCtc: e.target.value,
    }));
  };

  const handleRemoveLanguage = (language) => {
    const updatedLanguages = formData.knownLanguage.filter(
      (lang) => lang !== language
    );
    handleLanguageChange(null, updatedLanguages);
  };

  const handleAddLanguage = () => {
    if (newLanguage && !formData.knownLanguage.includes(newLanguage)) {
      setFormData((prevData) => ({
        ...prevData,
        knownLanguage: [...(prevData.knownLanguage || []), newLanguage],
      }));
      setNewLanguage("");
    }
  };

  const handleLocationChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      currentLocation: e.target.value,
    }));
  };

  const handlePreferredLocationChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      preferredLocation: e.target.value,
    }));
  };
  useEffect(() => {
    console.log("Updated noticePeriod:", formData.noticePeriod);
  }, [formData.noticePeriod]);

  const handleNoticePeriodChange = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      noticePeriod: value, // Store numeric value (1, 2, 3, etc.)
    }));
  };

  const handleDescriptionChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      description: e.target.value,
    }));
  };

  const handleExpectedCtcChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      expectedCtc: e.target.value,
    }));
  };

  const handleExperienceTypeChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      experienceType: e.target.value, // 'experienced' or 'freshers'
    }));
  };

  const handleServingNoticePeriodChange = (isServing) => {
    setServingNoticePeriod(isServing);
    setFormData((prevData) => ({
      ...prevData,
      servingNoticePeriod: isServing, // Update the form data with the new value
    }));
  };

  console.log("formData in casetwo", formData);

  return (
    <Box sx={{ backgroundColor: "#ffffff", padding: 2, borderRadius: 4 }}>
      {formData.hasWorkExperience && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Total Years of Experience
                <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Years"
                variant="outlined"
                value={formData.totalExp}
                onChange={handleTotalExpChange}
                size="small"

              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Total Months of Experience
                <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Months"
                variant="outlined"
                value={formData.totalExpInMonths}
                onChange={handleTotalExpInMonthsChange}
                size="small"

              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Experience Type
              </Typography>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"

              >

                <Select
                  value={formData.experienceType || ""}
                  onChange={handleExperienceTypeChange}
                >
                  <MenuItem value="experienced">Experienced</MenuItem>
                  <MenuItem value="fresher">Freshers</MenuItem>
                </Select>
              </FormControl>
            </Grid>


            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Job Title
                <Typography
                  component="span"
                  sx={{ color: 'red', marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter job title"
                variant="outlined"
                value={formData.currentDesignation || ""}
                onChange={handleDesignationChange}
                size="small"

              />
            </Grid>

            {/* <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold",  }}
              >
                Job Role
              </Typography>
              <Autocomplete
                multiple
                options={jobRoles}
                value={formData.jobRole || []}
                onChange={handleJobRoleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Job Role"
                    size="small"
                    sx={{  }}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >

                Company Name
                <Typography
                  component="span"
                  sx={{ color: 'red', marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter company name"
                variant="outlined"
                value={formData.currentCompany || ""}
                onChange={handleCompanyChange}
                size="small"

              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Industry
                <Typography
                  component="span"
                  sx={{ color: 'red', marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Autocomplete
                options={industries}
                value={formData.industry || ""}
                onChange={handleIndustryChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Industry"
                    size="small"

                  />
                )}
              />

              {/* current location */}
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold" }}
              >
                Job Description
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Describe your experience"
                variant="outlined"
                value={formData.description || ""}
                onChange={handleDescriptionChange}
                size="small"
                multiline
                rows={4}

              />
            </Grid>



            <Grid item xs={12} md={6}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Current CTC
                <Typography
                  component="span"
                  sx={{ color: 'red', marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter current CTC"
                variant="outlined"
                name="ctc"
                value={formData.currentCtc || ""}
                onChange={handleCurrentCtcChange}
                size="small"

              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Expected CTC
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter your expected CTC"
                variant="outlined"
                value={formData.expectedCtc || ""}
                onChange={handleExpectedCtcChange}
                size="small"

              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Current Location
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter your current location"
                variant="outlined"
                value={formData.currentLocation || ""}
                onChange={handleLocationChange}
                size="small"

              />
            </Grid>

            {/* preferedLocation */}

            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Preferred Location
                <Typography
                  component="span"
                  sx={{ color: 'red', marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter your preferred location"
                variant="outlined"
                value={formData.preferredLocation || ""}
                onChange={handlePreferredLocationChange} // Handle change for preferred location
                size="small"

              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Skills
              </Typography>
              <Autocomplete
                multiple
                options={skills} // `skills` is an array of possible options
                value={formData.skills} // This binds the selected skills to formData.skills
                onChange={handleSkillsChange} // This updates the skills when changed
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Skills"
                    size="small"

                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Are you currently working in this company?
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant={
                      formData.currentlyWorking
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleCurrentlyWorkingChange(true)}
                    sx={{
                      borderRadius: 6,
                      backgroundColor: formData.currentlyWorking
                        ? "#423BFA"
                        : "transparent",
                      border: "1px solid #ccc",
                      color: formData.currentlyWorking
                        ? "#fff"
                        : "#000",
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">
                      Yes
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={
                      !formData.currentlyWorking
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleCurrentlyWorkingChange(false)}
                    sx={{
                      borderRadius: 6,
                      backgroundColor: !formData.currentlyWorking
                        ? "#423BFA"
                        : "transparent",
                      border: "1px solid #ccc",
                      color: !formData.currentlyWorking
                        ? "#fff"
                        : "#000",
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">
                      No
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Start Date
              </Typography>
              <TextField
                fullWidth
                type="date"
                variant="outlined"
                value={formData.startDate || ""}
                onChange={handleStartDateChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}

              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                End Date
              </Typography>
              <TextField
                fullWidth
                type="date"
                variant="outlined"
                value={formData.endDate || ""}
                onChange={handleEndDateChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}

              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Serving Notice Period
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant={servingNoticePeriod ? "contained" : "outlined"}
                    onClick={() => handleServingNoticePeriodChange(true)}
                    sx={{
                      borderRadius: 6,
                      backgroundColor: servingNoticePeriod
                        ? "#423BFA"
                        : "transparent",
                      border: "1px solid #ccc",
                      color: servingNoticePeriod ? "#fff" : "#000",
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">
                      Yes
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={!servingNoticePeriod ? "contained" : "outlined"}
                    onClick={() => handleServingNoticePeriodChange(false)}
                    sx={{
                      borderRadius: 6,
                      backgroundColor: !servingNoticePeriod
                        ? "#423BFA"
                        : "transparent",
                      border: "1px solid #ccc",
                      color: !servingNoticePeriod ? "#fff" : "#000",
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">
                      No
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* Notice Period */}
            <Grid item xs={12} md={8}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Notice Period
              </Typography>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"

              >
                <InputLabel>Notice Period</InputLabel>
                <Select
                  value={formData.noticePeriod || ""} // Use numeric value (1, 2, 3, etc.)
                  onChange={handleNoticePeriodChange} // Handle change for notice period
                  label="Notice Period"
                >
                  <MenuItem value={1}>1 Month</MenuItem>
                  <MenuItem value={2}>2 Months</MenuItem>
                  <MenuItem value={3}>3 Months</MenuItem>
                  <MenuItem value={4}>4 Months</MenuItem>
                  <MenuItem value={5}>5 Months</MenuItem>
                  <MenuItem value={6}>6 Months</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* //language */}

            <Grid item xs={12} >
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold", }}
              >
                Add a New Language
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
                <TextField
                  fullWidth
                  value={newLanguage}
                  onChange={(e) => setNewLanguage(e.target.value)} // Handle input changes
                  placeholder="Enter a language"
                  variant="outlined"
                  size="small"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddLanguage}
                  sx={{
                    borderRadius: 6,
                    backgroundColor: '#423BFA',
                    fontWeight: 'bold',
                    border: "1px solid #ccc",
                    color: "#fff",
                  }}
                >
                  Add
                </Button>
              </Box>
            </Grid>
            {/* Display Selected Languages as Chips */}
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {knownLanguages.length > 0 ? (
                  knownLanguages.map((language) => (
                    <Chip
                      key={language}
                      label={language}
                      onDelete={() => handleRemoveLanguage(language)}
                      color="primary"
                      size="small"
                    />
                  ))
                ) : (
                  <Typography>No languages added yet</Typography> // Fallback message
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default JobSeekerCaseTwo;
