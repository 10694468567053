import React from 'react';
import { Box, Skeleton } from '@mui/material';

const TitleCardSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#F7F7F7FF', // Match the background color
        borderRadius: 4,
        p: 3, // Add padding
        position: 'relative',
        width: '314px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add a subtle shadow
      }}
    >
      {/* Shimmer Effect Styles */}
      <style>
        {`
          @keyframes shimmer {
            0% { background-position: -200% 0; }
            100% { background-position: 200% 0; }
          }

          .shimmer {
            background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
            background-size: 200% 100%;
            animation: shimmer 5s infinite;
          }
        `}
      </style>

      {/* Logo Skeleton in the top-right corner */}
      <Box
        sx={{
          position: 'absolute',
          top: 16, // Adjust top position as needed
          right: 16, // Adjust right position as needed
        }}
      >
        <Skeleton
          variant="rectangular"
          width={60}
          height={50}
          sx={{
            borderRadius: '30%',
            animation: 'shimmer 1.5s infinite',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
          }}
        />
      </Box>

      {/* Title and Content Skeleton */}
      <Box
        sx={{
          textAlign: 'left',
          paddingRight: '100px', // Add padding to prevent text overlap with the logo
        }}
      >
        {/* Organization Name Skeleton */}
        <Skeleton
          variant="text"
          width="60%"
          height={40}
          sx={{
            mb: 1,
            animation: 'shimmer 1.5s infinite',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
          }}
        />

        {/* Additional Content Skeleton */}
        <Skeleton
          variant="text"
          width="80%"
          height={20}
          sx={{
            mb: 1,
            animation: 'shimmer 1.5s infinite',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
          }}
        />
        <Skeleton
          variant="text"
          width="50%"
          height={20}
          sx={{
            animation: 'shimmer 1.5s infinite',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
          }}
        />
      </Box>
    </Box>
  );
};

export default TitleCardSkeleton;