import React from 'react';
import { Box, Skeleton } from '@mui/material';

const LogoCarouselSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '10px',
        marginBottom: '16px',
        maxWidth: '83%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          animation: 'scroll 20s linear infinite',
          listStyle: 'none',
          padding: 0,
          margin: 0,
          '@keyframes scroll': {
            '0%': { transform: 'translateX(0)' },
            '100%': { transform: 'translateX(-50%)' },
          },
        }}
      >
        {[...Array(2)].map((_, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1px',
            }}
          >
            {[...Array(4)].map((_, idx) => (
              <Box
                key={idx}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                  padding: '10px',
                }}
              >
                {/* Skeleton for the first logo */}
                <Skeleton
                  variant="rectangular"
                  width={94}
                  height={36}
                  sx={{
                    borderRadius: '10px',
                    marginTop: '6px',
                    marginLeft: '10px',
                    background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
                    backgroundSize: '200% 100%',
                    animation: 'shimmer 1.5s infinite',
                    '@keyframes shimmer': {
                      '0%': { backgroundPosition: '-200% 0' },
                      '100%': { backgroundPosition: '200% 0' },
                    },
                  }}
                />
                {/* Skeleton for the second logo */}
                <Skeleton
                  variant="rectangular"
                  width={94}
                  height={36}
                  sx={{
                    borderRadius: '10px',
                    marginTop: '6px',
                    marginLeft: '10px',
                    background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
                    backgroundSize: '200% 100%',
                    animation: 'shimmer 5s infinite',
                  }}
                />
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LogoCarouselSkeleton;