import React from "react";
// import NavBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
import JobsApplied from "./JobsApplied";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

const JobSeekerAppliedJobs = () => {
  return (
    <>
      <NewNavBar />
      <JobsApplied />
    </>
  );
};

export default JobSeekerAppliedJobs;
