const manageUserstyles = {
  checkBox: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.75rem",
    },
  },
  submitButton: {
    bgcolor: "#423bfa",
    color: "white",
    borderRadius: 2,
    ":hover": {
      backgroundColor: "#423bfa",
    },
  },
  select: {
    width: "100%",
    height: "60%",
  },
};
export default manageUserstyles;
