import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function JobPostPage() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        padding: 2,
        position: "relative",
        height: "100vh",
        backgroundImage: "url(/jobpostbg.jpeg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Button
        variant="contained"
        sx={{
          position: "absolute",
          bottom: 0,
          right: 5,
          backgroundColor: "green",
        }}
        onClick={() => navigate("/subcriptions")}
      >
        View Subscriptions
      </Button>
    </Box>
  );
}

export default JobPostPage;
