import React, { useEffect, useState } from "react";
import {
  Card,
  Dialog,
  DialogContent,
  Radio,
  Box,
  CardContent,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { jwtDecode } from "jwt-decode";
import baseURL from "../../api/baseUrl";

const SelectJobDialog = ({
  openSelectJob,
  handleSelectJob,
  inviteData,
  handleCloseDialog,
  sendInvite,
}) => {
  // const [userData, setUserData] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]); // State for filtered jobs
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const token = localStorage.getItem("token");

  // Fetch job postings
  const fetchJobPostings = async (userData) => {
    try {
      const { role, id, token, organizationId, EmployerOranizationId } = userData;

      let url;
      if (role === "recruiter_admin") {
        url = `${baseURL}/jobpost/${organizationId || EmployerOranizationId}`;
      } else if (role === "recruiter") {
        url = `${baseURL}/jobpost/by/${id}`;
      } else {
        console.error("Invalid role:", role);
        return;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        setJobs(data);
        setFilteredJobs(data); // Initialize filtered jobs with all jobs
      } else {
        console.error("Error fetching job postings:", data.message);
      }
    } catch (error) {
      console.error("Error fetching job postings:", error);
    }
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  
    // Filter jobs based on search query
    const filtered = jobs.filter(
      (job) =>
        job.jobTitle?.toLowerCase().includes(query) || // Use optional chaining
        job.jobRole?.toLowerCase().includes(query) || // Use optional chaining
        job.ctc?.toLowerCase().includes(query) || // Use optional chaining
        job.requiredExperience?.toLowerCase().includes(query) // Use optional chaining
    );
    setFilteredJobs(filtered);
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userData = {
          organizationId: decodedToken.organizationId || localStorage.getItem("organizationId"),
          role: decodedToken.role || localStorage.getItem("role"),
          token,
          id: decodedToken.id,
          fullName: decodedToken.name,
        };
        // setUserData(userData);
        fetchJobPostings(userData);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [token]);

  return (
    <Dialog
      open={openSelectJob}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "16px",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: 24,
              fontFamily: "IBM Plex Sans, sans-serif",
              fontWeight: 600,
              color: "#423BFA",
            }}
          >
            Select a Job for Invite
          </Typography>
          <CloseIcon
            sx={{ fontSize: 30, cursor: "pointer", color: "#666" }}
            onClick={handleCloseDialog}
          />
        </Box>

        {/* Search Box */}
        <TextField
          fullWidth
          placeholder="Search jobs..."
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            mb: 3,
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              "& fieldset": {
                borderColor: "#ddd",
              },
              "&:hover fieldset": {
                borderColor: "#423BFA",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#423BFA",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#666" }} />
              </InputAdornment>
            ),
          }}
        />

        {/* Job List */}
        {filteredJobs.map((job) => (
          <Card
            key={job._id}
            sx={{
              mb: 2,
              borderRadius: "12px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
              transition: "box-shadow 0.3s, transform 0.3s",
              "&:hover": {
                boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
                transform: "translateY(-2px)",
              },
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    gutterBottom
                    sx={{
                      color: "#423BFA",
                      fontSize: 18,
                      fontWeight: 600,
                      mb: 1,
                    }}
                  >
                    {job.jobTitle}
                  </Typography>
                  <Typography
                    sx={{ color: "#666", fontSize: 14, mb: 0.5 }}
                  >
                    CTC: {job.ctc}
                  </Typography>
                  <Typography
                    sx={{ color: "#666", fontSize: 14, mb: 0.5 }}
                  >
                    Experience: {job.requiredExperience}
                  </Typography>
                  <Typography
                    sx={{ color: "#666", fontSize: 14 }}
                  >
                    Job Role: {job.jobRole}
                  </Typography>
                </Box>
                <Radio
                  checked={inviteData.jobId === job._id}
                  onChange={handleSelectJob}
                  value={job._id}
                  sx={{
                    color: "#423BFA",
                    "&.Mui-checked": {
                      color: "#423BFA",
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        ))}

        {/* Send Invite Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            bgcolor: "white",
            position: "sticky",
            bottom: 0,
            pt: 2,
            pb: 1,
            zIndex: 10,
          }}
        >
          <Button
            variant="contained"
            onClick={sendInvite}
            sx={{
              textTransform: "none",
              borderRadius: "12px",
              backgroundColor: "#423BFA",
              fontFamily: "IBM Plex Sans, sans-serif",
              fontSize: "16px",
              fontWeight: 500,
              padding: "8px 24px",
              "&:hover": {
                backgroundColor: "#3229c8",
              },
            }}
          >
            Send Invite
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SelectJobDialog;