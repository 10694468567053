import React from "react";
import {
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Grid,
  Skeleton,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";

const RecentSearches = ({ savedSearch, handleFillSearch, handleSearch }) => {
  // Limit to the last 5 items
  const recentSearches = savedSearch?.slice(0, 5);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 2,
        mt: 2,
        borderRadius: 1,
        width: "100%", // Take maximum available width
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <HistoryIcon sx={{ mr: 1, fontSize: 20, opacity: 0.7 }} />
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          fontSize="20px"
          sx={{ opacity: 1.1 }}
        >
          Recent searches
        </Typography>
      </Box>

      {/* Recent Searches List */}
      {recentSearches && recentSearches.length > 0 ? (
        <List
          sx={{
            maxHeight: 300,
            overflow: "hidden", // Initially hide overflow
            transition: "overflow 0.3s ease", // Smooth transition
            "&:hover": {
              overflowY: "auto", // Show overflow when hovering
            },
            "&::-webkit-scrollbar": {
              width: "8px", // Custom scrollbar width
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#BDB7B7FF", // Scrollbar thumb color
              borderRadius: "4px", // Rounded scrollbar thumb
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#A6A6A6", // Scrollbar thumb hover color
            },
          }}
        >
          {recentSearches.map((search, index) => (
            <ListItem key={index} alignItems="flex-start">
              <Grid container direction="column" spacing={0}>
                {/* Search Details */}
                <Grid item>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: 14 }}>
                        {`${
                          search?.searchQuery?.keyword
                            ? search?.searchQuery?.keyword
                            : "No keyword"
                        } | 
  ${
    search?.searchQuery?.experienceType
      ? search?.searchQuery?.experienceType
      : "No experience type"
  } | 
  ${
    search?.searchQuery?.currentLocation
      ? search?.searchQuery?.currentLocation
      : "No current location"
  } | 
  ${
    search?.searchQuery?.preferredLocation
      ? search?.searchQuery?.preferredLocation
      : "No preferred location"
  }`}
                      </Typography>
                    }
                  />
                </Grid>
                {/* Buttons */}
                <Grid item>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      size="small"
                      sx={{
                        textTransform: "none",
                        color: "#0e0593",
                      }}
                      onClick={() => handleFillSearch(search)}
                    >
                      <Typography variant="body2" sx={{ fontSize: 14 }}>
                        Fill search
                      </Typography>
                    </Button>
                    <Button
                      size="small"
                      sx={{
                        textTransform: "none",
                        color: "#0e0593",
                      }}
                      onClick={() => handleSearch(search)}
                    >
                      <Typography variant="body2" sx={{ fontSize: 14 }}>
                        Search profiles
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      ) : savedSearch && savedSearch.length === 0 ? (
       
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 100,
          }}
        >
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            No recent searches
          </Typography>
        </Box>
      ) : (
        // Show skeleton loading if data is still loading
        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Horizontal direction
            gap: 1,
            mt: 2,
            overflowX: "auto", // Allow horizontal scrolling if content overflows
            "&::-webkit-scrollbar": {
              height: "8px", // Custom scrollbar height for horizontal scroll
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#BDB7B7FF", // Scrollbar thumb color
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#A6A6A6", // Scrollbar thumb hover color
            },
          }}
        >
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={42}
              sx={{
                borderRadius: "16px",
                animation: "shimmer 5s infinite",
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
              }}
            />
          ))}
        </Box>
      )}
    </Paper>
  );
};

export default RecentSearches;