import React from "react";
import { Box } from "@mui/system";
import { Button, Grid, Paper, Typography } from "@mui/material";
import AccountDetailsModel from "../../../../components/EmployerComponents/CompanyProfileComponents/AccountDetailsModel";

const AccountDetails = ({
  openDialog,
  handleCloseDialog,
  handleSaveDialog,
  handleEdit,
  orgData,
}) => {
  return (
    <Paper elevation={0} sx={{ backgroundColor: "#FFF", padding: 3 }}>
      <Typography sx={{ fontSize: "large", marginBottom: 2 }}>
        Account Details
      </Typography>

      <Grid container spacing={2}>
        {orgData?.recruiterAdmins.map((account) => (
          <Grid item xs={12}>
            <Typography component="legend" sx={{ fontSize: 14, mb: 1 }}>
              Role : {account.role}
            </Typography>
            <Typography component="legend" sx={{ fontSize: 14, mb: 1 }}>
              Reporting Manager : {account.rcName}
            </Typography>
            <Typography component="legend" sx={{ fontSize: 14 }}>
              Mobile Number : {account.rcPhone}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <AccountDetailsModel
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleSaveDialog={handleSaveDialog}
        orgData={orgData}
      />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button sx={{ borderRadius:'2rem',backgroundColor:'#423BFA'}} variant="contained" onClick={handleEdit} disabled>
          Edit
        </Button>
      </Box>
    </Paper>
  );
};
export default AccountDetails;
