import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  // Autocomplete,
} from "@mui/material";
import baseURL from "../../../../api/baseUrl";

const allPerks = [
  { label: "Flexible Working Hours", value: "flexibleWorkingHours" },
  { label: "Overtime Pay", value: "overtimePay" },
  { label: "Joining Bonus", value: "JoiningBonus" },
  { label: "Annual Bonus", value: "AnnualBonus" },
  { label: "PF", value: "PF" },
  { label: "Travel Allowance (TA)", value: "travelAllowance" },
  { label: "Petrol Allowance", value: "petrolAllowance" },
  { label: "Mobile Allowance", value: "mobileAllowance" },
  { label: "Internet Allowance", value: "internetAllowance" },
  { label: "Laptop", value: "laptop" },
  { label: "Health Insurance", value: "healthInsurance" },
];

const EmployerCaseZero = ({ formData, handleChange, handleOptionChange, handleNext, handleBack }) => {
  const [isNightShift, setIsNightShift] = useState(formData.isNightShift || false);
  const [perks, setPerks] = useState(formData.additionalPerks || []);
  const [showSalary, setShowSalary] = useState(formData.showSalary || false);

  const handleShiftChange = (event) => {
    const checked = event.target.checked;
    setIsNightShift(checked);
    handleOptionChange("isNightShift", checked);
  };

  const handleSalaryChange = (event) => {
    const checked = event.target.checked;
    setShowSalary(checked);
    handleOptionChange("showSalary", checked);
  };

  const handleButtonChange = (field, value) => {
    handleOptionChange(field, value);
  };

  // const companies = [
  //   { label: "Million Talents Tech", value: "Million Talents Private Limited" },
  //   { label: "Selfeey Infotech", value: "Selfeey Infotech India Private Limited" },
  // ];

  const handleAddPerk = (perkValue) => {
    const newPerks = perks.includes(perkValue)
      ? perks.filter((p) => p !== perkValue)
      : [...perks, perkValue];
    setPerks(newPerks);
    handleOptionChange("additionalPerks", newPerks);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const postData = {
      jobId: formData.jobId,
      organizationId: formData.organizationId,
      page: 1,
      hiringFor: formData.companyHiringFor,
      jobTitle: formData.jobTitle,
      jobRoleCategory: formData.jobRoleCategory,
      jobType: formData.jobType,
      nightShift: isNightShift,
      jobMode: formData.workLocation,
      applicationLocation: formData.applicationLocation || "specificCity",
      jobCity: formData.jobCity || "",
      compensation: formData.payType,
      minFixedSalary: formData.minFixedSalary || 0,
      maxFixedSalary: formData.maxFixedSalary || 0,
      showSalary: showSalary,
      additionalPerks: perks,
      joiningFee: formData.joiningFee || "no",
      feeAmount: formData.feeAmount || 0,
      feeReason: formData.feeReason || "NA",
      otherReason: formData.otherReason || "NA",
      feePaymentTime: formData.feePaymentTime || "NA",
    };

    try {
      const response = await fetch(`${baseURL}/jobpost/update/page`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        handleNext();  // Move to the next step on successful submission
      } else {
        console.error("Error submitting data:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box
      component="form"
      sx={{ "& .MuiTextField-root": { m: 1, width: "100%" }, p: 2 }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12}>
          <Typography component="legend" variant="bold" sx={{ fontWeight: "bold" }}>
            Company you're hiring for *
          </Typography>
          <TextField
            id="company-hiring"
            value={formData.companyHiringFor || ""}
            onChange={(event) => {
              handleChange({
                target: {
                  name: "companyHiringFor",
                  value: event.target.value,
                },
              });
            }}
            placeholder="Enter company name"
            variant="outlined"
            fullWidth
            required
            size="small"
            sx={{ marginTop: 1 }}
          />
          <Typography component="legend" variant="bold" sx={{ fontWeight: "bold" }}>
            Job Title / Designation
          </Typography>
          <TextField
            required
            id="job-title"
            placeholder="Job title / Designation"
            variant="outlined"
            fullWidth
            value={formData.jobTitle || ""}
            onChange={handleChange}
            name="jobTitle"
            size="small"
            sx={{ marginBottom: 2, marginTop: 1 }}
          />
          <Typography component="legend" variant="bold" sx={{ fontWeight: "bold" }}>
            Job Role / Category
          </Typography>
          <TextField
            required
            id="job-role"
            placeholder="Job Role / Category"
            variant="outlined"
            fullWidth
            value={formData.jobRoleCategory}
            onChange={handleChange}
            name="jobRoleCategory"
            size="small"
            sx={{ marginBottom: 2, marginTop: 1 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend" variant="bold" sx={{ fontWeight: "bold" }} gutterBottom>
            Type of Job
          </Typography>
          <Grid container spacing={2}>
            {["fullTime", "partTime", "freelance"].map((type) => (
              <Grid item key={type}>
                <Button
                  variant={formData.jobType === type ? "contained" : "outlined"}
                  onClick={() => handleButtonChange("jobType", type)}
                  sx={{
                    borderRadius: 6,
                    backgroundColor: formData.jobType === type ? "#423BFA" : "transparent",
                    border: "1px solid #ccc",
                    color: formData.jobType === type ? "#fff" : "#000",
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {type === "fullTime" ? "Full Time" : type === "partTime" ? "Part Time" : "Freelance"}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={isNightShift}
                onChange={handleShiftChange}
                inputProps={{ 'aria-label': 'controlled' }}
                name="isNightShift"
              />
            }
            label="This is a night shift job"
            sx={{ mt: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend" variant="bold" sx={{ fontWeight: "bold" }} gutterBottom>
            Work Location Type
          </Typography>
          <Grid container spacing={2}>
            {["workFromOffice", "workFromHome", "fieldJob"].map((location) => (
              <Grid item key={location}>
                <Button
                  variant={formData.workLocation === location ? "contained" : "outlined"}
                  onClick={() => handleButtonChange("workLocation", location)}
                  sx={{
                    borderRadius: 6,
                    backgroundColor: formData.workLocation === location ? "#423BFA" : "transparent",
                    border: "1px solid #ccc",
                    color: formData.workLocation === location ? "#fff" : "#000",
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {location === "workFromOffice" ? "Work From Office" : location === "workFromHome" ? "Work From Home" : "Field Job"}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend" variant="bold" sx={{ fontWeight: "bold" }}>
            Compensation
          </Typography>
          <Grid container spacing={2}>
            {["fixedOnly", "fixedPlusIncentive", "incentiveOnly"].map((payType) => (
              <Grid item key={payType}>
                <Button
                  variant={formData.payType === payType ? "contained" : "outlined"}
                  onClick={() => handleButtonChange("payType", payType)}
                  sx={{
                    borderRadius: 6,
                    backgroundColor: formData.payType === payType ? "#423BFA" : "transparent",
                    border: "1px solid #ccc",
                    color: formData.payType === payType ? "#fff" : "#000",
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {payType === "fixedOnly" ? "Fixed Only" : payType === "fixedPlusIncentive" ? "Fixed + Incentive" : "Incentive Only"}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          {["fixedOnly", "fixedPlusIncentive"].includes(formData.payType) && (
            <>
              <Grid item xs={6}>
                <TextField
                  required
                  id="min-salary"
                  placeholder="Minimum Fixed Salary"
                  variant="outlined"
                  fullWidth
                  value={formData.minFixedSalary}
                  onChange={handleChange}
                  name="minFixedSalary"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  id="max-salary"
                  placeholder="Maximum Fixed Salary"
                  variant="outlined"
                  fullWidth
                  value={formData.maxFixedSalary}
                  onChange={handleChange}
                  name="maxFixedSalary"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showSalary}
                onChange={handleSalaryChange}
                inputProps={{ 'aria-label': 'controlled' }}
                name="showSalary"
              />
            }
            label="Show Salary to Candidates"
            sx={{ mt: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend" variant="bold" sx={{ fontWeight: "bold" }} gutterBottom>
            Additional Perks
          </Typography>
          <Grid container spacing={1}>
            {allPerks.map((perk) => (
              <Grid item key={perk.value}>
                <Button
                  variant={perks.includes(perk.value) ? "contained" : "outlined"}
                  onClick={() => handleAddPerk(perk.value)}
                  sx={{
                    borderRadius: 6,
                    backgroundColor: perks.includes(perk.value) ? "#423BFA" : "transparent",
                    border: "1px solid #ccc",
                    color: perks.includes(perk.value) ? "#fff" : "#000",
                  }}
                >


                  <Typography fontWeight="bold" fontSize="12px">
                    {perk.label}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", }}>
        {/* <Button
          type="submit"
          variant="contained"
          color="inherit"

          onClick={handleBack}
          sx={{
            backgroundColor: "#423BFA",
            color: "#fff",
            borderRadius: 6,
            px: 3,
            mt: 1,
            py: 1.5,
            "&:hover": {
              backgroundColor: "#372FD1",
            },
          }}
        >
          Back
        </Button> */}
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#423BFA",
            color: "#fff",
            borderRadius: 6,
            px: 3,
            "&:hover": {
              backgroundColor: "#372FD1",
            },
          }}
          onClick={handleNext}
        >
          Save & Next
        </Button>
      </Box>
    </Box>
  );
};

export default EmployerCaseZero;
