import React, { useState, useEffect } from "react";
import { fetchOrgSupport,fetchOrgSupportById,employerSendMessage } from "../../../api/orgSupport";
import CustomSnackbar from "../../../components/AdvancedComponents/CustomSnackbar";
import EmployerChat from "./EmployerChat";
import { Typography } from "@mui/material";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";

const EmployerSupport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [supportUser, setSupportUser] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [chatMessages, setChatMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageData, setMessageData] = useState("");
  const [statusMessage, setStatusMessage] = useState("Open");

  const handleChatClick = async (chat) => {
    setSelectedChat(chat._id); // Update the selected chat ID
    try {
      const messages = await fetchOrgSupportById(chat._id); // Fetch the messages for the clicked chat
      console.log("Fetched Messages:", messages); // Log the fetched messages
      setChatMessages(messages?.messages); // Update state with the fetched messages
      setSupportUser(messages?.organization?.organizationName);
      setStatusMessage(messages?.status);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
      handleErrorMessage(error); // Handle the error (optional, based on your setup)
    }
  };

  // Fetch the employer support data on component mount
  useEffect(() => {
    const getData = async () => {
      try {
        const supportData = await fetchOrgSupport();
        setData(supportData);
        if (supportData.length > 0) {
          setSelectedChat(supportData[0]._id); // Select the first chat by default
        }
      } catch (error) {
        handleErrorMessage(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  // Handle error messages
  const handleErrorMessage = (error) => {
    const message =
      error.response?.data?.message ||
      error.message ||
      "An unknown error occurred";
    setSnackbarMessage(message);
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
  };

  // Close snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleInputChange = (event) => {
    setMessageData(event.target.value);
  };
  const handleSend = async () => {
    if (!messageData.trim()) return; // Avoid sending empty messages

    const payload = { messageContent: messageData }; // Create the payload
    try {
      const response = await employerSendMessage(payload, selectedChat); // Call sendMessage API
      console.log("Message sent:", response); // Log the sent message response
      setChatMessages([...chatMessages, response]); // Update the chat messages with the new message
      setMessageData(""); // Clear the input field after sending
      setSnackbarMessage("Message sent successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true); // Show snackbar for success
    } catch (error) {
      handleErrorMessage(error); // Handle error if message sending fails
    }
  };


  return (
    <EmployerDrawer>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <EmployerChat
          users={data}
          selectedChat={selectedChat}
          handleChatClick={handleChatClick}
          chatMessages={chatMessages} // Pass chatMessages to EmployerChat
          handleSend={handleSend}
          handleInputChange={handleInputChange}
          messageData={messageData}
          supportUser={supportUser}
          statusMessage={statusMessage}
        />
      )}
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        type={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </EmployerDrawer>
  );
};

export default EmployerSupport;
