import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Divider,
} from "@mui/material";

const SubscriptionsCard = ({
  subscriptionData,
  handleBuyNow,
  handleContactSales,
}) => {
  console.log("subscriptionData", subscriptionData);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        flexWrap: "wrap",
        mt: 5,
        px: 2,
      }}
    >
      {/* Pro Plan */}
      {subscriptionData?.map((plans) => (
        <Card
          sx={{
            width: 320,
            border: "2px solid #6A5ACD",
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#F8F8FF",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bgcolor: "#6A5ACD",
              color: "#fff",
              textAlign: "center",
              py: 1,
              fontWeight: "bold",
              borderRadius: "8px 8px 0 0",
            }}
          >
            {plans.tier.charAt(0).toUpperCase() + plans.tier.slice(1)}
          </Box>
          <CardContent sx={{ mt: 3 }}>
            {/* <Typography
              variant="h6"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Pro
            </Typography> */}
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                mt: 2,
                mb: 1,
              }}
            >
              ₹{plans?.subscriptionFee}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: "center", color: "#666" }}
            >
              ₹{plans?.subscriptionFee} + taxes INR if billed monthly
              <br /> {plans?.userAccess} User Access
            </Typography>
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => handleBuyNow(plans.tier, plans._id)} // Pass subscription fee on click
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "#6A5ACD",
                  "&:hover": { backgroundColor: "#483D8B" },
                  mr: 1,
                }}
              >
                Buy now ↗
              </Button>
            </Box>
            <Divider sx={{ my: 3 }} />
            <Typography variant="body2" sx={{ mb: 1 }}>
              ✓ All Standard plan features, plus:
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              ✓ Includes {plans?.cvDownloads} CV downloads
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              ✓ Offers {plans?.invites} invitations
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              ✓ Access to {plans?.jobPosts} job postings
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              ✓ 24/7 phone support for assistance
            </Typography>
          </CardContent>
        </Card>
      ))}
      {/* Enterprise Plan */}
      <Card
        sx={{
          width: 320,
          border: "1px solid #ccc",
          borderRadius: 2,
          boxShadow: "none",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              borderBottom: "2px solid grey",
            }}
          >
            Custom Plans
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              mt: 2,
              mb: 1,
            }}
          >
            ₹...?
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: "center", color: "#666" }}
          >
            Contact Customer Support to get <br /> accurate price
          </Typography>
          <Box sx={{ textAlign: "center", mt: 2 }}>
           
            <Button
              onClick={handleContactSales} // Pass subscription fee on click
              variant="outlined"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderColor: "#6A5ACD",
                color: "#6A5ACD",
                "&:hover": { borderColor: "#483D8B", color: "#483D8B" },
              }}
            >
              Get a Quote ↗
            </Button>
          </Box>
          <Divider sx={{ my: 3 }} />

          <Typography variant="body2" sx={{ mb: 1 }}>
            ✓ Unlimited CV downloads to access candidate profiles without
            restrictions
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            ✓ Unlimited user access to manage teams and streamline workflows
          </Typography>

          <Typography variant="body2" sx={{ mb: 1 }}>
            ✓ Enhanced 24/7 phone support for priority assistance and faster
            resolutions and more
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SubscriptionsCard;
