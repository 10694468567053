const savedSearchStyles = {
  parentBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    height: "90vh",
    gap: 2,
    pb: 5,
    pt: 2,
    px: 4,
  },
  accordianBox: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    mt: 2.3,
  },
  filterTitle: {
    ml: 1,
    display: "flex",
    alignItems: "center"
  },
  accordian: {
    width: 300,
    padding: 0,
    mb: 0,
  },
  accordinTitle: {
    fontSize: "14px",
    fontWeight: "regular",
    fontFamily: "poppins",
  },
  tableHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "white",
  },
  table: {
    minWidth: 650,
  },
  tablecell: {
    fontFamily: "poppins",
  },
  tableParent: {
    display: "flex",
    flexDirection: "column",
    height: '80vh', // Fixed height for the parent container
    width: '100%', // Ensure it takes full width
  },
  tableActionParent: {
    minWidth: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 2,
  },
  pageFilterBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    mb: 2,
  },
};

export default savedSearchStyles;