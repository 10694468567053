import React, { useState } from "react";
import { Card, CardMedia, IconButton, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box } from "@mui/system";

const images = [
  "/slider1.png",
  "/slider3.png",
  "/slider4.png",
  "/slider5.png",
  "/slider6.png",
  "/slider12.png",
  "/slider8.png",
  "/slider9.png",
  "/slider10.png",
  "/slider11.png",
  "/slider13.png",
  "/slider2.png",
  "/slider14.png",
  "/slider15.png",
  "/slider16.png",
]

export default function JobSeekerImageCarousel() {
  const [centerIndex, setCenterIndex] = useState(4);

  const handlePrev = () => {
    setCenterIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNext = () => {
    setCenterIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <Box sx={{ width: '100vw', backgroundColor: '#585858FF', padding: '50px' }}>
      <Typography sx={{ textAlign: "center", fontWeight: "bold", fontFamily: 'IBM plex sans', mb: 14 ,color:"#DBDBDBFF"}} variant="h3">
        Become Future Ready with Vacanzi
      </Typography>
      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          perspective: "2000px",
          overflow: "hidden",
          marginTop: '30px'
        }}
      >
        <IconButton
          style={{
            position: "absolute",
            left: "20px",
            zIndex: 20,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "50%",
            padding: "12px",
          }}
          onClick={handlePrev}
          aria-label="Previous slide"
        >
          <ChevronLeft />
        </IconButton>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {images.map((image, index) => {
            const offset =
              ((index - centerIndex + images.length) % images.length) -
              Math.floor(images.length / 2);
            return (
              <Card
                key={index}
                style={{
                  position: "absolute",
                  width: "200px",
                  height: "300px",
                  transition: "all 0.5s ease",
                  transform: `
                  translateX(${offset * 102}px)
                  scale(${1 - Math.abs(offset) * 0.1})
                  translateZ(${-Math.abs(offset) * 60}px)
                `,
                  zIndex: 9 - Math.abs(offset),
                  opacity: Math.max(1 - Math.abs(offset) * 0.2, 1),
                  boxShadow:
                    offset === 0
                      ? "0 20px 40px rgba(0,0,0,0.5)"
                      : "0 10px 20px rgba(0,0,0,0.3)",
                }}
              >
                <CardMedia
                  component="img"
                  height="100%"
                  width="100vw"
                  image={image}
                  alt={`Slide ${index + 1}`}
                  style={{
                    objectFit: "cover",
                    borderRadius: "4px",
                    // filter: brightness(`${100 - Math.abs(offset) * 20}%`),
                  }}
                />
              </Card>
            );
          })}
        </div>
        <IconButton
          style={{
            position: "absolute",
            right: "20px",
            zIndex: 20,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "50%",
            padding: "12px",
          }}
          onClick={handleNext}
          aria-label="Next slide"
        >
          <ChevronRight />
        </IconButton>
      </div>
    </Box>
  );
}
