import React, { useEffect, useState } from "react";
import { Grid, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import NavBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
import Topcompanies from "../../../components/TopCompaniesDetails/Topcompanies";
import CompanyCards from "../../../components/TopCompaniesDetails/CompanyCards";
import CompaySidebar from "../../../components/TopCompaniesDetails/CompanySidebar";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import { getTopIndustryMasters } from "../../../api/common";
import baseURL from "../../../api/baseUrl";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

const TopHiringCompanies = () => {
  const [companies, setCompanies] = useState([]); // State to store companies
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [companiesDetails, setCompanyDetails] = useState([]); // State for industry details
  const [selectedIndustry, setSelectedIndustry] = useState(""); // Selected industry state
  const navigate = useNavigate(); // For navigation

  // Filter states
  const [companyTypeFilter, setCompanyTypeFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");

  // Fetch top industry masters and set the selected industry
  useEffect(() => {
    const fetchTopIndustryMasters = async () => {
      try {
        const response = await getTopIndustryMasters();
        if (response) {
          // Extract names and counts from the response array
          const allCategories = response.reduce((acc, resItem) => {
            resItem.items.forEach((item) => {
              if (item.name) {
                acc.push({
                  name: item.name,
                  count: item.organizationCount || 0, // Include the count
                });
              }
            });
            return acc;
          }, []);

          setCompanyDetails(allCategories);
        }
      } catch (error) {
        console.error("Error fetching top industry masters:", error.message);
      }
    };

    fetchTopIndustryMasters();
  }, []);

  // Fetch companies based on the selected industry and filters
  useEffect(() => {
    const fetchCompaniesByIndustry = async () => {
      if (!selectedIndustry) return; // Don't fetch if no industry is selected

      setLoading(true);
      setError(null);
      const queryParams = new URLSearchParams({
        orgType: companyTypeFilter,
        city: locationFilter,
        industry: selectedIndustry,
      });

      try {
        const authToken = localStorage.getItem("authToken")?.replace(/"/g, "");

        // Construct the query parameters for filters
        const response = await fetch(
          `${baseURL}/organization/organizations-by-industry?${queryParams}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "Application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setCompanies(data?.data || []); // Assuming response.data contains the required company data
      } catch (error) {
        setError("Failed to fetch companies. Please try again.");
        console.error("Error fetching companies:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCompaniesByIndustry();
  }, [companyTypeFilter, locationFilter, selectedIndustry]);

  // Function to handle company type filter change
  const handleCompanyTypeFilter = (value) => {
    setCompanyTypeFilter(value);
  };

  // Function to handle location filter change
  const handleLocationFilter = (value) => {
    setLocationFilter(value);
  };

  // Function to navigate to the company page
  const goToCompanyPage = (companyId) => {
    navigate(`/organizationOverview?companyId=${companyId}`); // Pass companyId as a query parameter
  };

  // Scroll to the top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavbarIndustrySelect = (industry) =>{
   setSelectedIndustry(industry)
  }


  return (
    <>
      <NewNavBar onIndustrySelect = {handleNavbarIndustrySelect}/>
      <Container>
        {/* Page Layout: Grid with Sidebar and Company Cards */}
        <Grid container spacing={4} sx={{ background: "#f7f7f9", padding: 2 }}>
          {/* Top Companies Section (Full Width) */}
          <Grid item xs={12}>
            <Topcompanies
              companiesDetails={companiesDetails}
              selectedIndustry={selectedIndustry} // Pass the selected industry
              onIndustrySelect={setSelectedIndustry} // Pass a callback to handle industry selection
            />
          </Grid>

          {/* Sidebar and Company Cards Section */}
          {/* Sidebar for Filters (takes 1/3 of the width) */}
          <Grid item xs={12} md={4}>
            <CompaySidebar
              companyTypeFilter={companyTypeFilter}
              handleCompanyTypeFilter={handleCompanyTypeFilter}
              locationFilter={locationFilter}
              handleLocationFilter={handleLocationFilter}
              selectedIndustry={selectedIndustry}
              // handleIndustryFilter={handleIndustryFilter}
            />
          </Grid>

          {/* Company Cards (takes remaining 2/3 of the width) */}
          <Grid item xs={12} md={8}>
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: "red" }}>{error}</p>}
            {!loading && !error && (
              <CompanyCards
                companies={companies}
                goToCompanyPage={goToCompanyPage}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default TopHiringCompanies;
