import * as React from "react";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import { useState } from "react";
import { Button, Typography, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { getInvoicesApi } from "../../../api/subscriptions";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const SubscriptionStatus = () => {
  const [invoices, setInvoices] = useState([]);
  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: "IBM Plex Sans, sans-serif",
    "&.MuiTableCell-head": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      fontWeight: 600,
      borderBottom: "2px solid #e0e0e0",
    },
  }));

  const StyledLink = styled(Link)({
    color: "#0078D4",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  });

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await getInvoicesApi(orgId, token);
        setInvoices(response.invoices || []);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchInvoices();
  }, [orgId, token]);

  // Function to convert JSON data to CSV format
  const jsonToCSV = (data) => {
    const headers = Object.keys(data[0]).join(",") + "\n";
    const rows = data
      .map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`) // Wrap values in quotes to handle commas
          .join(",")
      )
      .join("\n");
    return headers + rows;
  };

  // Function to handle CSV download
  const handleDownloadCSV = () => {
    try {
      // Prepare the data for the CSV file
      const csvData = invoices.map((invoice) => ({
        Invoice: invoice.invoiceId,
        "Billing Date": formatDate(invoice.subscribedOn),
        Amount: `${invoice.amount} INR`,
        Status:
          new Date(invoice.validUntil) > new Date() ? "Active" : "Inactive",
      }));

      // Convert JSON data to CSV
      const csvContent = jsonToCSV(csvData);

      // Create a Blob and trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", "Invoices.csv");
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <EmployerDrawer>
      <>
        <Typography
          onClick={handleBack}
          gutterBottom
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            mb:'15px',
            color: "grey", // Adjust color for better visibility
            "&:hover": {
              color: "primary.dark", // Hover effect
            },
          }}
        >
          <ArrowBackIcon sx={{ marginRight: "4px" }} />
        
        </Typography>
        {invoices.length > 0 ? (
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              borderRadius: "10px",
              p: 3,
            }}
          >
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleDownloadCSV}
                  sx={{
                    backgroundColor: "#423bfa",
                    color: "#ffffff",
                    textTransform: "none",
                    fontFamily: "IBM plex sans",
                    borderRadius: "20px",
                    padding: "8px 16px",
                    "&:hover": {
                      backgroundColor: "#544FE6FF",
                    },
                  }}
                >
                  Download as CSV
                </Button>
              </Box>
              <Box sx={{ mr: 3 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#423bfa",
                    textTransform: "none",
                    borderRadius: 4,
                  }}
                  onClick={() => navigate("/subcriptions")}
                >
                  View plans
                </Button>
              </Box>
            </Box>

            <TableContainer component={Paper} elevation={0}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    <StyledTableCell>Invoice</StyledTableCell>
                    <StyledTableCell>Billing Date</StyledTableCell>
                    <StyledTableCell>Amount</StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoices.map((invoice) => (
                    <TableRow
                      key={invoice.invoiceId}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                    >
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell>
                        <Tooltip title="Preview and Download" arrow>
                          <StyledLink
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/subscriptionInvoice/${invoice._id}`);
                            }}
                          >
                            {invoice.invoiceId}
                          </StyledLink>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{formatDate(invoice.subscribedOn)}</TableCell>
                      <TableCell>
                        <Typography sx={{ fontFamily: "IBM Plex Sans" }}>
                          {invoice.amount}
                          <Typography
                            component="span"
                            sx={{ color: "#666", ml: 1 }}
                          >
                            INR
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {new Date(invoice.validUntil) > new Date() ? (
                          <span style={{ color: "green" }}>Active</span>
                        ) : (
                          <span style={{ color: "red" }}>Inactive</span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography
              sx={{ fontFamily: "IBM plex sans", fontSize: "30px", mb: 3 }}
            >
              No Purchases Found
            </Typography>
            <Box sx={{ mr: 3 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#423bfa",
                  textTransform: "none",
                  borderRadius: 4,
                }}
                onClick={() => navigate("/subcriptions")}
              >
                View plans
              </Button>
            </Box>
          </Box>
        )}
      </>
    </EmployerDrawer>
  );
};

export default SubscriptionStatus;
