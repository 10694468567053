import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Box, Chip, Grid, Divider, IconButton, useMediaQuery, Container } from '@mui/material';
import { ChevronLeft, Star } from '@mui/icons-material';
// import NavBar from '../../../components/JobSeekerComponents/NavBar/NavBar';
import Footer from '../../../components/JobSeekerComponents/Footer/Footer';
import CustomSnackbar from '../../../components/AdvancedComponents/CustomSnackbar';
import vacanziLogo from '../../../assets/icons/logos/vacanzi.png'; // Adjust the path as needed
import baseURL from '../../../api/baseUrl';
import NewNavBar from '../../../components/JobSeekerComponents/NavBar/NewNavbar';

const JobAppliedDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [job, setJob] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await fetch(`${baseURL}/jobs/${id}`, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch job details');
        }
        const data = await response.json();
        setJob(data?.job);
      } catch (error) {
        console.error('Error fetching job details:', error);
        setSnackbarMessage('Error fetching job details');
        setSnackbarType('error');
        setSnackbarOpen(true);
      }
    };

    fetchJobDetails();
  }, [id]);

  if (!job) return <Typography variant="h6">Loading...</Typography>;

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <NewNavBar />
      <Box sx={{ p: 2 }}>
        <IconButton color="primary" onClick={() => navigate('/jobSeekerAppliedJobs')} sx={{ mb: 2 }}>
          <ChevronLeft />
        </IconButton>
        <Container>
          <Card variant="outlined" sx={{ mb: 2, p: isMobile ? 1 : 2 }}>
            <CardContent>
              <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems="flex-start">
                    <Box sx={{ marginBottom: isMobile ? 1 : 0 }}>
                      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {job.jobTitle}
                      </Typography>
                      <Box display="flex" alignItems="center" sx={{ marginBottom: isMobile ? 1 : 0 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          {job.organizationId?.organizationName}
                        </Typography>
                        {job.rating && (
                          <>
                            <Star sx={{ ml: 1, mr: 0.5, fontSize: '15px' }} color="warning" />
                            <Typography variant="body2" color="textSecondary">
                              {job.rating} ({job.reviews} Reviews)
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ marginBottom: isMobile ? 1 : 0 }}>
                      <img height={isMobile ? 30 : 40} width={isMobile ? 30 : 40} src={job?.organizationId?.companyLogo?.url || vacanziLogo} alt="company logo" />
                    </Box>
                  </Box>
                  <Divider sx={{ my: isMobile ? 1 : 2 }} />
                  <Grid container spacing={isMobile ? 1 : 2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Experience Type
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {job.experienceType} ({job.requiredExperience})
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Salary
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        ₹ {job.ctc} per annum
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Location
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {job.jobLocation}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Skills
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {Array.isArray(job.skillPreferences) && job.skillPreferences.map((skill, index) => (
                          <Chip key={index} label={skill} />
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                      Job Description
                    </Typography>
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                      {job.jobRole}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body2" color="textSecondary">
                      Posted on: {new Date(job.postDate).toDateString()}
                    </Typography>

                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Footer />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        type={snackbarType}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default JobAppliedDetails;
