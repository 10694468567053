import { Box, Typography, styled } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules"; // Import Autoplay module
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay"; // Import Autoplay CSS
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect } from "react";
import { getCunsellorsApi } from "../../api/common";
import { useState } from "react";

const StyledSwiper = styled(Swiper)({
  "& .swiper-button-next, & .swiper-button-prev": {
    color: "white",
    width: "40px",
    "&::after": {
      display: "none",
    },
  },
});

const SlideContainer = styled(Box)({
  position: "relative",
  textAlign: "center",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "30%",
    borderRadius: "0 0 20px 20px",
    zIndex: 1,
  },
});

const ImageContainer = styled(Box)({
  width: "100%",
  height: "calc(100vw / 5)", // Adjust the height based on the number of slidesPerView
  maxHeight: "300px", // Ensure it doesn't exceed 300px on larger screens
  position: "relative",
  borderRadius: "2px",
  overflow: "hidden",
  backgroundColor: "white",
});

const TextContent = styled(Box)({
  position: "absolute",
  bottom: "20px",
  left: "0",
  width: "100%",
  zIndex: 2,
});

// const teamMembers = [
//   {
//     name: "Basil Thampi",
//     role: "Success Guru",
//     image: "/couns1.png",
//     roleColor: "#e83e8c",
//   },
//   {
//     name: "Nikitha Verma",
//     role: "Stock Market Guru",
//     image: "/couns2.png",
//     roleColor: "#20c997",
//   },
//   {
//     name: "Ankit Pareek",
//     role: "Business Guru",
//     image: "/couns3.png",
//     roleColor: "#fd7e14",
//   },
//   {
//     name: "Avinash Jha",
//     role: "Online Business Guru",
//     image: "/couns4.png",
//     roleColor: "#ffc107",
//   },
//   {
//     name: "Onish kodiyala",
//     role: "Finance Guru",
//     image: "/couns5.png",
//     roleColor: "#e83e8c",
//   },
//   {
//     name: "Ankit Pareek",
//     role: "Business Guru",
//     image: "/couns6.png",
//     roleColor: "#fd7e14",
//   },
// ];




const VacanziCounselors = () => {
  const [counsellors,setCounsellors] = useState()

  useEffect(()=>{
  const getAllCounsellors = async()=>{
    try{
     const response = await getCunsellorsApi()
     setCounsellors(response)
     console.log(response)
    }
    catch(error){
      console.log(error.message)
    }
  }
  getAllCounsellors()
  },[])

  return (
    <Box sx={{ backgroundColor: "white", width: "100vw" }}>
      <Typography
        variant="h3"
        sx={{
          fontFamily: "IBM Plex Sans",
          textAlign: "center",
          padding: "20px",
          fontWeight: "bold",
          color: "#03030CFF",
        }}
      >
        Vacanzi Counselors
      </Typography>
      <Box sx={{ py: 6, px: 6 }}>
        <StyledSwiper
          modules={[Navigation, Autoplay]} // Add Autoplay module here
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          slidesPerView={5}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          spaceBetween={30}
        >
    {counsellors?.map((member, index) => (
  <SwiperSlide key={index}>
    <SlideContainer>
      <ImageContainer>
        {/* First Image (Background Image) */}
        <Box
          component="img"
          src={'/couns1.png'}
          alt={'counsimg'}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "contain", // Ensure the whole image is visible
            borderRadius: "10px",
            position: "relative", // Add relative positioning
          }}
        />
        {/* Second Image (Profile Image) */}
        <Box
          component="img"
          src={member?.profileImage?.path}
          alt={member?.fullName}
          sx={{
         
            objectFit: "contain", // Ensure the image covers the area
           
            position: "absolute", // Position it absolutely inside the first image
            top: "47%", // Center vertically
            left: "50%", // Center horizontally
            transform: "translate(-50%, -50%)", // Center the image
           
          }}
        />
      </ImageContainer>
      <TextContent>
        <Typography
          variant="h6"
          sx={{
            color: "white",
            fontWeight: "bold",
            
          
          }}
        >
          {member?.fullName}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: 'yellow',
            fontWeight: "medium",
          }}
        >
          {member?.position}
        </Typography>
      </TextContent>
    </SlideContainer>
  </SwiperSlide>
))}
          <Box
            className="swiper-button-prev"
            sx={{
              right: 20,
              zIndex: 10,
              color: "black",
              "&::after": { display: "none" },
            }}
          >
            <ArrowBackIosIcon sx={{ color: "blue" }} />
          </Box>
          <Box
            className="swiper-button-next"
            sx={{
              right: 20,
              zIndex: 10,
              color: "black",
              "&::after": { display: "none" },
            }}
          >
            <ArrowForwardIosIcon sx={{ color: "blue" }} />
          </Box>
        </StyledSwiper>
      </Box>
    </Box>
  );
};

export default VacanziCounselors;