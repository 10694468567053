import {
    Button,
    Dialog,
    DialogContent,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import searchCandidates from "../../../styles/searchCandidatesStyles";

const CreateVideoDialog = ({ openDialog, handleCloseDialog, }) => {

    return (
        <>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 10,
                    }}
                >
                    <Box
                        sx={{
                            backgroundImage: "url('/jobpostbg.jpeg')",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: "800px",
                            height: "278px",
                            position: "relative",
                        }}
                    />

                    <DialogContent
                        sx={{
                            borderRadius: 2,
                            position: "absolute",
                            left: 0,
                            top: 90,
                            ml: 0,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 20,
                                color: "#F5F5F5FF",
                                lineHeight: 1, // Adjust to control spacing between lines
                                maxWidth: 350, // Adjust width to ensure wrapping
                                wordWrap: "break-word",
                                textAlign: "center", // Center align for better presentation
                            }}
                        >
                            For Security reason, Kindly login with <br />
                            your mobile App for Creating <br />
                            Video & Audio CV
                        </Typography>
                    </DialogContent>

                    <Box
                        component="img"
                        sx={{
                            height: 40,
                            width: 100,
                            position: "absolute",
                            top: 250,
                            left: 50,
                            cursor: "pointer",
                        }}
                        alt="#apple"
                        src="/apple.png"
                    />
                    <Box
                        component="img"
                        sx={{
                            height: 40,
                            width: 100,
                            position: "absolute",
                            top: 250,
                            left: 170,
                            cursor: "pointer",
                        }}
                        alt="#playstore"
                        src="/playstore.png"
                    />
                </Box>
                <Box sx={{ ...searchCandidates.flexboxend }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            position: "absolute",
                            marginTop: "20px",
                            right: 30,

                            bottom: 10,
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "red", ":hover": {
                                    backgroundColor: 'red'
                                },
                            }}
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Dialog>


        </>
    );
};

export default CreateVideoDialog;
