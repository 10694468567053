import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import deleteAccount from "../../../styles/deleteAccount";
import { Button, TextField, Typography } from "@mui/material";
import { getOrgDetails } from "../../../api/companyProfile";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import { deleteOrganization } from "../../../api/deleteOrgAccount";
import { DeleteConfirmPopup } from "./DeleteConfirmPopup";

const DeleteAccount = () => {
  const [orgData, setOrgData] = useState();
  const [reason, setReason] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false)
  };
  setTimeout(() => {
    handleCloseDialog();
  }, 4000);
  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        if (orgId) {
          const response = await getOrgDetails(orgId, token);
          setOrgData(response);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchOrgDetails();
  }, [orgId,token]);

  const deleteOrgAccount = async () => {
    try {
      if (orgId) {
        const response = await deleteOrganization(orgId, token);
        if (response) {
          handleOpenDialog();
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <EmployerDrawer>
      <Box sx={{ ...deleteAccount.parentBox }}>
        <Box sx={{ ...deleteAccount.formBox }}>
          <Typography sx={{ ...deleteAccount.typography }}>
            Delete your Account
          </Typography>
          <TextField
            id="outlined-basic"
            value={orgData?.creatorFullName}
            sx={{ ...deleteAccount.textFiled }}
            disabled
          />
          <TextField
            id="outlined-basic"
            value={orgData?.contactNumber}
            sx={{ ...deleteAccount.textFiled }}
            disabled
          />
          <TextField
            id="outlined-basic"
            value={orgData?.email}
            sx={{ ...deleteAccount.textFiled }}
            disabled
          />
          <TextField
            id="outlined-multiline-static"
            multiline
            placeholder="Reason for deleting Account"
            value={reason}
            rows={4}
            onChange={handleReasonChange}
            sx={{ ...deleteAccount.multiline }}
          />
          <Button
            variant="contained"
            sx={{
              bgcolor: "red",
              ":hover": {
                bgcolor: "#A30F0FFF",
              },
            }}
            onClick={deleteOrgAccount}
          >
            Delete Account
          </Button>
        </Box>
        <DeleteConfirmPopup openDialog={openDialog} />
      </Box>
    </EmployerDrawer>
  );
};

export default DeleteAccount;
