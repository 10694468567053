import React from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import VisibilityIcon from "@mui/icons-material/Visibility";

const JobSeekerStats = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#6A64FF", // Lighter shade of purple
        color: "white",
        textAlign: "center",
        paddingTop:1,
        paddingBottom:2,
        borderRadius: "2rem",
      }}
    >
      <Typography variant="h5" sx={{marginBottom:2,fontSize:'1.3rem'}}>In Last 30 Days</Typography>
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        alignItems="center"
        sx={{ position: "relative" }} // Add relative positioning for dividers
      >
        {/* Learners */}
        <Grid item xs={12} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              
              fontWeight="bold"
              sx={{ display: "inline-flex", alignItems: "center",fontSize:"2.5rem" }}
            >
              <PeopleIcon sx={{ mr: 1, fontSize: 52 }} />
              14 Lakhs
            </Typography>
            <Typography sx={{fontSize:"1.3rem"}}>Jobseekers Registered</Typography>
          </Box>
        </Grid>

        {/* Divider 1 */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            height: "80px", // Adjust height to match content
            display: { xs: "none", sm: "block" }, // Hide on small screens
            position: "absolute",
            left: "33.33%", // Position between first and second grid item
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />

        {/* Videos */}
        <Grid item xs={12} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h3"
              fontWeight="bold"
              sx={{ display: "inline-flex", alignItems: "center",fontSize:"2.5rem"  }}
            >
              <VideoLibraryIcon sx={{ mr: 1, fontSize: 52 }} />
              7.5 Lakhs
            </Typography>
            <Typography sx={{fontSize:"1.3rem"}}>Short Videos Uploaded</Typography>
          </Box>
        </Grid>

        {/* Divider 2 */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            height: "80px", // Adjust height to match content
            display: { xs: "none", sm: "block" }, // Hide on small screens
            position: "absolute",
            left: "66.66%", // Position between second and third grid item
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />

        {/* Minutes Watched */}
        <Grid item xs={12} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h3"
              fontWeight="bold"
              sx={{ display: "flex", alignItems: "center",fontSize:"2.5rem" }}
            >
              <VisibilityIcon sx={{ mr: 1, fontSize: 52 }} />
              1.5 Lakhs
            </Typography>
            <Typography sx={{fontSize:"1.3rem"}}>Conversations</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobSeekerStats;
