import React, { createContext, useState } from 'react';

// Create the context
export const SearchContext = createContext();

// Create a provider component
export const SearchProviderResults = ({ children }) => {
  const [profiles, setProfiles] = useState([]);

  return (
    <SearchContext.Provider value={{ profiles, setProfiles }}>
      {children}
    </SearchContext.Provider>
  );
};