// RouteComponent.jsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/JobSeekerPages/SignInSignUp/SignInSignUp";
import EmployerSignIn from "../pages/EmployerPages/EmployerLogin/EmployerSignIn";
import HomePage from "../pages/Home/Home";
// import JobSeekerProfile from "../pages/EmployerPages/JobSeekerProfiles/JobSeekerProfiles";
import JobSeekerRegestrationProfile from "../pages/JobSeekerPages/JobseekerRegestrationProfile/jobSeekerRegestrationProfile";
import JobSeekerFilter from "../pages/JobSeekerPages/JobSeekerFilters/JobSeekerFilters";
import JobSeekerJobDetails from "../pages/JobSeekerPages/JobSeekerJobDetails/JobSeekerJobDetails"; // Import correct component
import EmployersRegistrationProfile from "../pages/EmployerPages/EmployersRegistrationProfile/EmployersRegistrationProfile";
import JobSeekerPersonalProfile from "../pages/JobSeekerPages/JobSeekerPersonalProfiles/JobSeekerPersonalProfile";
import EmployerJobListings from "../pages/EmployerPages/EmployerJobList/EmployerJobListings";
import SearchCandidate from "../pages/EmployerPages/EmployerDatabases/searchCandidate";
// import UnlockCandidates from "../pages/EmployerPages/EmployerDatabases/unlockCandidates";
import CandidateSearchList from "../pages/EmployerPages/CandidateSearchList/CandidateSearchList";
import EmployerProfile from "../pages/EmployerPages/EmployerPersonalProfile/EmployerProfile";
import EmployerTeamMembers from "../pages/EmployerPages/EmployerTeamMemebers/EmployerTeamMembers";
import JobSeekerAppliedJobs from "../pages/JobSeekerPages/JobSeekerAppliedJobs/JobSeekerAppliedJobs";
import JobSeekerSavedJobs from "../pages/JobSeekerPages/JobSeekerSavedJobs/JobSeekerSavedJobs";
import EmployerRegistration from "../pages/EmployerPages/EmployerRegistration/EmployerRegistration";
import JobAppliedDetails from "../pages/JobSeekerPages/JobSeekerAppliedJobs/JobAppliedDetails";
import JobSavedDetails from "../pages/JobSeekerPages/JobSeekerSavedJobs/JobSavedDetails";
import ManageUsers from "../pages/EmployerPages/ManageUsers/ManageUsers";
import ManageQuota from "../pages/EmployerPages/ManageQuota/ManageQuota";
import CompanyProfile from "../pages/EmployerPages/CompanyProfile/CompanyProfile";
import SubscriptionStatus from "../pages/EmployerPages/SubscriptionStatus/SubscriptionStatus";
import JobPostPage from "../pages/EmployerPages/EmployerJobPost/JobPostPage";
import SubScriptionPage from "../pages/EmployerPages/EmployerJobPost/SubScriptionPage";
import RegisterForm from "../pages/JobSeekerPages/SignInSignUp/RegisterForm";
import DeleteAccount from "../pages/EmployerPages/EmployerPersonalProfile/DeleteAccount";
import SavedSearch from "../pages/EmployerPages/EmployerDatabases/SavedSearch";
import EmployerResetPassword from "../components/EmployerComponents/EmployerLoginSignup/EmployerResetPassword";
import JobSeekeerSubscription from "../pages/JobSeekerPages/JobSeekerSubscription/JobSeekerSubscription";
import JobSeekerInvoice from "../pages/JobSeekerPages/JobSeekerInvoice/JobSeekerInvoice";
import PaymentCallback from "../pages/Payment/PaymentCallback";
import PaymentFailure from "../pages/Payment/PaymentFailure";
import PaymentSuccess from "../pages/Payment/PaymentSuccess";
import JobSeekerPaymentVerification from "../components/JobSeekerSubscription/JobSeekerPaymentVerification";
import JobSeekerPaymentSuccessful from "../components/JobSeekerSubscription/JobSeekerPaymentSuccessful";
import JobSeekerPaymentFailed from "../components/JobSeekerSubscription/JobSeekerPaymentFailed";
import EmployerJobPostPage from "../pages/EmployerPages/EmployerJobPost/EmployerJobPostPage";
import JobSeekerSupport from "../pages/JobSeekerPages/JobSeekerSupport/JobSeekerSupport";
import ProtectedJobSeekerRoute from "../utils/protectedJobSeekerRoute";
import ProtectedEmployerRoute from "../utils/protectedEmployerRoute";
import JobSeekerTopIndustries from "../components/JobSeekerComponents/JobSeekerIndustries/JobSeekerTopIndustries";
import TopHiringCompanies from "../pages/JobSeekerPages/TopHiringCompanies/TopHiringCompanies";
import JobSeekerProfileDetail from "../pages/JobSeekerPages/JobSeekerProfile/JobSeekerProfileDetails";
import OrgOverView from "../pages/JobSeekerPages/OrganizationOverView/OrganizationOverView";
import ViewJobSeekerProfile from "../pages/EmployerPages/JobSeeekerProfile/ViewJobSeekerProfile";
import EmployerSupport from "../pages/EmployerPages/EmployerSupport/EmployerSupport";
import EmployerInvoice from "../pages/EmployerPages/SubscriptionStatus/EmployerInvoice";
import EmployerReports from "../pages/EmployerPages/EmployerReports/EmployerReports";
import JobSeekerFAQ from "../components/JobSeekerComponents/JobSeekerContentManageMent/jobSeekerFAQ";
import JobSeekerPaymentPolicy from "../components/JobSeekerComponents/JobSeekerContentManageMent/JobSeeekerPaymentPolicy";
import JobSeekerPrivacyPolicy from "../components/JobSeekerComponents/JobSeekerContentManageMent/JobSeeekerPrivacyPolicy";
import JobSeekerTermsAndCons from "../components/JobSeekerComponents/JobSeekerContentManageMent/JobSeeekerTermsAndCons";
import JobSeekerAboutUs from "../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerAboutUs";
import EmployerFAQ from "../components/EmployerComponents/EmployerContentManageMent/EmployerFAQ";

const RouteComponent = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />

      {/* Employer Routes */}
      <Route path="/employerSignIn" element={<EmployerSignIn />} />
      <Route
        path="/viewJobSeekerProfile/:id"
        element={
          <ProtectedEmployerRoute>
            <ViewJobSeekerProfile />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employersRegistrationProfile"
        element={<EmployersRegistrationProfile />}
      />
      <Route
        path="/employerJobPosting"
        element={
          <ProtectedEmployerRoute>
            <EmployerJobPostPage />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employersResetPassword"
        element={
          <ProtectedEmployerRoute>
            <EmployerResetPassword />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employerJobListings"
        element={
          <ProtectedEmployerRoute>
            <EmployerJobListings />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/search-candidates"
        element={
          <ProtectedEmployerRoute>
            <SearchCandidate />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="saved-searches"
        element={
          <ProtectedEmployerRoute>
            <SavedSearch />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/unlock-candidates"
        element={
          <ProtectedEmployerRoute>
            <CandidateSearchList />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/candidate-searchList"
        element={
          <ProtectedEmployerRoute>
            <CandidateSearchList />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employerProfile"
        element={
          <ProtectedEmployerRoute>
            <EmployerProfile />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/team-members"
        element={
          <ProtectedEmployerRoute>
            <EmployerTeamMembers />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employerRegistration"
        element={
          <ProtectedEmployerRoute>
            <EmployerRegistration />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employer/manageUsers"
        element={
          <ProtectedEmployerRoute>
            <ManageUsers />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employer/manageQuota"
        element={
          <ProtectedEmployerRoute>
            <ManageQuota />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employer/companyProfile"
        element={
          <ProtectedEmployerRoute>
            <CompanyProfile />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/jobSeeker/invoices"
        element={
          <ProtectedEmployerRoute>
            <JobSeekerInvoice />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employer/subscriptionStatus"
        element={
          <ProtectedEmployerRoute>
            <SubscriptionStatus />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/jobPosting"
        element={
          <ProtectedEmployerRoute>
            <JobPostPage />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/subcriptions"
        element={
          <ProtectedEmployerRoute>
            <SubScriptionPage />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/subscriptionInvoice/:invoiceId"
        element={
          <ProtectedEmployerRoute>
            <EmployerInvoice />
          </ProtectedEmployerRoute>
        }
      />
      <Route path="/employer-reports" element={<EmployerReports />} />

      <Route
        path="/deleteAccount"
        element={
          <ProtectedEmployerRoute>
            <DeleteAccount />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/paymentVerify"
        element={
          <ProtectedEmployerRoute>
            <PaymentCallback />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/paymentFailure"
        element={
          <ProtectedEmployerRoute>
            <PaymentFailure />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/paymentSuccess"
        element={
          <ProtectedEmployerRoute>
            <PaymentSuccess />
          </ProtectedEmployerRoute>
        }
      />
      <Route
        path="/employerSupport"
        element={
          <ProtectedEmployerRoute>
            <EmployerSupport />
          </ProtectedEmployerRoute>
        }
      />
      <Route path="/employer-faq" element={<EmployerFAQ />} />

      {/* JobSeeker Routes */}
      <Route path="/signinsignup" element={<LoginPage />} />
      <Route path="/registerFromfill" element={<RegisterForm />} />
      <Route
        path="/jobSeekerRegestrationProfile"
        element={<JobSeekerRegestrationProfile />}
      />
      <Route path="/JobSeekerFilter" element={<JobSeekerFilter />} />
      <Route
        path="/JobSeekerFilter/job/:id"
        element={<JobSeekerJobDetails />}
      />
      <Route
        path="/jobSeeker/support"
        element={
          <ProtectedJobSeekerRoute>
            {" "}
            <JobSeekerSupport />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerPersonalProfile />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobSeeker-Profile"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerProfileDetail />{" "}
          </ProtectedJobSeekerRoute>
        }
      />
      <Route path="/findJobs" element={<JobSeekerFilter />} />
      <Route
        path="/jobSeekerAppliedJobs"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerAppliedJobs />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobSeekerSavedJobs"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerSavedJobs />
          </ProtectedJobSeekerRoute>
        }
      />

      <Route
        path="/jobsApplied/:id"
        element={
          <ProtectedJobSeekerRoute>
            <JobAppliedDetails />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobsSaved/:id"
        element={
          <ProtectedJobSeekerRoute>
            <JobSavedDetails />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobSeekerPaymentVeirfy"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerPaymentVerification />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobseekerpayment-successful"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerPaymentSuccessful />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobseekerpayment-failed"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekerPaymentFailed />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route
        path="/jobSeekeerSubscription"
        element={
          <ProtectedJobSeekerRoute>
            <JobSeekeerSubscription />
          </ProtectedJobSeekerRoute>
        }
      />
      <Route path="/top-hiring-companies" element={<TopHiringCompanies />} />
      <Route
        path="/organizations-by-industry"
        element={<TopHiringCompanies />}
      />
      <Route
        path="/top-industries-companies"
        element={<JobSeekerTopIndustries />}
      />

      <Route
        path="/organizationOverview"
        element={
          <ProtectedJobSeekerRoute>
            <OrgOverView />{" "}
          </ProtectedJobSeekerRoute>
        }
      />
      <Route path="/job-seeker-faq" element={<JobSeekerFAQ />} />
      <Route path="/payment-policy" element={<JobSeekerPaymentPolicy />} />
      <Route path="/privacy-policy" element={<JobSeekerPrivacyPolicy />} />
      <Route path="/terms-&-cons" element={<JobSeekerTermsAndCons />} />

      <Route path="/about-vacanzi" element={<JobSeekerAboutUs />} />
    </Routes>
  </Router>
);

export default RouteComponent;
