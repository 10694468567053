import * as React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { editAccountDetails } from "../../../api/companyProfile";

function CompanyDetailsModel({
  openCompanyDialog,
  handleCompanyDialogClose,
  orgData,
}) {
  // Initialize formDetails state
  const [formDetails, setFormDetails] = useState({
    industryType: "",
    contactPersonName: "",
    website: "",
    contactNumber: "",
    contactPersonNumber: "",
  });

  // Update formDetails state when orgData changes
  useEffect(() => {
    if (orgData) {
      setFormDetails({
        industryType: orgData.industryType || "",
        contactPersonName: orgData.contactPersonName || "",
        website: orgData.website || "",
        contactNumber: orgData.contactNumber || "",
        contactPersonNumber: orgData.contactPersonNumber || "",
      });
    }
  }, [orgData]);

  // Handle input changes and update formDetails state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevData) => ({
      ...prevData,
      [name]: value, // Update the specific field in formDetails
    }));
  };

  // Function to handle saving company details
  const editCompanyDetails = async () => {
    const orgId = localStorage.getItem("organizationId");
    const token = localStorage.getItem("token");
    if (orgId) {
      try {
        await editAccountDetails(orgId, token, formDetails);
        handleCompanyDialogClose();
      } catch (error) {
        console.log(error.message);
      }
    } else {
      throw new Error("No organization ID");
    }
  };

  return (
    <Dialog open={openCompanyDialog} onClose={handleCompanyDialogClose}>
      <DialogTitle>Edit Company Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Industry Type
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="industryType"
              value={formDetails.industryType} // Use formDetails state for value
              onChange={handleInputChange} // Update state on change
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Contact Person
            </Typography>
            <TextField
              disabled
              fullWidth
              variant="outlined"
              size="small"
              name="contactPersonName"
              value={formDetails.contactPersonName} // Use formDetails state for value
              onChange={handleInputChange} // Update state on change
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Website URL
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="website"
              value={formDetails.website} // Use formDetails state for value
              onChange={handleInputChange} // Update state on change
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Phone Number 1
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="contactNumber"
              value={formDetails.contactNumber} // Use formDetails state for value
              onChange={handleInputChange} // Update state on change
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component="legend" sx={{ fontSize: 12 }}>
              Phone Number 2
            </Typography>
            <TextField
              disabled
              fullWidth
              variant="outlined"
              size="small"
              name="contactPersonNumber"
              value={formDetails.contactPersonNumber} // Use formDetails state for value
              onChange={handleInputChange} // Update state on change
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          sx={{
            bgcolor: "#FA3B3BFF",
            borderRadius: '2rem',
            ":hover": {
              backgroundColor: "#FA3B3BFF",
            },
          }}
          onClick={handleCompanyDialogClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            bgcolor: "#423bfa",
            color: "white",
            borderRadius: '2rem',
            ":hover": {
              backgroundColor: "#423bfa",
            },
          }}
          onClick={editCompanyDetails} // Save the details
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompanyDetailsModel;
