import React, { useState } from "react";
import {
  Paper,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Avatar,
  InputAdornment,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import userAvatar from "../../../assets/images/avatarwomen.jpg";

const EmployerChatList = ({ onChatClick, users }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredUsers = Array.isArray(users)
    ? users.filter((user) => {
        const userName = user?.organization?.organizationName || "";
        const profileImage = user?.user?.profileImage || "P";
        const lastMessage = user?.messages?.length
          ? user.messages[user.messages.length - 1].messageContent
          : "";

        const searchTarget =
          `${profileImage} ${userName} ${lastMessage}`.toLowerCase();
        return searchTarget.includes(searchTerm.toLowerCase());
      })
    : [];

  return (
    <Paper
      elevation={0}
      sx={{
        maxWidth: 430,
        width: "100%", // Ensures responsiveness
        padding: 2,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        boxSizing: "border-box", // Ensures consistent box model
      }}
    >
      <TextField
        placeholder="Search..."
        variant="standard"
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        sx={{
          marginBottom: 2,
          backgroundColor: "transparent",
          border: "1px solid #ccc",
          borderRadius: "14px",
          padding: "4px 8px",
        }}
      />

      <Box
        sx={{
          overflowY: "auto",
          height: "100%",
        }}
      >
        {filteredUsers && filteredUsers.length > 0 ? (
          <List>
            {filteredUsers.map((user) => {
              const userName =
                user?.organization?.organizationName || "Unknown Topic";
              const profileImage = user?.user?.profileImage || "P";
              const lastMessage = user?.messages?.length
                ? user.messages[user.messages.length - 1].messageContent
                : "No messages yet";

              return (
                <ListItem
                  key={user._id}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                    display: "flex",
                    alignItems: "center",
                    gap: 2, // Adds consistent spacing between elements
                  }}
                  onClick={() => onChatClick(user)}
                >
                  <Avatar
                    sx={{
                      width: 40,
                      height: 40,
                      flexShrink: 0,
                    }}
                    src={profileImage || userAvatar}
                  >
                    {userName.charAt(0)}
                  </Avatar>

                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "text.primary",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {userName}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "text.secondary",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {lastMessage}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Typography>No Chats Found</Typography>
        )}
      </Box>
    </Paper>
  );
};

export default EmployerChatList;
