import React, { useState, useEffect } from "react";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import { getloggedHours } from "../../../api/employerAuth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const EmployerReports = () => {
  const [loggedHours, setLoggedHours] = useState([]);

    const navigate = useNavigate();
  

  // Convert UTC time to local time
  const convertToLocalTime = (utcTime) => {
    const date = new Date(utcTime);
    return date.toLocaleTimeString();
  };

  // Convert UTC time to local date
  const convertToLocalDate = (utcTime) => {
    const date = new Date(utcTime);
    return date.toLocaleDateString();
  };

  // Calculate duration between login and logout
  const calculateDuration = (loginTime, logoutTime) => {
    const loginDate = new Date(loginTime);
    const logoutDate = new Date(logoutTime);
    const durationMs = logoutDate - loginDate;
    const totalMinutes = Math.floor(durationMs / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const token = localStorage.getItem("token");
  const recruiterId = localStorage.getItem("recruiterId");

  // Fetch logged hours
  useEffect(() => {
    const totalLoggedHours = async () => {
      try {
        const response = await getloggedHours(token, recruiterId);
        setLoggedHours(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    totalLoggedHours();
  }, [token, recruiterId]);

  // Sort sessions by loginTime (most recent first)
  const sortedSessions = loggedHours?.sessions
    ? [...loggedHours.sessions].sort((a, b) => {
        const dateA = new Date(a.loginTime);
        const dateB = new Date(b.loginTime);
        return dateB - dateA; // Sort in descending order (most recent first)
      })
    : [];

    const handleBack = () => {
      navigate(-1); // Go back to the previous page
    };
  

  return (
    <EmployerDrawer>
       <Typography
        onClick={handleBack}
        gutterBottom
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          mb: "15px",
          color: "grey", // Adjust color for better visibility
          "&:hover": {
            color: "primary.dark", // Hover effect
          },
        }}
      >
        <ArrowBackIcon sx={{ marginRight: "4px" }} />
      </Typography>
      <Box sx={{ mb: 3, overflow: "hidden" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", ml: 3, mb: 1 }}>
          Login Reports
        </Typography>

        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: 3,
            gap: 2,
            bgcolor: "#FFFFFFFF",
            mr: 3,
            borderRadius: 2,
            mb: 3,
          }}
        >
          <TableContainer sx={{ margin: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Sl.No</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Log In Time</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Log In Date</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Log Out Time
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Log Out Date
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Duration</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedSessions.map((session, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {convertToLocalTime(session?.loginTime)}
                    </TableCell>
                    <TableCell>
                      {convertToLocalDate(session?.loginTime)}
                    </TableCell>
                    <TableCell>
                      {session?.logoutTime
                        ? convertToLocalTime(session?.logoutTime)
                        : "Online"}
                    </TableCell>
                    <TableCell>
                      {session?.logoutTime
                        ? convertToLocalDate(session?.logoutTime)
                        : "Online"}
                    </TableCell>
                    <TableCell>
                      {session?.logoutTime
                        ? calculateDuration(
                            session?.loginTime,
                            session?.logoutTime
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </EmployerDrawer>
  );
};

export default EmployerReports;