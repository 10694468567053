import React from "react";
import {
  TextField,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

const EmployerRegistrationForm = ({ formData, handleChange }) => {
  return (
    <>
      <Typography variant="body2" textAlign="center" gutterBottom>
        We use this information to know about the company you’re hiring for and
        to generate an invoice
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {" "}
          {/* Two items per row on medium screens and larger */}
          <TextField
            fullWidth
            placeholder="Enter Company Name"
            name="organizationName"
            variant="outlined"
            value={formData.organizationName}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>Industry Type</InputLabel>
            <Select
              name="industryType"
              value={formData.industryType}
              onChange={handleChange}
              label="Industry Type"
            >
              <MenuItem value="">
                <em>Select Industry</em>
              </MenuItem>
              <MenuItem value="Tech">Tech</MenuItem>
              <MenuItem value="Finance">Finance</MenuItem>
              <MenuItem value="Marketing">Marketing</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            placeholder="Enter Contact Person Name"
            name="contactPersonName"
            variant="outlined"
            value={formData.contactPersonName}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            placeholder="Enter Contact Person Phone Number"
            name="contactPersonNumber"
            variant="outlined"
            value={formData.contactPersonNumber}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            placeholder="Enter Company Address"
            name="organizationAddress"
            variant="outlined"
            value={formData.organizationAddress}
            onChange={handleChange}
            size="small"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            placeholder="Enter website URL"
            name="website"
            variant="outlined"
            value={formData.website}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            placeholder="Number Of Employees"
            name="numberOfEmployees"
            variant="outlined"
            value={formData.numberOfEmployees}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            placeholder="Enter Country"
            name="orgCountry"
            variant="outlined"
            value={formData.orgCountry}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            placeholder="Enter State"
            name="orgState"
            variant="outlined"
            value={formData.orgState}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            placeholder="Enter City"
            name="orgCity"
            variant="outlined"
            value={formData.orgCity}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            placeholder="Enter Pincode"
            name="orgPinCode"
            variant="outlined"
            value={formData.orgPinCode}
            onChange={handleChange}
            size="small"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EmployerRegistrationForm;
