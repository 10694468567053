import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import searchCandidates from "../../styles/searchCandidatesStyles";


const AddItSkills = ({ setAddItSkillsForm, addSkills, setAddskills }) => {
  const [inputValue, setInputValue] = useState(""); // Current skill being typed
  const [selectedSkills, setSelectedSkills] = useState([]); // List of selected skills

  // Update parent state whenever selected skills change
  useEffect(() => {
    setAddskills(selectedSkills); // Update parent state
  }, [selectedSkills, setAddskills]);

  // Handle input change
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Handle Enter key press to add a skill
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      addSkill();
    }
  };

  // Add a skill (common function for Enter key and button)
  const addSkill = () => {
    if (inputValue.trim() !== "") {
      setSelectedSkills([...selectedSkills, inputValue.trim()]); // Add the skill
      setInputValue(""); // Clear the input field
    }
  };

  // Remove a skill
  const handleRemoveSkill = (index) => {
    const updatedSkills = selectedSkills.filter((_, i) => i !== index);
    setSelectedSkills(updatedSkills);
  };

  // // Close the form if no skills are left
  // const handleCloseForm = () => {
  //   if (selectedSkills.length === 0) {
  //     setAddItSkillsForm(false);
  //   }
  // };

  return (
    <Box>
      <Typography variant="h6" sx={{ fontSize: 13, mb: 1 }}>
        Add Skills
      </Typography>

      <Box sx={{ ...searchCandidates.flexboxCenter, gap: 2 }}>
        <TextField
          sx={{ width: "100%", maxWidth: "400px" }} // Increased width
          size="small"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Add Skills"
          variant="outlined"
          InputProps={{
            startAdornment: selectedSkills.map((skill, index) => (
              <Chip
                key={index}
                label={skill}
                onDelete={() => handleRemoveSkill(index)}
                sx={{ marginRight: 1 }}
              />
            )),
          }}
        />
      </Box>

      <Box sx={{ ...searchCandidates.flexboxend, mt: 2 }}>
        <Button
          sx={{ ...searchCandidates.button, fontSize: 11 }}
          variant="contained"
          onClick={addSkill}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
};

export default AddItSkills;