import baseURL from "./baseUrl";

export const getTopOrgs = async () => {
    const authToken = localStorage.getItem('authToken')?.replace(/"/g, '');

    try {
        const response = await fetch(`${baseURL}/org/getFeaturedOrg`, {


            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                Authorization: `Bearer ${authToken}`,
            },
        });

        if (!response.ok) {
            throw new Error("network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.log(error.message);
    }
};


export const getTopIndustries = async () => {
    const authToken = localStorage.getItem('authToken')?.replace(/"/g, '');

    try {
        const response = await fetch(`${baseURL}/jobpost/industry`, {


            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                Authorization: `Bearer ${authToken}`,
            },
        });

        if (!response.ok) {
            throw new Error("network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.log(error.message);
    }
};


export const getFeaturedJobSeekers = async () => {
    const authToken = localStorage.getItem('authToken')?.replace(/"/g, '');

    try {
        const response = await fetch(`${baseURL}/featured/jobseekers`, {


            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                Authorization: `Bearer ${authToken}`,
            },
        });

        if (!response.ok) {
            throw new Error("network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.log(error.message);
    }
};

export const getTrendingJobs = async () => {
    const authToken = localStorage.getItem('authToken')?.replace(/"/g, '');

    try {
        const response = await fetch(`${baseURL}/organization/trending-jobs`, {
            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                Authorization: `Bearer ${authToken}`,
            },
        });

        if (!response.ok) {
            throw new Error("network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.log(error.message);
    }
};


export const getTopIndustryMasters = async () => {
    const authToken = localStorage.getItem('authToken')?.replace(/"/g, '');

    try {
        const response = await fetch(`${baseURL}/Industry`, {
            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                Authorization: `Bearer ${authToken}`,
            },
        });

        if (!response.ok) {
            throw new Error("network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.log(error.message);
    }
};



export const getTopCitiesApi = async (query = "", isTopCity = false) => {
    try {
      // Construct query parameters
      const params = new URLSearchParams();
      if (query) params.append("query", query); // Add query parameter if provided
      if (isTopCity) params.append("topCity", true); // Add topCity parameter if true
  
      // Make the API call with query parameters
      const response = await fetch(`${baseURL}/get/cities?${params.toString()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
      return data; // Return the parsed JSON data
    } catch (error) {
      console.error("Error in getTopCitiesApi:", error.message);
      throw error; // Re-throw the error to handle it in the calling function
    }
  };


  
export const allDepartmentsApi = async () => {
   
    try {
        const response = await fetch(`${baseURL}/departments`, {
            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                
            },
        });

        if (!response.ok) {
            throw new Error("network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.log(error.message);
    }
};
  

export const getCunsellorsApi = async () => {
   
    try {
        const response = await fetch(`${baseURL}/get/counsellors`, {
            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                
            },
        });

        if (!response.ok) {
            throw new Error("network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.log(error.message);
    }
};
  