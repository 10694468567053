import React, { useState, useEffect } from "react";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import {
  Box,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Avatar,
  MenuItem,
  Button,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import VacanziLogo from "../../../../assets/icons/logos/vacanzi.png";
import { useNavigate } from "react-router-dom";
// import avatarmen from "../../../../assets/images/avatarmen.jpg";
// import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SupportIcon from "@mui/icons-material/Support";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode
// import EmployerDrawerList from "./EmployerDrawerList";
import DeleteIcon from "@mui/icons-material/Delete";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { logoutApi } from "../../../../api/employerAuth";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PaymentDialog from "../../../AdvancedComponents/PaymentModel";
import EmployeeDrawerList from "./EmployerDrawerList";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import SettingsIcon from "@mui/icons-material/Settings";
// import HelpIcon from "@mui/icons-material/Help";
// import FeedbackIcon from "@mui/icons-material/Feedback";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import BusinessIcon from "@mui/icons-material/Business";
import EmployerJobSeekerSupport from "../../../../pages/EmployerPages/EmployerJobSeekerSupport/EmployerJobSeekerSupport";
// import zIndex from "@mui/material/styles/zIndex";
// import { borderRadius, fontFamily } from "@mui/system";
import { getOrgWalletApi, getRecruiterApi } from "../../../../api/payment";
import PersonIcon from "@mui/icons-material/Person";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 10,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const RightDrawer = styled(MuiDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: 300,
    boxSizing: "border-box",
    fontFamily: "IBM plex sans",
    zIndex: 11, // Ensure it's above everything
    position: "fixed",
    backgroundColor: "#ffffff", // White background
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add shadow for depth
    borderRadius: "20px 0 10px 0",
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: 9,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const EmployerDrawer = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [wallet, setWallet] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userData, setUserData] = useState(null); // State to store user data
  // const [loggedHours, setLoggedHours] = useState();
  const [openPayDialog, setOpenPayDialog] = useState(false);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState(null); // Changed state name to 'menuAnchor'

  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");
  const recruiterId = localStorage.getItem("recruiterId");

  const [recruiterDetails, setRecruiterDetails] = useState("");
  // const recruiterId = localStorage.getItem("recruiterId");
  const handleOpenPayMenu = () => {
    setOpenPayDialog(true);
  };
  const handlePayMenuClose = () => {
    setOpenPayDialog(false);
  };

  const handleClick = (event) => {
    setMenuAnchor(event.currentTarget); // Open the menu when button is clicked
  };

  const handleClose = () => {
    setMenuAnchor(null); // Close the menu
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserData(decodedToken); // Set user data from decoded token
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    } else {
      navigate("/employerSignIn"); // Redirect to login if no token found
    }
  }, [navigate, token]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleNavigation = (path, index) => {
    setSelectedIndex(index);
    navigate(path);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  // const handleMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleMenuClose();
    navigate("/employerProfile");
  };

  //signout functionality
  const handleSignOutClick = async () => {
    try {
      const formValues = {
        recruiterId: recruiterId,
      };
      await logoutApi(formValues);
      // handleMenuClose();
      navigate("/");
      localStorage.clear();
    } catch (error) {
      console.log(error.message);
    }
  };

  //   useEffect(()=>{
  //     const getEmployerDetails = async()=>{
  //       try{
  //      const response = await getRecruiterDetailsApi(recruiterId,token)
  //      setRecruiterData(response)
  //       }
  //       catch(error){
  //         console.log(error.message)
  //       }
  //     }
  //     getEmployerDetails()
  //   },[])
  // console.log('recruiterDta',recruiterData)

  // useEffect(() => {
  //   const totalLoggedHours = async () => {
  //     try {
  //       const response = await getloggedHours(token, recruiterId); // Pass recruiterId
  //       setLoggedHours(response);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   totalLoggedHours();
  // }, [token, recruiterId]);

  useEffect(() => {
    const getOrgWallet = async () => {
      try {
        const response = await getOrgWalletApi(orgId, token);
        setWallet(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    getOrgWallet();
  }, [orgId, token]);

  useEffect(() => {
    const getRecruiter = async () => {
      try {
        const response = await getRecruiterApi();
        setRecruiterDetails(response?.recruiter);
      } catch (error) {
        console.log(error.message);
      }
    };
    getRecruiter();
  }, []);

  const profileImage = recruiterDetails?.organization?.companyLogo?.url;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", backgroundColor: "#f5f5f5" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "#ffffff",
            boxShadow: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="toggle drawer"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{ color: "black", mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <img
              style={{ height: 50, width: 50 }}
              src={VacanziLogo}
              alt="Vacanzi Logo"
            />
            <Typography
              variant="h4"
              noWrap
              component="a"
              href="/search-candidates"
              sx={{
                display: { xs: "none", md: "flex" },
                fontFamily: "GlanceSansBold",
                color: "#423BFA",
                textDecoration: "none",
                ml: 2,
              }}
            >
              Vacanzi
            </Typography>
            {/* <MenuItem
            onClick={() => handleNavigation("/jobseekerProfile")}
            sx={{ ml: 2 }}
          >
            <Typography variant="body2" sx={{ color: "#423BFA" }}>
              JobSeeker Profiles
            </Typography>
          </MenuItem> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <>
                {userData?.role === "recruiter_admin" && (
                  <div>
                    <Button
                      sx={{
                        marginRight: 5,
                        display: "flex",
                        alignItems: "center",
                        color: "black",
                        textTransform: "none",
                        backgroundColor: "transparent",
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                      disableRipple
                      onClick={handleClick} // Open the menu on button click
                    >
                      <AccountBalanceWalletIcon sx={{ mr: 0.5 }} />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "poppins",
                          fontSize: 17,
                          marginRight: 4,
                        }}
                      >
                        Rs.{" "}
                      </span>
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: "bold",
                          color: "green",
                        }}
                      >
                        {wallet?.balance}
                      </Typography>
                    </Button>

                    <Menu
                      anchorEl={menuAnchor} // Menu anchored to the button using the new state name
                      open={Boolean(menuAnchor)} // Check if the menu is open
                      onClose={handleClose} // Close the menu when clicked outside
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleOpenPayMenu}>
                        {" "}
                        Add Money
                      </MenuItem>{" "}
                    </Menu>
                  </div>
                )}
                {/* <Button
                  sx={{
                    marginRight: 5,
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    textTransform: "none",
                    backgroundColor: "transparent",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  disableRipple
                >
                  <MonetizationOnIcon sx={{ mr: 0.5 }} />
                  Available Credits
                </Button> */}
                <Button
                  sx={{
                    marginRight: 2,
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    textTransform: "none",
                    backgroundColor: "transparent",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  disableRipple
                  onClick={() => navigate("/employerSupport")}
                >
                  <SupportIcon sx={{ mr: 0.5 }} />
                  Support
                </Button>
              </>
            </Box>

            <IconButton
              onClick={() => setOpenRightDrawer(true)}
              sx={{ color: "inherit" }}
            >
              <Avatar
                src={recruiterDetails?.organization?.companyLogo?.url}
                alt="User Avatar"
              />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  borderRadius: 2,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  minWidth: 250,
                },
              }}
            >
              <MenuItem sx={{ display: "flex", alignItems: "center", px: 2 }}>
                <Avatar
                  src={profileImage}
                  alt="User Avatar"
                  sx={{ width: 40, height: 40 }}
                />
                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {userData?.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Role: {userData?.role}
                  </Typography>
                </Box>
              </MenuItem>
              <Divider />
              {/* <MenuItem sx={{ px: 2, "&:hover": { bgcolor: "action.hover" } }}>
              <ListItemIcon>
                <AccessTimeIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Logged Hours"
                secondary={`${loggedHours?.totalHours} hrs`}
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "bold",
                  color: "text.primary",
                }}
                secondaryTypographyProps={{
                  variant: "caption",
                  color: "text.secondary",
                }}
              />
            </MenuItem> */}
              {/* <MenuItem
              onClick={handleProfileClick}
              sx={{ px: 2, "&:hover": { bgcolor: "action.hover" } }}
            >
              <ListItemIcon>
                <AccountCircleIcon color="action" />
              </ListItemIcon>
              <ListItemText
                primary="View Profile"
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "bold",
                  color: "text.primary",
                }}
              />
            </MenuItem> */}
              {/* <MenuItem
              onClick={() => navigate("/deleteAccount")}
              sx={{
                px: 2,
                "&:hover": { bgcolor: "rgba(255, 0, 0, 0.1)" },
              }}
            >
              <ListItemIcon>
                <DeleteIcon sx={{ color: "red" }} />
              </ListItemIcon>
              <ListItemText
                primary="Delete Account"
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "bold",
                  color: "red",
                }}
              />
            </MenuItem> */}
              <MenuItem
                onClick={handleSignOutClick}
                sx={{
                  px: 2,
                  "&:hover": { bgcolor: "rgba(255, 0, 0, 0.1)" },
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Sign Out"
                  primaryTypographyProps={{
                    variant: "body2",
                    fontWeight: "bold",
                    color: "text.primary",
                  }}
                />
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <EmployeeDrawerList
          Drawer={Drawer}
          userRole={userData?.role}
          theme={theme}
          DrawerHeader={DrawerHeader}
          open={open}
          selectedIndex={selectedIndex}
          handleDrawerToggle={handleDrawerToggle}
          handleNavigation={handleNavigation}
        />

        <RightDrawer
          anchor="right"
          open={openRightDrawer}
          onClose={() => setOpenRightDrawer(false)}
        >
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Avatar
                src={profileImage}
                sx={{ width: 56, height: 56, bgcolor: "primary.main" }} // Customize avatar
              ></Avatar>
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {userData?.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {userData?.email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {userData?.role}
                </Typography>
              </Box>
            </Box>
            <Divider />
          </Box>

          {/* Menu Items */}
          <MenuItem
            sx={{ px: 2, "&:hover": { bgcolor: "action.hover" } }}
            onClick={handleProfileClick}
          >
            <ListItemIcon>
              <PersonIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="View Profile"
              primaryTypographyProps={{
                variant: "body2",
                fontWeight: "bold",
                color: "text.primary",
              }}
            />
          </MenuItem>
          <MenuItem
            sx={{ px: 2, "&:hover": { bgcolor: "action.hover" } }}
            onClick={() => navigate("/employer/companyProfile")}
          >
            <ListItemIcon>
              <BusinessIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Company Profile"
              primaryTypographyProps={{
                variant: "body2",
                fontWeight: "bold",
                color: "text.primary",
              }}
            />
          </MenuItem>
          <MenuItem
            sx={{ px: 2, "&:hover": { bgcolor: "action.hover" } }}
            onClick={() => navigate("/employer/subscriptionStatus")}
          >
            <ListItemIcon>
              <SubscriptionsIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="My Subscriptions"
              primaryTypographyProps={{
                variant: "body2",
                fontWeight: "bold",
                color: "text.primary",
              }}
            />
          </MenuItem>
          <MenuItem
            sx={{ px: 2, "&:hover": { bgcolor: "action.hover" } }}
            onClick={() => navigate("/deleteAccount")}
          >
            <ListItemIcon>
              <DeleteIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Delete Account"
              primaryTypographyProps={{
                variant: "body2",
                fontWeight: "bold",
                color: "text.primary",
              }}
            />
          </MenuItem>
          {/* <MenuItem sx={{ px: 2, "&:hover": { bgcolor: "action.hover" } }}>
            <ListItemIcon>
              <AccessTimeIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Logged Hours"
              secondary={`${loggedHours?.totalHours} hrs`}
              primaryTypographyProps={{
                variant: "body2",
                fontWeight: "bold",
                color: "text.primary",
              }}
              secondaryTypographyProps={{
                variant: "caption",
                color: "text.secondary",
              }}
            />
          </MenuItem> */}
          <Divider sx={{ mb: "50px" }} />

          <MenuItem
            onClick={handleSignOutClick}
            sx={{
              px: 2,
              mt: 1,
              fontFamily: "IBM plex sans",
              "&:hover": { bgcolor: "rgba(255, 0, 0, 0.1)" },
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon sx={{ color: "red" }} />
            </ListItemIcon>
            <ListItemText
              primary="Logout from Vacanzi"
              primaryTypographyProps={{
                variant: "body2",
                fontWeight: "bold",
                color: "red",
              }}
            />
          </MenuItem>
        </RightDrawer>

        <Box
          component="main"
          sx={{ width: "100%", minHeight: "100vh", bgcolor: "#F4EEEEFF", p: 3 }}
        >
          <DrawerHeader />
          {children}
        </Box>
        <PaymentDialog
          openPayDialog={openPayDialog}
          handlePayMenuClose={handlePayMenuClose}
        />
      </Box>
      <EmployerJobSeekerSupport />
    </ThemeProvider>
  );
};

export default EmployerDrawer;
