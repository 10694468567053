import React from "react";
import { Box, IconButton, Card, CardMedia, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const CompanyVideoCarousel = ({ videos, onVideoSelect }) => {
  const [startIndex, setStartIndex] = React.useState(0);

  const handlePrev = () => {
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setStartIndex((prev) => Math.min(prev + 1, videos.length - 3));
  };

 
  return (
    <Box sx={{ width: 1200, position: "relative", overflow: "hidden", p: 1 }}>
      <Typography
        sx={{ fontFamily: "IBM plex sans", fontWeight: "bold", mb: 1 }}
      >
        Previously Posted Jobs
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 4,
          transition: "transform 0.3s ease-in-out",
          transform: `translateX(-${startIndex * (100 / 3)}%)`,
          borderRadius: 0,
        }}
      >
        
        {videos?.map((video) => (
          <Card
            key={video.id}
            sx={{
              maxWidth: `calc(100% / 4.7 - 6px)`, // Each slide takes 1/3 of the carousel width
              borderRadius: 2,
              cursor: "pointer",
              width:200,
              flexShrink: 0,
              backgroundColor: "#EAFBF4FF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => onVideoSelect(video)}
          >
            <CardMedia
              component="video"
              image={video?.jobVideoUrl}
              alt={"No Job video found for this"}
              sx={{
                borderRadius: 2,
                height: 300,
                width: "100%",
                objectFit: "cover",
              }}
            />
            <Typography
              sx={{
                backgroundColor: "#EAFBF4FF",
                fontFamily: "IBM plex sans",
                fontSize: 12,
                px: 2,
              }}
            >
              {video.jobTitle}
            </Typography>
          </Card>
        ))}
      </Box>

      {/* Left Navigation Button */}
      <IconButton
        onClick={handlePrev}
        disabled={startIndex === 0}
        sx={{
          position: "absolute",
          left: 8,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <ChevronLeftIcon />
      </IconButton>

      {/* Right Navigation Button */}
      <IconButton
        onClick={handleNext}
        disabled={startIndex >= videos?.length - 3}
        sx={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default CompanyVideoCarousel;
