import React from 'react';
import { Box, Button, Typography, Modal, TextField } from '@mui/material';

const OTPModal = ({
    open,
    handleClose,
    handleFieldChange,
    handleLoginSubmit,
    canResend,
    timer,
    email,
    otp,
    errors,
    handleOtpSubmit,
}) => {

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleOtpSubmit();
        }
      };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="otp-modal-title"
            closeAfterTransition

        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 420,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 3,

                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', marginBottom: '16px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
                >
                    Enter One Time Password to proceed
                </Typography>

                <Typography variant="body2" sx={{ color: '#535252' }}>
                    For enhanced security of your account, we have sent a One-Time Password (OTP) to
                    your <b>mobile number or email ID {email}</b>.
                </Typography>
                <Typography fontSize='14px' sx={{ color: '#535252' }}>Please enter the OTP </Typography>
                <TextField
                    value={otp}
                    size="small"
                    fullWidth
                    placeholder="Fill OTP received on mobile / email"
                    onChange={(event) => handleFieldChange('otp', event.target.value)} // Corrected
                    onKeyDown={handleKeyDown}
                    error={!!errors.otp}
                    inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: {
                            fontSize: '16px', // Adjust the font size of the input value
                        },
                    }}
                    sx={{
                        '& input::placeholder': {
                            fontSize: '13px', // Adjust placeholder font size
                            color: '#535252', // Optional: Change placeholder color
                        },
                    }}
                />

                <Typography color="error" sx={{ mt: 1 }}>
                    {errors.otp}
                </Typography>

                <Button
                    fullWidth
                    variant="contained"
                    sx={{
                        mt: 2,
                        backgroundColor: '#423bfa',
                        color: 'white',
                        fontWeight: 'bold',
                        padding: '0.5rem',
                        textTransform: 'none',
                    }}
                    onClick={handleOtpSubmit}
                >
                    Verify
                </Button>
                {canResend ? (
                    <>
                        <Typography fontSize='14px' sx={{ color: '#535252' }}>Didn't Receive OTP?
                            <Button color="primary" onClick={handleLoginSubmit} sx={{ textTransform: 'none' }}>
                                <Typography fontSize='14px' fontWeight='bold'>Resend OTP</Typography>
                            </Button>
                        </Typography>
                    </>
                ) : (
                    <Typography fontSize='14px'>Resend OTP in {timer}s</Typography>
                )}
            </Box>
        </Modal>
    );
};

export default OTPModal;
