import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VideoShortsScroll = ({ videos, thumbNails, industryData }) => {
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 5 videos at a time
    slidesToScroll: 1, // Scroll 1 video at a time
    nextArrow: (
      <ArrowForwardIcon
        sx={{
          fontSize: "2rem",
          color: "#052341FF",
          cursor: "pointer",
          ":hover": { color: "#59A3EDFF" },

          transform: "translateY(-100%)",
          zIndex: 1,
        }}
      />
    ),
    prevArrow: (
      <ArrowBackIcon
        sx={{
          fontSize: "2rem",
          color: "#052341FF",
          cursor: "pointer",
          ":hover": { color: "#59A3EDFF" },

          transform: "translateY(-100%)",
          zIndex: 1,
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "80vw",
        maxWidth: "1200px",
        margin: "0 auto",
        paddingInline: { xs: 2, sm: 0 },
      }}
    >
      {thumbNails.length === 0 ? (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", color: "#6B7280", mt: 4 }}
        >
          No thumbnails found
        </Typography>
      ) : (
        <Slider {...settings}>
          {thumbNails.map((thumbnail, index) => {
            const industryItem = industryData[index]; // Fetch industry data for the current thumbnail
            return (

              <Box
                key={index}
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "10px",
                    overflow: "hidden",
                    maxWidth: "200px",
                    height: "280px",
                    margin: "0 auto",
                    backgroundColor: "#f0f0f0",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the event from bubbling up to the slider
                    setActiveVideoIndex(activeVideoIndex === index ? null : index);
                    navigate(`/JobSeekerFilter/job/${industryItem?._id}`)
                  }}
                >
                  {activeVideoIndex === index ? (
                    <video
                      width="100%"
                      height="300px"
                      autoPlay
                      style={{ objectFit: "cover", borderRadius: "10px" }}
                    >
                      <source src={videos[index]} type="video/mp4" />
                      Your browser does not support HTML video.
                    </video>
                  ) : (
                    <img
                      src={thumbnail}
                      alt={`Thumbnail ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </Box>
                {/* Display Job Title and Job Type */}
                {industryItem && (
                  <Box sx={{ mt: 2, cursor: 'pointer' }} onClick={() => navigate(`/JobSeekerFilter/job/${industryItem?._id}`)}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", color: "#052341FF", fontSize: "1rem" }}
                    >
                      {industryItem.jobTitle}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#6B7280", fontSize: "0.9rem" }}
                    >
                      {industryItem.jobType}
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          })}        </Slider>
      )}
    </Box>
  );
};

export default VideoShortsScroll;
