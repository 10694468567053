import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Paper, Button, Stepper, Step, StepLabel, Divider } from '@mui/material';
import NonRegisteredAppBar from '../../../components/NavBarNonRegistered/NonRegisteredNavBar';
import VacanziLogo from '../../../assets/icons/logos/vacanzi.png';
import EmployerRegistrationForm from './EmployerRegistrationForm';
import EmployerVerifyDetails from './EmployerVerifyDetails';
import baseURL from '../../../api/baseUrl';

const steps = ['Company Details', 'Register Account'];

const EmployerRegistration = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Use navigate from react-router-dom

    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        name: location.state?.signupName,
        email: location.state?.signupEmail,
        contactNumber: location.state?.signupPhone,
        role: location.state?.role,
        organizationName: '',
        industryType: '',
        contactPersonName: '',
        website:'',
        numberOfEmployees:'',
        contactPersonNumber: '',
        orgPinCode: '',
        organizationAddress: '',
        orgCity: '',
        orgState: '',
        orgCountry: '',
        receiveUpdates: false,
    });

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleEditCompanyDetails = () => {
        setActiveStep(0); // Go back to the first step
    };

    const handleRegisterAccount = async () => {
        try {
            // Fetch token from localStorage
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found');
            }

            // Decode the token if needed (optional)

            // API request body
            const requestBody = {
                organizationName: formData.organizationName,
                organizationAddress: formData.organizationAddress,
                contactNumber: formData.contactNumber,
                email: formData.email,
                orgState: formData.orgState,
                orgCity: formData.orgCity,
                orgPinCode: formData.orgPinCode,
                website:formData.website,
                numberOfEmployees:formData.numberOfEmployees,
                contactPersonName: formData.contactPersonName,
                contactPersonNumber: formData.contactPersonNumber,
                industryType: formData.industryType,
                orgCountry: formData.orgCountry,
                receiveUpdates: formData.receiveUpdates,
            };

            // API call
            const response = await fetch(`${baseURL}/organization/create`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to register organization: ${errorData.message}`);
            }

            const responseData = await response.json();
            console.log('Organization registered successfully:', responseData);

            // Store the organization._id in localStorage
            localStorage.setItem('EmployerOrganizationId', responseData.organization._id);

            // Navigate to /employerJobListings
            navigate('/employerJobListings');
        } catch (error) {
            console.error('Error during registration:', error.message);
            // Show error message to the user
        }
    };

    return (
        <>
            <NonRegisteredAppBar />
            <Box sx={{ backgroundColor: '#e5e7eb', minHeight: '100vh', py: 2 }}>
                <Container maxWidth="md">
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Paper elevation={1} sx={{ p: 4, backgroundColor: '#ffffff' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                            <img
                                src={VacanziLogo}
                                alt="Vacanzi Logo"
                                style={{ width: 80, height: 80 }}
                            />
                            <Divider />
                        </Box>

                        {activeStep === 0 && (
                            <>
                                <EmployerRegistrationForm formData={formData} handleChange={handleChange} />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                    <Button
                                        disabled
                                        variant="outlined"
                                        onClick={handleBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: '#423bfa', color: 'white', fontWeight: 'bold', padding: '0.5rem' }}
                                        onClick={handleNext}
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </>
                        )}

                        {activeStep === 1 && (
                            <>
                                <Box>
                                    <EmployerVerifyDetails formData={formData} handleChange={handleChange} handleEditCompanyDetails={handleEditCompanyDetails} />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                        <Button
                                            sx={{ backgroundColor: '#423bfa', color: 'white', fontWeight: 'bold', padding: '0.5rem' }}
                                            onClick={handleBack}
                                            variant="contained"
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: '#423bfa', color: 'white', fontWeight: 'bold', padding: '0.5rem' }}
                                            onClick={handleRegisterAccount}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Paper>
                </Container>
            </Box>
        </>
    );
};

export default EmployerRegistration;
