import React, { useEffect, useState, useMemo } from "react";
import {
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getTopCitiesApi } from "../../../../api/common";
import { debounce } from "@mui/material/utils";

const CandidateCity = ({ formData, setFormData }) => {
  const [locations, setLocations] = useState([]); // Initialize as an empty array
  const [inputValue, setInputValue] = useState(""); // Track input value for dynamic search

  // Debounced fetchCities function
  const fetchCities = useMemo(
    () =>
      debounce(async (query) => {
        try {
          if (query.trim().length >= 2) {
            const response = await getTopCitiesApi(query);
            if (response && response.cities) {
              setLocations(response.cities); 
            }
          } else {
            setLocations([]); 
          }
        } catch (error) {
          console.log(error.message);
        }
      }, 300),
    [] 
  );

  useEffect(() => {
    fetchCities(inputValue);

   
    return () => fetchCities.clear();
  }, [inputValue, fetchCities]);


  const handleCityChange = (event, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      currentLocation: value, 
    }));
  };

  // Handle input change for dynamic search
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue); 
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
        sx={{
          padding: "8px 0",
          minHeight: "40px",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "IBM Plex Sans, sans-serif",
            color: "#333",
          }}
        >
          Current City/Area
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "0 0 16px 0", backgroundColor: "transparent" }}
      >
        <Autocomplete
          options={locations.map((city)=>city.name)} // List of cities fetched from the API
          value={formData.currentLocation} // Current selected city
          onChange={handleCityChange} // Handle city selection
          onInputChange={handleInputChange} // Handle input change for dynamic search
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search City"
              variant="outlined"
            />
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateCity;