import React from "react";
import { Box, Grid, Typography, Card, CardMedia, CardContent } from "@mui/material";

const newsData = [
  {
    title: "This Bangalore startup is building a Netflix for educational content",
    image: "https://www.shutterstock.com/image-vector/morning-world-news-cup-coffee-600nw-2470596983.jpg", // Replace with your image URL
    date: "Jul 2023",
  },
  {
    title: "Seekho featured in Top 30 Startups To Watch: 2023",
    image: "https://www.shutterstock.com/image-vector/morning-world-news-cup-coffee-600nw-2470596983.jpg", // Replace with your image URL
    date: "Dec 2023",
  },
  {
    title: "3one4 exits Seekho with 7.5x return",
    image: "https://www.shutterstock.com/image-vector/morning-world-news-cup-coffee-600nw-2470596983.jpg", // Replace with your image URL
    date: "Apr 2023",
  },
];

const NewsSection = () => {
    return (
        <Box
          sx={{
            backgroundColor: "#EAFBF4FF",
            color: "white",
            py: 6,
            px: { xs: 2, md: 6 },
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              mb: 4,
              color:'black',
              fontFamily: "IBM plex sans",
            }}
          >
            In the News
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {newsData.map((news, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    maxWidth: 345,
                    backgroundColor: "#1a1a1a",
                    color: "white",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                    height:'270px'
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={news.image}
                    alt={news.title}
                    sx={{
                      objectFit: "contain",
                      backgroundColor: "#333",
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mb: 1,
                        fontFamily: "IBM plex sans",
                      }}
                    >
                      {news.title}
                    </Typography>
                    <Typography variant="body2" color="gray" sx={{ fontFamily: "IBM plex sans" }}>
                      {news.date}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      );
}

export default NewsSection