import React from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  // Link,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MuiOtpInput } from "mui-one-time-password-input";
import GoogleIconImg from "../../../assets/icons/logos/google-icon.png";
// import { useState } from "react";
// import { useEffect } from "react";

const JobSeekerOTPpage = ({
  handleBack,
  phoneNumber,
  handleFieldChange,
  onResendOtp,
  timer,
  otp,
  errors,
  handleOtpSubmit,
}) => {

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOtpSubmit();
    }
  };

  return (
    <Box
      sx={{
        color: "black",
        maxWidth: 400,
        padding: 4,
        border: "1px solid #ddd",
        borderRadius: 2,
        backgroundColor: "#fff",
        textAlign: "center",
        boxShadow: "-1px 1px 10px 1px rgba(0, 0, 0, 0.1)",
        margin: "auto",
        mt: 4,
      }}
    >
      <IconButton
        onClick={handleBack}
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography
        variant="h5"
        fontWeight="bold"
        gutterBottom
        sx={{ fontFamily: "IBM Plex Sans", mb: 2 }}
      >
        Login
      </Typography>
      <Box
        sx={{
          textAlign: "left",
          mb: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "IBM plex sans",
            fontWeight: "bold",
            fontSize: "13px",
            mb: 2,
          }}
        >
          Mobile Number
        </Typography>

        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{ ml: 1, flex: 1, color: "black", opacity: 0.7, display: "flex" }}
        >
          +91 {phoneNumber} <Typography sx={{ ml: 1 }}>✎</Typography>
        </Typography>
      </Box>

      <Typography
        variant="body1"
        fontWeight="bold"
        sx={{
          mb: 1,
          fontFamily: "IBM Plex Sans",
          textAlign: "left",
          fontSize: 14,
          opacity: 0.7,
        }}
      >
        OTP
      </Typography>
      <MuiOtpInput
        length={4}
        value={otp}
        onChange={(value) => handleFieldChange("otp", value)}
        onKeyDown={handleKeyDown} 
        error={!!errors.otp}
        sx={{
          display: "flex",
          justifyContent: "center",
          "& .MuiInputBase-root": {
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
            width: "3rem",
            height: "3rem",
            margin: "0 0.5rem",
          },
        }}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      />
      {errors.otp && (
        <Typography color="error" sx={{ mt: 1 }}>
          {errors.otp}
        </Typography>
      )}
      <Typography
        sx={{
          display: "block",
          mt: 1,
          textAlign: "left",
          fontSize: "0.9rem",
          fontFamily: "IBM Plex Sans",
        }}
      >
        Didn’t receive an OTP?{" "}
        {timer > 0 ? (
          <span style={{ fontFamily: "IBM Plex Sans", color: "#666" }}>
            Resend OTP in {timer}s
          </span>
        ) : (
          <strong
            style={{
              fontFamily: "IBM Plex Sans",
              color: "#423bfa",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleOtpSubmit}
          >
            Resend OTP
          </strong>
        )}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          mt: 1,
          mb: 2,
          fontFamily: "IBM Plex Sans",
          textAlign: "left",
        }}
      >
        An OTP has been sent to {phoneNumber}. You may not receive the OTP if
        the email/number is not registered.
      </Typography>
      <Button
        fullWidth
        variant="contained"
        sx={{
          mt: 2,
          backgroundColor: "#423bfa",
          color: "white",
          fontWeight: "bold",
          borderRadius: "10px",
          height: "45px",
        }}
        onClick={handleOtpSubmit}
      >
        Login
      </Button>
      <Divider
        sx={{
          display: "flex",
          alignItems: "center",
          color: "gray",
          marginTop: "20px",
          fontSize: "14px",
          "&::before, &::after": {
            borderColor: "lightgray",
          },
        }}
      >
        Or
      </Divider>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#fff",
            color: "black",
            fontWeight: "bold",
            borderRadius: "30px",
            fontFamily: "IBM plex sans",
            textTransform: "none",
            position: "relative",
            opacity: 0.8,
            mb: 2,
            ":hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          <img
            src={GoogleIconImg}
            alt="Google logo"
            style={{
              width: "24px",
              height: "24px",
              marginRight: "8px",
              left: 18,
              position: "absolute",
            }}
          />
          Sign in with Google
        </Button>
      </Box>
    </Box>
  );
};

export default JobSeekerOTPpage;
