import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getUserIdFromToken } from "../../../utils/userAuth";
import baseURL from "../../../api/baseUrl";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { getTopIndustries } from "../../../api/common";
import { toast } from "react-toastify";

const WorkExperience = () => {
  const [openModal, setOpenModal] = useState(false);
  const [experienceId, setExperienceId] = useState("");
  const [industries, setIndustries] = useState("");
  const [isAdding, setIsAdding] = useState(true); // true if adding, false if editing
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete confirmation dialog
  const [experienceToDelete, setExperienceToDelete] = useState(null); // Store the experience to delete

  const [formData, setFormData] = useState({
    designation: "",
    company: "",
    industry: "",
    skills: "",
    start: "",
    end: "",
    description: "",
    location: "",
    salary: "",
    responsibilities: "",
    currentJob: false,

    designationError: "",
    companyError: "",
    descriptionError: "",
    startError: "",
    endError: "",
  });

  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    currentLocation: "",
    workExperience: [],
    totalExperience: "",
    salary: "",
    noticePeriod: "",
    videoCV: "",
    resume: "",
    description: "",
    industry: "",
    expectedCtc: "",
    servingNoticePeriod: false,
  });

  // Fetch User Details and Set State
  useEffect(() => {
    const fetchUserDetails = async () => {
      const userId = await getUserIdFromToken();
      try {
        const response = await fetch(`${baseURL}/jobseeker/${userId}`);
        const data = await response.json();
        const user = data.user;

        setExperienceId(user.experiences?.[0]?._id || "");
        setUserData({
          fullName: user.fullName || "Not Available",
          email: user.email || "Not Available",
          phone: user.phone,
          dob: user.dob,
          gender: user.gender,
          jobDescription: user?.description,
          currentLocation: user.currentLocation,
          workExperience:
            user.experiences?.map((exp) => ({
              id: exp._id,
              title: exp.designation ? exp.designation : exp.title || "Not Available", // Use `designation` instead of `title`
              company: exp.company || "Not Available",
              industry: exp.industry || "Not Available",
              skills: exp.technologiesUsed || [],
              startDate: exp.startDate || "Not Available",
              endDate: exp.endDate || "Present",
              jobType: exp.jobType || "Not Available",
              jobRole: exp.jobRole || [],
              location: exp.location || "Not Available",
              ctc: exp.ctc || "Not Available",
              responsibilities: exp.responsibilities || "Not Available",
            })) || [],
          totalExpInYears: user.totalExpInYears,
          totalExpInMonths: user.totalExpInMonths,
          totalExperience: `${user.totalExpInYears} years ${user.totalExpInMonths} months`,
          salary: `${user.expectedCtc} LPA`,
          noticePeriod: user.noticePeriod,
          videoCV: user.videoCV?.[0]?.url || "Not Available",
          resume: user.resume?.[0]?.url || "Not Available",
          description: user.description || "Not Available",
          industry: user.industry || "Not Available",
          expectedCtc: `${user.expectedCtc} LPA`,
          servingNoticePeriod: user.servingNoticePeriod,
        });
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    const fetchIndustryDetails = async () => {
      try {
        const response = await getTopIndustries();
        setIndustries(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchIndustryDetails();
  }, []);

  const handleAddExperience = () => {
    setIsAdding(true); // Set to true for adding
    setExperienceId(null); // No experience ID when adding
    setFormData({
      designation: "",
      company: "",
      industry: "",
      description: "",
      skills: "",
      start: "",
      end: "",
      salary: "",
      responsibilities: "",
      currentJob: false,
    });
    setOpenModal(true); // Open modal for adding new experience
  };

  // Toggle Modal
  const handleEdit = (experience) => {
    setIsAdding(false); // Set to false for editing
    setExperienceId(experience.id); // Set the experience ID to the one being edited

    // Convert startDate to YYYY-MM-DD format
    const startDate = experience.startDate
      ? new Date(experience.startDate).toISOString().split("T")[0]
      : "";

    // Handle endDate: if "Present", set currentJob to true and leave endDate empty
    const endDate =
      experience.endDate === "Present" ? "" : experience.endDate
        ? new Date(experience.endDate).toISOString().split("T")[0]
        : "";

    setFormData({
      designation: experience.title ? experience.title : experience.designation || "", // Use `title` from the experience object
      company: experience.company || "",
      industry: experience.industry || "",
      description: experience.jobDescription || "",
      skills: experience.skills?.join(", ") || "", // Assuming skills are an array
      start: startDate, // Use formatted startDate
      end: endDate, // Use formatted endDate
      salary: experience.ctc || "",
      responsibilities: experience.responsibilities || "",
      currentJob: experience.endDate === "Present", // Set currentJob based on endDate
    });

    setOpenModal(true); // Open modal for editing existing experience
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsAdding(false);
  };

  // Handle Form Changes
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      [`${name}Error`]: "", // Clear the corresponding error when the user types
    }));
  };

  // Open Delete Confirmation Dialog
  const handleDeleteClick = (experience) => {
    setExperienceToDelete(experience); // Set the experience to delete
    setDeleteDialogOpen(true); // Open the delete confirmation dialog
  };

  // Close Delete Confirmation Dialog
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setExperienceToDelete(null); // Reset the experience to delete
  };

  // Handle Delete Confirmation
  const handleDeleteConfirm = async () => {
    if (!experienceToDelete) return;

    const token = localStorage.getItem("authToken")?.replace(/"/g, "");

    try {
      const response = await fetch(
        `${baseURL}/deleteExperience/${experienceToDelete.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Remove the deleted experience from the userData state
        setUserData((prevState) => ({
          ...prevState,
          workExperience: prevState.workExperience.filter(
            (experience) => experience.id !== experienceToDelete.id
          ),
        }));

        toast.success("Experience deleted successfully");
      } else {
        toast.error("Failed to delete experience");
      }
    } catch (error) {
      console.error("Error deleting experience:", error);
      toast.error("Failed to delete experience");
    } finally {
      setDeleteDialogOpen(false); // Close the delete confirmation dialog
      setExperienceToDelete(null); // Reset the experience to delete
    }
  };

  const handleSave = async () => {
    const authToken = localStorage.getItem("authToken");

    const errors = {
      designationError: !formData.designation ? "Job Title is required" : "",
      companyError: !formData.company ? "Company is required" : "",
      startError: !formData.start ? "Start Date is required" : "",
      endError:
        !formData.currentJob && !formData.end ? "End Date is required" : "",
    };

    if (formData.start && formData.end && formData.start > formData.end) {
      errors.startError = "Start Date cannot be after End Date";
    }

    const hasError = Object.values(errors).some((error) => error !== "");
    if (hasError) {
      setFormData((prev) => ({
        ...prev,
        ...errors,
      }));
      return;
    }

    const updatedData = {
      designation: formData.designation,
      company: formData.company,
      industry: formData.industry,
      startDate: formData.start,
      endDate: formData.end,
      jobDescription: formData.description,
      ctc: formData.salary,
      location: formData.location,
      responsibilities: formData.responsibilities,
      technologiesUsed: formData.skills
        ?.split(", ")
        .map((skill) => skill.trim()), // assuming skills is a comma-separated list
    };

    const method = isAdding ? "POST" : "PUT"; // Determine API method
    const url = isAdding
      ? `${baseURL}/addExperience`
      : `${baseURL}/editExperience/${experienceId}`; // API endpoint

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error("Failed to save experience");
      }

      const newExperience = await response.json(); // Extract the experience object from response

      // Update the userData state immutably
      setUserData((prevUserData) => {
        const updatedExperiences = isAdding
          ? [...(prevUserData.workExperience || []), newExperience.experience] // Add new experience
          : prevUserData.workExperience.map(
              (experience) =>
                experience.id === experienceId ? newExperience : experience // Update existing experience
            );

        return {
          ...prevUserData,
          workExperience: updatedExperiences, // Ensure workExperience is an array
        };
      });

      toast.success(newExperience.message);
      setOpenModal(false); // Close modal after saving
    } catch (error) {
      console.error("Error saving experience:", error);
      toast.error("Failed to save experience");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" fontWeight="bold">
            Work Experience
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="primary"
          onClick={handleAddExperience}
          sx={{
            cursor: "pointer",
            fontWeight: "bold",
            marginRight: 2,
          }}
        >
          + Add
        </Typography>
      </Box>
      <Paper
        sx={{
          padding: "20px",
          margin: "0 auto",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          backgroundColor: "#fff",
          boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
        }}
        variant="outlined"
        elevation={1}
      >
        {/* Header Section */}
        {userData?.workExperience?.length > 0 ? (
          userData?.workExperience?.map((experience) => (
            <Box
              key={experience.id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                <CorporateFareIcon
                  sx={{ color: "#423BFA", fontSize: "2.5rem" }}
                />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      color: "#333",
                    }}
                  >
                    {experience?.title || experience?.designation}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "1rem", color: "#666" }}
                  >
                    {experience?.company}
                  </Typography>

                  {/* Additional Details */}
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1rem", color: "#555" }}
                  >
                    <strong>Industry:</strong> {experience?.industry || "N/A"}{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "1rem", color: "#555" }}
                  >
                    <strong>CTC:</strong> {experience?.ctc || "N/A"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "1rem", color: "#555" }}
                  >
                    <strong>Start Date:</strong>{" "}
                    {experience?.startDate
                      ? new Date(experience.startDate).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            year: "numeric",
                          }
                        )
                      : "not mentioned"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "1rem", color: "#555" }}
                  >
                    <strong>End Date:</strong>{" "}
                    {experience?.endDate === "Present"
                      ? "Present"
                      : experience?.endDate
                      ? new Date(experience.endDate).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            year: "numeric",
                          }
                        )
                      : "not mentioned"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", gap: 2 }}>
                <EditIcon
                  sx={{ cursor: "pointer", color: "grey" }}
                  onClick={() => handleEdit(experience)}
                />
                <DeleteIcon
                  sx={{ cursor: "pointer", color: "red" }}
                  onClick={() => handleDeleteClick(experience)}
                />
              </Box>
            </Box>
          ))
        ) : (
          <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
            No experiences added yet.
          </Typography>
        )}
      </Paper>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <b>Confirm Deletion</b>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this experience? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteDialogClose}
            color="secondary"
            variant="outlined"
            sx={{
              borderRadius: "2rem",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="primary"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: "2rem",
              background: "#423bfa",
              textTransform: "none",
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Editing Experience */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <b>{isAdding ? "Add Work Experience" : "Edit Work Experience"}</b>
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            {/* Job Title */}
            <TextField
              fullWidth
              label="Job Title"
              value={formData?.designation}
              name="designation"
              onChange={handleFormChange}
              onBlur={handleFormChange} // Validate on blur
              variant="outlined"
              margin="normal"
              error={!!formData.designationError} // Show error only if `designationError` is set
              helperText={formData.designationError || ""} // Display error message
            />

            {/* Company */}
            <TextField
              fullWidth
              label="Company"
              value={formData?.company}
              name="company"
              onChange={handleFormChange}
              onBlur={handleFormChange} // Validate on blur
              variant="outlined"
              margin="normal"
              error={!!formData.companyError} // Show error only if `companyError` is set
              helperText={formData.companyError || ""} // Display error message
            />

            {/* Industry */}
            <TextField
              fullWidth
              select
              value={formData?.industry || ""}
              name="industry"
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              SelectProps={{ native: true }}
            >
              <option value="">Select Industry</option>
              {industries &&
                industries?.map((item, index) => (
                  <option key={index} value={item.industry}>
                    {item.industry}
                  </option>
                ))}
            </TextField>

            {/* Job Role */}
            <TextField
              fullWidth
              label="Job Role"
              value={formData.description}
              name="description"
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
            />

            {/* Start Date and End Date */}
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
              <TextField
                label="Start Date"
                type="date"
                value={formData.start}
                name="start"
                onChange={handleFormChange}
                onBlur={handleFormChange}
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                fullWidth
                error={!!formData.startError}
                helperText={formData.startError || ""}
                sx={{ mb: 2 }} // Add margin to ensure space for error message
              />
              <TextField
                label="End Date"
                type="date"
                value={formData.end}
                name="end"
                onChange={handleFormChange}
                onBlur={handleFormChange}
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled={formData.currentJob}
                error={!!formData.endError}
                helperText={formData.endError || ""}
                sx={{ mb: 2 }} // Add margin to ensure space for error message
              />
            </Box>

            {/* Current Job Checkbox */}
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Checkbox
                checked={formData?.currentJob}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    currentJob: e.target.checked,
                    end: e.target.checked ? "" : prev.end,
                  }))
                }
              />
              <Typography>This is my current job</Typography>
            </Box>

            {/* Current CTC */}
            <TextField
              fullWidth
              label="Current CTC"
              value={formData?.salary}
              name="salary"
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              placeholder="Enter salary in numbers"
            />

            {/* Responsibilities */}
            <TextField
              fullWidth
              label="Responsibilities"
              value={formData?.responsibilities}
              name="responsibilities"
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              multiline
              rows={3}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="secondary"
            variant="outlined"
            sx={{
              borderRadius: "2rem",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: "2rem",
              background: "#423bfa",
              textTransform: "none",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WorkExperience;