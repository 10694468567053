import baseURL from "./baseUrl";


 export const savedSearchesApi = async(orgId,token)=>{
     try{
     const response = await fetch(`${baseURL}/organization/recentSearches/${orgId}`,{
        method:'GET',
        headers:{
            "Authorization":`Bearer ${token}`,
            "Content-Type":'Application/json'
          }
     })
     if(!response.ok){
        throw new Error("network response was not ok");
      }
      const data = await response.json()
      return data
     }
     catch(error){
        console.log(error.message)
     }
}


export const addSavedSearchApi = async (token,formValues) => {
   try {
     const response = await fetch(`${baseURL}/saveSearch`, {
       method: "POST",
       headers: {
         Authorization: `Bearer ${token}`,
         "Content-Type": "application/json",
       },
       body: JSON.stringify(formValues),
     });
     if (!response.ok) {
       throw new Error("network response was not ok");
     }
   } catch (error) {
     console.log(error.message);
   }
 };

 export const getSavedSearchApi = async (token, formValues) => {
   try {
     // Convert formValues to query parameters
     const queryParams = new URLSearchParams(formValues).toString();
     const url = `${baseURL}/getSavedSearch?${queryParams}`;
 
     const response = await fetch(url, {
       method: 'GET',
       headers: {
         Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json',
       },
     });
 
     if (!response.ok) {
       throw new Error(`HTTP error! Status: ${response.status}`);
     }
 
     const data = await response.json();
     return data;
   } catch (error) {
     console.error('Error in getSavedSearchApi:', error.message);
     throw error; // Re-throw the error to handle it in the calling function
   }
 };


 