import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Link,
  Divider,
  Grid,
  IconButton,
  Container,
  InputAdornment,
} from "@mui/material";
// import GoogleIconImg from "../../../assets/icons/logos/google-icon.png";
import searchCandidates from "../../../styles/searchCandidatesStyles";
import { forgotPasswordApi, resetPasswordApi } from "../../../api/employerAuth";
import { toast, ToastContainer } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import { FilterList, SmartToy, Phone } from "@mui/icons-material";

// import PeopleIcon from "@mui/icons-material/People";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { useEffect } from "react";
// import CountUp from "react-countup";
// import { useInView } from "react-intersection-observer";
import Faq from "../../LoginPageComponents/Faq";
import EmployerFooter from "../../JobSeekerComponents/Footer/EmployerFooter";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ChatIcon from '@mui/icons-material/Chat';


const stats = [
  {
    value: 5,
    suffix: "Lakhs+",
    description: "New candidates join apna every month.",
  },
  {
    value: 200,
    suffix: "+",
    description: "Job categories to publish your job.",
  },
  {
    value: 5,
    prefix: "Upto ",
    suffix: "X",
    description: "More walk-ins than competitors",
  },
  {
    value: 80,
    suffix: "%",
    description: "Business get qualified candidates within 24 hrs",
  },
];

const videoLinks = [
  "https://youtube.com/embed/Ume9BKap5I4",
  "https://youtube.com/embed/xQhV1pD2u_w",
  "https://youtube.com/embed/2VRTlTVGk0k",
  "https://youtube.com/embed/Ume9BKap5I4",
  "https://youtube.com/embed/xQhV1pD2u_w",
  "https://youtube.com/embed/2VRTlTVGk0k",
  "https://youtube.com/embed/Ume9BKap5I4",
  "https://youtube.com/embed/xQhV1pD2u_w",
];

const features = [
  {
    icon: (
      <span style={{ fontSize: "3rem" }}>
        <VideoCameraFrontIcon sx={{ color: "#349beb", fontSize: "60px" }} />
      </span>
    ),
    title: "Make an Impact",
    description:
      "Get Noticed with Video Profiles",
  },
  {
    icon: (
      <span style={{ fontSize: "3rem" }}>
        <FlashOnIcon sx={{ color: "#349beb", fontSize: "60px" }} />
      </span>
    ),
    title: "Hassle-Free Applications",
    description:
      "Skip the Paperwork, Instant Job Matches with Video",
  },
  {
    icon: (
      <span style={{ fontSize: "3rem" }}>
        <ConnectWithoutContactIcon sx={{ color: "#349beb", fontSize: "60px" }} />
      </span>
    ),
    title: "Instant Opportunities",
    description: "Connect with Employers in Seconds",
  },
];


const testimonials = [
  {
    quote:
      "We are closely working with apna for talent discovery and hiring across job families. Their quickest turn-around time is enabling us to uninterruptedly continue our daily functions and services, especially during the present times.",
    author: "Dr Varun",
    position: "SVP, Medical Affairs",
    company: "TATA 1mg",
    image:
      "https://media.istockphoto.com/id/1230749818/photo/portrait-of-smiling-male-owner-of-fashion-store-standing-in-front-of-clothing-display.jpg?s=612x612&w=0&k=20&c=xoWhF-hrQcbMEPDYncHiHF8HJX2YgmYt7T-KLCPZIpY=",
    logo: "https://apacnewsnetwork.com/wp-content/uploads/2021/06/Tata-logo.png",
  },
  {
    quote:
      "The platform has helped us streamline our hiring process significantly. The quality of candidates is exceptional.",
    author: "Sarah Chen",
    position: "Head of Recruitment",
    company: "Tech Corp",
    image:
      "https://photoheads.co.uk/wp-content/uploads/2020/05/headshot-with-client-testimonial.jpg",
    logo: "https://community.coops.tech/uploads/default/original/1X/e04e75ea81344973bff11acfb76026136676e529.png",
  },
];

const EmployerLoginSignUp = ({
  handleFieldChange,
  errors,
  loginEmail,
  loginPassword,
  handleLoginSubmit,
  handleSignupSubmit,
  signupEmail,
  signupName,
  signupPhone,
  setValue,
  value,
}) => {
  // const [isInView, setIsInView] = useState(false);
  // const [counterValues, setCounterValues] = useState(stats.map(() => 0));
  const [forgotPassword, setForgotPassword] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState({ rcEmail: "" });
  const [emailVerified, setEmailVerified] = useState(false);
  const token = localStorage.getItem("token");
  // const navigate = useNavigate();
  const [message, setMessage] = useState();
  const [resetData, setResetData] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const handleEmailVerification = (e) => {
    setVerifyEmail({ rcEmail: e.target.value });
  };

  const handleSendVerificationMail = async () => {
    try {
      if (!verifyEmail.rcEmail) {
        toast.error("Please enter your email");
        return;
      }

      // Simulate sending verification email
      const formValues = { rcEmail: verifyEmail.rcEmail };
      const response = await forgotPasswordApi(token, formValues);

      setMessage(response.message);
      setEmailVerified(true); // Set email as verified
      setResetData((prev) => ({ ...prev, email: verifyEmail.rcEmail })); // Pre-fill email in resetData
      toast.success("OTP sent to your email");
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to send OTP. Please try again.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLoginSubmit();
    }
  };

  const handleResetData = (key, value) => {
    setResetData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // const formValues = {
  //   rcEmail: resetData?.email,
  //   otp: resetData?.otp,
  //   newPassword: resetData?.password,
  // };

  const handleSubmitResetPassword = async () => {
    try {
      if (
        !resetData.email ||
        !resetData.otp ||
        !resetData.password ||
        !resetData.confirmPassword
      ) {
        toast.error("Please fill all the fields");
      } else if (resetData.password !== resetData.confirmPassword) {
        toast.error("Passwords do not match");
      } else {
        const response = await resetPasswordApi(token, {
          rcEmail: resetData.email,
          otp: resetData.otp,
          newPassword: resetData.password,
        });
        toast.success(response?.message);
        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to reset password. Please try again.");
    }
  };

  // const { ref, inView } = useInView({
  //   threshold: 0.2,
  //   triggerOnce: true,
  // });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "IBM Plex Sans",
          minHeight: "100vh",
          backgroundColor: "#f5faf5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: "100vh",
            overflow: "hidden",
            width: "90vw",
            px: 3,
            backgroundColor: "#f5faf5", // Light grayish-white background
          }}
        >
          <Box>
            {value === 0 && !forgotPassword && (
              <Box
                sx={{
                  color: "black",
                  flex: 1,
                  maxWidth: 400,
                  padding: 4,
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  backgroundColor: "#fff",
                  textAlign: "center",
                  boxShadow: "-1px 1px 10px 1px rgba(0, 0, 0, 0.1)", // Shadow effect
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  gutterBottom
                  sx={{ fontFamily: "IBM Plex Sans" }}
                >
                  Hi, Welcome Back
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Email"
                  margin="normal"
                  sx={{ backgroundColor: "white" }}
                  value={loginEmail}
                  onChange={(e) =>
                    handleFieldChange("loginEmail", e.target.value)
                  }
                  error={!!errors.loginEmail}
                  helperText={errors.loginEmail}
                />
                <TextField
                  fullWidth
                  placeholder="Password"
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  margin="normal"
                  sx={{ backgroundColor: "white" }}
                  value={loginPassword}
                  onChange={(e) =>
                    handleFieldChange("loginPassword", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                  error={!!errors.loginPassword}
                  helperText={errors.loginPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ ...searchCandidates.flexboxend }}>
                  <Typography
                    onClick={handleForgotPassword}
                    sx={{
                      fontSize: 13,
                      color: "blue",
                      fontFamily: "IBM Plex Sans",
                      mb: 1,
                      ":hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    Forgot Password?
                  </Typography>
                </Box>

                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#423bfa",
                    color: "white",
                    fontWeight: "bold",
                    padding: "0.5rem",
                    fontFamily: "IBM Plex Sans",
                    textTransform: "none",
                    borderRadius: "10px",
                  }}
                  onClick={handleLoginSubmit}
                >
                  Login
                </Button>

                {/* <Typography
                align="center"
                variant="body2"
                sx={{ mt: 2, fontFamily: "IBM Plex Sans" }}
              >
                or continue with
              </Typography>
              <Button fullWidth color="primary" sx={{ mt: 1 }}>
                <img src={GoogleIconImg} alt="google-logo" />
              </Button> */}
                <Typography
                  align="center"
                  variant="body2"
                  sx={{ mt: 2, fontFamily: "IBM Plex Sans" }}
                >
                  Don't have an account?{" "}
                  <Button
                    onClick={() => setValue(1)}
                    sx={{
                      textTransform: "none",
                      color: "#423bfa",
                      fontFamily: "IBM Plex Sans",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Register here
                  </Button>
                </Typography>
              </Box>
            )}

            {/* Forgot Password Tab */}
            {forgotPassword && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/vacanziLogo.png"
                  style={{ width: 50, height: 50, marginBottom: 10 }}
                  alt="#vacanziLogo"
                />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontSize: 19, fontFamily: "IBM Plex Sans" }}
                >
                  Provide your Email for Reset Password
                </Typography>

                {!emailVerified ? (
                  // Show email verification form if email is not verified
                  <Box sx={{ maxWidth: 400, width: "100%" }}>
                    <TextField
                      fullWidth
                      placeholder="Email"
                      margin="normal"
                      sx={{ backgroundColor: "white" }}
                      value={verifyEmail.rcEmail}
                      required
                      onChange={handleEmailVerification}
                    />
                    {message && <Typography>{message}</Typography>}
                    <Box sx={{ ...searchCandidates.flexboxCenter }}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{
                          backgroundColor: "#423bfa",
                          borderRadius: "20px",
                          color: "white",
                          fontWeight: "bold",
                          padding: "0.5rem",
                          mt: 1,
                        }}
                        onClick={handleSendVerificationMail}
                      >
                        Verify
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  // Show the reset password form after email verification
                  <Box
                    sx={{
                      maxWidth: 400,
                      width: "100%",
                      padding: 4,
                      border: "1px solid #ddd",
                      borderRadius: 2,
                      backgroundColor: "#fff",
                      textAlign: "center",
                      boxShadow: "-1px 1px 10px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Reset Your Password
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Email"
                      margin="normal"
                      sx={{ backgroundColor: "white" }}
                      value={resetData.email}
                      disabled
                    />
                    <TextField
                      fullWidth
                      placeholder="OTP"
                      margin="normal"
                      type="number"
                      sx={{
                        backgroundColor: "white",
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        "& input[type=number]::-webkit-inner-spin-button": {
                          display: "none",
                        },
                        "& input[type=number]::-webkit-outer-spin-button": {
                          display: "none",
                        },
                      }}
                      value={resetData.otp}
                      onChange={(e) => handleResetData("otp", e.target.value)}
                    />
                    <TextField
                      fullWidth
                      placeholder="Password"
                      margin="normal"
                      type="password"
                      sx={{ backgroundColor: "white" }}
                      value={resetData.password}
                      onChange={(e) =>
                        handleResetData("password", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      placeholder="Confirm Password"
                      margin="normal"
                      type="password"
                      sx={{ backgroundColor: "white" }}
                      value={resetData.confirmPassword}
                      onChange={(e) =>
                        handleResetData("confirmPassword", e.target.value)
                      }
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        backgroundColor: "#423bfa",
                        borderRadius: "20px",
                        color: "white",
                        fontWeight: "bold",
                        padding: "0.5rem",
                        mt: 2,
                      }}
                      onClick={handleSubmitResetPassword}
                    >
                      Reset Password
                    </Button>
                  </Box>
                )}
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  hideProgressBar={false}
                  closeOnClick
                  pauseOnHover
                  draggable
                  theme="colored"
                />
              </Box>
            )}
            {/* Sign Up Tab */}
            {value === 1 && (
              <Box
                sx={{
                  color: "black",
                  flex: 1,
                  maxWidth: 400,
                  padding: 4,
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  backgroundColor: "#fff",
                  textAlign: "center",
                  boxShadow: "1px 1px 7px 3px rgba(0, 0, 0, 0.1)", // Shadow effect
                }}
              >
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  Register
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Full Name"
                  margin="normal"
                  sx={{ backgroundColor: "white" }}
                  value={signupName}
                  onChange={(e) =>
                    handleFieldChange("signupName", e.target.value)
                  }
                  error={!!errors.signupName}
                  helperText={errors.signupName}
                />
                <TextField
                  fullWidth
                  placeholder="Email"
                  margin="normal"
                  sx={{ backgroundColor: "white" }}
                  value={signupEmail}
                  onChange={(e) =>
                    handleFieldChange("signupEmail", e.target.value)
                  }
                  error={!!errors.signupEmail}
                  helperText={errors.signupEmail}
                />
                <TextField
                  fullWidth
                  placeholder="Phone"
                  margin="normal"
                  sx={{ backgroundColor: "white" }}
                  value={signupPhone}
                  onChange={(e) =>
                    handleFieldChange("signupPhone", e.target.value)
                  }
                  error={!!errors.signupPhone}
                  helperText={errors.signupPhone}
                />

                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#423bfa",
                    color: "white",
                    fontWeight: "bold",
                    padding: "0.5rem",
                  }}
                  onClick={handleSignupSubmit}
                >
                  Sign Up
                </Button>
                {/* <Typography align="center" variant="body2" sx={{ mt: 2 }}>
                or continue with
              </Typography> */}
                {/* <Button fullWidth color="primary" sx={{ mt: 1 }}>
                <img src={GoogleIconImg} alt="google-logo" />
              </Button> */}
                <Typography align="center" variant="body2" sx={{ mt: 2 }}>
                  Already have an account?{" "}
                  <Button
                    onClick={() => setValue(0)}
                    sx={{
                      textTransform: "none",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Login here
                  </Button>
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              flex: 1,
              textAlign: { xs: "center", md: "left" },
              marginLeft: { md: 5 },
              color: "#191a19",
            }}
          >
            <Typography
              variant="h3"
              fontWeight="regular"
              gutterBottom
              sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
            >
              Hire top talent in 48 hours with Vacanzi.com
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
              Streamline your recruitment with AI-driven precision. Single
              solution from fresher to experienced hiring.
            </Typography>
            <Link
              href="#"
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "blue",
                fontWeight: "bold",
                mt: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontFamily: "IBM Plex Sans", color: "#423bfa" }}
              >
                Watch video
              </Typography>
            </Link>
            <Divider sx={{ my: 2, color: "black", bgcolor: "black" }} />{" "}
            {/* Horizontal line between the link and the box */}
            <Box sx={{ display: "flex", marginTop: 3, color: "#423BFA" }}>
              <Box sx={{ marginRight: 3 }}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ fontFamily: "IBM Plex Sans" }}
                >
                  5 Crores+
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: "IBM Plex Sans" }}
                >
                  Qualified candidates
                </Typography>
              </Box>
              <Box sx={{ marginRight: 3 }}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ fontFamily: "IBM Plex Sans" }}
                >
                  7 Lakhs+
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: "IBM Plex Sans" }}
                >
                  Employers use vacanzi
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ fontFamily: "IBM Plex Sans" }}
                >
                  900+
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: "IBM Plex Sans" }}
                >
                  Available cities
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            padding: 3,
            overflow: "hidden",
            backgroundColor: "white",
            color: "#191a19",
            borderRadius: "10px",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
            Trusted by 1000+ enterprises and 7 lakhs+ MSMEs for hiring
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              animation: "scroll 20s linear infinite",
              "@keyframes scroll": {
                "0%": { transform: "translateX(100%)" },
                "100%": { transform: "translateX(-100%)" },
              },
            }}
          >
            {/* Logo Images */}
            <img
              src="https://logos-world.net/wp-content/uploads/2020/11/Flipkart-Logo.png"
              alt="Flipkart"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://www.startupinsider.in/wp-content/uploads/2022/09/Big-Basket2.jpg"
              alt="BigBasket"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://m.economictimes.com/thumb/msid-113833509,width-1200,height-900,resizemode-4,imgsize-12884/hdfc-bank-share-price-live-updates-01-oct-2024.jpg"
              alt="HDFC Bank"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://startuparticle.com/wp-content/uploads/2019/11/swiggy-2.jpg"
              alt="Swiggy"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://img.etimg.com/photo/msid-104946989,imgsize-25938/Uber.jpg"
              alt="Uber"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsrtAlXGZ5qUsN3jPYrmM88R-bp7axQB_fDQ&s"
              alt="Urban Company"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://m.economictimes.com/thumb/msid-113833509,width-1200,height-900,resizemode-4,imgsize-12884/hdfc-bank-share-price-live-updates-01-oct-2024.jpg"
              alt="HDFC Bank"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://startuparticle.com/wp-content/uploads/2019/11/swiggy-2.jpg"
              alt="Swiggy"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://img.etimg.com/photo/msid-104946989,imgsize-25938/Uber.jpg"
              alt="Uber"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsrtAlXGZ5qUsN3jPYrmM88R-bp7axQB_fDQ&s"
              alt="Urban Company"
              style={{ height: 80, marginRight: 30 }}
            />
          </Box>

          <Box
            sx={{
              textAlign: "center",
              marginBottom: 4,
              color: "black",
              backgroundColor: "white",
              marginTop: 4,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "black",
                marginBottom: 1,
                fontSize: 40,
              }}
            >
              A single platform for your hiring needs
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* Colored Line Segments */}
              <Box
                sx={{
                  height: "4px",
                  width: "20%",
                  backgroundColor: "#018F82",
                }}
              ></Box>
              <Box
                sx={{
                  height: "4px",
                  width: "20%",
                  backgroundColor: "#0052CC",
                }}
              ></Box>
              <Box
                sx={{
                  height: "4px",
                  width: "20%",
                  backgroundColor: "#F6A623",
                }}
              ></Box>
            </Box>
          </Box>
        </Box>

        <Box  sx={{
            display: "flex",
            flexDirection:"column",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop:2,
            backgroundColor: "#f5faf5",
            width: "100vw",
          }}>
        <Typography variant='h3' sx={{fontWeight:"bold"}}>
          Why Vacanzi
        </Typography>
      <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
            paddingBottom:3,
            backgroundColor: "#f5faf5",
            width: "100vw",
          }}
        >
          
          <Box
            sx={{
              flex: "0 0 50%",
              marginRight: "2rem",
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
       
            {videoLinks.map((videoUrl, index) => (
              <Box
                key={index}
                sx={{
                  width: "calc(25% - 1rem)",
                  borderRadius: "10px", // Apply border radius on all corners
                  overflow: "hidden", // Prevent overflow of content
                  // boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // Optional: Add some shadow for effect
                }}
              >
                <iframe
                  width="100%"
                  height="200"
                  src={videoUrl}
                  title={`YouTube video ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ borderRadius: "10px" }}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap:"1rem",
              padding: "2rem",
              textAlign: "start",
              fontFamily: "IBM Plex Sans, sans-serif", // Apply IBM Plex Sans globally
            }}
          >
            {/* <Typography
              variant="h4"
              sx={{
                fontSize: "2rem",
                fontWeight: "regular",
                marginBottom: "1rem",
                textAlign: "start",
                fontFamily: "IBM Plex Sans, sans-serif", // Make sure IBM Plex Sans is applied here too
              }}
            >
              Get applications from relevant, high-intent candidates
            </Typography> */}

            {/* Advanced Job Filters & Assessments */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2rem",
              }}
            >
              <TrendingUpIcon />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontFamily: "IBM Plex Sans" }}
              >
                Instantly Stand Out
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"start"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
            Show Your True Potential with Video Profiles
            </Typography>

            {/* Smart AI Lead Management */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                mt: 2,
              }}
            >
              <BusinessCenterIcon />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  textAlign: "left",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                Fast-Track Your Job Search
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"start"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
             Showcase Your Skills & Personality
            </Typography>

            {/* Inbound Calls & WhatsApp Alerts */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                mt: 2,
              }}
            >
              <ChatIcon />
              <Typography
                variant="h6"
                textAlign={"start"}
                sx={{ fontWeight: "bold", fontFamily: "IBM Plex Sans" }}
              >
                Quick Connections
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"center"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
              Stay informed on the go with real-time candidate calls and
              application alerts directly on WhatsApp.
            </Typography>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#18181b",
                color: "#ffffff",
                borderRadius: "0.7rem",
                textTransform: "none",
                fontSize: "14px",
               
                mt: 3,
                padding: "0.375rem 0.75rem",
                fontFamily: "IBM Plex Sans",
                ":hover":{
                    backgroundColor:"#18181b",
                    color:"#ffffff",
                }
              }}
              onClick={() => window.scrollTo(0, 0)}
            >
              Search Jobs
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#f5faf5",
            width: "100vw",
            height: 300,
            px:"6rem",  
            gap: "2rem",
          }}
        >
          {features.map((feature) => (
            <Grid item key={feature.title} sx={{ textAlign: "center" }}>
              {feature.icon}
              <Typography
                variant="h6"
                component="div"
                sx={{ mt: 2, fontFamily: "IBM Plex Sans" }}
              >
                {feature.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ mt: 1, fontFamily: "IBM Plex Sans" }}
              >
                {feature.description}
              </Typography>
            </Grid>
          ))}
        </Box>
      </Box>
    
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
            backgroundColor: "#f1eafa",
            width: "100vw",
            gap: 7,
          }}
        >
          <Box
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: "2rem",
                fontWeight: "regular",
                marginBottom: "1rem",
                textAlign: "start",
                fontFamily: "IBM Plex Sans, sans-serif", // Make sure IBM Plex Sans is applied here too
              }}
            >
              Get applications from relevant, high-intent candidates
            </Typography>

            {/* Added headers and descriptions */}
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                gap: "0.5rem",
              }}
            >
              <FilterList />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontFamily: "IBM Plex Sans" }}
              >
                Advanced Job Filters & Assessments
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"start"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
              Use advanced filters and automated assessments to attract the most
              relevant candidates.
            </Typography>

            {/* Smart AI Lead Management */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                mt: 2,
              }}
            >
              <SmartToy />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  textAlign: "left",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                Smart AI Lead Management
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"start"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
              Boost recruiter productivity by automatically categorizing leads
              into matched and non-matched candidates.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                mt: 2,
              }}
            >
              <Phone />
              <Typography
                variant="h6"
                textAlign={"start"}
                sx={{ fontWeight: "bold", fontFamily: "IBM Plex Sans" }}
              >
                Inbound Calls & WhatsApp Alerts
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"start"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
              Stay informed on the go with real-time candidate calls and
              application alerts directly on WhatsApp.
            </Typography>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#18181b",
                color: "#ffffff",
                borderRadius: "0.7rem",
                  textTransform: "none",  
                mt: 3,
                fontFamily: "IBM Plex Sans",
                padding: "0.375rem 0.75rem",
                "&:hover": { backgroundColor: "#21867a" },
              }}
              onClick={() => window.scrollTo(0, 0)}
            >
              Search Candidates
            </Button>
          </Box>
          <Box
            sx={{
              flex: "0 0 50%",
              marginRight: "2rem",
            }}
          >
            {/* Your image here */}
            <img
              src="https://framerusercontent.com/images/yzj6ZpzoqsWeWLJxNMkU6aDZeU.svg"
              alt="#"
              style={{ width: "90%", borderRadius: "10px" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f1eafa",
            width: "100vw",
            height: 300,
            padding: "2rem",
            gap: "2rem",
          }}
        >
          {features.map((feature) => (
            <Grid item key={feature.title} sx={{ textAlign: "center" }}>
              {feature.icon}
              <Typography
                variant="h6"
                component="div"
                sx={{ mt: 2, fontFamily: "IBM Plex Sans" }}
              >
                {feature.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ mt: 1, fontFamily: "IBM Plex Sans" }}
              >
                {feature.description}
              </Typography>
            </Grid>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
            backgroundColor: "#e7f9f9",
            width: "100vw",
            gap: 7,
          }}
        >
          <Box
            sx={{
              flex: "0 0 50%",
              marginRight: "2rem",
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
            {videoLinks.map((videoUrl, index) => (
              <Box
                key={index}
                sx={{
                  width: "calc(25% - 1rem)",
                  borderRadius: "10px", // Apply border radius on all corners
                  overflow: "hidden", // Prevent overflow of content
                  // boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // Optional: Add some shadow for effect
                }}
              >
                <iframe
                  width="100%"
                  height="200"
                  src={videoUrl}
                  title={`YouTube video ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ borderRadius: "10px" }}
                />
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: "2rem",
                fontWeight: "regular",
                marginBottom: "1rem",
                textAlign: "start",
                fontFamily: "IBM Plex Sans, sans-serif", // Make sure IBM Plex Sans is applied here too
              }}
            >
              Get applications from relevant, high-intent candidates
            </Typography>

            {/* Added headers and descriptions */}
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                gap: "0.5rem",
              }}
            >
              <FilterList />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontFamily: "IBM Plex Sans" }}
              >
                Advanced Job Filters & Assessments
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"start"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
              Use advanced filters and automated assessments to attract the most
              relevant candidates.
            </Typography>

            {/* Smart AI Lead Management */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                mt: 2,
              }}
            >
              <SmartToy />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  textAlign: "left",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                Smart AI Lead Management
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"start"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
              Boost recruiter productivity by automatically categorizing leads
              into matched and non-matched candidates.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                mt: 2,
              }}
            >
              <Phone />
              <Typography
                variant="h6"
                textAlign={"start"}
                sx={{ fontWeight: "bold", fontFamily: "IBM Plex Sans" }}
              >
                Inbound Calls & WhatsApp Alerts
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"start"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
             Skip the Wait and Meet Employers Instantly
            </Typography>

            {/* <Button
              variant="contained"
              sx={{
                backgroundColor: "blue",
                color: "#fff",
                borderRadius: "5px",
                textTransform: "none",
                mt: 3,
                fontFamily: "IBM Plex Sans",
              }}
            >
              Install Chrome Extension
            </Button> */}
          </Box>
        </Box>
        <Box sx={{ bgcolor: "#FFF5F1", py: 8, px: { xs: 2, md: 6 } }}>
          <Typography
            variant="h2"
            component="h2"
            align="center"
            sx={{
              mb: 6,
              fontWeight: "bold",
              color: "#1A1A1A",
              fontFamily: "IBM Plex Sans",
            }}
          >
            Testimonials
          </Typography>

          <Box sx={{ position: "relative", maxWidth: 1200, mx: "auto" }}>
            <IconButton
              onClick={goToPrev}
              sx={{
                position: "absolute",
                left: { xs: -16, md: -24 },
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1,
                bgcolor: "white",
                "&:hover": { bgcolor: "grey.100" },
              }}
            >
              <ChevronLeft />
            </IconButton>

            <Slider ref={sliderRef} {...settings}>
              {testimonials.map((testimonial, index) => (
                <Box key={index}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: { xs: "1fr", md: "400px 1fr" },
                      gap: 4,
                      alignItems: "center",
                      px: { xs: 2, md: 4 },
                    }}
                  >
                    {/* Image Section */}
                    <Box
                      sx={{
                        borderRadius: 2,
                        overflow: "hidden",
                        height: { xs: 300, md: 400 },
                        bgcolor: "#FFE4D6",
                      }}
                    >
                      <Box
                        component="img"
                        src={testimonial.image}
                        alt="Testimonial"
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>

                    {/* Content Section */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        fontFamily: "IBM Plex Sans",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#1A1A1A",
                          fontWeight: 500,
                          lineHeight: 1.6,
                          mb: 2,
                          fontFamily: "IBM Plex Sans",
                        }}
                      >
                        {testimonial.quote}
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          height: 1,
                          borderBottom: "2px dashed #E0E0E0",
                          my: 2,
                        }}
                      />

                      <Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            color: "#1A1A1A",
                            mb: 0.5,
                            fontFamily: "IBM Plex Sans",
                          }}
                        >
                          {testimonial.author}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#666",
                            mb: 2,
                            fontFamily: "IBM Plex Sans",
                          }}
                        >
                          {testimonial.position}
                        </Typography>
                        <Box
                          component="img"
                          src={testimonial.logo}
                          alt={testimonial.company}
                          sx={{
                            height: 40,
                            width: "auto",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>

            <IconButton
              onClick={goToNext}
              sx={{
                position: "absolute",
                right: { xs: -16, md: -24 },
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1,
                bgcolor: "white",
                "&:hover": { bgcolor: "grey.100" },
              }}
            >
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ py: 8, bgcolor: "background.paper", width: "100vw" }}>
          <Container maxWidth="lg">
            <Typography
              variant="h2"
              component="h2"
              align="center"
              sx={{
                mb: 2,
                fontWeight: "bold",
                color: "#14234A",
              }}
            >
              Why hire from Vacanzi?
            </Typography>

            <Typography
              variant="h6"
              align="center"
              sx={{
                mb: 6,
                color: "#475569",
                maxWidth: 900,
                mx: "auto",
              }}
            >
              From startups to SMEs to established enterprises, Apna
              revolutionizes the way businesses find high-quality talent quickly
              & effortlessly.
            </Typography>

            <Grid
              container
              spacing={4}
              // ref={ref}
              sx={{
                mt: 4,
                "& .MuiGrid-item": {
                  borderRight: "1px solid #E2E8F0",
                  "&:last-child": {
                    borderRight: "none",
                  },
                },
              }}
            >
              {stats.map((stat, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  sx={{
                    textAlign: "center",
                    "&:not(:last-child)": {
                      borderRight: { md: "1px solid #E2E8F0" },
                    },
                    borderRight: {
                      xs: "none",
                      md:
                        index < stats.length - 1 ? "1px solid #E2E8F0" : "none",
                    },
                  }}
                >
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{
                      color: "#423bfa",
                      fontWeight: "bold",
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {stat.value && stat.value}
                    {/* {inView && (
                    <CountUp end={stat.value} duration={2.5} separator="," />
                  )} */}
                    {stat.suffix && stat.suffix}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#475569",
                      maxWidth: 200,
                      mx: "auto",
                    }}
                  >
                    {stat.description}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            py: 8,
            bgcolor: "background.paper",
            fontFamily: "IBM Plex Sans, sans-serif",
            width: "100vw",
          }}
        >
          <Faq />
        </Box>
        <EmployerFooter />
      </Box>
    </>
  );
};

export default EmployerLoginSignUp;
