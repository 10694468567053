import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import bikevideo from "../../../assets/videos/bikevideo.mp4";
// import videoplayback2 from "../../../assets/videos/videoplayback2.mp4";
import { getFeaturedJobSeekers } from "../../../api/common";

const SearchCandidateVideos = () => {
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);

  // const staticVideos = [bikevideo, videoplayback2, bikevideo, videoplayback2];
  const [featuredJobs, setFeaturedJobs] = useState();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 5 videos at a time
    slidesToScroll: 1, // Scroll 1 video at a time
    nextArrow: (
      <ArrowForwardIcon
        sx={{
          fontSize: "2rem",
          color: "#052341FF",
          cursor: "pointer",
          ":hover": { color: "#59A3EDFF" },
          transform: "translateY(-50%)",
          zIndex: 1,
        }}
      />
    ),
    prevArrow: (
      <ArrowBackIcon
        sx={{
          fontSize: "2rem",
          color: "#052341FF",
          cursor: "pointer",
          ":hover": { color: "#59A3EDFF" },
          transform: "translateY(-50%)",
          zIndex: 1,
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const response = await getFeaturedJobSeekers();
        setFeaturedJobs(response?.data || []);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchOrgDetails();
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "60vw",
        margin: "0 auto",
        paddingInline: { xs: 2, sm: 0 },
      }}
    >
      {!featuredJobs ||
      featuredJobs.filter((job) => job.videoCV?.[0]?.url).length === 0 ? (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", color: "#6B7280", mt: 4 }}
        >
          No videos found
        </Typography>
      ) : (
        <Slider {...settings}>
          {featuredJobs
            .filter((job) => job.videoCV?.[0]?.url) // Filter to only include jobs with valid video URLs
            .map((video, index) => (
              <Box
                key={index}
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "10px",
                    overflow: "hidden",
                    maxWidth: "200px",
                    height: "300px",
                    margin: "0 auto",
                    backgroundColor: "#f0f0f0",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setActiveVideoIndex(
                      activeVideoIndex === index ? null : index
                    )
                  }
                >
                  {activeVideoIndex === index ? (
                    <video
                      width="100%"
                      height="360px"
                      controls
                      autoPlay
                      style={{ objectFit: "cover", borderRadius: "10px" }}
                    >
                      <source src={video?.videoCV?.[0]?.url} type="video/mp4" />
                      Your browser does not support HTML video.
                    </video>
                  ) : (
                    <video
                      width="100%"
                      height="350px"
                      style={{
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      muted
                      loop
                    >
                      <source src={video?.videoCV?.[0]?.url} type="video/mp4" />
                      Your browser does not support HTML video.
                    </video>
                  )}
                </Box>
              </Box>
            ))}
        </Slider>
      )}
    </Box>
  );
};

export default SearchCandidateVideos;
