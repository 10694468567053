import React from "react";
import {
  Avatar,
  Typography,
  Chip,
  Grid,
  IconButton,
  Paper,
  Rating,
} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const CompanyCards = ({ companies, goToCompanyPage }) => {
  return (
    <Grid container spacing={2}>
      {companies?.map((industry) =>
        industry?.organizations?.map((company, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <Paper
              elevation={0}
              style={{
                padding: 10,
                borderRadius: 16,
                marginBottom: 16,
                background: "#FFFFFF",
                cursor:"pointer"
              }}
              onClick={() => goToCompanyPage(company?.id)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* Left Side - Logo and Info */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    alt={company?.organizationName?.toUpperCase()}
                    src={company?.logo?.url || 'z'} 
                    style={{ marginRight: 16, width: 50, height: 50 }}
                  />
                  <div>
                    {/* Company Name */}
                    <Typography variant="h6" style={{ fontWeight: "bold", fontSize: 16 }}>
                      {company?.organizationName || "Company Name"}
                    </Typography>
                    {/* Rating and Review Count */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Rating
                        value={3.6} // Dynamically set based on data
                        precision={0.1}
                        size="small"
                        readOnly
                      />
                      <Typography
                        variant="body2"
                        style={{ marginLeft: 8, color: "#757575" }}
                      >
                        3.6 | 186 reviews
                      </Typography>
                    </div>
                    {/* Tags */}
                    <div
                      style={{
                        marginTop: 8,
                        display: "flex",
                        gap: 8, // Adds spacing between chips
                      }}
                    >
                      <Chip
                        label={
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: 80, // Adjust as needed
                            }}
                          >
                            {company?.industry || "Industry"}
                          </div>
                        }
                        style={{ fontSize: 10 }}
                      />
                      <Chip
                        label={
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: 100, // Adjust as needed
                            }}
                          >
                            {company?.subIndustry || "Sub-Industry"}
                          </div>
                        }
                        style={{ fontSize: 10 }}
                      />
                    </div>
                  </div>
                </div>
                {/* Arrow Icon */}
                <IconButton>
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </Paper>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default CompanyCards;