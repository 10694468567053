import React, { useEffect, useState } from "react";
// import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import JobSeekerVideo from "../../../components/EmployerComponents/JobSeekerProfile/JobSeekerVideo";
import JobSeekerDetails from "../../../components/EmployerComponents/JobSeekerProfile/JobSeekerDetails";
import { Box, Container } from "@mui/system";
import SimilarProfiles from "../../../components/EmployerComponents/JobSeekerProfile/SimilarProfiles";
import { getJobseekerProfileApi } from "../../../api/jobseeker";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const ViewJobSeekerProfile = () => {
  const [jobSeekerData, setJobseekerData] = useState(null);
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [currentId,setCurrentId] = useState(id)

const handleSelectProfile = (newId)=>{
  setCurrentId(newId)
}
  useEffect(() => {
    if (currentId) {
      const candidateProfile = async () => {
        try {
          const response = await getJobseekerProfileApi(currentId, token);
          console.log("response", response);

          if (response.status === 400) {
            // Show toast error
            toast.error(response.message);

            // Delay navigation to allow the toast to display
            setTimeout(() => {
              navigate(-1); // Go back to the previous page after a delay
            }, 2000); // 1-second delay

            return; // Exit the function to prevent further execution
          }

          setJobseekerData(response?.user); // Set the fetched data
        } catch (error) {
          console.log(error.message);
          toast.error('Not Enough user access to view profile');

          // Delay navigation to allow the toast to display
          setTimeout(() => {
            navigate(-1); // Go back to the previous page after a delay
          }, 1000); // 1-second delay
        }
      };
      candidateProfile();
    }
  }, [currentId, token, navigate]);

  console.log('jobseekerdata',jobSeekerData)

  return (
   
    <Container
    maxWidth="lg" // Adjust the max width of the container
    sx={{
      padding: 4, // Add padding around the container
      backgroundColor: "#f9f9f9", // Light background color
      minHeight: "100vh", // Ensure the container takes the full height of the viewport
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 4, // Increase the gap between elements
        fontFamily: "IBM Plex Sans, sans-serif", // Ensure the font is applied
        flexDirection: { xs: "column", md: "row" }, // Stack vertically on small screens, horizontally on medium and larger screens
      }}
    >
      {/* Left Side: Title, Video, Video Carousel */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4, // Add gap between child elements
          width: { xs: "100%", md: "70%" }, // Full width on small screens, 70% on medium and larger screens
        }}
      >
        {/* Conditional rendering for JobSeekerVideo and JobSeekerDetails */}
        {jobSeekerData && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: 4, // Increase the gap between video and details
                flexDirection: { xs: "column", md: "row" }, // Stack vertically on small screens, horizontally on medium and larger screens
              }}
            >
              {/* Video Card */}
              <Box
                sx={{
                  flex: 1, // Take up available space
                  maxWidth: { xs: "100%", md: "50%" }, // Full width on small screens, 50% on medium and larger screens
                }}
              >
                <JobSeekerVideo data={jobSeekerData} />
              </Box>
  
              {/* Description */}
              <Box
                sx={{
                  flex: 1, // Take up available space
                  maxWidth: { xs: "100%", md: "50%" }, // Full width on small screens, 50% on medium and larger screens
                }}
              >
                <JobSeekerDetails data={jobSeekerData} />
              </Box>
            </Box>
  
            {/* Video Carousel */}
            <Box
              sx={{
                width: "100%",
                marginTop: 4, // Add margin at the top
              }}
            >
              <SimilarProfiles
                currentId={currentId}
                handleSelectProfile={handleSelectProfile}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  
    {/* Toast Container */}
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      closeOnClick
      pauseOnHover
      draggable
      theme="colored"
    />
  </Container>
 
  );
};

export default ViewJobSeekerProfile;