import { z } from "zod";

// Zod schema for job post validation
const jobPostSchema = z.object({
  jobTitle: z.string().min(1, "Job title is required "),
  // secretKey: z.string().min(1, "Secret key is required"),
  jobLocation: z.string().min(1, "Job city is required"),
  jobRole: z.string().min(1, "Role description is required"),
  educationRequired: z.string().min(1, "Education is required"),
  experienceRequired: z.string().min(1, "Experience is required"),
  ctc: z.string().min(1, "CTC range is required"),
  department: z.string().min(1, "Department is required"),
  industry: z.string().min(1, "Industry is required"),
  jobType: z.string().min(1, "Job type is required"),
  noOfPostionsOpen: z
    .number()
    .min(0, "Open positions must be a positive number"),
  skillPreferences: z
    .array(z.string())
    .nonempty("At least one skill is required"),
  walkIn: z.boolean(),
  
  revealSalary: z.boolean(),
  startDate: z.string().min(1, "Start date is required"),
  endDate: z.string().min(1, "End date is required"),
});

export default jobPostSchema;
