import baseURL from "./baseUrl";

export const allUsers = async (orgId, token) => {
  try {
    const response = await fetch(
      `${baseURL}/organization/${orgId}/getallrecruiters`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export const addUser = async (orgId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/${orgId}/addrecruiter`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
  } catch (error) {
    console.log(error.message);
  }
};


export const verifyRecruiterApi = async (orgId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/${orgId}/verifyRecruiter`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};
export const getUsersOfOrg = async (orgId, token) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/admins/${orgId}`, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": 'Application/json'
      }
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error.message);
  }
};

export const editUser = async (userId, token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/${userId}`, {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": 'Application/json'
      },
      body: JSON.stringify(formValues),
    })
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json()
    return data
  }
  catch (error) {
    console.log(error.message)
  }
}

export const deleteRecruiter = async (userId, token) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/${userId}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": 'Application/json'
      },
    })
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json()
    return data
  }
  catch (error) {
    console.log(error.message)
  }
}