import React, { useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import StorageIcon from "@mui/icons-material/Storage";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LockOpenIcon from "@mui/icons-material/LockOpen";
// import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
// import GroupIcon from '@mui/icons-material/Group';
const EmployeeDrawerList = ({
  Drawer,
  DrawerHeader,
  open,
  selectedIndex,
  handleDrawerToggle,
  theme,
  handleNavigation,
  userRole,
}) => {
  const [openDatabases, setOpenDatabases] = useState(true);

  const handleClickDatabases = () => {
    setOpenDatabases(!openDatabases);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerToggle}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 0}
            onClick={() => handleNavigation("/employerJobListings", 0)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <BusinessCenterIcon />
            </ListItemIcon>
            <ListItemText primary="Jobs" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={handleClickDatabases}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <StorageIcon />
            </ListItemIcon>
            <ListItemText primary="Databases" sx={{ opacity: open ? 1 : 0 }} />
            {openDatabases ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openDatabases} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{
                  display: "flex",
                  justifyContent: "space-between",

                  alignItems: "center",
                  gap: 3,
                }}
                onClick={() => handleNavigation("/search-candidates", 6)}
              >
                <PersonSearchIcon sx={{ opacity: 0.6, marginLeft: 1 }} />
                <ListItemText secondary="Search Candidates" />
              </ListItemButton>
              <ListItemButton
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                }}
                onClick={() => handleNavigation("/saved-searches", 7)}
              >
                <BookmarkIcon sx={{ opacity: 0.6, marginLeft: 1 }} />
                <ListItemText secondary="Saved Searches" />
              </ListItemButton>
              <ListItemButton
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                }}
                onClick={() => handleNavigation("/unlock-candidates", 8)}
              >
                <LockOpenIcon sx={{ opacity: 0.6, marginLeft: 1 }} />
                <ListItemText secondary="Unlock Candidates" />
              </ListItemButton>
            </List>
          </Collapse>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={() => handleNavigation("/employer-reports", 2)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <QueryStatsIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {/* <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 3}
            onClick={() => handleNavigation("/team-members", 3)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              primary="Team Members"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem> */}
        '{" "}
        {userRole === "recruiter_admin" && (
          <>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={selectedIndex === 3}
                onClick={() => handleNavigation("/employer/manageUsers", 3)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Manage Users"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={selectedIndex === 3}
                onClick={() => handleNavigation("/employer/companyProfile", 3)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <CorporateFareIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Company Profile"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
              <ListItemButton
                selected={selectedIndex === 3}
                onClick={() =>
                  handleNavigation("/employer/subscriptionStatus", 3)
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ReceiptLongIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Subscription Status"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </>
        )}
        <>
          {/* <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={selectedIndex === 4}
                onClick={() => handleNavigation("/credits-usage", 4)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Credits & Usage"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={selectedIndex === 5}
                onClick={() => handleNavigation("/billing", 5)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <MailIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Billing"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem> */}
        </>
      </List>
    </Drawer>
  );
};

export default EmployeeDrawerList;
