import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Container, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { jwtDecode } from "jwt-decode";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import JobCard from "../../../components/EmployerComponents/EmployerJobListingComponents/JobCard";
import JobFilterButtons from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerJobFilterTabs";
// import { createJobPost } from '../../../api/jobPostAPI';
import baseURL from "../../../api/baseUrl";
import EmployerJobSeekerSupport from "../EmployerJobSeekerSupport/EmployerJobSeekerSupport";
// import PostJobDialog from "../../../components/EmployerComponents/PostJobComponents/PostJobDialog";

const EmployerJobListings = () => {
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState(0); // Managing active state for the filter buttons
  const [jobs, setJobs] = useState([]); // State to store job postings
  // const [openDialog, setOpenDialog] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const organizationId = localStorage.getItem("organizationId");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userData = {
          organizationId: decodedToken.organizationId || organizationId,
          role: decodedToken.role || role,
          token,
          id: decodedToken.id,
          fullName: decodedToken.name,
        };

        setUserData(userData); // Set userData to state
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    } else {
      navigate("/employerSignIn");
    }
  }, [navigate]);

  // Fetch job postings based on the userData
  const fetchJobPostings = useCallback(async () => {
    if (!userData) return;

    const { role, id, token, organizationId } = userData;
    let url = "";

    if (role === "recruiter_admin") {
      url = `${baseURL}/jobpost/${organizationId}`;
    } else if (role === "recruiter") {
      url = `${baseURL}/jobpost/by/${id}`;
    } else {
      console.error("Invalid role:", role);
      return;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        setJobs(data);
      } else {
        console.error("Error fetching job postings:", data.message);
      }
    } catch (error) {
      console.error("Error fetching job postings:", error);
    }
  }, [userData]);

  useEffect(() => {
    fetchJobPostings();
  }, [userData, fetchJobPostings]);

  // const handlePostJobClick = async () => {
  //   try {
  //     const data = await createJobPost(userData.organizationId, userData.token);
  //     navigate('/employersRegistrationProfile', { state: { jobData: data } });
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const handleNavigateJobPostPage = () => {
    navigate("/employerJobPosting");
  };

  const handleFilterButtonClick = (index) => {
    setActiveFilter(index);
  };

  // const handleOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  // Filter jobs based on the selected filter
  const filteredJobs = jobs?.filter((job) => {
    switch (activeFilter) {
      case 1:
        return job.status === "active"; // Active jobs
      case 2:
        return job.status === "expired"; // Expired jobs
      case 3:
        return job.planStatus === "unpaid"; // Jobs with unpaid plans
      default:
        return true; // All jobs
    }
  });

  return (
    <EmployerDrawer>
      <Container>
        <Box sx={{ padding: 2 }}>
          {/* Title and Post a New Job Button */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ textAlign: { xs: "center", sm: "left" } }}
            >
              All Jobs
            </Typography>
            <Button
              onClick={handleNavigateJobPostPage}
              sx={{
                backgroundColor: "#423bfa",
                color: "#fff",
                fontWeight: "bold",
                textTransform: "none",
                borderRadius: 10,
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                marginTop: { xs: 2, sm: 0 },
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#423bfa",
                  border: "1px solid #423bfa",
                },
              }}
            >
              <AddIcon sx={{ marginRight: 1 }} />
              Post a new job
            </Button>
          </Box>

          {/* Job Filter Buttons */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <JobFilterButtons
              activeFilter={activeFilter}
              onFilterClick={handleFilterButtonClick}
            />
          </Box>

          {/* Job Cards */}
          <Grid container spacing={2}>
            {filteredJobs
              ?.slice()
              .reverse()
              .map((job) => (
                <Grid item xs={12} key={job._id}>
                  <JobCard
                    job={{
                      title: job.jobTitle,
                      status: job.status,
                      location: job.jobLocation,
                      postedOn: new Date(job.postDate).toLocaleDateString(),
                      postedBy: job.recruiter_Name,
                      company: job.organizationId.organizationName,
                      actionPending: job.actionPending,
                      allCandidates: job.allCandidates,
                      planRequired: job.planStatus,
                      applicationCount: job.applicationCount,
                      viewCount: job.viewCount,
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
        {/* <PostJobDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
        /> */}
      </Container>
      <EmployerJobSeekerSupport />
    </EmployerDrawer>
  );
};

export default EmployerJobListings;
