import React, { useCallback, useEffect, useState } from "react";
import { Button, Typography, Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
// import AllUser from "../../../components/EmployerComponents/ManageUserComponents/AllUsers";
import AddUser from "./AddUser";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MemberStats from "./MemebersStats";
import MemberTable from "./MemberTable";
import ManageQuota from "../ManageQuota/ManageQuota";
import { allUsers } from "../../../api/manageUsers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ManageUsers = () => {
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [orgUsers, setOrgUsers] = useState();

  const handlOpenDialog = () => {
    setOpenAddUserDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenAddUserDialog(false);
  };

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId");
  const fetchUsers = useCallback(async () => {
    try {
      if (orgId) {
        const manageUserData = await allUsers(orgId, token);
        setOrgUsers(manageUserData);
      } else {
        throw new Error("No organization id");
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [orgId, token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <EmployerDrawer>
      <Typography
        onClick={handleBack}
        gutterBottom
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          mb: "15px",
          color: "grey", // Adjust color for better visibility
          "&:hover": {
            color: "primary.dark", // Hover effect
          },
        }}
      >
        <ArrowBackIcon sx={{ marginRight: "4px" }} />
      </Typography>
      <Container sx={{ borderRadius: "5px" }}>
        <Box sx={{ padding: "2rem", height: "100%" }}>
          {/* Header Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              marginBottom: 3,
              borderRadius: "10px",
            }}
          >
            <Box>
              {/* Team Members Header */}
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: "bold", lineHeight: 0.5 }}
              >
                Team members
              </Typography>

              {/* Invite New Members Text */}
              <Typography
                variant="body2"
                sx={{ marginTop: 0, lineHeight: 1.5 }}
              >
                Invite new members to your team and assign them different roles
                and permissions.
              </Typography>
            </Box>

            {/* Add Users Button */}
            <Button
              variant="contained"
              onClick={handlOpenDialog}
              startIcon={<PersonAddIcon />}
              sx={{
                backgroundColor: "#1f8268",
                color: "#fff",
                fontWeight: "bold",
                textTransform: "none",
                borderRadius: 8,
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#423bfa",
                  border: "1px solid #423bfa",
                },
              }}
            >
              Add Member
            </Button>
          </Box>

          <Container
            elevation={0}
            sx={{
              padding: 2,
              backgroundColor: "#f9f9f9",
            }}
          >
            <MemberStats orgUsers={orgUsers} />
            <ManageQuota />
            <MemberTable />
          </Container>
          {/* Tabs Section */}
          {/* <AllUser /> */}
        </Box>

        {/* Add User Dialog */}
        <AddUser
          openAddUserDialog={openAddUserDialog}
          handleCloseDialog={handleCloseDialog}
        />
      </Container>
    </EmployerDrawer>
  );
};
export default ManageUsers;
