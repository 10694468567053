import React, { memo, useCallback } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  Badge,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const EmployerJobSeekerChatList = memo(({ chatList, onChatSelect }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Detect small screens

  const handleChatSelect = useCallback(
    (chatId) => {
      onChatSelect(chatId);
    },
    [onChatSelect]
  );

  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.up("md") && theme.breakpoints.down("lg")
  );

  return (
    <List
      sx={{
        padding: 0,
        background: "linear-gradient(135deg, #e0f7fa, #f9f9f9)",
        borderRadius: "12px",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
        maxHeight: "400px", // Adjust height as per your design
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#b3b3b3",
          borderRadius: "2px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#888",
        },
      }}
    >
      {chatList?.map((chat) => {
        const jobseeker =
          chat.participants.find((p) => p.userModel === "User") || {};

        return (
          <ListItem
            key={chat._id}
            onClick={() => handleChatSelect(chat._id)}
            sx={{
              padding: isExtraSmallScreen
                ? "6px"
                : isSmallScreen
                ? "8px"
                : isMediumScreen
                ? "10px"
                : "12px",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                backgroundColor: "#ebebeb",
                cursor: "pointer",
              },
              borderBottom: "1px solid #e5e5e5",
              "&:last-child": {
                borderBottom: "none",
              },
            }}
          >
            <ListItemAvatar
              sx={{
                marginRight: isExtraSmallScreen
                  ? "8px"
                  : isSmallScreen
                  ? "12px"
                  : isMediumScreen
                  ? "14px"
                  : "16px",
              }}
            >
              <Badge
                color="success"
                variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Avatar
                  alt={jobseeker?.userInfo?.fullName || "User"}
                  src={jobseeker?.userInfo?.profileImage}
                  sx={{
                    width: isExtraSmallScreen
                      ? 32
                      : isSmallScreen
                      ? 36
                      : isMediumScreen
                      ? 42
                      : 46,
                    height: isExtraSmallScreen
                      ? 32
                      : isSmallScreen
                      ? 36
                      : isMediumScreen
                      ? 42
                      : 46,
                    border: "none",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    color: "#111",
                  }}
                >
                  {jobseeker?.userInfo?.fullName || "User"}
                </Typography>
              }
              secondary={
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.9rem",
                      color: "#4a4a4a",
                    }}
                  >
                    {chat.lastMessage || "No messages yet"}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "0.8rem",
                      color: "#6d6d6d",
                      fontWeight: "bold",
                      marginLeft: "16px",
                    }}
                  >
                    {chat.lastMessageTimestamp
                      ? new Date(chat.lastMessageTimestamp).toLocaleTimeString()
                      : ""}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
});

export default EmployerJobSeekerChatList;
