import React, { useContext } from "react";
import { Box, IconButton, Card, CardMedia, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SearchContext } from "../../../context/SearchResults";

const SimilarProfiles = ({ currentId, handleSelectProfile }) => {
  // Access profiles from the context
  const { profiles } = useContext(SearchContext);


  const storedProfiles = JSON.parse(localStorage.getItem("profiles")) || [];


  const profilesToUse = profiles.length > 0 ? profiles : storedProfiles;

  // Filter out the current profile
  const filteredProfiles = profilesToUse.filter((profile) => profile.id !== currentId);

  // Carousel state
  const [startIndex, setStartIndex] = React.useState(0);

  const handlePrev = () => {
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setStartIndex((prev) => Math.min(prev + 1, filteredProfiles.length - 3));
  };

  return (
    <Box sx={{ width: { xs: "100%", sm: 314 }, position: "relative", overflow: "hidden", p: 1 }}>
      <Typography sx={{ fontFamily: "IBM plex sans", fontWeight: "bold", mb: 1 }}>
        Similar profiles
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 1,
          transition: "transform 0.3s ease-in-out",
          transform: `translateX(-${startIndex * (100 / 3)}%)`,
          borderRadius: 0,
        }}
      >
        {filteredProfiles?.map((profile) => (
          <Card
            key={profile.id}
            sx={{
              minWidth: `calc(100% / 2.2 - 8px)`, // Each slide takes 1/3 of the carousel width
              borderRadius: 2,
              cursor: "pointer",
              flexShrink: 0,
              backgroundColor: "#EAFBF4FF",
              width: 80,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardMedia
              component="video"
              image={profile?.videoCV}
              alt={"No Job video found for this"}
              sx={{
                borderRadius: 2,
                height: 130,
                width: "100%",
                objectFit: "cover",
              }}
              controls // Add controls to the video
            />
            <Typography
              sx={{
                backgroundColor: "#EAFBF4FF",
                fontFamily: "IBM plex sans",
                fontSize: 12,
                px: 2,
              }}
              onClick={() => handleSelectProfile(profile.id)}
            >
              <span style={{ fontFamily: "IBM plex sans", fontWeight: "bold", fontSize: 14 }}>
                {profile?.name}
              </span>{" "}
              <br />
              {profile?.workExperience?.map((work) => work?.designation).join(", ")}
            </Typography>
          </Card>
        ))}
      </Box>

      {/* Left Navigation Button */}
      <IconButton
        onClick={handlePrev}
        disabled={startIndex === 0}
        sx={{
          position: "absolute",
          left: 8,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <ChevronLeftIcon />
      </IconButton>

      {/* Right Navigation Button */}
      <IconButton
        onClick={handleNext}
        disabled={startIndex >= filteredProfiles?.length - 3}
        sx={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default SimilarProfiles;