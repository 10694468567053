import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon
import { employerJobSeekerChat } from "../../../api/chat"; // Assuming this is the correct API call
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";

const EmployerJobSeekerChatWindow = ({
  chatData,
  onMessageSent,
  handleAccordionChange,
}) => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);

  // Initialize messages with chatData when the component mounts or chatData changes
  useEffect(() => {
    if (Array.isArray(chatData)) {
      const formattedMessages = chatData.map((msg) => ({
        text: msg.message,
        sender: msg.sender.name,
        userModel: msg.sender.userModel,
        timestamp: new Date(msg.timestamp),
      }));
      setMessages(formattedMessages);
    } else {
      console.error("chatData is not an array:", chatData);
    }
  }, [chatData]);

  const handleSendMessage = async () => {
    if (input.trim()) {
      const targetMessage = chatData[chatData.length - 1]; // Get the last message or adjust logic to target the message you need
      const { sender, receiver } = targetMessage;

      const messagePayload = {
        senderId: sender.userId,
        receiverId: receiver.userId,
        senderModel: sender.userModel,
        receiverModel: receiver.userModel,
        message: input,
      };
      const newMessage = {
        text: input,
        sender: sender.name,
        userModel: sender.userModel,
        timestamp: new Date(),
      };

      try {
        await employerJobSeekerChat(messagePayload);
        onMessageSent(newMessage);
        setInput("");
      } catch (error) {
        console.error("Failed to send message:", error.message);
      }
    }
  };

  // Function to format the timestamp
  const formatTimestamp = (timestamp) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (timestamp.toDateString() === today.toDateString()) {
      return "Today";
    } else if (timestamp.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return timestamp.toLocaleDateString(); // For other dates, return the date
    }
  };
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: 1,
        borderRadius: 2,
        backgroundColor: "#f0f2f5", // WhatsApp-like background
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      {/* Close Icon */}
      <IconButton
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "#4caf50", // WhatsApp green color
          "&:hover": {
            color: "#388e3c",
          },
        }}
        onClick={handleAccordionChange}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          display: "flex", // Use flexbox for horizontal alignment
          marginBottom: 2,
        }}
      >
        <Avatar src={chatData?.[0]?.sender?.profileImage} sx={{ mr: 2 }} />
        <Typography
          variant="h6"
          sx={{
            textAlign:'center',
            fontWeight: "bold",
            color: "#075e54", // WhatsApp header color
          }}
        >
          {chatData?.[0]?.receiver?.name}
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: "auto", padding: 2 }}>
        {messages.length === 0 ? (
          <Typography
            variant="body2"
            sx={{ textAlign: "center", color: "gray" }}
          >
            No messages yet. Start the conversation!
          </Typography>
        ) : (
          messages.map((msg, index) => {
            const previousMessage = messages[index - 1];
            const showDate =
              !previousMessage ||
              previousMessage.timestamp.toDateString() !==
                msg.timestamp.toDateString();

            return (
              <Box key={index} sx={{ marginBottom: 2 }}>
                {showDate && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "bold",
                        marginBottom: 1,
                        padding: "4px 8px",
                        borderRadius: "12px",
                        background: "#128c7e", // WhatsApp's green
                      }}
                    >
                      {formatTimestamp(msg.timestamp)}
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection:
                      msg.userModel === "Recruiter" ? "row-reverse" : "row",
                    marginBottom: 2,
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "70%",
                      padding: "10px 15px",
                      borderRadius: "12px",
                      backgroundColor:
                        msg.userModel === "Recruiter" ? "#d9fdd3" : "#ffffff", // Green for sent, white for received
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography variant="body2" sx={{ display: "inline" }}>
                      {msg.text}
                      <span
                        style={{
                          marginLeft: "8px", // Add spacing between the text and timestamp
                          fontStyle: "italic",
                          color: "#999",
                          fontSize: "0.875rem", // Slightly smaller font size for the timestamp
                        }}
                      >
                        {msg.timestamp.toLocaleTimeString()}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px 8px",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
          gap: 1,
        }}
      >
        <IconButton
          sx={{
            color: "#4caf50", // WhatsApp green
            "&:hover": { color: "#388e3c" },
          }}
        >
          <AttachFileIcon />
        </IconButton>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
          sx={{
            borderRadius: "12px",
            backgroundColor: "#f0f2f5",
            boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
          }}
        />

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#25d366", // WhatsApp green
            borderRadius: "50%",
            minWidth: "40px",
            minHeight: "40px",
            padding: 0,
            "&:hover": {
              backgroundColor: "#128c7e",
            },
          }}
          onClick={handleSendMessage}
        >
          <SendIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default EmployerJobSeekerChatWindow;
