import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";

// Static array for minExperience (0 to 30 years)
const minExperienceOptions = Array.from({ length: 31 }, (_, i) => ({
  value: i,
  label: i === 0 ? `${i} year` : `${i} years`,
}));

// Static array for maxExperience (1 to 30 years)
const maxExperienceOptions = Array.from({ length: 30 }, (_, i) => ({
  value: i,
  label: i + 1 === 1 ? `${i + 1} year` : `${i + 1} years`,
}));



// Static array for salary options (0 to 100 Lakhs)
const salaryOptions = Array.from({ length: 101 }, (_, i) => ({
  value: i,
  label: i === 100 ? "100+ Lakhs" : `${i} Lakhs`,
}));

export default function SingleSelect({
  formData,
  handleChange,
  initialMinExp,
  initialMaxExp,
  initialMinSalary,
  initialMaxSalary
}) {
  const [experienceSearch, setExperienceSearch] = useState("");
  const [salarySearch, setSalarySearch] = useState("");


  console.log('maxexp',initialMaxExp)

  // Map formData values to their corresponding labels
  const getOptionFromValue = (value, options) => {
    // Return null if value is null, undefined, or not a number
    if (value === null || value === undefined || typeof value !== "number") {
      return null;
    }
  
    // Explicitly return null if the value is 0
    if (value === 0) {
      return null;
    }
  
    // Find the matching option
    const option = options?.find((option) => option?.value === value);
  
    // Return null if no option is found
    return option || null;
  };

  // Get initial options for min and max experience
  const initialMinExpOption = getOptionFromValue(Number(initialMinExp), minExperienceOptions);
  const initialMaxExpOption = getOptionFromValue(Number(initialMaxExp), maxExperienceOptions);
  const initialMinSalaryOptions = getOptionFromValue(Number(initialMinSalary),salaryOptions)
  const initialMaxSalaryOptions = getOptionFromValue(Number(initialMaxSalary),salaryOptions)

  console.log('initialminexp',initialMinExpOption)


  const filteredMinExperienceOptions = React.useMemo(
    () =>
      minExperienceOptions.filter((option) =>
        option.label.toLowerCase().includes(experienceSearch.toLowerCase())
      ),
    [experienceSearch]
  );

  const filteredMaxExperienceOptions = maxExperienceOptions.filter((option) =>
    option.label.toLowerCase().includes(experienceSearch.toLowerCase())
  );

  const filteredSalaryOptions = salaryOptions.filter((option) =>
    option.label.toLowerCase().includes(salarySearch.toLowerCase())
  );

  return (
    <Grid container spacing={2} sx={{ mb: 1 }}>
      <Grid item xs={6}>
        <Typography
          component="legend"
          sx={{ fontWeight: "regular", fontSize: 13 }}
          gutterBottom
        >
          Minimum experience
        </Typography>
        <Autocomplete
          size="small"
          fullWidth
          options={filteredMinExperienceOptions}
          getOptionLabel={(option) => option.label}
          value={
            getOptionFromValue(formData.minExperience, minExperienceOptions) ||
            initialMinExpOption
          }
          onChange={(event, newValue) =>
            handleChange({
              target: {
                name: "minExperience",
                value: newValue ? newValue.value : null,
              },
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="minimum experience"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setExperienceSearch(e.target.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography
          component="legend"
          sx={{ fontWeight: "regular", fontSize: 13 }}
          gutterBottom
        >
          Maximum experience
        </Typography>
        <Autocomplete
          size="small"
          fullWidth
          options={filteredMaxExperienceOptions}
          getOptionLabel={(option) => option.label}
          value={
            getOptionFromValue(formData.maxExperience, maxExperienceOptions) ||
            initialMaxExpOption
          }
          onChange={(event, newValue) =>
            handleChange({
              target: {
                name: "maxExperience",
                value: newValue ? newValue.value : null,
              },
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="maximum experience"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setExperienceSearch(e.target.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography
          component="legend"
          sx={{ fontWeight: "regular", fontSize: 13 }}
          gutterBottom
        >
          Min. salary in lakhs
        </Typography>
        <Autocomplete
          size="small"
          fullWidth
          options={filteredSalaryOptions}
          getOptionLabel={(option) => option.label}
          value={getOptionFromValue(formData.minSalary, salaryOptions)||initialMinSalaryOptions}
          onChange={(event, newValue) =>
            handleChange({
              target: {
                name: "minSalary",
                value: newValue ? newValue.value : null,
              },
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="minimum salary"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSalarySearch(e.target.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography
          component="legend"
          sx={{ fontWeight: "regular", fontSize: 13 }}
          gutterBottom
        >
          Max. salary in lakhs
        </Typography>
        <Autocomplete
          size="small"
          fullWidth
          options={filteredSalaryOptions}
          getOptionLabel={(option) => option.label}
          value={getOptionFromValue(formData.maxSalary, salaryOptions)||initialMaxSalaryOptions}
          onChange={(event, newValue) =>
            handleChange({
              target: {
                name: "maxSalary",
                value: newValue ? newValue.value : null,
              },
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="maximum salary"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSalarySearch(e.target.value)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}