import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
// import { FilterList, SmartToy, Phone } from "@mui/icons-material";
// import PsychologyIcon from "@mui/icons-material/Psychology";
// import SettingsIcon from "@mui/icons-material/Settings";
// import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ChatIcon from '@mui/icons-material/Chat';

const videoLinks = [
    "https://youtube.com/embed/Ume9BKap5I4",
    "https://youtube.com/embed/xQhV1pD2u_w",
    "https://youtube.com/embed/2VRTlTVGk0k",
    "https://youtube.com/embed/Ume9BKap5I4",
    "https://youtube.com/embed/xQhV1pD2u_w",
    "https://youtube.com/embed/2VRTlTVGk0k",
    "https://youtube.com/embed/Ume9BKap5I4",
    "https://youtube.com/embed/xQhV1pD2u_w",
  ];
  const features = [
    {
      icon: (
        <span style={{ fontSize: "3rem" }}>
          <VideoCameraFrontIcon sx={{ color: "#349beb", fontSize: "60px" }} />
        </span>
      ),
      title: "Make an Impact",
      description:
        "Get Noticed with Video Profiles",
    },
    {
      icon: (
        <span style={{ fontSize: "3rem" }}>
          <FlashOnIcon sx={{ color: "#349beb", fontSize: "60px" }} />
        </span>
      ),
      title: "Hassle-Free Applications",
      description:
        "Skip the Paperwork, Instant Job Matches with Video",
    },
    {
      icon: (
        <span style={{ fontSize: "3rem" }}>
          <ConnectWithoutContactIcon sx={{ color: "#349beb", fontSize: "60px" }} />
        </span>
      ),
      title: "Instant Opportunities",
      description: "Connect with Employers in Seconds",
    },
  ];

const Advertisements = () => {
  return (
   <>
   <Box
          sx={{
            textAlign: "center",
            padding: 3,
            overflow: "hidden",
            backgroundColor: "white",
            color: "#191a19",
            borderRadius: "10px",
            margin:0,
            marginTop:13,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 4 }}>
            Trusted by 1000+ enterprises and 7 lakhs+ MSMEs for hiring
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor:"white",
              animation: "scroll 10s linear infinite",
              "@keyframes scroll": {
                "0%": { transform: "translateX(100%)" },
                "100%": { transform: "translateX(-100%)" },
              },
            }}
          >
            {/* Logo Images */}
            <img
              src="https://logos-world.net/wp-content/uploads/2020/11/Flipkart-Logo.png"
              alt="Flipkart"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://www.startupinsider.in/wp-content/uploads/2022/09/Big-Basket2.jpg"
              alt="BigBasket"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://m.economictimes.com/thumb/msid-113833509,width-1200,height-900,resizemode-4,imgsize-12884/hdfc-bank-share-price-live-updates-01-oct-2024.jpg"
              alt="HDFC Bank"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://startuparticle.com/wp-content/uploads/2019/11/swiggy-2.jpg"
              alt="Swiggy"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://img.etimg.com/photo/msid-104946989,imgsize-25938/Uber.jpg"
              alt="Uber"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsrtAlXGZ5qUsN3jPYrmM88R-bp7axQB_fDQ&s"
              alt="Urban Company"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://m.economictimes.com/thumb/msid-113833509,width-1200,height-900,resizemode-4,imgsize-12884/hdfc-bank-share-price-live-updates-01-oct-2024.jpg"
              alt="HDFC Bank"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://startuparticle.com/wp-content/uploads/2019/11/swiggy-2.jpg"
              alt="Swiggy"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://img.etimg.com/photo/msid-104946989,imgsize-25938/Uber.jpg"
              alt="Uber"
              style={{ height: 80, marginRight: 30 }}
            />
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsrtAlXGZ5qUsN3jPYrmM88R-bp7axQB_fDQ&s"
              alt="Urban Company"
              style={{ height: 80, marginRight: 30 }}
            />
          </Box>

          <Box
            sx={{
              textAlign: "center",
              marginBottom: 4,
              color: "black",
              backgroundColor: "white",
              marginTop: 4,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "black",
                marginBottom: 1,
                fontSize: 40,
              }}
            >
              A single platform for your hiring needs
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* Colored Line Segments */}
              <Box
                sx={{
                  height: "4px",
                  width: "20%",
                  backgroundColor: "#1FD3C4FF",
                }}
              ></Box>
              <Box
                sx={{
                  height: "4px",
                  width: "20%",
                  backgroundColor: "#0052CC",
                }}
              ></Box>
              <Box
                sx={{
                  height: "4px",
                  width: "20%",
                  backgroundColor: "#000000FF",
                }}
              ></Box>
            </Box>
          </Box>
        </Box>
      <Box  sx={{
            display: "flex",
            flexDirection:"column",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop:2,
            backgroundColor: "#f5faf5",
            width: "100vw",
          }}>
        <Typography variant='h3' sx={{fontWeight:"bold"}}>
          Why Vacanzi
        </Typography>
      <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
            paddingBottom:3,
            backgroundColor: "#f5faf5",
            width: "100vw",
          }}
        >
          
          <Box
            sx={{
              flex: "0 0 50%",
              marginRight: "2rem",
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
       
            {videoLinks.map((videoUrl, index) => (
              <Box
                key={index}
                sx={{
                  width: "calc(25% - 1rem)",
                  borderRadius: "10px", // Apply border radius on all corners
                  overflow: "hidden", // Prevent overflow of content
                  // boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // Optional: Add some shadow for effect
                }}
              >
                <iframe
                  width="100%"
                  height="200"
                  src={videoUrl}
                  title={`YouTube video ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ borderRadius: "10px" }}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap:"1rem",
              padding: "2rem",
              textAlign: "start",
              fontFamily: "IBM Plex Sans, sans-serif", // Apply IBM Plex Sans globally
            }}
          >
            {/* <Typography
              variant="h4"
              sx={{
                fontSize: "2rem",
                fontWeight: "regular",
                marginBottom: "1rem",
                textAlign: "start",
                fontFamily: "IBM Plex Sans, sans-serif", // Make sure IBM Plex Sans is applied here too
              }}
            >
              Get applications from relevant, high-intent candidates
            </Typography> */}

            {/* Advanced Job Filters & Assessments */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2rem",
              }}
            >
              <TrendingUpIcon />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontFamily: "IBM Plex Sans" }}
              >
                Instantly Stand Out
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"start"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
            Show Your True Potential with Video Profiles
            </Typography>

            {/* Smart AI Lead Management */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                mt: 2,
              }}
            >
              <BusinessCenterIcon />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  textAlign: "left",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                Fast-Track Your Job Search
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"start"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
             Showcase Your Skills & Personality
            </Typography>

            {/* Inbound Calls & WhatsApp Alerts */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                mt: 2,
              }}
            >
              <ChatIcon />
              <Typography
                variant="h6"
                textAlign={"start"}
                sx={{ fontWeight: "bold", fontFamily: "IBM Plex Sans" }}
              >
                Quick Connections
              </Typography>
            </Box>
            <Typography
              variant="body1"
              textAlign={"center"}
              sx={{ fontFamily: "IBM Plex Sans" }}
            >
              Stay informed on the go with real-time candidate calls and
              application alerts directly on WhatsApp.
            </Typography>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#18181b",
                color: "#ffffff",
                borderRadius: "0.7rem",
                textTransform: "none",
                fontSize: "14px",
               
                mt: 3,
                padding: "0.375rem 0.75rem",
                fontFamily: "IBM Plex Sans",
                ":hover":{
                    backgroundColor:"#18181b",
                    color:"#ffffff",
                }
              }}
              onClick={() => window.scrollTo(0, 0)}
            >
              Search Jobs
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#f5faf5",
            width: "100vw",
            height: 300,
            px:"6rem",  
            gap: "2rem",
          }}
        >
          {features.map((feature) => (
            <Grid item key={feature.title} sx={{ textAlign: "center" }}>
              {feature.icon}
              <Typography
                variant="h6"
                component="div"
                sx={{ mt: 2, fontFamily: "IBM Plex Sans" }}
              >
                {feature.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ mt: 1, fontFamily: "IBM Plex Sans" }}
              >
                {feature.description}
              </Typography>
            </Grid>
          ))}
        </Box>
      </Box>
       
       
   </>
  )
}

export default Advertisements