import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import baseURL from "../../../api/baseUrl";
import CompanyOverView from "../CompanyOverView/CompanyOverView";
import CircularProgress from "@mui/material/CircularProgress"; // Import a loading spinner
import { Box } from "@mui/system"; // Import Box for centering

const JobSeekerJobDetails = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state

  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchJob = async () => {
      try {
        // Determine the API endpoint and method based on whether userId exists
        const endpoint = !userId
          ? `${baseURL}/jobs/${id}` // Endpoint for non-logged-in users (GET)
          : `${baseURL}/jobs/incrementview/${id}`; // Endpoint for logged-in users (POST)

        const method = userId ? "POST" : "GET"; // Use POST if userId exists, otherwise GET

        const headers = {
          "Content-Type": "application/json",
        };

        // Add authorization header only if userId exists
        if (userId) {
          headers.Authorization = `Bearer ${token}`;
        }

        const response = await fetch(endpoint, {
          method, // Use the determined method (GET or POST)
          headers,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setJob(data?.job);
      } catch (error) {
        console.error("Error fetching job:", error);
        setError(error.message); // Handle the error and set an error message
      } finally {
        setLoading(false); // Set loading to false after the request completes
      }
    };

    fetchJob();
  }, [id, userId, token]);

  console.log("selected job jobs", job);

  if (error) {
    return <p>Error: {error}</p>; // Display an error message if there's an error
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh" // Center the spinner vertically and horizontally
      >
        <CircularProgress /> {/* Display a loading spinner */}
      </Box>
    );
  }

  return (
    <div>
      {job ? (
        <CompanyOverView job={job} />
      ) : (
        <p>No job data found.</p> // Display a message if no job data is found
      )}
    </div>
  );
};

export default JobSeekerJobDetails;