import React, { useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { getTopIndustryMasters } from "../../../../api/common";

const CandidateIndustries = ({ industry, setIndustry }) => {
  const [industryList,setIndustryList]  = useState([])

    
useEffect(() => {
  const fetchIndustries = async () => {
    try {
      const response = await getTopIndustryMasters();
      if (response) {
        // Extract names from the response array
        const allCategories = response.reduce((acc, resItem) => {
          resItem.items.forEach((item) => {
            if (item?.name) {
              acc.push(item?.name); // Only push the name of the industry
            }
          });
          return acc;
        }, []);

        setIndustryList(allCategories); // Set the industry list with the extracted names
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  fetchIndustries();
}, []);
const handleIndustryChange = (event,newValue) => {
  // newValue is an array of selected options
  setIndustry(newValue);

}


  return (
    <Accordion
    defaultExpanded
    sx={{
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:before": {
        display: "none",
      },
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
      sx={{
        padding: "8px 0",
        minHeight: "40px",
        "& .MuiAccordionSummary-content": {
          margin: "0",
        },
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "bold",
          fontFamily: "IBM Plex Sans, sans-serif",
          color: "#333",
        }}
      >
        Industries
      </Typography>
    </AccordionSummary>
    <AccordionDetails
      sx={{ padding: "0 0 16px 0", backgroundColor: "transparent" }}
    >
      <Autocomplete
        multiple // Allow multiple selections
        options={industryList} // List of options
        value={industry} // Current selected industries
        onChange={handleIndustryChange} // Handle selection changes
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select industries"
            variant="outlined"
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
      />
    </AccordionDetails>
  </Accordion>
  );
};

export default CandidateIndustries;