import React, { useState, useEffect } from 'react';
import { Box, Grid, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
// import NavBar from '../../../components/JobSeekerComponents/NavBar/NavBar';
import Footer from '../../../components/JobSeekerComponents/Footer/Footer';
import UpdateJSProfile from '../../../components/JobSeekerComponents/JobSeekerUpdateProfile/UpdateJobSeekerProfile';
import EditProfileDrawer from '../../../components/JobSeekerComponents/JobSeekerUpdateProfile/UpdateJobSeekerProfileDrawer';
import ProfileSection from '../../../components/JobSeekerComponents/JobSeekerUpdateProfile/UpdateJobSeekerProfileSection';
import signupVideo from '../../../assets/videos/videoplayback2.mp4';
import { getUserIdFromToken } from '../../../utils/userAuth';
import baseURL from '../../../api/baseUrl'; // Ensure the correct path
import NewNavBar from '../../../components/JobSeekerComponents/NavBar/NewNavbar';

const JobSeekerPersonalProfile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpen, setIsOpen] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const toggleDrawer = (open) => {
    setIsOpen(open);
  };



  useEffect(() => {
    const fetchProfileData = async () => {
      const userId = getUserIdFromToken();
      if (!userId) {
        console.error("User ID not found");
        setLoading(false);
        setError("User ID not found");
        return;
      }

      const authToken = localStorage.getItem("token"); // Retrieve authToken from localStorage

      try {
        const response = await fetch(`${baseURL}/jobseeker/${userId}`, {
          headers: {
            'Authorization': `Bearer ${authToken}`, // Pass authToken in headers
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setProfileData(data?.user);
      } catch (error) {
        console.error("Failed to fetch profile data", error);
        setError("Failed to load profile data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);


  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>{error}</div>
      </Box>
    );
  }

  return (
    <>
      <NewNavBar />
      <Box sx={{ p: { xs: 2, sm: 5, md: 10 } }}>
        <Grid container spacing={isMobile ? 2 : 4}>
          <Grid item xs={12} md={3}>
            <UpdateJSProfile toggleDrawer={toggleDrawer} isOpen={isOpen} profileData={profileData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ProfileSection profileData={profileData} setProfileData={setProfileData} />
          </Grid>
          <Grid item md={3}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <video autoPlay loop muted
                style={{
                  width: '100%',
                  height: isMobile ? '50vh' : '70vh',
                  objectFit: 'cover',
                  borderRadius: '1rem'
                }}
              >
                <source src={signupVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <div style={{ display: 'none' }}>
        <EditProfileDrawer profileData={profileData} open={isOpen} onClose={() => toggleDrawer(false)} />
      </div>
      <Footer />
    </>
  );
};

export default JobSeekerPersonalProfile;
