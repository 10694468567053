import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, Divider } from "@mui/material";

import JobSeekerOTPpage from "./JobSeekerOTPpage";
import CustomSnackbar from "../../../components/AdvancedComponents/CustomSnackbar";
import baseURL from "../../../api/baseUrl";
// import { getUserIdFromToken } from "../../../utils/userAuth";
import ImageCorousel from "../../../components/LoginPageComponents/ImageCorousel";
import MobileScreenBanner from "../../../components/LoginPageComponents/MobileScreenBanner";
import Features from "../../../components/LoginPageComponents/Features";
import AvailableFeatures from "../../../components/LoginPageComponents/AvailableFeatures";
import VacanziCounselors from "../../../components/LoginPageComponents/VacanziCounselors";
import Testimonials from "../../../components/LoginPageComponents/Testimonials";
import NewsSection from "../../../components/LoginPageComponents/NewsSection";
import Faq from "../../../components/LoginPageComponents/Faq";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
// import ResponsiveAppBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
import GoogleSignIn from "./GoogleSignIn";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";
// import { useUser } from "../../../context/UserContext";



export default function SignInSignUp() {
  
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [formData, setFormData] = useState({
    loginPhone: "",
    signupEmail: "",
    signupName: "",
    signupPhone: "",
    otp: "",
  });
  const [errors, setErrors] = useState({});

  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(60);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown); // Clear interval on unmount
    }
  }, [timer]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const phoneNumberRegex = /^[6-9]\d{9}$/; // Regex for Indian phone numbers

  const validatePhoneNumber = (number) => phoneNumberRegex.test(number);

  const handleFieldChange = (field, value) => {
    setFormData((prevState) => {
      const newState = { ...prevState, [field]: value };

      if (formData.loginPhone.length > 3) {
        if (field === "loginPhone" && validatePhoneNumber(value)) {
          setErrors((prevErrors) => ({ ...prevErrors, loginPhone: "" }));
        }
        if (field === "signupPhone" && validatePhoneNumber(value)) {
          setErrors((prevErrors) => ({ ...prevErrors, signupPhone: "" }));
        }
      }

      if (field === "signupEmail") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          signupEmail: value ? "" : "Email is required",
        }));
      }
      if (field === "signupName") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          signupName: value ? "" : "Full Name is required",
        }));
      }

      return newState;
    });
  };

  const handleLoginSubmit = async () => {
    if (!validatePhoneNumber(formData.loginPhone)) {
      setErrors({ ...errors, loginPhone: "Invalid phone number" });
      showSnackbar("Invalid phone number", "error");
      return;
    }
    try {
      const response = await fetch(`${baseURL}/js_login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone: formData.loginPhone }),
      });

      const result = await response.json();
   
      if (response.ok) {
        setShowOtpBox(true);
        showSnackbar("OTP sent successfully", "success");
      } else {
        showSnackbar(result.message, "error");
        if (result.message === "User does not exist, please sign up") {
          setErrors({ ...errors, login: result.message });
          setTimeout(() => {
            setValue(1); // Switch to Sign-Up tab after 2 seconds
            setSnackbarOpen(false);
          }, 2000);
        } else {
          setErrors({ ...errors, login: result.message });
        }
      }
      setTimer(30);
    } catch (error) {
      console.error("Error during login:", error);
      showSnackbar("Error during login", "error");
    }
  };

  const handleResendOtp = () => {
    setTimer(60); // Reset timer
    setCanResend(false);
  };

  const showSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOtpSubmit = async () => {
    const phone = value === 0 ? formData.loginPhone : formData.signupPhone;
    const url =
      value === 0
        ? `${baseURL}/validatejs_register`
        : `${baseURL}/auth/validatejs_register`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone, otp: formData.otp }),
      });

      const result = await response.json();
      if (response.ok) {
        // getUserIdFromToken();
        showSnackbar("OTP validated successfully", "success");

        if (result.existingUser === true) {
          localStorage.setItem("authToken", result.token);
          localStorage.setItem('refreshToken',result.refreshToken)
          localStorage.setItem('userId',result.user._id)
         
        
          navigate("/");
        } else {
          navigate("/jobSeekerRegestrationProfile", {
            state: {
              signupEmail: formData.signupEmail,
              signupName: formData.signupName,
              loginPhone: formData.loginPhone,
            },
          });
        }
      } else {
        showSnackbar(result.message || "Invalid OTP", "error");
        setErrors({ ...errors, otp: "Invalid OTP" });
      }
    } catch (error) {
      console.error("Error during OTP validation:", error);
      showSnackbar("Error during OTP validation", "error");
      setErrors({ ...errors, otp: "Invalid OTP" });
    }
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLoginSubmit();
    }
  };

  return (
    <>
      <NewNavBar />
      <Box
        sx={{
          width: "100vw",
          backgroundColor: "#EAFBF4FF",
          margin: 0,
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            px: 20,
            backgroundColor: "#EAFBF4FF",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#EAFBF4FF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            <img
              src="/heroMobile.png"
              alt=""
              style={{ width: "300px", height: "500px" }}
            />
          </Box>
          <Box
            sx={{
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              backgroundColor: "#EAFBF4FF",
            }}
          >
            {!showOtpBox ? (
              <>
                <Box
                  sx={{
                    color: "black",
                    flex: 1,
                    maxWidth: 400,
                    padding: 4,
                    border: "1px solid #ddd",
                    borderRadius: 2,
                    backgroundColor: "#fff",
                    textAlign: "center",
                    boxShadow: "-1px 1px 10px 1px rgba(0, 0, 0, 0.1)", // Shadow effect
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    gutterBottom
                    sx={{ fontFamily: "IBM Plex Sans", mb: 2 }}
                  >
                    Login
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      flexDirection: "column",
                      marginBottom: 6,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "IBM plex sans",
                        fontWeight: "bold",
                        fontSize: "13px",
                        mb: -2,
                      }}
                    >
                      Mobile Number
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Mobile"
                      size="small"
                      margin="normal"
                      sx={{ backgroundColor: "white", opacity: 0.7 }}
                      error={Boolean(errors.loginPhone)}
                      helperText={errors.loginPhone}
                      onChange={(e) =>
                        handleFieldChange("loginPhone", e.target.value)
                      }
                      onKeyDown={handleKeyDown} // Add Enter key functionality
                      required
                    />

                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "13px",
                        opacity: 0.8,
                        fontFamily: "IBM plex sans",
                        mt: -1,
                        ml: "2px",
                      }}
                    >
                      You will receive an OTP on this number
                    </Typography>
                  </Box>

                  <Button
                    disabled={
                      validatePhoneNumber(formData.loginPhone) ? false : true
                    }
                    sx={{
                      mt: 2,
                      backgroundColor: "#423bfa",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      mb: 2,
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleLoginSubmit}
                  >
                    Get OTP
                  </Button>
                  <Divider
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "gray",
                      fontSize: "14px",
                      "&::before, &::after": {
                        borderColor: "lightgray",
                      },
                    }}
                  >
                    Or
                  </Divider>
                  {/* Google Sign-In Button */}
                  <Box sx={{ mt: 2 }}>
                    <GoogleSignIn />
                  </Box>
                </Box>
              </>
            ) : (
              <Box sx={{ width: "100%" }}>
                <JobSeekerOTPpage
                  handleBack={() => setShowOtpBox(false)}
                  phoneNumber={
                    value === 0 ? formData.loginPhone : formData.signupPhone
                  }
                  signupEmail={formData.signupEmail}
                  handleFieldChange={handleFieldChange}
                  handleResendOtp={handleResendOtp}
                  canResend={canResend}
                  timer={timer}
                  otp={formData.otp}
                  errors={errors}
                  handleOtpSubmit={handleOtpSubmit}
                />
              </Box>
            )}
            <CustomSnackbar
              open={snackbarOpen}
              message={snackbarMessage}
              type={snackbarType}
              onClose={handleCloseSnackbar}
            />
          </Box>
        </Box>
        <MobileScreenBanner />
        <AvailableFeatures handleScrollTop={handleScrollTop} />
        <ImageCorousel />
        <Features />

        <VacanziCounselors />
        <Testimonials />
        <NewsSection />
        <Faq />
        <Footer />
      </Box>
    </>
  );
}
