import { useState } from "react";


export const useFormErrors = () => {
  const [errors, setErrors] = useState({
    rcName: "",
    rcEmail: "",
    rcPhone: "",
    password: "",
    role: "",
    reportingManagerId: "",
  });

  return { errors, setErrors };
};


export const validateForm = (formDetails, setErrors, errors) => {
  let formIsValid = true;
  let errorObj = { ...errors };

  if (!formDetails.rcName) {
    errorObj.rcName = "Name is required";
    formIsValid = false;
  } else {
    errorObj.rcName = "";
  }

  if (!formDetails.rcEmail) {
    errorObj.rcEmail = "Email is required";
    formIsValid = false;
  } else {
    errorObj.rcEmail = "";
  }

  if (!formDetails.rcPhone) {
    errorObj.rcPhone = "Phone number is required";
    formIsValid = false;
  } else {
    errorObj.rcPhone = "";
  }

  if (!formDetails.password) {
    errorObj.password = "Password is required";
    formIsValid = false;
  } else {
    errorObj.password = "";
  }

  if (!formDetails.role) {
    errorObj.role = "Role is required";
    formIsValid = false;
  } else {
    errorObj.role = "";
  }

  if (!formDetails.reportingManagerId) {
    errorObj.reportingManagerId = "Reporting Manager is required";
    formIsValid = false;
  } else {
    errorObj.reportingManagerId = "";
  }

  setErrors(errorObj);
  return formIsValid;
};

export default validateForm;
