// src/ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const ProtectedEmployerRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  if (!token) {
    return <Navigate to="/employerSignIn" />;
  }

  try {
    // Decode the token to extract the payload
    const decodedToken = jwtDecode(token);

    // Validate the expiration time (exp key)
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    if (decodedToken.exp <= currentTime) {
      return <Navigate to="/employerSignIn" />;
    }

    // If needed, you can add additional checks here (e.g., token version)
  } catch (error) {
    return <Navigate to="/employerSignIn" />;
  }

  return children;
};

export default ProtectedEmployerRoute;
