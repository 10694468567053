import React from 'react';
import { Box, Skeleton, Card } from '@mui/material';

const VideoCardSkeleton = () => {
  return (
    <Card
      sx={{
        width: 314,
        borderRadius: 4,
        position: 'relative',
        height: 550,
        overflow: 'hidden', // Ensure the skeleton doesn't overflow
      }}
    >
      {/* Shimmer Effect Styles */}
      <style>
        {`
          @keyframes shimmer {
            0% { background-position: -200% 0; }
            100% { background-position: 200% 0; }
          }

          .shimmer {
            background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
            background-size: 200% 100%;
            animation: shimmer 5s infinite;
          }
        `}
      </style>

      {/* Video Placeholder Skeleton */}
      <Box
        sx={{
          height: '100%',
          width: '100%',
          borderRadius: 4,
          backgroundColor: '#f0f0f0',
          animation: 'shimmer 1.5s infinite',
          background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
          backgroundSize: '200% 100%',
        }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{ borderRadius: 4 }}
        />
      </Box>

      {/* Title Skeleton */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 16,
          left: 16,
          p: 1,
          borderRadius: 2,
        }}
      >
        <Skeleton
          variant="text"
          width={200}
          height={30}
          sx={{
            animation: 'shimmer 1.5s infinite',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
          }}
        />
      </Box>
    </Card>
  );
};

export default VideoCardSkeleton;