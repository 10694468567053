import baseURL from "./baseUrl";

export const paymentInitiateApi = async (token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/initiateOrgPayment`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const VerifyPayementApi = async (token, merchantTransactionId) => {
  try {
    const response = await fetch(
      `${baseURL}/verifyOrgPayment/${merchantTransactionId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const jobSeekerVerifyPaymentApi = async (
  token,
  merchantTransactionId
) => {
  try {
    const response = await fetch(`${baseURL}/verify/${merchantTransactionId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    console.log("job seeker veiry api ", data);
    if (response.ok) {
      console.log("response is okay");
    } else {
      throw new Error("job seeker network issue ");
    }
    return data;
  } catch (error) {
    console.log("jobseeker verify api", error);
  }
};

export const getOrgWalletApi = async (orgId, token) => {
  try {
    const response = await fetch(`${baseURL}/getOrgWallet/${orgId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getRecruiterApi = async () => {
  const token = localStorage.getItem("token");
  const recruiterId = localStorage.getItem("recruiterId");
  try {
    const response = await fetch(`${baseURL}/recruiter/${recruiterId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
