import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import AddIcon from "@mui/icons-material/Add";
// import DoneIcon from "@mui/icons-material/Done";
import {
  Autocomplete,
  // Button,
  MenuItem,
  Paper,
  Divider,
  TextField,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import searchCandidates from "../../styles/searchCandidatesStyles";
import { getTopIndustryMasters } from "../../api/common";
// import { TwentyOneMp } from "@mui/icons-material";

// const industry = [
//   {
//     value: "Analytics/KPO/Research",
//     label: "Analytics/KPO/Research",
//   },
//   {
//     value: "Business Process Outsourcing",
//     label: "Business Process Outsourcing",
//   },
//   { value: "InvestmentBanking", label: "Investment Banking" },
//   { value: "ITServicesAndConsulting", label: "IT Services & Consulting" },
//   { value: "Technology", label: "Technology" },
// ];

// const noticePeriod = [
//   { id: 1, value: "Any" },
//   { id: 2, value: "0-15 days" },
//   { id: 3, value: "1 month" },
//   { id: 4, value: "3 months" },
//   { id: 5, value: "More than 3 months" },
//   { id: 6, value: "Currently in Notice period" },
// ];

const EmploymentDetails = ({ onSelectIndusty,initialIndustry }) => {
  // const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectIndustry, setSelectIndustry] = useState([]);
  // const [selectNoticePeriod, setSelectNoticePeriod] = useState([]);
  // const [allDepartments, setAllDepartments] = useState([]);
  const [allIndustries,setAllindustries] = useState([])

  // Fetch all departments from API
  // useEffect(() => {
  //   const getAllDepartment = async () => {
  //     try {
  //       const response = await allDepartmentsApi();
  //       const allCategories = response.reduce((acc, resItem) => {
  //         resItem.items.forEach((item) => {
  //           if (item.name) {
  //             acc.push(item.name);
  //           }
  //         });
  //         return acc;
  //       }, []);
  //       setAllDepartments(allCategories);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   getAllDepartment();
  // }, []);

useEffect(() => {
    const getAllIndustries = async () => {
      try {
        const response = await getTopIndustryMasters();
        if (response) {
          // Extract names and counts from the response array
          const allCategories = response.reduce((acc, resItem) => {
            resItem.items.forEach((item) => {
              if (item.name) {
                acc.push(
                  item.name,
                );
              }
            });
            return acc;
          }, []);

          setAllindustries(allCategories);
        }
      } catch (error) {
        console.error("Error fetching top industry masters:", error.message);
      }
    };

    getAllIndustries();
  }, []);

  // Handle department selection
  // const handleSelectDepartment = (event, value) => {
  //   setSelectedDepartment(value);
  // };

  // Handle industry selection
  const handleSelectIndustry = (event, value) => {
    setSelectIndustry(value);
    onSelectIndusty(value);
  };

  const getInitialIndustryOptions = () => {
    if (!initialIndustry || !Array.isArray(initialIndustry)) return [];
    return initialIndustry.map((industry) =>
      allIndustries.find((item) => item === industry)
    ).filter(Boolean); // Filter out undefined values
  };
  // Handle notice period selection
  // const handleNoticePeriod = (value) => {
  //   setSelectNoticePeriod((prev) => {
  //     if (prev.includes(value)) {
  //       return prev.filter((item) => item !== value);
  //     }
  //     return [...prev, value];
  //   });
  // };
  return (
    <Paper
      elevation={0}
      sx={{
        mt: 2,
        mb: 2,
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <Divider sx={{ borderColor: "#ddd" }} />
      <Accordion
        defaultExpanded
        sx={{
          boxShadow: "none",
          borderRadius: "inherit",
          pt: 4,
          pb: 4,
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Divider />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Employment Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Department and Role Section */}
          {/* <Box sx={{ mb: 2 }}>
            <Typography sx={{ ...searchCandidates.fontStyles, mb: 1 }}>
              Department and Role
            </Typography>
            <Autocomplete
              multiple
              options={allDepartments}
              value={selectedDepartment}
              onChange={handleSelectDepartment}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  placeholder="Add Department/Role"
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option}>
                  <ListItemText primary={option} sx={{ fontSize: 10, marginLeft: 1,fontFamily:"IBM plex sans"  }} />
                </MenuItem>
              )}
            />
          </Box> */}

          {/* Industry Section */}
          <Box>
            <Typography sx={{ ...searchCandidates.fontStyles, mb: 1, mt: 1 }}>
              Industry
            </Typography>
            <Autocomplete
              multiple
              options={allIndustries}
          
              value={selectIndustry.length > 0 ? selectIndustry : getInitialIndustryOptions()}
              onChange={handleSelectIndustry}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  placeholder="Add Industry"
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option}>
                  <ListItemText
                    primary={option}
                    sx={{ fontSize: 10, marginLeft: 1,fontFamily:"IBM plex sans" }}
                  />
                </MenuItem>
              )}
            />
          </Box>

          {/* Notice Period Section */}
          {/* <Box>
            <Typography sx={{ ...searchCandidates.fontStyles, mb: 1, mt: 1 }}>
              Notice Period/Availability to join
            </Typography>
            {noticePeriod.map((item) => (
              <Button
                variant="outlined"
                key={item.id}
                sx={{
                  borderRadius: 4,
                  ml: 1,
                  mt: 1,
                  textTransform: "none",
                }}
                onClick={() => handleNoticePeriod(item.value)}
              >
                <Typography
                  sx={
                    selectNoticePeriod.includes(item.value)
                      ? { color: "black", fontSize: 13, fontWeight: "bold" }
                      : { color: "blue", fontSize: 13 }
                  }
                >
                  {item.value}
                </Typography>
                {selectNoticePeriod.includes(item.value) ? (
                  <DoneIcon sx={{ fontSize: "medium", ml: 1 }} />
                ) : (
                  <AddIcon sx={{ fontSize: "medium", ml: 1 }} />
                )}
              </Button>
            ))}
          </Box> */}
        </AccordionDetails>
      </Accordion>
      <Divider sx={{ borderColor: "#ddd" }} />
    </Paper>
  );
};

export default EmploymentDetails;