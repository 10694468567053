import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import manageQuota from "../../styles/manageQuota";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { getOrgDetails } from "../../api/companyProfile";

const CustomOtpBox = ({ openOtpBox, handleOtpBoxClose, setEditTotal,updateQuota }) => {
  const [otp, setOtp] = useState("");
  const [orgData, setOrgData] = useState();

  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");

  // Fetch organization details
  const fetchOrgDetails = useCallback(async () => {
    try {
      if (orgId) {
        const companyDetails = await getOrgDetails(orgId, token);
        setOrgData(companyDetails);
      } else {
        throw new Error("No organization id");
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [orgId, token]);

  useEffect(() => {
    fetchOrgDetails();
  }, [fetchOrgDetails]);

  // Mask email and contact number
  const maskEmail = (email) => {
    if (!email) return "****@****.com";
    const [username, domain] = email.split("@");
    const maskedUsername =
      username.substring(0, 2) + "****" + username.substring(username.length - 2);
    return `${maskedUsername}@${domain}`;
  };

  const maskContactNumber = (contactNumber) => {
    if (!contactNumber) return "****";
    return (
      contactNumber.substring(0, 3) + "****" + contactNumber.substring(contactNumber.length - 2)
    );
  };

  const maskedMail = maskEmail(orgData?.email);
  const maskedContactNumber = maskContactNumber(orgData?.contactNumber);

  // Update OTP in the parent state
  const handleOtpChange = (otp) => {
    setOtp(otp);
    setEditTotal((prevState) => ({
      ...prevState,
      otp: otp, // Update the OTP field in the parent state
    }));
  };

  const handleVerifyAndUpdate = () => {
    updateQuota(); // Call the updateQuota function
    setOtp(""); // Clear the OTP input
    setEditTotal((prevState) => ({
      ...prevState,
      otp: "", // Clear the OTP field in the parent state
    }));
  };

  return (
    <Dialog open={openOtpBox} maxWidth="xs" fullWidth>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          px: 3,
          pt: 1,
          cursor: "pointer",
        }}
      >
        <CloseIcon onClick={handleOtpBoxClose} />
      </Box>
      <DialogTitle>
        <Typography sx={{ fontSize: 13 }}>
          {`A 6-digit OTP (One Time Password) has been sent to your email `}
          <span style={{ fontWeight: "bold" }}>{maskedMail}</span>
          {` and mobile `}
          <span style={{ fontWeight: "bold" }}>{maskedContactNumber}</span>
          {`.`}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <OtpInput
          value={otp}
          onChange={handleOtpChange} // Use the handleOtpChange function
          numInputs={4}
          renderSeparator={<span style={{ marginLeft: 2, marginRight: 2 }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                ...manageQuota.otpInput,
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            borderRadius: "2rem",
            backgroundColor: "#423bfa",
            textTransform: "none",
            fontWeight: "bold",
          }}
          onClick={handleVerifyAndUpdate}
        >
          Verify & Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomOtpBox;