import React from 'react'
import PsychologyIcon from "@mui/icons-material/Psychology";
import SettingsIcon from "@mui/icons-material/Settings";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Box, Grid, Typography } from "@mui/material";
// import { useNavigate } from 'react-router-dom';


const features = [
    {
      icon: (
        <span style={{ fontSize: "3rem" }}>
          <PsychologyIcon sx={{ color: "#349beb", fontSize: "60px" }} />
        </span>
      ),
      title: "AI-Suggested Candidates",
      description:
        "Get AI-recommended candidates from our database matching to your job postings.",
    },
    {
      icon: (
        <span style={{ fontSize: "3rem" }}>
          <SettingsIcon sx={{ color: "#349beb", fontSize: "60px" }} />
        </span>
      ),
      title: "Customized Lead Management",
      description:
        "Manage leads efficiently with ATS integration, CSV access, dashboard tracking, and WhatsApp alerts.",
    },
    {
      icon: (
        <span style={{ fontSize: "3rem" }}>
          <RocketLaunchIcon sx={{ color: "#349beb", fontSize: "60px" }} />
        </span>
      ),
      title: "Job Post Boosts",
      description: "Enhance visibility with walk-in and premium job post boosts.",
    },
  ];
const Features = () => {
    
  return (
    <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#EAFBF4FF",     
      width: "100vw",
      height: 300,
      padding: "2rem",
      gap: "2rem",
    }}
  >
    {features.map((feature) => (
      <Grid item key={feature.title} sx={{ textAlign: "center" }}>
        {feature.icon}
        <Typography
          variant="h6"
          component="div"
          sx={{ mt: 2, fontFamily: "IBM Plex Sans" }}
        >
          {feature.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ mt: 1, fontFamily: "IBM Plex Sans" }}
        >
          {feature.description}
        </Typography>
      </Grid>
    ))}
  </Box>
  )
}

export default Features