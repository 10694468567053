import {
  Autocomplete,
  // Button,
  Checkbox,
  ListItemText,
  MenuItem,
  TextField,
  // Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
// import searchCandidates from "../../styles/searchCandidatesStyles";
// import AddIcon from "@mui/icons-material/Add";
// import DoneIcon from "@mui/icons-material/Done";

const course = [
  {
    value: "Any Engineering",
    label: "Any Engineering",
  },
  {
    value: "Any Hotel Management",
    label: "Any Hotel Management",
  },
  {
    value: "Any Medical",
    label: "Any Medical",
  },
];

const institute = [
  {
    value: "Indian Institute of Technology Bombay",
    label: "Indian Institute of Technology Bombay",
  },
  {
    value: "Indian Institute of Technology Delhi",
    label: "Indian Institute of Technology Delhi",
  },
  {
    value: "National Institute of Technology Calicut",
    label: "National Institute of Technology Calicut",
  },
  {
    value: "National Institute of Technology Warangal",
    label: "National Institute of Technology Warangal",
  },
  {
    value: "Birla Institute of Technology and Science, Pilani",
    label: "Birla Institute of Technology and Science, Pilani",
  },
  { value: "VIT University", label: "VIT University" },
  { value: "University of Delhi", label: "University of Delhi" },
  {
    value: "Indian Statistical Institute",
    label: "Indian Statistical Institute",
  },
  {
    value: "Jawaharlal Nehru University",
    label: "Jawaharlal Nehru University",
  },
];

// const educations = [
//   { id: 1, value: "Full time" },
//   { id: 2, value: "Part time" },
//   { id: 3, value: "Correspondence" },
// ];

// const yearDummy = [
//   { id: 1, value: "2023" },
//   { id: 2, value: "2022" },
//   { id: 3, value: "2021" },
//   { id: 4, value: "2020" },
//   { id: 5, value: "2019" },
//   { id: 6, value: "2018" },
// ];

const SpecificPGqualification = () => {
  const [selectInstitute, setSelectInstitute] = useState([]);
  const [selectCourse, setSelectCourse] = useState([]);
  // const [educationType, setEducationType] = useState([]);
  // const [fromYear, setFromYear] = useState(""); // State for 'From' year
  // const [toYear, setToYear] = useState("");
  const handleSelectInstitute = (event, value) => {
    setSelectInstitute(value);
  };
  // const handleFromYearChange = (event, value) => {
  //   setFromYear(value);
  // };
  // const handleToYearChange = (event, value) => {
  //   setToYear(value);
  // };

  // const handleEducatonType = (value) => {
  //   setEducationType((prev) => {
  //     if (prev.includes(value)) {
  //       return prev.filter((item) => item !== value);
  //     }

  //     return [...prev, value];
  //   });
  // };

  const handleSelectCourse = (event, value) => {
    setSelectCourse(value);
  };

  return (
    <div>
      <Box>
        <Autocomplete
          sx={{ mt: 1 }}
          multiple
          options={course}
          getOptionLabel={(option) => option.label}
          value={selectCourse}
          onChange={handleSelectCourse}
          disableCloseOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              placeholder="Choose Course"
              variant="outlined"
            />
          )}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              key={option.value}
              sx={{ fontSize: 6, padding: "2px 0" }}
            >
              <Checkbox
                checked={selectInstitute.some(
                  (institute) => institute.value === option.value
                )}
                size="small"
              />
              <ListItemText
                primary={option.label}
                sx={{ fontSize: 10, marginLeft: 1 }}
              />
            </MenuItem>
          )}
        />
        <Autocomplete
          sx={{ mt: 1 }}
          multiple
          options={institute}
          getOptionLabel={(option) => option.label}
          value={selectInstitute}
          onChange={handleSelectInstitute}
          disableCloseOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              placeholder="Institute"
              variant="outlined"
            />
          )}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              key={option.value}
              sx={{ fontSize: 6, padding: "2px 0" }}
            >
              <Checkbox
                checked={selectInstitute.some(
                  (institute) => institute.value === option.value
                )}
                size="small"
              />
              <ListItemText
                primary={option.label}
                sx={{ fontSize: 10, marginLeft: 1 }}
              />
            </MenuItem>
          )}
        />
      </Box>
      {/* <Box>
        <Box sx={searchCandidates.flexboxStart}>
          <Typography sx={{ fontWeight: "regular", mt: 1, fontSize: 14 }}>
            Education Type
          </Typography>
        </Box>

        <Box sx={searchCandidates.flexboxCenter}>
          {educations.map((education) => (
            <Button
              variant="outlined"
              sx={{
                borderRadius: 4,
                ml: 1,
                mt: 1,
                textTransform: "none",
                fontSize: 10,
              }}
              onClick={() => handleEducatonType(education.value)}
            >
              <Typography
                sx={
                  educationType.includes(education.value)
                    ? { color: "black", fontSize: 13, fontWeight: "bold" }
                    : { color: "blue", fontSize: 13 }
                }
              >
                {education.value}
              </Typography>

              {educationType.includes(education.value) ? (
                <DoneIcon sx={{ fontSize: "medium", ml: 1 }} />
              ) : (
                <AddIcon sx={{ fontSize: "medium", ml: 1 }} />
              )}
            </Button>
          ))}
        </Box>
      </Box> */}
      {/* <Box>
        <Typography sx={{ fontWeight: "regular", mt: 1, mb: 1, fontSize: 14 }}>
          Year Of Completion
        </Typography>
        <Box sx={{ ...searchCandidates.flexboxCenter, gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Autocomplete
              size="small"
              sx={{ width: 150 }}
              options={yearDummy.map((option) => option.value)}
              value={fromYear}
              onChange={handleFromYearChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="From"
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                />
              )}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Autocomplete
              size="small"
              sx={{ width: 150 }}
              options={yearDummy.map((option) => option.value)}
              value={toYear}
              onChange={handleToYearChange}
              renderInput={(params) => (
                <TextField
                  inputProps={{
                    ...params.inputProps,
                    styleL: { fontSize: 12 },
                  }}
                  {...params}
                  placeholder="To"
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                />
              )}
            />
          </Box>
        </Box>
      </Box> */}
    </div>
  );
};

export default SpecificPGqualification;
