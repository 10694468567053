import React, { useState } from "react";
import { Box, TextField, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

const MultiSelect = ({
  label,
  placeholder,
  selectedOptions,
  setSelectedOptions,
  validationError,
  setValidationError,
  shouldntHave,
  setShouldntHave,
  setBoolean,
  boolean
}) => {
  const [inputValue, setInputValue] = useState("");

  // Effect to set boolean based on shouldntHave
  useEffect(() => {
    if (shouldntHave?.length > 0) {
      setBoolean(true); // Set boolean to true if shouldntHave has values
    }
    // No need to set boolean to false if shouldntHave is empty
  }, [shouldntHave, setBoolean]);

  // Function to add an option to the appropriate state
  const handleAddOption = (option) => {
    if (!option) return; // Skip empty options

    // Check if the option is wrapped in double quotes
    if (option.startsWith('"') && option.endsWith('"')) {
      const strippedOption = option.slice(1, -1); // Remove the quotes
      if (!shouldntHave?.includes(strippedOption)) {
        setShouldntHave((prev) => [...prev, strippedOption]);
      }
    } else {
      if (!selectedOptions.includes(option)) {
        setSelectedOptions((prev) => [...prev, option]);
      }
    }
    setInputValue("");
  };

  console.log('should',shouldntHave)

  // Function to remove an option from the selectedOptions state
  const handleRemoveOption = (option) => {
    setSelectedOptions((prev) => prev.filter((item) => item !== option));
  };

  // Function to remove an option from the shouldntHave state
  const handleRemoveShouldntHave = (option) => {
    setShouldntHave((prev) => prev.filter((item) => item !== option));
  };

  // Function to handle input changes
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setValidationError(false);

    // Check if the input contains a comma
    if (value.includes(",")) {
      const newOptions = value
        .split(",")
        .map((opt) => opt.trim())
        .filter((opt) => opt !== "");
      newOptions.forEach((opt) => handleAddOption(opt));
      setInputValue("");
    }
  };

  // Function to handle the Enter key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (inputValue.trim()) {
        handleAddOption(inputValue.trim());
      }
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      <TextField
        fullWidth
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        size="small"
        sx={
          validationError
            ? { border: "1px solid red", borderRadius: 1, marginBottom: 1 }
            : { marginBottom: 1 }
        }
        InputProps={{
          startAdornment: (
            <>
              {/* Render selectedOptions */}
              {selectedOptions?.map((option) => (
                <Box
                  key={option}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 1,
                    padding: "0 8px",
                    borderRadius: 4,
                    backgroundColor: "#e0e0e0",
                    fontSize: "0.75rem",
                  }}
                >
                  {option}
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveOption(option)}
                    sx={{ marginLeft: 0.5 }}
                  >
                    <CloseIcon fontSize="xs" />
                  </IconButton>
                </Box>
              ))}
              {/* Render shouldntHave options */}
              {shouldntHave?.map((option) => (
                <Box
                  key={option}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 1,
                    padding: "0 8px",
                    borderRadius: 4,
                    backgroundColor: "#ffcccc", // Different background for shouldntHave
                    fontSize: "0.75rem",
                  }}
                >
                  {`"${option}"`}
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveShouldntHave(option)}
                    sx={{ marginLeft: 0.5 }}
                  >
                    <CloseIcon fontSize="xs" />
                  </IconButton>
                </Box>
              ))}
            </>
          ),
        }}
      />
      {validationError && (
        <Typography sx={{ color: "red", fontSize: 12, mb: 1, ml: 0.5 }}>
          Keywords Cannot be Empty
        </Typography>
      )}
    </Box>
  );
};

export default MultiSelect;