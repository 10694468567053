import {
  Autocomplete,
  // selectClasses,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import searchCandidates from "../../styles/searchCandidatesStyles";

const jobtypes = [
  { id: 1, value: "fullTIme", label: "Full Time" },
  { id: 2, value: "partTime", label: "Part Time" },
  { id: 3, value: "freelance", label: "FreeLance" },
  { id: 4, value: "contract", label: "Contract" },
  { id: 5, value: "internship", label: "Internship" },
  { id: 6, value: "remote", label: "Remote" },
];
// const employmentTypes = [
//   { id: 1, value: "fullTIme" },
//   { id: 2, value: "partTime" },
//   { id: 3, value: "freelance" }, // Fixed duplicate id
//   { id: 4, value: "contract" }, // Fixed duplicate id
//   { id: 5, value: "internship" }, // Fixed duplicate id
//   { id: 6, value: "remote" }, // Fixed duplicate id
// ];

const WorkDetails = ({
  handleJobType,
  handlePrefferedJobType,
  jobType,
  prefferedJobType,
}) => {

  const selectedJobTypeLabel =
    jobtypes.find((option) => option.value === jobType)?.label || "";

  return (
    <div>
      <Box>
        <Typography sx={{ fontSize: 12, mb: 1 }}>
          Show Candidates seeking
        </Typography>
        <Box sx={{ ...searchCandidates.flexboxCenter, gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Autocomplete
              size="small"
              sx={{ width: 300 }}
              options={jobtypes.map((option) => option.label)} // Use labels for options
              value={selectedJobTypeLabel} // Set value to the label
              onChange={(event, value) => {
                // Map the selected label back to its value
                const selectedJobType = jobtypes.find(
                  (option) => option.label === value
                );
                handleJobType(selectedJobType ? selectedJobType.value : null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Job Type"
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 13 },
                  }}
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                />
              )}
            />
          </Box>

          {/* <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Autocomplete
              size="small"
              sx={{ width: 200 }}
              options={employmentTypes.map((option) => option.value)}
              value={prefferedJobType}
              onChange={(event, value) => handlePrefferedJobType(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Preffered Job Type"
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 12 },
                  }}
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                />
              )}
            />
          </Box> */}
        </Box>
      </Box>
    </div>
  );
};

export default WorkDetails;
