import baseURL from './baseUrl';

export const createJobPost = async (orgId,token,formValues) => {
  try {
    const response = await fetch(`${baseURL}/jobpost/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization:`Bearer ${token}`
      },
      body: JSON.stringify( formValues ),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const saveJobAsDraftApi = async (token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/jobpost/saveDraft`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(formValues),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Return an object containing both the status code and the response data
    return {
      status: response.status,
      data: data
    };
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


export const getSecretKey = async (token,recruiterId) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/createSecret/${recruiterId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization:`Bearer ${token}`
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
