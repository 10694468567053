import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  createTheme,
  ThemeProvider,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import {
  Menu as MenuIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import { getTopOrgs, getTopIndustries } from "../../../api/common"; // Import your API functions
import { useLocation, useNavigate } from "react-router-dom";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
// import LogoutIcon from "@mui/icons-material/Logout";
import { useScroll } from "../../../context/ScrollContext";
import { getUserApi } from "../../../api/jobseeker";
// import { UserProvider, useUser } from "../../../context/UserContext";

// Create light theme
const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "##f5faf5",
      paper: "##f5faf5",
    },
    primary: {
      main: "#000000",
    },
  },
  typography: {
    button: {
      textTransform: "none",
      fontWeight: 500,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5faf5",
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          padding: "6px 12px",
        },
      },
    },
  },
});

// const NavButton = ({ children, hasDropdown, items }) => {
//   const [open, setOpen] = useState(false);
//   const anchorRef = React.useRef(null);

//   const handleToggle = () => {
//     setOpen((prevOpen) => !prevOpen);
//   };

//   const handleClose = (event) => {
//     if (anchorRef.current && anchorRef.current.contains(event.target)) {
//       return;
//     }
//     setOpen(false);
//   };

//   if (!hasDropdown) {
//     return (
//       <Button
//         color="primary"
//         sx={{
//           color: "white",
//           "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
//         }}
//       >
//         {children}
//       </Button>
//     );
//   }

//   return (
//     <>
//       <Button
//         ref={anchorRef}
//         color="primary"
//         onMouseOver={handleToggle} // Open dropdown on mouse over
//         onMouseLeave={handleClose} // Close dropdown on mouse leave
//         endIcon={<KeyboardArrowDownIcon />}
//         sx={{
//           color: "white",
//           "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
//         }}
//       >
//         {children}
//       </Button>
//       <Popper
//         open={open}
//         anchorEl={anchorRef.current}
//         placement="bottom-start"
//         transition
//         disablePortal
//         onMouseOver={handleToggle} // Keep dropdown open when hovering over it
//         onMouseLeave={handleClose} // Close dropdown when mouse leaves
//       >
//         {({ TransitionProps }) => (
//           <Grow {...TransitionProps}>
//             <Paper
//               elevation={0}
//               sx={{
//                 backgroundColor: "#ffffff",
//                 border: "1px solid rgba(255, 255, 255, 0.1)",
//                 mt: 1,
//                 borderRadius: "13px",
//               }}
//             >
//               <ClickAwayListener onClickAway={handleClose}>
//                 <MenuList autoFocusItem={open}>
//                   {items?.map((item, index) => (
//                     <MenuItem
//                       key={index}
//                       onClick={handleClose}
//                       sx={{ minWidth: "160px" }}
//                     >
//                       {item}
//                     </MenuItem>
//                   ))}
//                 </MenuList>
//               </ClickAwayListener>
//             </Paper>
//           </Grow>
//         )}
//       </Popper>
//     </>
//   );
// };

export default function NewNavBar({ onIndustrySelect }) {
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [whyVacanziActive, setWhyVacanziActive] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { scrollToWhyVacanzi } = useScroll();
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenMobileMenu = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleCloseMobileMenu = () => {
    setMobileMenuAnchor(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Fetch companies and industries data
  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const response = await getTopOrgs();
        setCompanies(response?.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchOrgDetails();
  }, []);

  useEffect(() => {
    const fetchIndustryDetails = async () => {
      try {
        const response = await getTopIndustries();
        setIndustries(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchIndustryDetails();
  }, []);

  const handleNavigation = (path, industry) => {
    navigate(path);
    if (onIndustrySelect) {
      onIndustrySelect(industry);
    }
  };
  console.log(whyVacanziActive);

  const handleWhyVacanziClick = () => {
    if (location.pathname === "/") {
      scrollToWhyVacanzi();
      setWhyVacanziActive(true); // Set active state
    } else {
      navigate("/", { state: { scrollToWhyVacanzi: true } });
      setWhyVacanziActive(true); // Set active state
    }
  };

  // Check location.state to determine if "Why Vacanzi" should be active
  useEffect(() => {
    if (location.state?.scrollToWhyVacanzi) {
      setTimeout(() => {
        scrollToWhyVacanzi();
        setWhyVacanziActive(true); // Set active state
      }, 500);
    }
  }, [location.state, scrollToWhyVacanzi]);

  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        try {
          const response = await getUserApi(userId, token);
          setUserData(response);
        } catch (error) {
          console.log(error.message);
        }
      };
      getUserDetails();
    }
  }, [token, userId]);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    navigate("/");
  };

  const isActiveRoute = (path) => {
    return window.location.pathname === path;
  };
  console.log("userDta", userData);
  return (
    <ThemeProvider theme={lightTheme}>
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar sx={{ minHeight: "64px" }}>
            {/* Logo */}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
                gap: "2px",
              }}
            >
              <Avatar
                src={"/vacanziLogo.png"}
                alt="Vacanzi Logo"
                sx={{
                  width: 60,
                  height: 60,
                  display: { xs: "none", md: "flex" },
                }}
              />
              <span
                style={{
                  color: "#423bfa",
                  fontSize: "40px",
                  fontFamily: "GlanceSansBold",
                }}
              >
                Vacanzi
              </span>
            </Typography>

            {/* Mobile menu icon */}
            <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }}>
              <IconButton
                size="large"
                onClick={handleOpenMobileMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={mobileMenuAnchor}
                open={Boolean(mobileMenuAnchor)}
                onClose={handleCloseMobileMenu}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                <MenuItem onClick={handleCloseMobileMenu}>Companies</MenuItem>
                <MenuItem onClick={handleCloseMobileMenu}>Find Jobs</MenuItem>
                <MenuItem onClick={handleCloseMobileMenu}>Why Vacanzi</MenuItem>
                {token && (
                  <>
                    <MenuItem onClick={handleCloseMobileMenu}>
                      Applied Jobs
                    </MenuItem>
                    <MenuItem onClick={handleCloseMobileMenu}>
                      Saved Jobs
                    </MenuItem>
                  </>
                )}
              </Menu>
            </Box>

            {/* Desktop Navigation */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 2,
                marginLeft: "auto",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  padding: "4px 16px",
                  fontFamily: "IBM plex sans",
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  "& .MuiButton-root": {
                    color: "#000000",
                  },
                  "& .MuiButton-endIcon": {
                    color: "#000000",
                  },
                }}
              >
                <Button
                  onMouseOver={(event) => {
                    setAnchorEl(event.currentTarget);
                    setOpenSubMenu("companies");
                  }}
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{
                    color: "#000000",
                    fontSize: {
                      xs: "14px",
                      md: "14px",
                      lg: "14px",
                      xl: "22px",
                    },
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                  }}
                >
                  Companies
                </Button>
                <Button
                  onClick={() => handleNavigation("/findJobs")}
                  sx={{
                    cursor: "pointer",
                    fontSize: {
                      xs: "14px",
                      md: "14px",
                      lg: "14px",
                      xl: "22px",
                    },
                    ":hover": { color: "#454545FF" },
                  }}
                >
                  Find Jobs
                </Button>
                <Button
                  onClick={handleWhyVacanziClick}
                  sx={{
                    fontSize: {
                      xs: "14px",
                      md: "14px",
                      lg: "14px",
                      xl: "22px",
                    },
                  }}
                >
                  Why Vacanzi
                </Button>
                {token && (
                  <>
                    <Button
                      sx={{
                        cursor: "pointer",
                        fontSize: {
                          xs: "14px",
                          md: "14px",
                          lg: "14px",
                          xl: "22px",
                        },
                        ":hover": { color: "#373636FF" },
                      }}
                      onClick={() => handleNavigation("/jobSeekerAppliedJobs")}
                    >
                      Applied Jobs
                    </Button>
                    <Button
                      onClick={() => handleNavigation("/jobSeekerSavedJobs")}
                      sx={{
                        cursor: "pointer",
                        fontSize: {
                          xs: "14px",
                          md: "14px",
                          lg: "14px",
                          xl: "22px",
                        },
                        ":hover": { color: "#373636FF" },
                      }}
                    >
                      Saved Jobs
                    </Button>
                  </>
                )}
              </Box>

              {/* Companies Dropdown Menu */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && openSubMenu === "companies"}
                onClose={() => {
                  setAnchorEl(null);
                  setOpenSubMenu(null);
                }}
                onMouseLeave={() => {
                  setAnchorEl(null);
                  setOpenSubMenu(null);
                }}
                MenuListProps={{
                  onMouseLeave: () => {
                    setAnchorEl(null);
                    setOpenSubMenu(null);
                  },
                }}
                sx={{
                  "& .MuiPaper-root": {
                    elevation: 0,
                    borderRadius: 4,
                    padding: 0.5,
                    minWidth: 300,
                    typography: "body2",
                    backgroundColor: "#ffffff",
                  },
                }}
              >
                <Box
                  sx={{ display: "flex", gap: 1, backgroundColor: "#ffffff" }}
                >
                  <Box sx={{ flex: 1 }}>
                    <MenuItem sx={{ paddingY: 0.5 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold", fontSize: "0.875rem" }}
                      >
                        Industries
                      </Typography>
                    </MenuItem>
                    <List sx={{ padding: 0 }}>
                      {industries
                        ?.map((data) => data.industry)
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )
                        .map((industry, index) => (
                          <ListItem
                            key={index}
                            button
                            sx={{ paddingY: 0.25, paddingX: 2 }}
                            onClick={() =>
                              handleNavigation(
                                `/organizations-by-industry?industry=${industry}`,
                                industry
                              )
                            }
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                variant: "body2",
                                fontSize: "0.875rem",
                              }}
                              primary={industry}
                            />
                            {isActiveRoute(
                              `/organizations-by-industry?industry=${industry}`
                            ) && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  height: 2,
                                  backgroundColor: "#423BFA",
                                  borderRadius: 2,
                                }}
                              />
                            )}
                          </ListItem>
                        ))}
                    </List>
                  </Box>
                  <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
                  <Box sx={{ flex: 1 }}>
                    <MenuItem sx={{ paddingY: 0.5 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold", fontSize: "0.875rem" }}
                      >
                        Explore Companies
                      </Typography>
                    </MenuItem>
                    <List sx={{ padding: 0 }}>
                      {companies?.slice(0, 5).map((company, index) => (
                        <ListItem
                          key={index}
                          button
                          sx={{ paddingY: 0.25, paddingX: 2 }}
                          onClick={() =>
                            handleNavigation(
                              `/organizationOverview?companyId=${company._id}`
                            )
                          }
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              variant: "body2",
                              fontSize: "0.875rem",
                            }}
                            primary={company.organizationName}
                          />
                          {isActiveRoute(
                            `/organizationOverview?companyId=${company._id}`
                          ) && (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: 2,
                                backgroundColor: "#423BFA",
                                borderRadius: 2,
                              }}
                            />
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <MenuItem sx={{ paddingY: 0.5 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold", fontSize: "0.875rem" }}
                      >
                        Top Companies
                      </Typography>
                    </MenuItem>
                    <List sx={{ padding: 0 }}>
                      <ListItem
                        button
                        sx={{ paddingY: 0.75, paddingX: 2 }}
                        onClick={() =>
                          handleNavigation(`/top-hiring-companies`)
                        }
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            variant: "body2",
                            fontSize: "0.875rem",
                          }}
                          primary="Top IT Companies"
                        />
                        {isActiveRoute(`/top-hiring-companies`) && (
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              right: 0,
                              height: 2,
                              backgroundColor: "#423BFA",
                              borderRadius: 2,
                            }}
                          />
                        )}
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              </Menu>

              {token ? (
                <>
                  <Box
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: "12px",
                      padding: "4px 16px",
                      display: "inline-flex",
                      lineHeight: 1.2,
                      alignItems: "center",
                      gap: "4px",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onMouseEnter={handleOpenUserMenu} // Open menu on mouseover
                  >
                    <Typography
                      sx={{
                        color: "#18181b",
                        fontSize: "13px",
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                      }}
                    >
                      {userData?.user?.email}
                    </Typography>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        alt="User Avatar"
                        src={
                          userData?.user?.profileImage
                            ? userData?.user?.profileImage
                            : ""
                        }
                      />
                    </IconButton>
                  </Box>

                  <Menu
                    sx={{
                      mt: "45px",
                      borderRadius: "8px", // Added border radius
                      "& .MuiMenu-paper": {
                        minWidth: "200px",
                        backgroundColor: "#ffffff", // White background
                        borderRadius: "8px", // Added border radius
                      },
                    }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    onMouseLeave={handleCloseUserMenu} // Close menu on mouse leave
                  >
                    {/* Email and Avatar Section */}
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        background: "none",
                        py: 2,
                        borderBottom: "1px solid #e0e0e0", // Divider line
                      }}
                    >
                      <Avatar
                        alt="User Avatar"
                        src={
                          userData?.user?.profileImage
                            ? userData?.user?.profileImage
                            : ""
                        }
                        sx={{ width: 32, height: 32 }}
                      />
                      <Typography
                        sx={{ fontWeight: "regular", fontSize: "13px" }}
                      >
                        {userData?.user?.email}
                      </Typography>
                    </MenuItem>

                    {/* Menu Items (Text Only) */}
                    <MenuItem
                      onClick={() => handleNavigation("/jobSeeker-Profile")}
                      sx={{
                        py: 1.5,
                        backgroundColor: "white", // Ensure white background
                        "&:hover": {
                          backgroundColor: "white", // Keep background white on hover
                          "& .MuiTypography-root": {
                            color: "#423BFA", // Change text color on hover
                          },
                        },
                      }}
                    >
                      <Typography textAlign="center">Profile</Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={() => handleNavigation("/jobSeeker/support")}
                      sx={{
                        py: 1.5,
                        backgroundColor: "white", // Ensure white background
                        "&:hover": {
                          backgroundColor: "white", // Keep background white on hover
                          "& .MuiTypography-root": {
                            color: "#423BFA", // Change text color on hover
                          },
                        },
                      }}
                    >
                      <Typography textAlign="center">Support</Typography>
                    </MenuItem>

                    <Divider sx={{ my: 1 }} />

                    <MenuItem
                      onClick={() =>
                        handleNavigation("/jobSeekeerSubscription")
                      }
                      sx={{
                        py: 1.5,
                        backgroundColor: "white", // Ensure white background
                        "&:hover": {
                          backgroundColor: "white", // Keep background white on hover
                          "& .MuiTypography-root": {
                            color: "#423BFA", // Change text color on hover
                          },
                        },
                      }}
                    >
                      <Typography textAlign="center">
                        Subscription Plans
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handleLogout}
                      sx={{
                        py: 1.5,
                        backgroundColor: "white", // Ensure white background
                        "&:hover": {
                          backgroundColor: "white", // Keep background white on hover
                          "& .MuiTypography-root": {
                            color: "#423BFA", // Change text color on hover
                          },
                        },
                      }}
                    >
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Box
                  sx={{
                    backgroundColor: "#FFFFFFFF",
                    borderRadius: "12px",
                    padding: "8px 16px",
                    display: "inline-flex",
                    lineHeight: 1.2,
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "IBM plex sans",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        lg: "14px",
                        xl: "22px",
                      },
                      fontWeight: "bold",
                      color: "#000000",
                      mr: 0.5,
                    }}
                  >
                    Login
                  </Typography>
                  <Button
                    sx={{
                      color:
                        location.pathname === "/employerSignIn"
                          ? "#18181b" // Black text on employerSignIn route
                          : "#ffffff", // White text everywhere else
                          fontSize: {
                            xs: "14px",
                            md: "14px",
                            lg: "14px",
                            xl: "22px",
                          },
                      backgroundColor:
                        location.pathname === "/employerSignIn"
                          ? "transparent" // Transparent background on employerSignIn route
                          : "#18181b", // Black background everywhere else
                      borderRadius: "0.7rem",
                      height: "2rem",
                      padding: "0.375rem 0.75rem",
                      ml: "2px",
                      "&:hover": {
                        backgroundColor:
                          location.pathname === "/employerSignIn"
                            ? "rgba(0, 0, 0, 0.05)" // Light gray on hover for employerSignIn route
                            : "rgba(0, 0, 0, 0.8)", // Darker black on hover everywhere else
                      },
                    }}
                    onClick={() => handleNavigation("/signinsignup")}
                  >
                    Jobseeker
                  </Button>
                  <Button
                    sx={{
                      color:
                        location.pathname === "/employerSignIn"
                          ? "#ffffff" // White text on employerSignIn route
                          : "#18181b", // Black text everywhere else
                          fontSize: {
                            xs: "14px",
                            md: "14px",
                            lg: "14px",
                            xl: "22px",
                          },                      backgroundColor:
                        location.pathname === "/employerSignIn"
                          ? "#18181b" // Black background on employerSignIn route
                          : "transparent", // Transparent background everywhere else
                      borderRadius: "0.7rem",
                      height: "2rem",
                      padding: "0.375rem 0.75rem",
                      "&:hover": {
                        backgroundColor:
                          location.pathname === "/employerSignIn"
                            ? "rgba(0, 0, 0, 0.8)" // Darker black on hover for employerSignIn route
                            : "rgba(0, 0, 0, 0.05)", // Light gray on hover everywhere else
                      },
                    }}
                    onClick={() => handleNavigation("/employerSignIn")}
                  >
                    Employer
                  </Button>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
