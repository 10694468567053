import { Box, Typography, Container, styled } from "@mui/material";

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  fontWeight: 700,

  textAlign: "center",
  fontFamily: "IBM Plex Sans",
  // marginBottom: theme.spacing(8),
  position: "relative",
  [theme.breakpoints.down("md")]: {
    fontSize: "3rem",
  },
  // '& .underline': {
  //   position: 'absolute',
  //   bottom: '-10px',
  //   left: '50%',
  //   transform: 'translateX(-50%)',
  //   width: '438px',
  //   height: '4px',
  //   opacity: 0.5,
  //   backgroundColor: '#FFD700',
  //   zIndex: -1,
  //   [theme.breakpoints.down('sm')]: {
  //     width: '280px',
  //   },
  // },
}));

// const PhoneGrid = styled(Box)(({ theme }) => ({
//   position: 'relative',
//   height: '600px',
//   width: '100%',
//   maxWidth: '1200px',
//   margin: '0 auto',
//   [theme.breakpoints.down('md')]: {
//     height: '400px',
//   },
// }));

// const PhoneImage = styled(Box)(({ index }) => ({
//     position: 'absolute',
//     width: '220px',
//     height: '440px',
//     left: `${index * 10}%`,  // Adjusts the position horizontally for each image
//     top: `${index * 2}%`,  // Adjusts the position vertically for each image
//     transform: `rotate(${index * 5 - 10}deg)`, // Slight rotation for each image
//     transition: 'transform 0.3s ease',
//     zIndex: 5 - index,  // Ensures each image is stacked behind the previous one
//   }));

export default function MobileScreenBanner() {
  return (
    <Container
      maxWidth={false}
      sx={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop:10
      }}
    >
      <Box sx={{ textAlign: "center", }}>
        <StyledHeading variant="h6">
          Watch new videos everyday
          <span className="underline" style={{ backgroundColor: "#423bfa" }} />
        </StyledHeading>
      </Box>

      <Box>
        <img
          src="/mobileBanner.png"
          alt=""
          style={{ width: "100%", height: "70vh" }}
        />
      </Box>
    </Container>
  );
}
