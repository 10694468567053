import { Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";

export const DeleteConfirmPopup = ({ openDialog, handleCloseDialog }) => {
  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogContent sx={{fontSize:20,display:'flex',flexDirection:"column",justifyContent:"center",alignItems:'center  '}}>
        <Typography sx={{color:'red'}}>
        Delete Request has been recieved
        </Typography>
        <Typography>
        Our team will get back to you within
        <span style={{fontWeight:'bold'}}> 6 hours</span>
        </Typography>
        
      </DialogContent>
    </Dialog>
  );
};
