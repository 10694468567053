import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Paper,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import DownloadIcon from "@mui/icons-material/Download";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const DatabaseCreditsInfo = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: 2,
        borderRadius: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box component={Paper} elevation={0} sx={{ borderRadius: 1 }}>
        <Accordion
          defaultExpanded
          elevation={0}
          square
          sx={{
            "&:before": {
              display: "none", // Remove default Accordion border
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ padding: 2, backgroundColor: "#f5f5f5" }}
          >
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              sx={{
                display: "flex",
                alignItems: "center",
                opacity: 0.7,
                fontSize: "18px",
              }}
            >
              <LocalOfferIcon
                style={{ marginRight: 8, fontSize: 20, opacity: 0.7 }}
              />
              How Database Credits work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 2 }}>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 16,
                marginBottom: 2,
              }}
            >
              <LockIcon sx={{ marginRight: 1, opacity: 0.7, fontSize: 20 }} />
              1 Profile Unlock = 1 Database Credit
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 16,
                marginBottom: 2,
              }}
            >
              <DownloadIcon
                sx={{ marginRight: 1, opacity: 0.7, fontSize: 20 }}
              />
              1 Excel Download = 2 Database Credits*
            </Typography>
            <Divider sx={{ marginY: 1 }} />
            <Typography
              sx={{ fontSize: 14, opacity: 0.7, marginTop: 1, lineHeight: 1.6 }}
            >
              For already unlocked profiles, 1 Database Credit is used for Excel
              Download
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Paper>
  );
};

export default DatabaseCreditsInfo;
