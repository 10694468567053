import baseURL from "./baseUrl";

export const getFeaturedJobSeekers = async () => {
  try {
    const response = await fetch(`${baseURL}/featured/jobseekers`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const allTestimonialsApi = async () => {
  try {
    const response = await fetch(`${baseURL}/testimonials/user`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getJobsByOrgApi = async (orgId) => {
  try {
    const response = await fetch(
      `${baseURL}/organization/getJobsByOrg/${orgId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getOrganizaionDetailsApi = async (orgId) => {
  try {
    const response = await fetch(`${baseURL}/jobs/org/${orgId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getJobseekerProfileApi = async (id, token) => {
  try {
    const response = await fetch(`${baseURL}/view/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

  export const generateRefereshTokenApi = async (formValues) => {
    try {
      const response = await fetch(`${baseURL}/generateAuthToken`, {
        method: "POST",
        headers: {
          "Content-Type": "Application/json",
        },
        body: JSON.stringify(formValues),
      });
      if (!response.ok) {
        throw new Error("network response was not ok");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error.message);
    }
  };


export const getProfilePercentageApi = async (userId,token) => {
  try {
    const response = await fetch(`${baseURL}/getProfilePercentage/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getUserApi = async (userId,token) => {
  try {
    const response = await fetch(`${baseURL}/jobseeker/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
