import React, { useState } from "react";
import { Card, Typography, Grid, Button, Box, Chip } from "@mui/material";
import { Container } from "@mui/system";
import { getUserIdFromToken } from "../../utils/userAuth";
import { useNavigate } from "react-router-dom";
import baseURL from "../../api/baseUrl";

const SubscriptionPlanJobSeeker = () => {
  const [selectedPlan, setSelectedPlan] = useState("basic");
  const navigate = useNavigate();

  const pricing = {
    basic: {
      price: "₹49*",
      originalPrice: "999*",
      discount: "20% Off",
    },
    premium: {
      price: "₹99*",
      originalPrice: "1999*",
      discount: "20% Off",
    },
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan); // Update selected plan on button click
  };

  const initiatePayment = async () => {
    const userId = await getUserIdFromToken();
    // Prepare data for payment initiation
    const paymentData = {
      amount: pricing[selectedPlan].price.replace("₹", "").replace("*", "") * 100, // Extract amount from the selected plan
      userId: userId, // You can dynamically set this if you have user authentication
      redirectUrl: "https://vacanzi.com/jobSeekerPaymentVeirfy", // URL to redirect after payment
    };

    try {
      // Sending request to backend to initiate payment
      const response = await fetch(`${baseURL}/initiateJSPayment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentData),
      });

      const data = await response.json();
      const { paymentUrl, merchantTransactionId } = data;

      // Save merchantTransactionId to localStorage for payment verification later
        localStorage.setItem("merchantTransactionId", merchantTransactionId);

        window.location.href = paymentUrl;

        navigate("/jobSeekerPaymentVeirfy")
      
    } catch (error) {
      console.error("Payment initiation failed:", error);
     
    }
  };

  return (
    <>
      <Box
        sx={{
          border: 2,
          padding: { xs: 2, sm: 4, md: 6 },
          paddingLeft: 0,
          margin: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          background: "linear-gradient(to right, #6a11cb, #2575fc)",
          borderRadius: 5,
          width: "100%",
          flexDirection: { xs: "column", sm: "row" }, // Stack on mobile, row on larger screens
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            textAlign: "left",
            width: "100%",
            order: { xs: 2, sm: 1 },
          }}
        >
          <Typography variant="h3" gutterBottom>
            Increase Your Chance to Get Interview Calls from Recruiters
          </Typography>
          <Typography variant="h6" gutterBottom>
            - with our monthly subscription plans
          </Typography>
        </Container>

        {/* Pricing Card Positioned on the Right */}
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={6}
            justifyContent="center"
            sx={{ marginTop: 2, width: "100%" }}
          >
            <Grid
              item
              xs={12}
              sm={8}
              md={3}
              sx={{
                order: { xs: 1, sm: 2 }, // On small screens, make it appear first
                width: { xs: "100%", sm: "auto" },
                maxWidth: { xs: "100%", sm: "400px", md: "500px" },
                margin: "auto",
              }}
            >
              {/* Pricing Card */}
              <Card
                variant="outlined"
                sx={{
                  textAlign: "center",
                  padding: 4,
                  width: "34VW",
                  maxWidth: { xs: "100%", sm: "400px", md: "40vw" },
                  margin: "auto",
                  boxShadow: 3,
                }}
              >
                <Chip
                  label={selectedPlan === "basic" ? "Basic Plan" : "Premium Plan"}
                  color={selectedPlan === "basic" ? "primary" : "secondary"}
                  sx={{ marginBottom: 2 }}
                />
                {/* Plan selection buttons */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 3,
                    marginBottom: 3,
                    width: "100%",
                    flexDirection: { xs: "column", sm: "row" }, // Stack buttons on small screens
                  }}
                >
                  <Button
                    variant={selectedPlan === "basic" ? "contained" : "outlined"}
                    color="primary"
                    size="small"
                    onClick={() => handlePlanSelect("basic")}
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      borderRadius: 0,
                      fontSize: { xs: "0.8rem", sm: "1rem" },
                    }}
                  >
                    Basic Plan
                  </Button>
                  <Button
                    variant={selectedPlan === "premium" ? "contained" : "outlined"}
                    color="secondary"
                    size="small"
                    onClick={() => handlePlanSelect("premium")}
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      borderRadius: 0,
                      fontSize: { xs: "0.8rem", sm: "1rem" },
                      letterSpacing: 1,
                    }}
                  >
                    Premium Plan
                  </Button>
                </Box>

                <Typography variant="h4" fontWeight="bolder" gutterBottom>
                  {pricing[selectedPlan].price}
                  <Typography
                    variant="body2"
                    component="span"
                    color="text.secondary"
                    fontWeight="bold"
                  >
                    {" "}
                    <s style={{ fontSize: "15px" }}>₹</s>
                    <s>{pricing[selectedPlan].originalPrice}</s>
                  </Typography>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  *Per Month + Taxes
                </Typography>

                {/* Payment Button */}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  onClick={initiatePayment} // Trigger the payment initiation
                >
                  Pay Now
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SubscriptionPlanJobSeeker;
