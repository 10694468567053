import React, { useEffect, useState } from "react";
import SchoolIcon from "@mui/icons-material/School";
import {
  Box,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { getJobSeekerDetails } from "../../../api/JobSeekerDetailsApi";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import baseURL from "../../../api/baseUrl";

const JobSeekerEducationProfile = () => {
  const [userData, setUserData] = useState(null);

  // State to manage each field's modal visibility
  // const [openDegreeModal, setOpenDegreeModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  // State to store current editing field values
  const [editedDegree, setEditedDegree] = useState("");
  const [editedInstitution, setEditedInstitution] = useState("");
  const [editedDegreeType, setEditedDegreeType] = useState("highSchoolDiploma"); // Default to High School Diploma
  const [editedGrade, setEditedGrade] = useState("");
  const [editedGraduationYear, setEditedGraduationYear] = useState("");
  const [editedFieldOfStudy, setEditedFieldOfStudy] = useState("");
  const [editedStartDate, setEditedStartDate] = useState("");
  const [editedEndDate, setEditedEndDate] = useState("");
  const [editedCurrentlyPursuing, setEditedCurrentlyPursuing] = useState(false);

  const [isAdding, setIsAdding] = useState(true); // Track add or edit action
  const [educationId, setEducationId] = useState(null); // Store Education ID

  useEffect(() => {
    const fetchJobSeeker = async () => {
      const response = await getJobSeekerDetails();
      if (response) {
        setUserData(response);
      } else {
        console.log("Failed to fetch job seeker details.");
      }
    };
    fetchJobSeeker();
  }, []);

  const handleAddClick = () => {
    setIsAdding(true);
    setEducationId(null);
    setEditedDegree("");
    setEditedInstitution("");
    setEditedDegreeType("highSchoolDiploma"); // Reset to default
    setEditedGrade("");
    setEditedGraduationYear("");
    setEditedFieldOfStudy("");
    setEditedStartDate("");
    setEditedEndDate("");
    setEditedCurrentlyPursuing(false);
    setOpenModal(true); // Open modal
  };

  const handleEditClick = (education) => {
    setIsAdding(false);
    setEducationId(education._id);
    setEditedDegree(education.degree);
    setEditedInstitution(education.institution);
    setEditedDegreeType(education.degreeType || "highSchoolDiploma"); // Default if not provided
    setEditedGrade(education.grade);
    setEditedGraduationYear(education.graduationYear);
    setEditedFieldOfStudy(education.fieldOfStudy);
    setEditedStartDate(education.startDate?.split("T")[0] || ""); // Format date for input
    setEditedEndDate(education.endDate?.split("T")[0] || ""); // Format date for input
    setEditedCurrentlyPursuing(education.currentlyPursuing || false);
    setOpenModal(true); // Open modal
  };

  const handleDeleteEducation = async (educationId) => {
    const token = localStorage.getItem("authToken")?.replace(/"/g, "");

    if (!educationId) {
      console.error("Education ID is missing");
      return;
    }

    const confirmed = window.confirm(
      "Are you sure you want to delete this education?"
    );
    if (!confirmed) return;

    try {
      const response = await fetch(
        `${baseURL}/deleteEducation/${educationId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setUserData((prevState) => ({
          ...prevState,
          educations: prevState.educations.filter(
            (edu) => edu._id !== educationId
          ),
        }));
        console.log("Education deleted successfully");
      } else {
        console.error("Failed to delete education:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting education:", error);
    }
  };

  const degreeTypes = [
    { label: "High School Diploma", value: "highSchoolDiploma" },
    { label: "UG Education", value: "underGraduate" },
    { label: "PG Education", value: "postGraduate" },
    { label: "Doctorate (PhD)", value: "doctorate" },
    { label: "Technical Certification", value: "technicalCertification" },
    { label: "Vocational Training", value: "vocationalTraining" },
    { label: "GED (General Educational Development)", value: "ged" },
  ];

  const handleModalClose = () => {
    // setOpenDegreeModal(false);
    setOpenModal(false);
  };

  const handleSaveEducation = async () => {
    const token = localStorage.getItem("authToken")?.replace(/"/g, "");
    const educationData = {
      degree: editedDegree,
      institution: editedInstitution,
      degreeType: editedDegreeType,
      grade: editedGrade,
      graduationYear: editedGraduationYear,
      fieldOfStudy: editedFieldOfStudy,
      startDate: editedStartDate ? `${editedStartDate}T00:00:00.000Z` : null, // Convert to time string
      endDate: editedCurrentlyPursuing
        ? ""
        : editedEndDate
        ? `${editedEndDate}T00:00:00.000Z`
        : null, // Convert to time string
      currentlyPursuing: editedCurrentlyPursuing,
    };

    try {
      const method = isAdding ? "POST" : "PUT";
      const url = isAdding
        ? `${baseURL}/addEducation`
        : `${baseURL}/editEducation/${educationId}`;

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(educationData),
      });

      if (response.ok) {
        const responseData = await response.json();
        const newEducation = responseData.education;

        setUserData((prevUserData) => ({
          ...prevUserData,
          educations: isAdding
            ? [...(prevUserData.educations || []), newEducation]
            : prevUserData.educations.map((education) =>
                education._id === educationId ? newEducation : education
              ),
        }));

        handleModalClose();
      } else {
        console.error("Error saving education:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving education:", error);
    }
  };

  // const formatYear = (dateString) => {
  //   const date = new Date(dateString);
  //   const options = {
  //     year: "numeric", // Only year
  //   };
  //   return date.toLocaleDateString("en-US", options);
  // };

  return (
    <>
      <Paper
        sx={{
          margin: "0 auto",
          borderRadius: "15px",
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Education
            </Typography>
          </Box>
          <Typography
            variant="body1"
            color="primary"
            onClick={handleAddClick}
            sx={{
              cursor: "pointer",
              fontWeight: "bold",
              marginRight: 2,
            }}
          >
            + Add
          </Typography>
        </Box>

        <Paper
          sx={{
            padding: "20px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "15px",
          }}
          variant="outlined"
          elevation={3}
        >
          <Typography sx={{ fontWeight: "bold", color: "gray" }}>
            Highest Qualification:{" "}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            {userData?.educations?.length > 0 ? (
              userData.educations.map((education) => (
                <Typography
                  key={education._id}
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                >
                  {education.degreeType}
                </Typography>
              ))
            ) : (
              <Typography sx={{ fontWeight: "bold" }}>
                {userData?.education?.degreeType || "No Education"}
              </Typography>
            )}
            <ArrowForwardIosIcon sx={{ cursor: "pointer" }} />
          </Box>
        </Paper>

        <Box sx={{ backgroundColor: "#f9f9f9", marginTop: 1 }}>
          <Paper
            sx={{
              padding: "20px",
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              borderRadius: "15px",
              gap: 3,
            }}
            variant="outlined"
            elevation={4}
          >
            {(userData?.education ||
              (userData?.educations && userData.educations.length > 0)) &&
              (userData.educations || [userData.education]).map(
                (education, index) => (
                  <Box
                    key={education._id || index}
                    sx={{
                      borderRadius: "10px",
                      padding: "15px",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <SchoolIcon
                          sx={{ color: "#423BFA", fontSize: "2.5rem" }}
                        />
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {education.degree} | {education.fieldOfStudy}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Edit
                          sx={{ cursor: "pointer", color: "grey" }}
                          onClick={() => handleEditClick(education)}
                        />
                        <DeleteIcon
                          sx={{ cursor: "pointer", color: "red" }}
                          onClick={() => handleDeleteEducation(education._id)}
                        />
                      </Box>
                    </Box>
                    <Typography variant="body1">
                      <strong>Institution:</strong> {education.institution}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Grade:</strong> {education.grade || "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Batch:</strong>{" "}
                      {education.graduationYear
                        ? new Date(education.graduationYear).getFullYear()
                        : "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Start Date:</strong>{" "}
                      {education.startDate
                        ? new Date(education.startDate).toLocaleDateString()
                        : "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>End Date:</strong>{" "}
                      {education.endDate
                        ? new Date(education.endDate).toLocaleDateString()
                        : "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Currently Pursuing:</strong>{" "}
                      {education.currentlyPursuing ? "Yes" : "No"}
                    </Typography>
                  </Box>
                )
              )}
          </Paper>
        </Box>

        <Dialog open={openModal} onClose={handleModalClose}>
          <DialogTitle>
            <b>{educationId ? "Edit Education" : "Add Education"}</b>
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Institution"
              value={editedInstitution || ""}
              onChange={(e) => setEditedInstitution(e.target.value)}
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Degree"
              value={editedDegree || ""}
              onChange={(e) => setEditedDegree(e.target.value)}
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Degree Type"
              select
              value={editedDegreeType || ""}
              onChange={(e) => setEditedDegreeType(e.target.value)}
              variant="outlined"
              margin="normal"
            >
              {degreeTypes.map((degree) => (
                <MenuItem key={degree.value} value={degree.value}>
                  {degree.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Field of Study"
              value={editedFieldOfStudy || ""}
              onChange={(e) => setEditedFieldOfStudy(e.target.value)}
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Grade"
              value={editedGrade || ""}
              onChange={(e) => setEditedGrade(e.target.value)}
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Graduation Year"
              value={editedGraduationYear || ""} // Ensure it's a string or empty string
              onChange={(e) => {
                setEditedGraduationYear(e.target.value);
              }}
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              value={editedStartDate || ""}
              onChange={(e) => setEditedStartDate(e.target.value)}
              variant="outlined"
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              label="End Date"
              type="date"
              value={editedEndDate || ""}
              onChange={(e) => setEditedEndDate(e.target.value)}
              variant="outlined"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              disabled={editedCurrentlyPursuing}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={editedCurrentlyPursuing}
                  onChange={(e) => setEditedCurrentlyPursuing(e.target.checked)}
                />
              }
              label="Currently Pursuing"
            />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                borderRadius: "2rem",
                textTransform: "none",
                fontWeight: "bold",
              }}
              onClick={handleModalClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              sx={{
                borderRadius: "2rem",
                textTransform: "none",
                background: "#423bfa",
                fontWeight: "bold",
              }}
              variant="contained"
              onClick={handleSaveEducation}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
};

export default JobSeekerEducationProfile;
