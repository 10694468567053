import React, { useState, useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from "@mui/material/styles";
import baseURL from '../../api/baseUrl';

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  border: "none",
  backgroundColor: "transparent",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    borderBottom: "1px solid #E2E8F0",
    padding: theme.spacing(2, 0),
  },
  "& .MuiAccordionSummary-content": {
    margin: "12px 0",
  },
  "& .MuiAccordionDetails-root": {
    padding: theme.spacing(3, 0),
  },
}));

const CustomExpandIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  color: "#14234A",
  transition: "transform 0.3s ease",
}));

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch FAQs on component mount
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await fetch(`${baseURL}/get/faqs`);
        if (!response.ok) {
          throw new Error(`Failed to fetch FAQs: ${response.statusText}`);
        }
        const data = await response.json();
        const EmployerFAQs = data.filter(faq => faq.category === "Employers");
        setFaqs(EmployerFAQs);
      } catch (err) {
        console.error("Error fetching FAQs:", err);
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchFaqs();
  }, []);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (loading) {
    return <Typography variant="h6">Loading FAQs...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  return (
    <Box
      sx={{
        py: 8,
        bgcolor: "#EAFBF4FF",
        fontFamily: "IBM Plex Sans, sans-serif",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          component="h2"
          sx={{
            mb: 6,
            color: "#14234A",
            fontSize: { xs: "2rem", md: "2.5rem" },
            fontWeight: 600,
            fontFamily: "IBM Plex Sans, sans-serif",
          }}
        >
          Frequently asked questions
        </Typography>

        <Box sx={{ maxWidth: "100%" }}>
          {faqs.map((faq, index) => (
            <CustomAccordion
              key={faq._id}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    transform: expanded === `panel${index}` ? "rotate(180deg)" : "none",
                    transition: "transform 0.3s ease",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "1rem", md: "1.125rem" },
                    fontWeight: 500,
                    color: "#14234A",
                    fontFamily: "IBM Plex Sans, sans-serif",
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "#475569",
                    lineHeight: 1.7,
                    fontSize: { xs: "0.875rem", md: "1rem" },
                    fontFamily: "IBM Plex Sans, sans-serif",
                  }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </CustomAccordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Faq;
