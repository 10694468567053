import React from "react";
import { Typography, Button, Box, TextField, Modal } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EducationModal = ({
  open,
  handleClose,
  handleEditChange,
  selectedEducation,
  handleSave,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h6" mb={2}>
          Edit Education
        </Typography>
        <TextField
          fullWidth
          label="Degree"
          name="degree"
          value={selectedEducation.degree}
          onChange={handleEditChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Degree Type"
          name="degreeType"
          value={selectedEducation.degreeType}
          onChange={handleEditChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Institution"
          name="institution"
          value={selectedEducation.institution}
          onChange={handleEditChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Place of Education"
          name="placeOfEducation"
          value={selectedEducation.placeOfEducation}
          onChange={handleEditChange}
          margin="normal"
        />
        {/* Optional fields that might exist in the payload */}
        {/* <TextField
          fullWidth
          label="Field of Study"
          name="fieldOfStudy"
          value={selectedEducation.fieldOfStudy || ""}
          onChange={handleEditChange}
          margin="normal"
        /> */}
        {/* <TextField
          fullWidth
          label="Graduation Year"
          name="graduationYear"
          type="number"
          value={selectedEducation.graduationYear || ""}
          onChange={handleEditChange}
          margin="normal"
        /> */}
        {/* <TextField
          fullWidth
          label="Start Date"
          name="startDate"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={selectedEducation.startDate || ""}
          onChange={handleEditChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="End Date"
          name="endDate"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={selectedEducation.endDate || ""}
          onChange={handleEditChange}
          margin="normal"
        /> */}
        {/* <TextField
          fullWidth
          label="Grade"
          name="grade"
          value={selectedEducation.grade || ""}
          onChange={handleEditChange}
          margin="normal"
        /> */}
        {/* <TextField
          fullWidth
          label="Activities and Societies"
          name="activitiesAndSocieties"
          value={selectedEducation.activitiesAndSocieties || ""}
          onChange={handleEditChange}
          margin="normal"
        /> */}
        {/* <TextField
          fullWidth
          label="Description"
          name="description"
          value={selectedEducation.description || ""}
          onChange={handleEditChange}
          margin="normal"
        /> */}
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default EducationModal;
