import React from "react";
import { Box } from "@mui/system";
import { Button, Grid, Paper, Typography } from "@mui/material";
import CompanyDetailsModel from "../../../../components/EmployerComponents/CompanyProfileComponents/CompanyDetailsModel";

const CompanyDetails = ({
  openCompanyDialog,
  handleCompanyDialogClose,
  handleSaveCompanyDetails,
  handleCompanyEdit,
  orgData,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: "#FFF", padding: 3, marginTop: 2 }}
    >
      <Typography sx={{ fontSize: "large", marginBottom: 2 }}>
        Company Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            Industry Type : {orgData?.industryType}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            Contact Person : {orgData?.contactPersonName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            Website URL : {orgData?.website}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            Phone Number1 : {orgData?.contactNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            Phone Number 2 : {orgData?.contactPersonNumber}
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button sx={{ borderRadius:'2rem',backgroundColor:'#423bfa'}} variant="contained" onClick={handleCompanyEdit}>
          Edit
        </Button>
      </Box>
      <CompanyDetailsModel
        openCompanyDialog={openCompanyDialog}
        handleCompanyDialogClose={handleCompanyDialogClose}
        handleSaveCompanyDetails={handleSaveCompanyDetails}
        orgData={orgData}
      />
    </Paper>
  );
};

export default CompanyDetails;
