import React, { useEffect, useState } from "react";
// import ResponsiveAppBar from "../NavBar/NavBar";
import {
  Box,
  CircularProgress,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import baseURL from "../../../api/baseUrl";
import NewNavBar from "../NavBar/NewNavbar";

const JobSeekerFAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(["faq-0", "faq-1"]); // First two FAQs open by default

  const fetchFaqs = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`${baseURL}/get/faqs`);
      if (!response.ok) {
        throw new Error(`Failed to fetch FAQs: ${response.statusText}`);
      }
      const data = await response.json();
      const jobSeekerFaqs = data.filter(
        (faq) => faq.category === "Job Seekers"
      );
      setFaqs(jobSeekerFaqs);
    } catch (err) {
      console.error("Error fetching FAQs:", err);
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((item) => item !== panel)
    );
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading FAQs...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
          mt:6,

        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
        <Button variant="contained" color="primary" onClick={fetchFaqs}>
          Retry
        </Button>
      </Paper>
    );
  }

  return (
    <>
      <NewNavBar />
      <Paper
        sx={{
          maxWidth: "1000px",
          margin: "0 auto",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom sx={{ mt:4}}>
          Job Seeker FAQs
        </Typography>
        <Box sx={{ mt: 3 }}>
          {faqs.length > 0 ? (
            faqs.map((faq, index) => {
              const isExpanded = expanded.includes(`faq-${index}`);
              return (
                <Accordion
                  key={faq._id}
                  expanded={isExpanded}
                  onChange={handleAccordionChange(`faq-${index}`)}
                  sx={{
                    borderRadius: "5px",
                    mb: 2,
                    
                    transition: "all 0.3s ease-in-out",
                    
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      
                      fontWeight: isExpanded ? "bold" : "normal",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      sx={{
                        textAlign: "left",
                        color: "#333",
                        fontSize: "1rem",
                        lineHeight: "1.6",
                      }}
                    >
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <Typography variant="body1" color="textSecondary">
              No FAQs available for Job Seekers at the moment.
            </Typography>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default JobSeekerFAQ;
