import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import "./JobSeekerCaseZero.css"; // Ensure this CSS file contains necessary custom styles

const JobSeekerCaseZero = ({
  formData,
  setFormData,
  handleGenderChange,
  handleExperienceChange,
  handleCurrentlyPursuingChange,
  handlePursuingEducationChange,
}) => {
  const location = useLocation();
  const { signupEmail, signupName, signupPhone } = location.state || {};

  // Use useEffect to set initial form data from location.state
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: signupEmail || prevFormData.email,
      fullName: signupName || prevFormData.fullName,
      phone: signupPhone || prevFormData.phone,
    }));
  }, [signupEmail, signupName, signupPhone, setFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleLocationChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      currentLocation: e.target.value,
    }));
  };

  const handlePreferredLocationChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      preferredLocation: e.target.value,
    }));
  };

  const handleExpectedCtcChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      expectedCtc: e.target.value,
    }));
  };

  const degreeTypes = [
    { label: "High School Diploma", value: "highSchoolDiploma" },
    { label: "Under Graduate", value: "underGraduate" },
    { label: "Post Graduate", value: "postGraduate" },
    { label: "Doctorate", value: "doctorate" },
    { label: "Technical Certification", value: "technicalCertification" },
    { label: "Vocational Training", value: "vocationalTraining" },
    { label: "GED", value: "ged" },
  ];

  console.log("formData", formData);
  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      <Grid item xs={12} md={4}>
        <Typography
          component="legend"
          sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
        >
          Name
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>
        <TextField
          required
          fullWidth
          placeholder="Enter full name"
          name="fullName"
          variant="outlined"
          value={formData.fullName || ""}
          onChange={handleChange}
          size="small"
          sx={{ marginTop: 1 }}
          disabled={!!signupName}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography
          component="legend"
          variant="bold"
          sx={{ fontWeight: "bold" }}
        >
          Email
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>
        <TextField
          fullWidth
          type="email"
          name="email"
          variant="outlined"
          placeholder="Enter email"
          value={formData.email || ""}
          onChange={handleChange}
          size="small"
          sx={{ marginTop: 1 }}
          disabled={!!signupEmail}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography
          component="legend"
          variant="bold"
          sx={{ fontWeight: "bold" }}
        >
          Mobile
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>
        <TextField
          fullWidth
          type="tel"
          name="phone" // Ensure this matches the formData key
          variant="outlined"
          placeholder="Enter mobile number"
          value={formData.phone || ""} // Ensure this matches the formData key
          onChange={handleChange}
          size="small"
          sx={{ marginTop: 1 }}
          disabled
        />
      </Grid>

      <Grid item xs={12} md={4} sm={6}>
        <Typography
          component="legend"
          variant="bold"
          sx={{ fontWeight: "bold" }}
        >
          Date of Birth (DOB)
        </Typography>
        <TextField
          fullWidth
          type="date"
          name="dob"
          variant="outlined"
          value={formData.dob || ""}
          onChange={handleChange}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginTop: 1 }}
        />
      </Grid>

      {/* Gender Section */}
      <Grid item xs={12} sm={6} md={4}>
        <Typography
          component="legend"
          variant="bold"
          sx={{ marginBottom: 1.5, fontWeight: "bold" }}
        >
          Gender
          <Typography component="span" sx={{ color: "red", marginLeft: 0.5 }}>
            *
          </Typography>
        </Typography>
        <RadioGroup
          row
          value={formData.gender}
          onChange={(e) => handleGenderChange(e.target.value)} // Update gender on selection
          sx={{ justifyContent: "flex-start" }}
        >
          <FormControlLabel
            value="male" // Added value for Male
            control={
              <Radio
                sx={{ color: "#423BFA", "&.Mui-checked": { color: "#423BFA" } }}
              />
            }
            label="Male"
            sx={{
              ".MuiFormControlLabel-label": {
                fontWeight: "bold",
                fontSize: "14px",
                marginLeft: 0.5,
              },
            }}
          />
          <FormControlLabel
            value="female" // Corrected value for Female
            control={
              <Radio
                sx={{ color: "#423BFA", "&.Mui-checked": { color: "#423BFA" } }}
              />
            }
            label="Female"
            sx={{
              ".MuiFormControlLabel-label": {
                fontWeight: "bold",
                fontSize: "14px",
              },
            }}
          />
          <FormControlLabel
            value="other" // Corrected value for Other
            control={
              <Radio
                sx={{ color: "#423BFA", "&.Mui-checked": { color: "#423BFA" } }}
              />
            }
            label="Other"
            sx={{
              ".MuiFormControlLabel-label": {
                fontWeight: "bold",
                fontSize: "14px",
              },
            }}
          />
        </RadioGroup>
      </Grid>
      {/* Work Experience Section */}
      <Grid item xs={12} md={4} sm={6}>
        <Typography
          component="legend"
          variant="bold"
          sx={{ marginBottom: 1.5, fontWeight: "bold" }}
        >
          Work Experience
        </Typography>
        <RadioGroup
          row
          value={formData.hasWorkExperience}
          onChange={(e) => handleExperienceChange(e.target.value === "true")} // Convert string to boolean
          sx={{ justifyContent: "flex-start" }}
        >
          <FormControlLabel
            value={false}
            control={
              <Radio
                sx={{ color: "#423BFA", "&.Mui-checked": { color: "#423BFA" } }}
              />
            }
            label="Freshers"
            sx={{
              ".MuiFormControlLabel-label": {
                fontWeight: "bold",
                fontSize: "14px",
              },
            }}
          />
          <FormControlLabel
            value={true}
            control={
              <Radio
                sx={{ color: "#423BFA", "&.Mui-checked": { color: "#423BFA" } }}
              />
            }
            label="Experienced"
            sx={{
              ".MuiFormControlLabel-label": {
                fontWeight: "bold",
                fontSize: "14px",
              },
            }}
          />
        </RadioGroup>
      </Grid>

      {/* Section 1: "Are you still pursuing your education?" */}
      <Grid item xs={12}>
        <Typography
          component="legend"
          variant="bold"
          sx={{ fontWeight: "bold", marginBottom: 1.5 }}
        >
          Are you still pursuing your education?
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant={
                formData?.pursuingEducation === true ? "contained" : "outlined"
              }
              onClick={() => handlePursuingEducationChange(true)}
              sx={{
                borderRadius: 6,
                backgroundColor:
                  formData?.pursuingEducation === true
                    ? "#423BFA"
                    : "transparent",
                border: "1px solid #ccc",
                color: formData?.pursuingEducation === true ? "#fff" : "#000",
              }}
            >
              <Typography fontWeight="bold" fontSize="12px">
                Yes
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={
                formData?.pursuingEducation === false ? "contained" : "outlined"
              }
              onClick={() => handlePursuingEducationChange(false)}
              sx={{
                borderRadius: 6,
                backgroundColor:
                  formData?.pursuingEducation === false
                    ? "#423BFA"
                    : "transparent",
                border: "1px solid #ccc",
                color: formData?.pursuingEducation === false ? "#fff" : "#000",
              }}
            >
              <Typography fontWeight="bold" fontSize="12px">
                No
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* Section 2: If pursuing education, show degree options */}
      {formData?.pursuingEducation === true && (
        <Grid item xs={12}>
          <Typography
            component="legend"
            sx={{ fontWeight: "bold", marginBottom: 1.5 }}
          >
            What are you currently pursuing?
          </Typography>
          <Grid container spacing={1}>
            {["diploma", "graduate", "postGraduate"].map((option) => (
              <Grid item xs={6} key={option}>
                <Button
                  variant={
                    formData.currentlyPursuing === option
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleCurrentlyPursuingChange(option)}
                  sx={{
                    width: "100%",
                    borderRadius: 4,
                    backgroundColor:
                      formData.currentlyPursuing === option
                        ? "#423BFA"
                        : "transparent",
                    borderColor:
                      formData.currentlyPursuing === option
                        ? "#423BFA"
                        : "#ccc",
                    color:
                      formData.currentlyPursuing === option ? "#fff" : "#000",
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {option}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      {/* Section 3: If not pursuing education, show highest qualification and details */}
      {formData?.pursuingEducation === false && (
        <>
          <Grid item xs={12} md={4}>
            <Typography
              component="legend"
              variant="bold"
              sx={{ fontWeight: "bold", marginBottom: 1.5 }}
            >
              Highest Qualification
              <Typography
                component="span"
                sx={{ color: "red", marginLeft: 0.5 }}
              >
                *
              </Typography>
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter highest qualification"
              name="highestEducation"
              variant="outlined"
              value={formData.highestEducation}
              onChange={handleChange}
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography
              component="legend"
              variant="bold"
              sx={{ fontWeight: "bold", marginBottom: 1.5 }}
            >
              Institution Name
              <Typography
                component="span"
                sx={{ color: "red", marginLeft: 0.5 }}
              >
                *
              </Typography>
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter institution name"
              name="institution"
              variant="outlined"
              value={formData.institution}
              onChange={handleChange}
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography
              component="legend"
              variant="bold"
              sx={{ fontWeight: "bold", marginBottom: 1.5 }}
            >
              Year of Passing
              <Typography
                component="span"
                sx={{ color: "red", marginLeft: 0.5 }}
              >
                *
              </Typography>
            </Typography>
            <TextField
              fullWidth
              type="date"
              placeholder="Enter year of passing"
              name="graduationYear"
              variant="outlined"
              value={formData.graduationYear}
              onChange={handleChange}
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
        </>
      )}

      {/* Section 4: Pre-University or Degree details */}
      {formData?.pursuingEducation === false && (
        <>
          <Grid item xs={12} md={4}>
            <Typography component="legend" sx={{ fontWeight: "bold" }}>
              Degree Type
            </Typography>
            <FormControl fullWidth size="small">
              <Select
                name="degreeType"
                value={formData.degreeType || ""}
                onChange={handleChange}
              >
                {degreeTypes.map((degree) => (
                  <MenuItem key={degree.value} value={degree.value}>
                    {degree.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
      {!formData?.hasWorkExperience && (
        <>
          <Grid item xs={12} md={4}>
            <Typography
              component="legend"
              variant="bold"
              sx={{ fontWeight: "bold" }}
            >
              Current Location
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter your current location"
              variant="outlined"
              value={formData.currentLocation || ""}
              onChange={handleLocationChange}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              component="legend"
              variant="bold"
              sx={{ fontWeight: "bold" }}
            >
              Preferred Location
              <Typography
                component="span"
                sx={{ color: "red", marginLeft: 0.5 }}
              >
                *
              </Typography>
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter your preferred location"
              variant="outlined"
              value={formData.preferredLocation || ""}
              onChange={handlePreferredLocationChange} // Handle change for preferred location
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              component="legend"
              variant="bold"
              sx={{ fontWeight: "bold" }}
            >
              Preffered Job Role
              <Typography
                component="span"
                sx={{ color: "red", marginLeft: 0.5 }}
              >
                *
              </Typography>
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter Preffered Job Title"
              name="preferredJobTitle"
              variant="outlined"
              value={formData.preferredJobTitle}
              onChange={handleChange}
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              component="legend"
              variant="bold"
              sx={{ fontWeight: "bold" }}
            >
              Expected CTC
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter your expected CTC"
              variant="outlined"
              value={formData.expectedCtc || ""}
              onChange={handleExpectedCtcChange}
              size="small"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default JobSeekerCaseZero;
