import React from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@mui/material';
import { CheckCircle as CheckCircleIcon, Error as ErrorIcon, Close as CloseIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const CustomSnackbar = ({ open, message, type, handleClose }) => {
  const theme = useTheme();

  // Determine background color based on the type
  const backgroundColor = type === 'success' ? theme.palette.success.main : theme.palette.error.main;

  return (
    <Snackbar
      open={open}
      autoHideDuration={1000} // Set to 1 seconds
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      aria-live="assertive"
    >
      <SnackbarContent
        message={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {type === 'success' ? (
              <CheckCircleIcon style={{ marginRight: '8px', color: 'white' }} />
            ) : (
              <ErrorIcon style={{ marginRight: '8px', color: 'white' }} />
            )}
            {message}
          </span>
        }
        sx={{
          backgroundColor,
          color: 'white',
          borderRadius: 1,
          p: 1,
        }}
        action={
          <IconButton aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default CustomSnackbar;
