import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import UsageReport from "./UsageReport";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import searchCandidates from "../../../styles/searchCandidatesStyles";
import manageQuota from "../../../styles/manageQuota";
import { allocateQuota, requestQuotaApi, UnAllocateQuota } from "../../../api/manageQuota";
import { getOrgDetails } from "../../../api/companyProfile";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import CustomOtpBox from "../../AdvancedComponents/CustomOtpBox";

const AllQuota = () => {
  const [orgData, setOrgData] = useState();
  const [quotaResponse, setQuotaResponse] = useState();
  const [quota, setQuota] = useState();
  const [allocate, setAllocate] = useState("Allocate");
  const [dateChange, setDateChange] = useState("");
  const [openOtpBox, SetOpenOtpBox] = useState(false)

  const [editTotal, setEditTotal] = useState({
    inviteAccess: "",
    jobPostingAccess: "",
    userAccess: "",
    otp:''
  });

const otpVerifyData = {
  organizationId:localStorage.getItem('organizationId')
}

  const [editAccess, setEditAccess] = useState({
    inviteAccess: false,
    jobPostingAccess: false,
    userAccess: false,
  });

  
  const handleOpenOtpBox = () => {
    SetOpenOtpBox(true)
  }

  const handleOtpBoxClose = () => {
    SetOpenOtpBox(false)
    setEditTotal({
      inviteAccess: "",
      jobPostingAccess: "",
      userAccess: "",
      otp:''
    })
  }
console.log(quotaResponse)
  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");

  const handleEditToggle = (field) => {
    setEditAccess((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const tableTitles = [
    { id: 1, value: "Quota" },
    { id: 2, value: "Total" },
    { id: 3, value: "Used" },
    { id: 4, value: "Balance" },
  ];

  const updateQuota = async () => {
    const formData = {
      organizationId: localStorage.getItem("organizationId"),
      jobPostingQty: editTotal?.jobPostingAccess,
      invitesQty: editTotal?.inviteAccess,
      userAccessQty: editTotal?.userAccess,
      otp: editTotal?.otp,
    };
    try {
      if (orgId) {
        let response;
        if (allocate === "Allocate") {
          response = await allocateQuota(token, formData);
          setQuotaResponse(response);
          if (response?.data) {
            toast.success(response.message);
          } else {
            setQuotaResponse(response);
            toast.error(response?.message || "Insufficient Quota");
          }
        } else {
          response = await UnAllocateQuota(token, formData);
          setQuotaResponse(response);
          if (response?.data) {
            toast.success(response.message);
          } else {
            setQuotaResponse(response);
            toast.error(response?.message || "Insufficient Quota");
          }
        }
  
        const companyDetails = await getOrgDetails(orgId, token);
        setOrgData(companyDetails);
        setQuota(companyDetails.globalQuotas);
  
        setEditAccess({
          inviteAccess: false,
          jobPostingAccess: false,
          userAccess: false,
        });
        setEditTotal({
          inviteAccess: "",
          jobPostingAccess: "",
          userAccess: "",
          otp: "",
        });
  
        handleOtpBoxClose();
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to update quota. Please try again.");
    }
  };
  const requestQuota = async () => {
    try {
      const response = await requestQuotaApi(token, otpVerifyData);
      if (response) {
        toast.success(response.message);
        handleOpenOtpBox();
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to request quota. Please try again.");
    }
  };

  const fetchOrgDetails = useCallback(async () => {
    try {
      if (orgId) {
        const companyDetails = await getOrgDetails(orgId, token);
        setOrgData(companyDetails);
        setQuota(companyDetails.globalQuotas);
      } else {
        throw new Error("No organization id");
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [orgId, token]);

  useEffect(() => {
    fetchOrgDetails();
  }, [fetchOrgDetails]);


  console.log('edittotal',editTotal)


  return (
    <Box sx={{ padding: 1 }}>
      <Box sx={{ marginBottom: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", lineHeight: 0.5,mt:2 }}
          >
            Allocated Quota
          </Typography>
          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dateChange}
              onChange={(e) => setDateChange(e.target.value)}
              sx={{ height: 30, width: 130 }}
              displayEmpty
            >
              {/* Ensure the default value matches the value set in useState */}
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              <MenuItem value="7days">7 Days</MenuItem>
              <MenuItem value="30days">30 Days</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Typography
          variant="h6"
          component="h1"
          sx={{ ...manageQuota.typography }}
        >
          Note: A week is considered from Monday to Sunday. The numbers shown
          below include unused quota (if any) from previous weeks. We recommend
          that you change your quota release type to ‘Weekly’ only on a Monday.
          If you make additional payment against your subscription or purchase
          top-up packs, the additional quota credits will automatically get
          reallocated.
        </Typography>
      </Box>

      <Box>
        <Box sx={{ display: "flex", gap: 3 }}>
          <Paper sx={{ ...manageQuota.paper }}>
            <Paper elevation={0}>
              <Table>
                <TableHead>
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={allocate}
                      onChange={(e) => setAllocate(e.target.value)}
                      sx={{ height: 30, width: 130 }}
                    >
                      <MenuItem value="Allocate">Allocate</MenuItem>
                      <MenuItem value="UnAllocate">UnAllocate</MenuItem>
                    </Select>
                  </FormControl>
                  <TableRow>
                    {tableTitles.map((title) => (
                      <TableCell
                        key={title.id}
                        sx={{ fontWeight: "medium", opacity: "0.8" }}
                      >
                        {title.value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {["inviteAccess", "jobPostingAccess", "userAccess"].map(
                    (field, index) => (
                      <TableRow key={field}>
                        <TableCell component="th" scope="row">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1">
                              {field === "inviteAccess"
                                ? "Invite Access"
                                : field === "jobPostingAccess"
                                  ? "Job Posting Access"
                                  : "User Access"}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            {editAccess[field] ? (
                              <Box sx={{ ...searchCandidates.flexboxCenter }}>
                                <TextField
                                  placeholder={quota?.[field]}
                                  key={field}
                                  variant="outlined"
                                  type="number"
                                  value={editTotal[field]}
                                  sx={{ ...manageQuota.textField }}
                                  onChange={(e) => {
                                    setEditTotal((prev) => ({
                                      ...prev,
                                      [field]: Number(e.target.value),
                                    }));
                                  }}
                                />
                                <CloseIcon
                                  sx={{ ...manageQuota.closeicon }}
                                  onClick={() => handleEditToggle(field)}
                                />
                              </Box>
                            ) : (
                              <Box sx={{ ...searchCandidates.flexboxCenter }}>
                                <Typography variant="body1">
                                  {quota?.[field] ? quota[field] : ""}
                                </Typography>
                                <EditIcon
                                  onClick={() => handleEditToggle(field)}
                                  sx={{ ...manageQuota.editicon }}
                                />
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1">
                              {field === "inviteAccess"
                                ? quota?.usedInviteAccess
                                : field === "jobPostingAccess"
                                  ? quota?.usedJobPostingAccess
                                  : quota?.usedUserAccess}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1">
                              {quota?.[field] -
                                (field === "inviteAccess"
                                  ? quota?.usedInviteAccess
                                  : field === "jobPostingAccess"
                                    ? quota?.usedJobPostingAccess
                                    : quota?.usedUserAccess)}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
              <Box sx={{ ...manageQuota.buttonBox }}>
                <Button
                  variant="contained"
                  sx={{ ...manageQuota.internalCss }}
                  onClick={requestQuota}
                >
                  Get OTP
                </Button>
              </Box>
            </Paper>
          </Paper>
          <UsageReport orgData={orgData} />
        </Box>
      </Box>
 
      <CustomOtpBox openOtpBox={openOtpBox} handleOtpBoxClose={handleOtpBoxClose} setEditTotal={setEditTotal} updateQuota={updateQuota}/>
    </Box>
  );
};

export default AllQuota;  
