import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import baseURL from '../../../api/baseUrl';
import CustomSnackbar from '../../AdvancedComponents/CustomSnackbar';

const JobSeekerDeleteAccount = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'success', // Can be 'success' or 'error'
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDeleteAccount = async () => {
    const authToken = localStorage.getItem("authToken");

    if (!authToken) {
      setSnackbar({
        open: true,
        message: "User not authenticated. Please log in.",
        type: 'error',
      });
      return;
    }

    try {
      const response = await fetch(`${baseURL}/deleteAccount`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Pass authToken in headers
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete account");
      }

      const result = await response.json();
      console.log("Account deleted successfully:", result);

      setSnackbar({
        open: true,
        message: result?.message || "Your account deletion request has been recieved.",
        type: 'success',
      });
    } catch (error) {
      console.error("Error deleting account:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete account. Please try again.",
        type: 'error',
      });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          display: 'flex',
          mt: 4,
          ml: 'auto',
          backgroundColor: 'red', // Set button background color
          '&:hover': {
            backgroundColor: 'darkred', // Change color on hover
          },
        }}
        startIcon={<DeleteIcon />} // Add the delete icon
        onClick={handleDeleteAccount} // Handle button click
      >
        <Typography>Delete your Account</Typography>
      </Button>

      {/* Integrate CustomSnackbar */}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={handleSnackbarClose}
      />
    </>
  );
};

export default JobSeekerDeleteAccount;
