import baseURL from "./baseUrl";

export const getAllSubsciptions = async (orgId, token) => {
  try {
    const response = await fetch(`${baseURL}/subscriptions/${orgId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};



export const getAllPurchasedSubsriptions = async (orgId, token) => {
  try {
    const response = await fetch(`${baseURL}/purhasedsubscriptions/${orgId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};


export const upgradePlanApi = async (token,formValues) => {
  try {
    const response = await fetch(`${baseURL}/upgrade_subscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error.message);
  }
};


export const getInvoicesApi = async(orgId,token)=>{
  try {
    const response = await fetch(`${baseURL}/orgInvoices/${orgId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}



export const getInvoiceByIdApi= async(invoiceId,token)=>{
  try {
    const response = await fetch(`${baseURL}/invoices/${invoiceId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}



export const contactSales = async (token) => {
  try {
    const response = await fetch(`${baseURL}/createOrgSalesTicket`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error.message);
  }
};