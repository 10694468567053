
import baseURL from "./baseUrl";

export const sendInviteApi = async (token, formValues) => {
  try {
    const response = await fetch(`${baseURL}/sendInvite`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    });

    // Handle non-OK responses
    if (!response.ok) {
      const errorData = await response.json(); // Parse the error response
      let errorMessage = "An error occurred while sending invites";
      console.log('send inviteapi is called')
      // Customize error messages based on status codes
      switch (response.status) {
        case 400:
          errorMessage =
            errorData.message || "Bad request. Please check your input.";
          break;
        case 401:
          errorMessage = "Unauthorized. Please log in again.";
          break;
        case 403:
          errorMessage =
            "Forbidden. You do not have permission to perform this action.";
          break;
        case 404:
          errorMessage = "Resource not found.";
          break;
        case 429:
          errorMessage = "Too many requests. Please try again later.";
          break;
        case 500:
          errorMessage = "Internal server error. Please try again later.";
          break;
        default:
          errorMessage = errorData.message || "An unexpected error occurred.";
      }

      // Display the error toast
      
      throw new Error(errorMessage);
    }

    // If the response is OK, parse and return the JSON data
    const data = await response.json();
    return data;

  } catch (error) {
    // Handle any unexpected errors
    console.error("Error in sendInviteApi:", error.message);

    throw error; // Re-throw the error for further handling if needed
  }
};
