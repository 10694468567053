import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { generateRefereshTokenApi } from "../api/jobseeker";

const TOKEN_EXPIRY_THRESHOLD = 300; // 5 minutes in seconds

const ProtectedJobSeekerRoute = ({ children }) => {
  const [isValidToken, setIsValidToken] = useState(null);
  const token = localStorage.getItem("authToken");
  const refreshToken = localStorage.getItem("refreshToken");

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      if (!token) {
        setIsValidToken(false);
        return;
      }

      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);

        // Manually set token expiry to 300 seconds from now
        decodedToken.exp = currentTime + 300; // Simulate token expiring in 5 minutes

        const formdata = {
          refreshToken: refreshToken,
        };

        // Check if the token is expired or about to expire
        if (decodedToken.exp <= currentTime) {
          // Token is expired, attempt to refresh
          const response = await generateRefereshTokenApi(formdata);
          const newToken = response?.accessToken;

          if (newToken) {
            localStorage.setItem("authToken", newToken);
            setIsValidToken(true);
          } else {
            setIsValidToken(false);
            localStorage.removeItem("authToken"); // Clear expired token
          }
        } else if (decodedToken.exp <= currentTime + TOKEN_EXPIRY_THRESHOLD) {
          // Token is about to expire, attempt to refresh
          const response = await generateRefereshTokenApi(formdata);
          const newToken = response?.accessToken;

          if (newToken) {
            localStorage.setItem("authToken", newToken);
            setIsValidToken(true);
          } else {
            setIsValidToken(false);
            localStorage.removeItem("authToken"); // Clear expired token
          }
        } else {
          // Token is still valid
          setIsValidToken(true);
        }
      } catch (error) {
        setIsValidToken(false);
        localStorage.removeItem("authToken"); // Clear expired token
      }
    };

    checkAndRefreshToken();
  }, [token, refreshToken]);

  if (isValidToken === null) {
    return null; // Loading state
  }

  if (!isValidToken) {
    return <Navigate to="/signinsignup" />;
  }

  return children;
};

export default ProtectedJobSeekerRoute;