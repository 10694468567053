import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  useMediaQuery,
  Switch,
  // Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import MultiSelect from "../../../components/AdvancedComponents/AdvancedMultiSelect";
import CheckBoxSelect from "../../../components/AdvancedComponents/CheckBoxSelect";
import SingleSelect from "../../../components/AdvancedComponents/AdvancedSingleSelect";
import DatabaseCreditsInfo from "./DatabaseCreditsInfo";
import RecentSearches from "./RecentSearches";
// import { ActiveFilter } from "../../../components/AdvancedComponents/ActiveFilter";
import searchCandidates from "../../../styles/searchCandidatesStyles";
import AddItSkills from "../../../components/AdvancedComponents/AddItSkills";
import AddIcon from "@mui/icons-material/Add";
import EmploymentDetails from "../../../components/AdvancedComponents/EmploymentDetails";
import EducationDetails from "../../../components/AdvancedComponents/EducationDetails";
import AdditionalDetails from "../../../components/AdvancedComponents/AdditionalDetails";
import { savedSearchesApi } from "../../../api/search";
import SearchCandidateVideos from "./SearchCandidiateVideos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import SavedSearches from "./SavedSearches";

// import { SearchKeywordFilter } from "../../../components/AdvancedComponents/SearchKeywordFilter";

const SearchCandidate = () => {
  const navigate = useNavigate();
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [experienceType, setExperienceType] = useState("");
  const [prefferedType, setPrefferedType] = useState("Video");
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [prefferedCities, setPrefferedCities] = useState([]);
  const [boolean, setBoolean] = useState(false);
  const [addItSkillsForm, setAddItSkillsForm] = useState(false);
  const [recentSearch, setRecentSearch] = useState(null);
  const [addSkills, setAddskills] = useState([]);
  const [gender, setGender] = useState("");
  const [shouldntHave, setShouldntHave] = useState([]);
  // const [savedSearch,setSavedSearch] = useState(null)
  // const location = useLocation();

  const [age, setAge] = useState({
    minAge: "",
    maxAge: "",
  });
  const [jobType, setJobType] = useState();
  const [prefferedJobType, setPrefferedJobType] = useState();
  // const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [formData, setFormData] = useState({
    minimumEducation: "",
    activeIn: "",
    minExperience: null,
    maxExperience: null,
    minSalary: null,
    maxSalary: null,
    industry: null,
  });
  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId");

  useEffect(() => {
    const savedSearchData = JSON.parse(localStorage.getItem("savedSearchData"));

    if (savedSearchData) {
      const { searchQuery } = savedSearchData;

      // Populate the search fields
      setSelectedKeywords(splitStringToArray(searchQuery?.keyword));
      setSelectedCities(searchQuery?.currentLocation);
      setPrefferedCities(searchQuery?.preferredLocation);

      setSelectedIndustry(searchQuery?.industry);
      setGender(searchQuery?.gender);
      setAge({ minAge: searchQuery?.minAge, maxAge: searchQuery?.maxAge });
      setJobType(searchQuery?.jobType);
      setShouldntHave(searchQuery?.shouldntHave);

      setFormData((prevFormData) => ({
        ...prevFormData,
        minExperience: searchQuery.minExperience || null,
        maxExperience: searchQuery.maxExperience || null,
        minSalary: searchQuery.minSalary || null,
        maxSalary: searchQuery.maxSalary || null,
        minAge: searchQuery.minAge || "",
        maxAge: searchQuery.maxAge || "",
        jobType: searchQuery.jobType || "",
        preferredJobType: searchQuery.preferredJobType || "",
      }));

      // Clear the saved search data from localStorage after use
    }
    localStorage.removeItem("savedSearchData");
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleBooleanChange = () => {
    setBoolean((prev) => !prev);
  };

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleCitySelectionChange = useCallback((cities) => {
    setSelectedCities(cities);
  }, []);

  const handlePrefferedCities = useCallback((cities) => {
    setPrefferedCities(cities);
  }, []);

  const handleSelectIndustry = useCallback((industry) => {
    setSelectedIndustry(industry);
  }, []);
  // const handleSelectDepartment = useCallback((department) => {
  //   setSelectedIndustry(department.map((dep) => dep.value).join(","));
  // }, []);

  const handleAddItSkillsTrigger = useCallback(() => {
    setAddItSkillsForm(true);
  }, []);

  const handleRemoveOption = useCallback(
    (option) => {
      setSelectedKeywords((prev) => prev.filter((item) => item !== option));
    },
    [setSelectedKeywords]
  );

  //select job type
  const handleJobType = (value) => {
    setJobType(value);
  };
  const handlePrefferedJobType = (value) => {
    setPrefferedJobType(value);
  };
  // recent search results
  const fetchRecentSearch = useCallback(async () => {
    try {
      const response = await savedSearchesApi(orgId, token);
      setRecentSearch(response);
    } catch (error) {
      console.log(error.message);
    }
  }, [orgId, token]);

  useEffect(() => {
    fetchRecentSearch();
  }, [fetchRecentSearch]);

  // saved search results

  //  const fetchSavedSearches = useCallback(async () => {
  //   const formvalues = {
  //     recruiterId: localStorage.getItem('recruiterId'),
  //     organizationId: localStorage.getItem('organizationId'),
  //   };
  //   try {
  //     const response = await getSavedSearchApi(token, formvalues);
  //     setSavedSearch(response.data);
  //   } catch (error) {
  //     console.error('Error fetching saved searches:', error.message);
  //   }
  // }, [token]);
  // useEffect(() => {
  //   fetchSavedSearches();
  // }, [fetchSavedSearches]);

  const splitStringToArray = (str) => {
    if (typeof str !== "string") {
      return []; // Return an empty array if `str` is not a string
    }
    return str.split(",").map((item) => item.trim());
  };

  const handleFillSearch = useCallback((searchData) => {
    const keywords = splitStringToArray(searchData?.searchQuery?.keyword);
    const currentLocation = splitStringToArray(
      searchData?.searchQuery?.currentLocation
    );
    const preferredCities = splitStringToArray(
      searchData?.searchQuery?.preferredLocation
    );
    const experienceType = searchData?.searchQuery?.experienceType || [""];

    setSelectedKeywords(keywords);
    setSelectedCities(currentLocation);
    setPrefferedCities(preferredCities);
    setExperienceType(experienceType);
    setValidationError(false);
  }, []);

  const buildSearchParams = useCallback(
    (searchData) => {
      const keywords =
        selectedKeywords?.length > 0
          ? selectedKeywords
          : splitStringToArray(searchData?.searchQuery?.keyword);
      const currentLocation =
        selectedCities?.length > 0
          ? selectedCities
          : splitStringToArray(searchData?.searchQuery?.currentLocation);
      const preferredLocation =
        prefferedCities?.length > 0
          ? prefferedCities
          : splitStringToArray(searchData?.searchQuery?.preferredLocation);
      const industry =
        selectedIndustry?.length > 0
          ? selectedIndustry
          : splitStringToArray(searchData?.searchQuery?.industry);

      // Create an object to hold all query parameters
      const params = {
        experienceType:
          experienceType || searchData?.searchQuery?.experienceType || "",
        keyword: keywords.join(","),
        currentLocation: currentLocation.join(","),
        skills: addSkills.join(","),
        preferredLocation: preferredLocation.join(","),
        industry: industry.join(","),
        booleanSearch: boolean,
        minimumEducation: formData.minimumEducation || "",
        activeIn: formData.activeIn || "",
        totalExpMin: formData.minExperience ? formData.minExperience : "",
        totalExpMax: formData.maxExperience ? formData.maxExperience : "",
        minSalary: formData.minSalary ? formData.minSalary : "",
        maxSalary: formData.maxSalary ? formData.maxSalary : "",
        minAge: age.minAge || "",
        maxAge: age.maxAge || "",
        jobType: jobType || "",
        preferredJobType: prefferedJobType || "",
        gender: gender || "",
      };

      // const handleShouldntHave = ()=>{

      // }

      if (shouldntHave?.length > 0) {
        params.shouldntHave = JSON.stringify(shouldntHave); // Keep it as an array

       
      }

      // Manually construct the query string


      const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    return queryString;
    },
    [
      selectedKeywords,
      selectedCities,
      prefferedCities,
      experienceType,
      selectedIndustry,
      formData,
      age,
      boolean,
      jobType,
      prefferedJobType,
      addSkills,
      gender,
      shouldntHave,
    ]
  );

  const handleSearch = useCallback(
    (searchData) => {
      const keywords =
        selectedKeywords.length > 0
          ? selectedKeywords
          : splitStringToArray(searchData?.searchQuery?.keyword);

      if (keywords.length > 0) {
        setValidationError(false); // Clear validation error if keywords exist

        const queryParams = buildSearchParams(searchData);
        console.log("query", queryParams);

        // navigate(`/candidate-searchList?${queryParams}`);
      } else {
        setValidationError(true); // Show validation error if no keywords
      }
    },
    [selectedKeywords, buildSearchParams]
  );

  const fontStyles = {
    fontSize: 13,
    fontWeight: "regular",
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <EmployerDrawer>
      <Box>
        <Typography
          onClick={handleBack}
          gutterBottom
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            mb: "15px",
            color: "grey", // Adjust color for better visibility
            "&:hover": {
              color: "primary.dark", // Hover effect
            },
          }}
        >
          <ArrowBackIcon sx={{ marginRight: "4px" }} />
        </Typography>
      </Box>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Search Candidates
      </Typography>
      <Box sx={searchCandidates.parentBox}>
        <Paper sx={{ ...searchCandidates.paper }} elevation={0}>
          <FormControl component="fieldset" sx={{ mb: 3 }}>
            <FormLabel component="legend">Preferred Search</FormLabel>
            <RadioGroup
              row
              value={prefferedType}
              onChange={(e) => setPrefferedType(e.target.value)}
            >
              <FormControlLabel
                value="Video"
                control={<Radio />}
                label="Video Only"
              />
              <FormControlLabel value="All" control={<Radio />} label="All" />
            </RadioGroup>
          </FormControl>
          {prefferedType === "Video" && <SearchCandidateVideos />}

          <FormControl component="fieldset" sx={{ mb: 3 }}>
            <FormLabel component="legend">Searching for</FormLabel>
            <RadioGroup
              row
              value={experienceType}
              onChange={(e) => setExperienceType(e.target.value)}
            >
              <FormControlLabel
                value="fresher"
                control={<Radio />}
                label="Freshers"
              />
              <FormControlLabel
                value="experienced"
                control={<Radio />}
                label="Experienced Only"
              />

              <FormControlLabel value={""} control={<Radio />} label="Any" />
            </RadioGroup>
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={searchCandidates.flexBox}>
                <Typography component="legend" sx={fontStyles} gutterBottom>
                  Keywords
                </Typography>
                <span style={{ fontSize: 12 }}>
                  <Switch
                    size="small"
                    onChange={handleBooleanChange}
                    checked={boolean}
                  />
                  Boolean {`${boolean ? "On" : "Off"}`}
                </span>
              </Box>

              <MultiSelect
                placeholder="Enter keywords such as Name,Skills etc"
                selectedOptions={selectedKeywords}
                setSelectedOptions={setSelectedKeywords}
                validationError={validationError}
                setValidationError={setValidationError}
                savedSearch={recentSearch}
                handleRemoveOption={handleRemoveOption}
                shouldntHave={shouldntHave}
                setShouldntHave={setShouldntHave}
                setBoolean={setBoolean}
                boolean={boolean}
              />
              <Box sx={searchCandidates.flexBox}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 1,
                  }}
                >
                  {/* <Checkbox size="small" sx={{ padding: 0 }} />
                  <span style={{ fontSize: 12, color: "gray", marginTop: 1 }}>
                    Mark all keywords as mandatory
                  </span> */}
                </Box>

                {/* <SearchKeywordFilter/> */}
              </Box>
            </Grid>
            <Typography
              sx={searchCandidates.addItskills}
              onClick={handleAddItSkillsTrigger}
            >
              <AddIcon />
              Add IT Skills
            </Typography>
            <Grid item xs={12}>
              {addItSkillsForm && (
                <AddItSkills
                  setAddItSkillsForm={setAddItSkillsForm}
                  addSkills={addSkills}
                  setAddskills={setAddskills}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <CheckBoxSelect
                onCurrentCity={handleCitySelectionChange}
                onPrefferedCity={handlePrefferedCities}
                initialSelectedCities={selectedCities}
                initialPreferredCities={prefferedCities}
              />
            </Grid>
            {experienceType !== "Freshers" && (
              <Grid item xs={12}>
                <SingleSelect
                  formData={formData}
                  handleChange={handleChange}
                  initialMinExp={formData.minExperience}
                  initialMaxExp={formData.maxExperience}
                  initialMinSalary={formData.minSalary}
                  initialMaxSalary={formData.maxSalary}
                />
              </Grid>
            )}
          </Grid>
          {experienceType !== "Freshers" && (
            <EmploymentDetails
              onSelectIndusty={handleSelectIndustry}
              initialIndustry={selectedIndustry}
            />
          )}

          <EducationDetails />
          <AdditionalDetails
            setAge={setAge}
            initialMinAge={age.minAge}
            initialMaxAge={age.maxAge}
            handleJobType={handleJobType}
            handlePrefferedJobType={handlePrefferedJobType}
            jobType={jobType}
            prefferedJobType={prefferedJobType}
            gender={gender}
            setGender={setGender}
          />
          <Box sx={searchCandidates.searchParentBox}>
            {/* <ActiveFilter /> */}
            <Button
              onClick={handleSearch}
              variant="contained"
              color="primary"
              sx={searchCandidates.searchButton}
            >
              Search candidates
            </Button>
          </Box>
        </Paper>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!isMobile && <DatabaseCreditsInfo />}

          <RecentSearches
            savedSearch={recentSearch}
            handleFillSearch={handleFillSearch}
            handleSearch={handleSearch}
          />
          {/* <SavedSearches
            savedSearch={savedSearch}
            handleFillSearch={handleFillSearch} 
            handleSearch={handleSearch}
          /> */}
        </Box>
      </Box>
    </EmployerDrawer>
  );
};

export default SearchCandidate;
