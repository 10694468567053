import React, { useState, useEffect, Suspense, lazy } from "react";
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search"; // Import search icon
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import baseURL from "../../../api/baseUrl";
import LogoCarouselSkeleton from "../../../utils/lazy/CarouselSkeleton";
import JobCardSkeleton from "../../../utils/lazy/JobCardSkeleton";
import FilterSkeleton from "../../../utils/lazy/FilterSkeleton";
import { useLocation, useNavigate } from "react-router-dom";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

// Lazy-loaded components
const Filters = lazy(() =>
  import(
    "../../../components/JobSeekerComponents/JobSeekerFilters/Filters/FiltersJobSeeker/Filters"
  )
);
const JobCard = lazy(() =>
  import("../../../components/JobSeekerComponents/FindJob/JobCard")
);
const JobSeekerJobDetails = lazy(() =>
  import("../JobSeekerJobDetails/JobSeekerJobDetails")
);
const LogoCarousel = lazy(() =>
  import("../../../components/JobSeekerComponents/FindJob/LogoCarousel")
);

const JobSeekerFilter = () => {
  const [datePosted, setDatePosted] = useState("");
  const [jobs, setJobs] = useState([]);
  const [allJobData, setAllJobData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedJob, setSelectedJob] = useState();
  const [highestEducation, setHighestEducation] = useState("graduate");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState("newToOld");
  const [departments, setDepartments] = useState([]);
  const [experience, setExperience] = useState(0);
  const [salary, setSalary] = useState("");
  const [industry, setIndustry] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [jobType, setJobType] = useState("");
  const [minCTC, setMinCTC] = useState(0);
  const [maxCTC, setMaxCTC] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState(location.state?.keyword || "");
  const [jobCity, setJobCity] = useState(location.state?.jobCity || "");
  const [searchKeywords,setSearchKeywords] = useState('')

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchKeywords(event.target.value);
  };
  

  // Handle search submission
  const handleSearchSubmit = () => {
    setCurrentPage(1); 
    setKeyword(searchKeywords)
  };

  const handlekeyDown = (event)=>{
   if(event.key === 'Enter'){
    handleSearchSubmit()
   }
  }
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  useEffect(() => {
    const fetchJobs = async (limit = 10) => {
      const authToken = localStorage.getItem("authToken");
      let queryParams = "";

      // Construct query parameters
      if (keyword) queryParams += `keyword=${encodeURIComponent(keyword)}&`;
      if (jobCity) queryParams += `jobLocation=${encodeURIComponent(jobCity)}&`;
      if (currentPage) queryParams += `page=${currentPage}&`;
      if (sortBy) queryParams += `postDateFilter=${sortBy}&`;
      if (departments.length > 0)
        queryParams += `department=${encodeURIComponent(
          departments.join(",")
        )}&`;
      if (experience) queryParams += `requiredExperience=${experience}&`;
      if (salary) queryParams += `salarySort=${salary}&`;
      if (industry)
        queryParams += `industry=${encodeURIComponent(industry.join(","))}&`;
      if (selectedLocations)
        queryParams += `jobLocation=${encodeURIComponent(
          selectedLocations.join(",")
        )}&`;
      if (jobType) queryParams += `jobType=${jobType}&`;
      if (minCTC) queryParams += `minCTC=${minCTC}&`;
      if (maxCTC) queryParams += `maxCTC=${maxCTC}&`;
      if (limit) queryParams += `limit=${limit}`;

      // Construct the URL
      const url = `${baseURL}/jobs/all${queryParams ? `?${queryParams}` : ""}`;

      try {
        setLoading(true);
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setAllJobData(data); // Set all job data
        setJobs(data.data); // Set jobs data
        setTotalPages(data.totalPages); // Set total pages
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchJobs();
  }, [
    sortBy,
    currentPage,
    departments,
    experience,
    salary,
    industry,
    selectedLocations,
    jobType,
    maxCTC,
    minCTC,
    jobCity,
    keyword,
  ]);

  // Sorting callback
  const handleSortChange = (newSort) => {
    setSortBy(newSort);
    setCurrentPage(1);
  };

  // Filter by department
  const handleDepartmentChange = (newDepartment) => {
    setDepartments(newDepartment);
    setCurrentPage(1);
  };

  // Experience change handler
  const handleExperienceChange = (newValue) => {
    setExperience(newValue);
    setCurrentPage(1);
  };

  // Industry change handler
  const handleIndustryChange = (newIndustry) => {
    setIndustry(newIndustry);
    setCurrentPage(1);
  };

  // Salary change handler
  const handleSalaryChange = (newSalary) => {
    setSalary(newSalary);
  };

  // Location change handler
  const handleLocationChange = (newLocation) => {
    setSelectedLocations(newLocation);
    setCurrentPage(1);
  };

  // Job type change handler
  const handleJobTypeChange = (jobType) => {
    setJobType(jobType);
    setCurrentPage(1);
  };

  // CTC change handler
  const handleCTCRangeChange = (newRange) => {
    setMinCTC(newRange[0]); // Update minCTC
    setMaxCTC(newRange[1]); // Update maxCTC
  };

  // Clear keyword and job city
  const clearKeywordAndJobCity = () => {
    setKeyword("");
    setJobCity("");
    navigate(location.pathname, { replace: true, state: {} });
  };

  // Handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page); // Update current page
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
  };

  // Calculate job range for pagination
  const startJob = (currentPage - 1) * 10 + 1;
  const endJob = Math.min(currentPage * 10, allJobData?.totalJobPosts || 0);

  // Handle job selection
  const handleSelectJob = (id) => {
    setSelectedJobId(id); // Only set the selectedJobId
  };

  // Update selected job when selectedJobId changes
  useEffect(() => {
    if (selectedJobId) {
      const selectedJob = jobs.find((job) => job._id === selectedJobId);
      setSelectedJob(selectedJob); // Update selectedJob
    }
  }, [selectedJobId, jobs]);

  console.log('keyword',keyword)

  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #F8F9FA, #CED4DA)",
        display: "flex",
        margin: 0,
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NewNavBar />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          padding: (theme) => theme.spacing(4),
        }}
      >
        {/* Lazy-loaded LogoCarousel */}
        <Suspense fallback={<LogoCarouselSkeleton />}>
          <LogoCarousel />
        </Suspense>

        {/* Search Bar */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          <TextField
            fullWidth
            placeholder="Search jobs by title, keyword, or company..."
            value={searchKeywords}
            onChange={handleSearchChange}
            onKeyDown={handlekeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchSubmit}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                borderRadius: "25px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                color:"#3F3F3FFF",
                fontSize:"16px",
                px:"10px"
              },
            }}
          />
        </Box>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={3}>
            {/* Lazy-loaded Filters */}
            <Suspense fallback={<FilterSkeleton />}>
              <Filters
                handleSortChange={handleSortChange}
                sortBy={sortBy}
                departments={departments}
                handleDepartmentChange={handleDepartmentChange}
                handleExperienceChange={handleExperienceChange}
                experience={experience}
                salary={salary}
                handleSalaryChange={handleSalaryChange}
                industry={industry}
                handleIndustryChange={handleIndustryChange}
                selectedLocations={selectedLocations}
                handleLocationChange={handleLocationChange}
                jobType={jobType}
                handleJobTypeChange={handleJobTypeChange}
                handleCTCRangeChange={handleCTCRangeChange}
                minCTC={minCTC}
                maxCTC={maxCTC}
                datePosted={datePosted}
                onDatePostedChange={setDatePosted}
                highestEducation={highestEducation}
                onHighestEducationChange={setHighestEducation}
                keyword={keyword}
                jobCity={jobCity}
                clearKeywordAndJobCity={clearKeywordAndJobCity}
              />
            </Suspense>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "linear-gradient(250deg #CED4DA)",
                borderBottom: "1px solid #e0e0e0",
                fontFamily: "IBM Plex Sans, sans-serif",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#666",
                  fontFamily: "IBM Plex Sans, sans-serif",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                {startJob} - {endJob} of {allJobData?.totalJobPosts || 0} Jobs
              </Typography>

              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            </Box>

            {/* Lazy-loaded JobCard */}
            <Suspense fallback={<JobCardSkeleton />}>
              <JobCard jobs={jobs} handleSelectJob={handleSelectJob} />
            </Suspense>

            {/* Lazy-loaded JobSeekerJobDetails */}
            {!loading && !error && selectedJobId && (
              <Suspense fallback={<CircularProgress />}>
                <JobSeekerJobDetails job={selectedJob} />
              </Suspense>
            )}
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default JobSeekerFilter;