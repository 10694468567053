import React from "react";
import { Box, Skeleton } from "@mui/material";

const JobCardSkeleton = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2,alignItems:"center",justifyContent:"center" }}>
      <Box
        sx={{
          mt: 1,
          p: 3,
          bgcolor: "white",
          borderRadius: "20px",
          boxShadow: "0 6px 12px rgba(30, 10, 58, .04)",
          marginBottom: 3,
          padding: "24px 24px 20px",
          transition: "box-shadow .2s linear",
          fontFamily: "IBM Plex Sans, sans-serif",
          position: "relative",
          display: "flex",
          width:'650px',
          gap: 3,
        }}
      >
        {/* Left Side - Video Player Skeleton */}
        <Box
          sx={{
            width: "160px",
            height: "190px",
            borderRadius: 4,
            overflow: "hidden",
            bgcolor: "#f0f0f0",
            flexShrink: 0,
            position: "relative",
            background:
              "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
            backgroundSize: "200% 100%",
            animation: "shimmer 5s infinite",
          }}
        >
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </Box>

        {/* Right Side - Job Content Skeleton */}
        <Box sx={{ flex: 1 }}>
          {/* Header Section Skeleton */}
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Box>
              <Skeleton
                variant="text"
                width="60%"
                height={30}
                sx={{
                  background:
                    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 5s infinite",
                }}
              />
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0.5 }}
              >
                <Skeleton
                  variant="text"
                  width="40%"
                  height={20}
                  sx={{
                    background:
                      "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                    backgroundSize: "200% 100%",
                    animation: "shimmer 5s infinite",
                  }}
                />
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <Skeleton
                    variant="text"
                    width="20%"
                    height={20}
                    sx={{
                      background:
                        "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                      backgroundSize: "200% 100%",
                      animation: "shimmer 5s infinite",
                    }}
                  />
                  <Skeleton
                    variant="text"
                    width="30%"
                    height={20}
                    sx={{
                      background:
                        "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                      backgroundSize: "200% 100%",
                      animation: "shimmer 5s infinite",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Skeleton
              variant="circular"
              width={45}
              height={45}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 5s infinite",
              }}
            />
          </Box>

          {/* Details Section Skeleton */}
          <Box sx={{ display: "flex", gap: 3, mb: 2 }}>
            <Skeleton
              variant="text"
              width="30%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 5s infinite",
              }}
            />
            <Skeleton
              variant="text"
              width="20%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 5s infinite",
              }}
            />
            <Skeleton
              variant="text"
              width="25%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 5s infinite",
              }}
            />
          </Box>

          {/* Role Description Skeleton */}
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.8, mb: 2 }}>
            <Skeleton
              variant="text"
              width="100%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 5s infinite",
              }}
            />
            <Skeleton
              variant="text"
              width="80%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 5s infinite",
              }}
            />
          </Box>

          {/* Tags Section Skeleton */}
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 1 }}>
            {[...Array(3)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={60}
                height={24}
                sx={{
                  background:
                    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 5s infinite",
                }}
              />
            ))}
          </Box>

          {/* Footer Section Skeleton */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Skeleton
              variant="text"
              width="30%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 5s infinite",
              }}
            />
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 5s infinite",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 1,
          p: 3,
          bgcolor: "white",
          borderRadius: "20px",
          boxShadow: "0 6px 12px rgba(30, 10, 58, .04)",
          marginBottom: 3,
          padding: "24px 24px 20px",
          transition: "box-shadow .2s linear",
          fontFamily: "IBM Plex Sans, sans-serif",
          position: "relative",
          display: "flex",
          gap: 3,
        }}
      >
        {/* Left Side - Video Player Skeleton */}
        <Box
          sx={{
            width: "160px",
            height: "190px",
            borderRadius: 4,
            overflow: "hidden",
            bgcolor: "#f0f0f0",
            flexShrink: 0,
            position: "relative",
            background:
              "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
            backgroundSize: "200% 100%",
            animation: "shimmer 1.5s infinite",
          }}
        >
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </Box>

        {/* Right Side - Job Content Skeleton */}
        <Box sx={{ flex: 1 }}>
          {/* Header Section Skeleton */}
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Box>
              <Skeleton
                variant="text"
                width="60%"
                height={30}
                sx={{
                  background:
                    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 1.5s infinite",
                }}
              />
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0.5 }}
              >
                <Skeleton
                  variant="text"
                  width="40%"
                  height={20}
                  sx={{
                    background:
                      "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                    backgroundSize: "200% 100%",
                    animation: "shimmer 1.5s infinite",
                  }}
                />
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <Skeleton
                    variant="text"
                    width="20%"
                    height={20}
                    sx={{
                      background:
                        "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                      backgroundSize: "200% 100%",
                      animation: "shimmer 1.5s infinite",
                    }}
                  />
                  <Skeleton
                    variant="text"
                    width="30%"
                    height={20}
                    sx={{
                      background:
                        "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                      backgroundSize: "200% 100%",
                      animation: "shimmer 1.5s infinite",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Skeleton
              variant="circular"
              width={45}
              height={45}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.5s infinite",
              }}
            />
          </Box>

          {/* Details Section Skeleton */}
          <Box sx={{ display: "flex", gap: 3, mb: 2 }}>
            <Skeleton
              variant="text"
              width="30%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.5s infinite",
              }}
            />
            <Skeleton
              variant="text"
              width="20%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.5s infinite",
              }}
            />
            <Skeleton
              variant="text"
              width="25%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.5s infinite",
              }}
            />
          </Box>

          {/* Role Description Skeleton */}
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.8, mb: 2 }}>
            <Skeleton
              variant="text"
              width="100%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.5s infinite",
              }}
            />
            <Skeleton
              variant="text"
              width="80%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.5s infinite",
              }}
            />
          </Box>

          {/* Tags Section Skeleton */}
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 1 }}>
            {[...Array(3)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={60}
                height={24}
                sx={{
                  background:
                    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 1.5s infinite",
                }}
              />
            ))}
          </Box>

          {/* Footer Section Skeleton */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Skeleton
              variant="text"
              width="30%"
              height={20}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.5s infinite",
              }}
            />
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              sx={{
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 5s infinite",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default JobCardSkeleton;
