import React from "react";
import { Box } from "@mui/system";
import { Button, Grid, Paper, Typography } from "@mui/material";
import KYCDetailsModel from "../../../../components/EmployerComponents/CompanyProfileComponents/KYCDdetailsModel";

const KYCComplainsDetails = ({
  openKYCDialog,
  handleKYCDialogClose,
  handleSaveDialog,
  handleKYCEdit,
  orgData,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: "#FFF", padding: 3, marginTop: 2 }}
    >
      <Typography sx={{ fontSize: "large", marginBottom: 2 }}>
        KYC Compliance Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            Name : {orgData?.contactPersonName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            Address : {orgData?.organizationAddress}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            Country : {orgData?.orgCountry}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            State : {orgData?.orgState}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            City : {orgData?.orgCity}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component="legend" sx={{ fontSize: 14 }}>
            Pincode : {orgData?.orgPinCode}
          </Typography>
        </Grid>
      </Grid>
      <KYCDetailsModel
        openKYCDialog={openKYCDialog}
        handleKYCDialogClose={handleKYCDialogClose}
        handleSaveDialog={handleSaveDialog}
      />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button sx={{ borderRadius:'2rem',backgroundColor:'#423bfa'}} variant="contained" onClick={handleKYCEdit}>
          Edit
        </Button>
      </Box>
    </Paper>
  );
};
export default KYCComplainsDetails;
