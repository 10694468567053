import React, { useEffect, useState } from "react";
import baseURL from "../../api/baseUrl";
import { Typography, Container, Grid, Paper } from "@mui/material";

const ProfileActivity = () => {
  const [profileView, setProfileView] = useState(0);
  const [HrContact, setHrContact] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userId = localStorage.getItem("userId");
      try {
        const response = await fetch(`${baseURL}/jobseeker/${userId}`);
        const data = await response.json();
        setHrContact(data.user.hrContactedCount);
        setProfileView(data.user.profileViewCount);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container
      sx={{
        mt: 2,
        maxWidth: "800px", // Set a max width for the container
        paddingLeft: "16px", // Add some padding to the sides
        paddingRight: "16px",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{
          display: "flex",
          gap: 2,
          border: "2px black",
          flexDirection: {
            xs: "row", // For small screens, use flex row
            sm: "row", // For medium screens, also use flex row
            md: "row", // For larger screens, you can use flex row or default grid layout
          },
        }}
      >
        {/* Profile Views */}
        <Grid item xs={6} sm={6} md={12} lg={12} sx={{ border: "2px" }}>
          <Paper
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: 3,
              maxWidth: 350, // Limit max width for large screens
              width: "100%", // Make it responsive
            }}
          >
            <Typography variant="h6" sx={{ fontSize: "1rem" }}>
              Profile Views
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2, fontSize: "1.5rem" }}>
              {profileView}
            </Typography>
          </Paper>
        </Grid>

        {/* HR Contacts */}
        <Grid item xs={6} sm={6} md={12} lg={12} sx={{ border: "2px" }}>
          <Paper
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: 3,
              maxWidth: 350, // Limit max width for large screens
              width: "100%", // Make it responsive
            }}
          >
            <Typography variant="h6" sx={{ fontSize: "1rem" }}>
              HR Contacts
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: "bold", mt: 2, fontSize: "1.5rem" }}>
              {HrContact}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfileActivity;
