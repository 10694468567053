import React, { useRef } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import baseURL from "../../../../api/baseUrl";
import { toast, ToastContainer } from "react-toastify";

const JobSeekerCaseFour = ({ handleChange, formData }) => {
  const fileInputRef = useRef(null);

  const handleProfileDelete = () => {
    handleChange({ target: { name: "profileImage", value: null } });
  };

  const handleProfileEdit = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleProfileFileChange = (event) => {
    const file = event.target.files[0];
    handleChange({ target: { name: "profileImage", value: file } });
  };

  const videoInputRef = useRef("");
  const audioInputRef = useRef("");
  const pdfInputRef = useRef("");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    handleChange({ target: { name, value: file } });
  };

  const handleDelete = (type) => {
    handleChange({ target: { name: type, value: null } });
  };

  const handleSendAppLink = async () => {
    try {
      // Retrieve authToken and userId from localStorage
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        console.error("authToken or userId is missing in localStorage.");
        return;
      }

      const apiUrl = `${baseURL}/sendAppLink/${userId}`;

      // Call the API
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to send app link.");
      }

      const data = await response.json();
      console.log("App link sent successfully:", data);
      toast.success("App link sent successfully");
    } catch (error) {
      console.error("Error sending app link:", error);
      toast.error("Error sending app link");
    }
  };

  const handleEdit = (type) => {
    switch (type) {
      case "videoCV":
        videoInputRef.current.click();
        break;
      case "audioCV":
        audioInputRef.current.click();
        break;
      case "resume":
        pdfInputRef.current.click();
        break;
      default:
        break;
    }
  };

  console.log("formData", formData);

  return (
    <Box sx={{ borderRadius: 4, backgroundColor: "#ffffff", margin: "auto" }}>
      <Typography
        sx={{
          fontWeight: "bold",
          marginBottom: 2,
          textAlign: "left",
        }}
      >
        Profile
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: "flex-start",
          gap: 2, // Adds space between items
          ml: 15,
          mt: 5,
          mb: 5,
          marginBottom: 2,
        }}
      >
        {/* Profile Picture Box */}

        <Box
          sx={{
            flexGrow: 1,
            width: isSmallScreen ? "100%" : "30%", // Adjust width for responsive design
            height: 120,
            backgroundColor: "#f0f0f0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: isSmallScreen ? 2 : 0,
          }}
        >
          {formData.profileImage ? (
            <img
              src={URL.createObjectURL(formData.profileImage)}
              alt="Profile"
              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            />
          ) : (
            <Button
              variant="contained"
              component="label"
              sx={{
                borderRadius: "50%",
                minWidth: 100,
                minHeight: 100,
                backgroundColor: "#d6d6d6",
                boxShadow: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#d6d6d6",
                  boxShadow: "none",
                },
              }}
            >
              <AddIcon sx={{ fontSize: 40, color: "green" }} />
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                name="profileImage"
                onChange={handleProfileFileChange}
                hidden
              />
            </Button>
          )}
        </Box>

        {/* Action Buttons */}
        <Box
          sx={{
            flexGrow: 1,
            width: isSmallScreen ? "100%" : "20%", // Adjust width
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start", // Align items
            gap: 1, // Space between buttons
          }}
        >
          {formData.profileImage && (
            <>
              <IconButton
                onClick={handleProfileDelete}
                size="small"
                aria-label="delete"
                sx={{
                  backgroundColor: "#ffffff",
                  boxShadow: "none",
                  "&:hover": { backgroundColor: "#f5f5f5" },
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={handleProfileEdit}
                size="small"
                aria-label="edit"
                sx={{
                  backgroundColor: "#ffffff",
                  boxShadow: "none",
                  "&:hover": { backgroundColor: "#f5f5f5" },
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", ml: 16 }}>
        {/* File Information Box */}
        <Box
          sx={{
            flexGrow: 1,
            width: isSmallScreen ? "100%" : "0%", // Adjust width
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {formData.profileImage ? (
            <Typography variant="body2" gutterBottom>
              {formData.profileImage.name}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              color="error"
              sx={{ mb: 4 }}
              gutterBottom
            >
              File size should be less than 10 MB
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: 4,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ffffff",
          margin: "auto",
          maxWidth: 800,
        }}
      >
        <Typography sx={{ marginBottom: 2 }}>Choose CV Type</Typography>

        {/* Upload Video CV */}
        <Typography sx={{ fontWeight: "bold", marginBottom: 2 }}>
          Video CV
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Box
            sx={{
              width: isSmallScreen ? "100%" : "50%",
              height: 120,
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: isSmallScreen ? 0 : 2,
              marginBottom: isSmallScreen ? 2 : 0,
            }}
          >
            {formData.videoCV ? (
              <video
                src={URL.createObjectURL(formData.videoCV)}
                alt="Video CV"
                style={{ width: "100%", height: "100%" }}
                controls
              />
            ) : (
              <Button
                variant="contained"
                component="label"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#EDEDFC",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#EDEDFC",
                    boxShadow: "none",
                  },
                }}
              >
                <AddIcon sx={{ fontSize: 40, color: "green" }} />
                <input
                  ref={videoInputRef}
                  type="file"
                  accept="video/*"
                  name="videoCV" // This should match the backend name
                  onChange={handleFileChange}
                  hidden
                />
              </Button>
            )}
          </Box>

          {formData.videoCV && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: isSmallScreen ? "100%" : "40%",
              }}
            >
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {formData.videoCV.name}
              </Typography>
              <Box>
                <IconButton
                  onClick={() => handleDelete("videoCV")}
                  size="small"
                  aria-label="delete"
                  sx={{ backgroundColor: "#ffffff", boxShadow: "none" }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleEdit("videoCV")}
                  size="small"
                  aria-label="edit"
                  sx={{ backgroundColor: "#ffffff", boxShadow: "none" }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
        <Typography>
          Don't have Video to Upload?
          <Button
            onClick={handleSendAppLink}
            variant="text"
            sx={{ color: "#423BFA" }}
          >
            Click here
          </Button>
        </Typography>
        {/* Upload Audio CV */}
        {/* <Typography sx={{ fontWeight: "bold", marginBottom: 2 }}>
          Audio CV
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Box
            sx={{
              width: isSmallScreen ? "100%" : "50%",
              height: 120,
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: isSmallScreen ? 0 : 2,
              marginBottom: isSmallScreen ? 2 : 0,
            }}
          >
            {formData.audioCV ? (
              <audio
                src={URL.createObjectURL(formData.audioCV)}
                alt="Audio CV"
                controls
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <Button
                variant="contained"
                component="label"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#EDEDFC",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#EDEDFC",
                    boxShadow: "none",
                  },
                }}
              >
                <AddIcon sx={{ fontSize: 40, color: "green" }} />
                <input
                  ref={audioInputRef}
                  type="file"
                  accept="audio/*"
                  name="audioCV"
                  onChange={handleFileChange}
                  hidden
                />
              </Button>
            )}
          </Box>

          {formData.audioCV && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: isSmallScreen ? "100%" : "40%",
              }}
            >
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {formData.audioCV.name}
              </Typography>
              <Box>
                <IconButton
                  onClick={() => handleDelete("audioCV")}
                  size="small"
                  aria-label="delete"
                  sx={{ backgroundColor: "#ffffff", boxShadow: "none" }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleEdit("audioCV")}
                  size="small"
                  aria-label="edit"
                  sx={{ backgroundColor: "#ffffff", boxShadow: "none" }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box> */}

        {/* Upload Resume PDF */}
        <Typography sx={{ fontWeight: "bold", marginBottom: 2 }}>
          Resume
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Box
            sx={{
              width: isSmallScreen ? "100%" : "50%",
              height: 120,
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: isSmallScreen ? 0 : 2,
              marginBottom: isSmallScreen ? 2 : 0,
            }}
          >
            {formData.resume ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <PictureAsPdfIcon sx={{ fontSize: 60 }} />
              </Box>
            ) : (
              <Button
                variant="contained"
                component="label"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#EDEDFC",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#EDEDFC",
                    boxShadow: "none",
                  },
                }}
              >
                <AddIcon sx={{ fontSize: 40, color: "green" }} />
                <input
                  ref={pdfInputRef}
                  type="file"
                  accept="application/pdf"
                  name="resume"
                  onChange={handleFileChange}
                  hidden
                />
              </Button>
            )}
          </Box>

          {formData.resume && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: isSmallScreen ? "100%" : "40%",
              }}
            >
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {formData.resume.name}
              </Typography>
              <Box>
                <IconButton
                  onClick={() => handleDelete("resume")}
                  size="small"
                  aria-label="delete"
                  sx={{ backgroundColor: "#ffffff", boxShadow: "none" }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleEdit("resume")}
                  size="small"
                  aria-label="edit"
                  sx={{ backgroundColor: "#ffffff", boxShadow: "none" }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
          )}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
            theme="colored"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default JobSeekerCaseFour;
