import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import image1  from "../../assets/images/caller-01.png";
import image2  from "../../assets/images/computer lady-01.png";
import image3 from "../../assets/images/laptop-01.png";

const serviceDetails = {
  Resume_Display: {
    image: image2, 
    title: "Resume Display(Basic Perimum)",
    benefits: [
      "Rank Higher in Recruiter Searches - Your profile will be given an automatic boost when recruiters search CVs in Vacanzi database.",
      "More Recruiter Actions on your profile - Paid profile gets 3 times more recruiter actions (Recruiter Mails / Profile Views / CV Downloads).",
      "Get a Featured profile - Highlighting your profile gives you an edge over your peers and reduces your competition by 99.8%.",
    ],
  },
  premium: {
    image: image1, 
    title: "Premium Plan",
    benefits: [
      "Priority Access to Jobs on SMS/Mail - Get new jobs within 24 hours of being posted on Vacanzi. The first 20% applicants are 4 times more likely to be shortlisted.",
      "Handpicked jobs by Vacanzi domain expert - Detailed conversation with Vacanzi job expert to set your personalized job search criteria.",
      "Direct conversation with recruiters - Send your profile with a personalized message to get noticed by recruiters. Also, follow-up on your job applications.",
      "Get Recruiter Recommendations - You get exclusive access to recruiters having confidential jobs through recommendation mails.",
    ],
  },
   gold: {
    image: image3, 
    title: "Gold Plan",
    benefits: [
      "Direct Connection with recruiters - send your profile  with a personalised message to get noticed by recruiters. Also , follow-up on your job application ",
      "Get Recruiter Recommendations - you get exclusive access to recruiter  having confidential jobs through recommendation mails",
     
    ],
  },
};

const PaidServices = () => {
  const [selectedService, setSelectedService] = useState("Resume_Display");

  const handleServiceChange = (service) => {
    setSelectedService(service);
  };

  return (
    <Box sx={{ paddingTop: "3rem", }}>
      <Typography variant="h5" color="primary" sx={{ fontWeight: "bold", alignContent:"center",paddingBottom:"2rem" }}>
        Services include  in Basic & Premium Plans
      </Typography>

      {/* Buttons to Toggle Plans */}
      <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", marginBottom: "20px" }}>
        <Button
          variant={selectedService === "Resume_Display" ? "contained" : "outlined"}
          onClick={() => handleServiceChange("Resume_Display")}
          color="primary"
          sx={{
            padding: "10px 20px",
            width: "200px",
          }}
        >
           Resume Display (Basic & Premium)
        </Button>
        <Button
          variant={selectedService === "premium" ? "contained" : "outlined"}
          onClick={() => handleServiceChange("premium")}
          color="secondary"
          sx={{
            padding: "10px 20px",
            width: "200px",
          }}
        >
        Jobs on Mail adn SMS (Premium Only)
        </Button>
        <Button
          variant={selectedService === "gold" ? "contained" : "outlined"}
          onClick={() => handleServiceChange("gold")}
          color="warning"
          sx={{
            padding: "10px 20px",
            width: "200px",
          }}
        >
         Recruiter Connection (Premium Only)
        </Button>
        
      </Box>

      {/* Gold Plan */}
     
      {/* Display Selected Plan */}
      <Card variant="outlined" sx={{  borderRadius: "8px", overflow: "hidden" }}>
        <Grid container>
          {/* Image Section */}
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src={serviceDetails[selectedService].image}
              alt={serviceDetails[selectedService].title}
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
              }}
            />
          </Grid>

          {/* Benefits Section */}
          <Grid item xs={12} md={8}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                {serviceDetails[selectedService].title}
              </Typography>
              <Divider sx={{ marginBottom: "10px" }} />
              <List>
                {serviceDetails[selectedService].benefits.map((benefit, index) => (
                  <ListItem key={index} alignItems="flex-start">
                    <ListItemIcon>
                      <CheckCircleIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary={benefit} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default PaidServices;
