import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Description } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import EditIcon from "@mui/icons-material/Edit";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicIcon from "@mui/icons-material/Mic";
import { styled } from "@mui/system";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import DownhillSkiingTwoToneIcon from "@mui/icons-material/DownhillSkiingTwoTone";
import Diversity3TwoToneIcon from "@mui/icons-material/Diversity3TwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import CollectionsBookmarkTwoToneIcon from "@mui/icons-material/CollectionsBookmarkTwoTone";
import JobSeekerDeleteAccount from "./JobSeekerDeleteAccount";
import baseURL from "../../../api/baseUrl";
import WorkExpierenceModal from "./WorkExpierenceModal";
import EducationModal from "./EducationModal";
import SkillsModal from "./SkillsModal";
import CreateVideoDialog from "./CreateVideoDialog";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#423BFA" : "#308fe8",
  },
}));

const ProfileSection = ({ profileData, setProfileData }) => {
  const [coverImage, setCoverImage] = useState(
    "https://via.placeholder.com/600x200"
  );
  // const [showPhoneNumberField, setShowPhoneNumberField] = useState(false);
  // const [showSocialMediaField, setShowSocialMediaField] = useState(false);

  const [educationOpen, setEducationOpen] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);

  const [openSkillsModal, setOpenSkillsModal] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState([]);

  const handleOpenSkillsModal = () => setOpenSkillsModal(true);
  const handleCloseSkillsModal = () => setOpenSkillsModal(false);

  const [open, setOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openAudioModal, setOpenAudioModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const token = localStorage.getItem("token");

  const handleOpenVideoModal = (url) => {
    setVideoUrl(url);
    setOpenVideoModal(true);
  };

  const handleCloseVideoModal = (url) => {
    setOpenVideoModal(false);
    setVideoUrl("");
  };

  const handleOpenAudioModal = (url) => {
    setAudioUrl(url);
    setOpenAudioModal(true);
  };

  const handleCloseAudioModal = (url) => {
    setOpenAudioModal(false);
    setAudioUrl("");
  };

  useEffect(() => {
    if (profileData?.profile?.skills?.length) {
      setSelectedSkill(profileData?.profile?.skills);
    } else {
      setSelectedSkill([""]);
    }
  }, [profileData]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    // Calculate progress based on available data
    const totalSections = 5;
    let sectionsWithData = 0;

    if (profileData?.about) sectionsWithData += 1; // Assuming "About" section is present
    if (profileData?.profile?.skills?.length) sectionsWithData += 1; // Assuming "Skills" section has data
    if (profileData?.experiences?.length) sectionsWithData += 1; // Assuming "Work Experience" section has data
    if (profileData?.education?.length) sectionsWithData += 1; // Assuming "Education" section has data
    if (profileData?.projects?.length) sectionsWithData += 1; // Assuming "Projects" section has data

    const progress = (sectionsWithData / totalSections) * 100;
    setProgress(progress);
  }, [profileData]);

  const [progress, setProgress] = useState(0);

  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const width = 600; // desired width
          const height = 200; // desired height
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          const resizedImage = canvas.toDataURL("image/png");
          setCoverImage(resizedImage);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpen = (experience) => {
    setSelectedExperience(experience);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleEditChange = (e) => {
    setSelectedExperience({
      ...selectedExperience,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    const url = `${baseURL}/editExperience/${selectedExperience._id}`;
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(selectedExperience),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);

        const updatedExperiences = profileData.experiences.map((exp) =>
          exp._id === selectedExperience._id ? selectedExperience : exp
        );
        setProfileData({ ...profileData, experiences: updatedExperiences });

        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleEducationOpen = (education) => {
    setSelectedEducation(education);
    setEducationOpen(true);
  };

  const handleEducationClose = () => setEducationOpen(false);

  const handleEducationEditChange = (e) => {
    setSelectedEducation({
      ...selectedEducation,
      [e.target.name]: e.target.value,
    });
  };
  const handleEducationSave = async () => {
    const url = `${baseURL}/editEducation/${selectedEducation._id}`;
    try {

      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(selectedEducation),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          console.log("data education", data.education)
          console.log("profile Data", profileData);

          const updatedEducation = profileData.educations.map((ed) =>
            ed._id === selectedEducation._id ? selectedEducation : ed
          );
          setProfileData({ ...profileData, educations: updatedEducation });

          handleEducationClose();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.log(error);
    }

  };

  const handleSaveSkill = async (newSkill) => {
    try {
      // Update local state
      setSelectedSkill(newSkill);

      // Prepare the updated profile data
      const updatedProfileData = {
        ...profileData,
        profile: {
          ...profileData.profile,
          skills: [newSkill], // Update skills array
        },
      };

      // API call to update the profile
      const response = await fetch(
        `${baseURL}/jobseeker/profile/${profileData.profile._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedProfileData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update skill");
      }

      // Optionally update local state with the response data
      const result = await response.json();
      setProfileData(result);
    } catch (error) {
      console.error("Error updating skill:", error);
    } finally {
      handleCloseSkillsModal();
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        bgcolor: "background.paper",
        borderRadius: 2,
        maxWidth: "100%",
        mx: "auto",
        boxShadow: 3,
      }}
    >
      <Box sx={{ position: "relative", mb: 3 }}>
        <img
          src={coverImage}
          alt="cover"
          style={{ width: "100%", borderRadius: "10px" }}
        />
        <IconButton
          component="label"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            bgcolor: "background.paper",
          }}
          aria-label="edit cover image"
        >
          <EditIcon />
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleCoverImageChange}
          />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="body2" color="error" sx={{ flex: 1 }}>
          {`${progress}% completed`}
        </Typography>
      </Box>
      <BorderLinearProgress
        variant="determinate"
        value={progress}
        sx={{ mb: 2, boxShadow: 2 }}
      />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Description sx={{ mr: 4, fontSize: "2rem" }} />
          <Typography variant="h6">Resume</Typography>
        </AccordionSummary>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <DocumentScannerIcon
              sx={{ mr: 2, marginLeft: 2, fontSize: "2rem" }}
            />
            <Typography variant="h6"> PDF Resume</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {profileData?.resume && profileData?.resume.length > 0 ? (
              profileData?.resume.map((resumeFile, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "1rem",
                    padding: "1rem",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>File Name:</strong> {resumeFile.filename}
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        px: 2,
                        boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                        borderRadius: "20px",
                        background: "#423bfa",
                      }}
                      onClick={() => window.open(`${resumeFile.url}`, "_blank")}
                    >
                      View Resume
                    </Button>
                  </Box>
                </div>
              ))
            ) : (
              <Typography>No resume uploaded</Typography>
            )}
          </AccordionDetails>
        </Accordion>

        {/* Video CV Section */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <VideocamIcon sx={{ mr: 2, marginLeft: 2, fontSize: "2rem" }} />
            <Typography variant="h6">Video Resume</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {profileData?.videoCV && profileData?.videoCV.length > 0 ? (
              profileData?.videoCV.map((videoFile, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "1rem",
                    padding: "1rem",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>Video File Name:</strong> {videoFile.filename}
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        px: 2,
                        boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                        borderRadius: "20px",
                        background: "#423bfa",
                      }}
                      onClick={handleOpenDialog}
                    >
                      Create Video CV
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        px: 2,
                        boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                        borderRadius: "20px",
                        background: "#423bfa",
                      }}
                      onClick={() => handleOpenVideoModal(`${videoFile.url}`)}
                    >
                      View Video CV
                    </Button>
                  </Box>
                </div>
              ))
            ) : (
              <Typography>No video CV uploaded</Typography>
            )}
          </AccordionDetails>
        </Accordion>

        {/* Audio CV Section */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <MicIcon sx={{ mr: 2, marginLeft: 2, fontSize: "2rem" }} />
            <Typography variant="h6">Audio Resume</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {profileData?.audioCV && profileData?.audioCV.length > 0 ? (
              profileData?.audioCV.map((audioFile, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "1rem",
                    padding: "1rem",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>Audio File Name:</strong> {audioFile.filename}
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        px: 2,
                        boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                        borderRadius: "20px",
                        background: "#423bfa",
                      }}
                      onClick={() => handleOpenAudioModal(`${audioFile.url}`)}
                    >
                      Listen to Audio CV
                    </Button>
                  </Box>
                </div>
              ))
            ) : (
              <Typography>No audio CV uploaded</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Accordion>

      {/* Video CV Modal */}
      <Dialog open={openVideoModal} onClose={handleCloseVideoModal}>
        <DialogActions>
          <Button
            onClick={handleCloseVideoModal}
            color="primary"
            style={{ fontSize: "18px", color: "black" }}
          >
            X
          </Button>
        </DialogActions>
        <DialogContent>
          <video width="100%" controls>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </DialogContent>
      </Dialog>

      {/* Audio CV Modal */}
      <Dialog open={openAudioModal} onClose={handleCloseAudioModal}>
        <DialogActions>
          <Button
            onClick={handleCloseAudioModal}
            color="primary"
            style={{ fontSize: "18px", color: "black" }}
          >
            X
          </Button>
        </DialogActions>
        <DialogContent>
          <audio controls>
            <source src={audioUrl} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </DialogContent>
      </Dialog>
      <CreateVideoDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <PersonRoundedIcon sx={{ mr: 2, fontSize: "2rem" }} />
          <Typography variant="h6">About</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {profileData.description}
            {/* Your content for the "About" section goes here */}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <DownhillSkiingTwoToneIcon sx={{ mr: 2, fontSize: "2rem" }} />
          <Typography variant="h6">Skills</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {profileData?.skills && profileData?.skills.length > 0 ? (
            profileData?.skills.map((skills) => (
              <>
                <Typography>{skills}</Typography>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    startIcon={<EditIcon />}
                    onClick={handleOpenSkillsModal}
                    variant="contained"
                    color="primary"
                    sx={{
                      px: 2,
                      boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                      borderRadius: "20px",
                      background: "#423bfa",
                    }}
                  >
                    Edit
                  </Button>
                </Box>
              </>
            ))
          ) : (
            <Typography>No Skills available</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      <SkillsModal
        open={openSkillsModal}
        handleClose={handleCloseSkillsModal}
        skill={selectedSkill}
        handleSave={handleSaveSkill}
      />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Diversity3TwoToneIcon sx={{ mr: 2, fontSize: "2rem" }} />
          <Typography variant="h6">Work Experience</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {profileData?.experiences && profileData?.experiences.length > 0 ? (
            profileData?.experiences.map((experience) => (
              <div
                key={experience._id}
                style={{
                  marginBottom: "1rem",
                  padding: "1rem",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {experience.company}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Designation:</strong> {experience.designation}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Job Role:</strong>{" "}
                  {experience.jobRole && experience.jobRole.length > 0
                    ? experience.jobRole.join(", ")
                    : "N/A"}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Industry:</strong>{" "}
                  {profileData.industry || "Not specified"}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>CTC:</strong>{" "}
                  {experience.ctc ? `${experience.ctc} LPA` : "Not specified"}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Start Date:</strong>{" "}
                  {new Date(experience.startDate).toLocaleDateString()}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>End Date:</strong>{" "}
                  {experience.endDate
                    ? new Date(experience.endDate).toLocaleDateString()
                    : "Present"}
                </Typography>
                <Typography variant="sbtitle1">
                  <strong>Notice Period :</strong>{" "}
                  {profileData.noticePeriod
                    ? `${profileData.noticePeriod} Months`
                    : `Immediate joinner`}
                </Typography>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      px: 2,
                      boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                      borderRadius: "20px",
                      background: "#423bfa",
                    }}
                    startIcon={<EditIcon />}
                    onClick={() => handleOpen(experience)}
                  >
                    Edit
                  </Button>
                </Box>
              </div>
            ))
          ) : (
            <Typography>No experiences available</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Modal for editing work experience */}
      {selectedExperience && (
        <WorkExpierenceModal
          open={open}
          handleClose={handleClose}
          handleEditChange={handleEditChange}
          selectedExperience={selectedExperience}
          handleSave={handleSave}
        />
      )}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <SchoolTwoToneIcon sx={{ mr: 2, fontSize: "2rem" }} />
          <Typography variant="h6">Education</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {profileData?.educations && profileData?.educations.length > 0 ? (
            profileData?.educations.map((education) => (
              <div
                key={education._id}
                style={{
                  marginBottom: "1rem",
                  padding: "1rem",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {education.degree} - {education.degreeType}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Institution:</strong>{" "}
                  {education.institution || "Not available"}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Field of Study:</strong>{" "}
                  {education.fieldOfStudy || "Not specified"}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Graduation Year:</strong>{" "}
                  {education.graduationYear || "Not available"}
                </Typography>
                {/* <Typography variant="subtitle1">
                  <strong>Start Date:</strong>{" "}
                  {education.startDate
                    ? new Date(education.startDate).toLocaleDateString()
                    : "Not available"}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>End Date:</strong>{" "}
                  {education.endDate
                    ? new Date(education.endDate).toLocaleDateString()
                    : "Present"}
                </Typography> */}
                <Typography variant="subtitle1">
                  <strong>Grade:</strong> {education.grade || "Not available"}
                </Typography>
                {/* <Typography variant="subtitle1">
                  <strong>Activities and Societies:</strong>{" "}
                  {education.activitiesAndSocieties || "Not listed"}
                </Typography> */}
                {/* <Typography variant="subtitle1">
                  <strong>Description:</strong>{" "}
                  {education.description || "No description provided"}
                </Typography> */}
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      px: 2,
                      boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                      borderRadius: "20px",
                      background: "#423bfa",
                    }}
                    startIcon={<EditIcon />}
                    onClick={() => handleEducationOpen(education)}
                  >
                    Edit
                  </Button>
                </Box>
              </div>
            ))
          ) : (
            <Typography>No education data available</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {selectedEducation && (
        <EducationModal
          open={educationOpen}
          handleClose={handleEducationClose}
          handleEditChange={handleEducationEditChange}
          selectedEducation={selectedEducation}
          handleSave={handleEducationSave}
        />
      )}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <CollectionsBookmarkTwoToneIcon sx={{ mr: 2, fontSize: "2rem" }} />
          <Typography variant="h6">Project</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* Your content for the "Project" section goes here */}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <JobSeekerDeleteAccount />
    </Box>
  );
};

export default ProfileSection;
