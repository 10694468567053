import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Box,
  Grid,
  Divider,
  Checkbox,
  Snackbar,
  Modal,
} from "@mui/material";
import {
  LocationOn,
  Work,
  Language,
  Phone,
  CopyAll,
} from "@mui/icons-material";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";
import SchoolIcon from "@mui/icons-material/School";
import { styled } from "@mui/material/styles";
import avatarmen from "../../../assets/images/avatarmen.jpg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Message } from "@mui/icons-material"; // Import the Message icon
import CandidateChatModal from "./CandidateChatModal";

const StyledCard = styled(Card)({
  maxWidth: 800,
  margin: "auto",
  marginBottom: "20px", // To add some space between the cards
});

const StyledButton = styled(Button)({
  backgroundColor: "#4CAF50",
  "&:hover": {
    backgroundColor: "#45a049",
  },
});

// const SkillChip = styled("span")(({ theme }) => ({
//   backgroundColor: theme.palette.grey[200],
//   padding: "2px 4px",
//   margin: "0 2px",
//   borderRadius: 4,
// }));

const CandidateProfileCard = ({
  profile,
  handleCheckboxChange,
  inviteData,
  keyword,
  booleanSearch, // Receive the keyword as a prop
}) => {
  // const [drawerOpen, setDrawerOpen] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copiedText, setCopiedText] = useState("");
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const highlightKeyword = (text, keyword, booleanSearch) => {
    // Ensure text is a string (fallback to empty string if undefined/null)

    text = String(text || "");

    // Return original text if either keyword or booleanSearch is missing
    if (!keyword || !booleanSearch) return text;

    // Create a regex to match the keyword (case-insensitive)
    const regex = new RegExp(`(${keyword})`, "gi");

    // Replace the keyword with a styled span
    return text.replace(regex, "<span style='background-color: '';'>$1</span>");
  };

  const handleOpenChat = (profileId) => {
    if (currentId !== profileId) {
      setCurrentId(profileId);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentId(null); // Reset currentId when the modal closes
  };

  const handlePlay = (jobId) => {
    setPlayingVideoId(jobId); // Set the ID of the clicked video
  };

  const handleViewFullProfile = (id) => {
    const profileUrl = `/viewJobSeekerProfile/${id}`; // Construct the route
    window.open(profileUrl, "_blank", "noopener,noreferrer"); // Open in a new tab
  };

  // const handleCloseDrawer = () => {
  //   setDrawerOpen(false);
  // };

  const handleViewContactDetails = () => {
    setShowContactDetails(true);
  };

  const handleCopy = (text) => {
    setCopiedText(text);
    setCopySuccess(true);
  };

  const handleCloseSnackbar = () => {
    setCopySuccess(false);
    setCopiedText("");
  };

  return (
    <>
      <StyledCard variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <Checkbox
                checked={inviteData.userId.includes(profile.id)}
                onChange={() => handleCheckboxChange(profile.id)}
              />

              {profile?.videoCV ? (
                <Box
                  sx={{
                    width: "150px",
                    height: "190px",
                    borderRadius: 4,
                    marginTop: 7,
                    overflow: "hidden",
                    bgcolor: "black",
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  {playingVideoId !== profile.id ? (
                    <Box
                      component="img"
                      src={profile?.videoCVthumbnail}
                      alt="Video thumbnail"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlay(profile.id)}
                    />
                  ) : (
                    <Box
                      component="video"
                      src={profile?.videoCV}
                      alt="Video"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      autoPlay
                      controls
                    />
                  )}
                  {playingVideoId !== profile.id && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "rgba(0,0,0,0.6)",
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlay(profile.id)}
                    >
                      <PlayArrowIcon sx={{ fontSize: 24, color: "white" }} />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "150px",
                    height: "190px",
                    borderRadius: 4,
                    bgcolor: "#f0f0f0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 7,
                    textAlign: "center",
                    fontFamily: "IBM Plex Sans, sans-serif",
                    color: "#666",
                  }}
                >
                  <Typography variant="body2">No video CV found</Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={2}>
              <IconButton>
                <img
                  src={profile.profileImage || avatarmen}
                  alt="Profile"
                  style={{
                    width: "60%",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={9}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", maxWidth: "12rem" }}
                  dangerouslySetInnerHTML={{
                    __html: highlightKeyword(
                      profile?.name ?? "",
                      keyword,
                      booleanSearch
                    ),
                  }}
                />
                <Box sx={{ gap: 1, display: "flex", flexDirection: "column",justifyContent:'center' }}>
                  <Typography
                    onClick={() => handleViewFullProfile(profile.id)}
                    sx={{
                      color: "white",
                      fontWeight: "regular",
                      fontSize: 12,
                      cursor: "pointer",
                      bgcolor: "#38b538",
                      textAlign:'center',
                      p: 1,
                      borderRadius: 2,
                      textDecoration: "none", // To remove the underline
                      ":hover": { bgcolor: "#14A114FF" },
                    }}
                    variant="body2"
                  >
                    View Full Profile
                  </Typography>

                  <Typography
                    key={profile.id}
                    variant="contained"
                    startIcon={<Message />}
                    onClick={() => handleOpenChat(profile.id)}
                    sx={{
                      color: "white",
                      fontWeight: "regular",
                      fontSize: 12,
                      cursor: "pointer",
                      bgcolor: "#38b538",
                      p: 1,
                      borderRadius: 2,
                      textDecoration: "none", // To remove the underline
                      ":hover": { bgcolor: "#14A114FF" },
                    }}
                  >
                    Message {profile?.name}
                  </Typography>
                </Box>

                <Typography variant="body2">
                  {profile.experience} years
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: highlightKeyword(
                      profile?.workExperience?.[0]?.company ?? "",
                      keyword,
                      booleanSearch
                    ),
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <LocationOn fontSize="small" />{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlightKeyword(
                        profile?.location ?? "",
                        keyword,
                        booleanSearch
                      ),
                    }}
                  />
                </Typography>
              </Box>
              <Divider />
              <Box mt={2} sx={{ ml: 3 }}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <Work fontSize="small" sx={{ mr: 1 }} />
                  <strong>Current / Latest : </strong>
                  <span
                    style={{ marginLeft: 5 }}
                    dangerouslySetInnerHTML={{
                      __html: highlightKeyword(
                        profile?.workExperience?.[0]?.designation ?? "",
                        keyword,
                        booleanSearch
                      ),
                    }}
                  />
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <Work fontSize="small" sx={{ mr: 1 }} />
                  <strong>Preffered Job Title: </strong>
                  <span
                    style={{ marginLeft: 5 }}
                    dangerouslySetInnerHTML={{
                      __html: highlightKeyword(
                        profile?.prefferedJobType
                          ? profile?.prefferedJobType
                          : "Not Mentioned",
                        keyword,
                        booleanSearch
                      ),
                    }}
                  />
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <SchoolIcon fontSize="small" sx={{ mr: 1 }} />
                  <strong>Education : </strong>
                  <span
                    style={{ marginLeft: 3 }}
                    dangerouslySetInnerHTML={{
                      __html: highlightKeyword(
                        profile?.education?.[0]?.degree ?? "",
                        keyword,
                        booleanSearch
                      ),
                    }}
                  />
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <LocationOn fontSize="small" sx={{ mr: 1 }} />
                  <strong>Pref. Location : </strong>
                  <span
                    style={{ marginLeft: 3 }}
                    dangerouslySetInnerHTML={{
                      __html: highlightKeyword(
                        profile?.preferredLocations?.join(", ") ?? "",
                        keyword,
                        booleanSearch
                      ),
                    }}
                  />
                </Typography>
                <Typography variant="body2" mb={2}>
                  <DownhillSkiingIcon fontSize="small" sx={{ mr: 0.5 }} />
                  <strong>Skills : </strong>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlightKeyword(
                        profile?.skills?.join(", ") ?? "",
                        keyword,
                        booleanSearch
                      ),
                    }}
                  />
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Language fontSize="small" sx={{ mr: 1 }} />
                  <strong>Languages : </strong>
                  <span
                    style={{ marginLeft: 2 }}
                    dangerouslySetInnerHTML={{
                      __html: highlightKeyword(
                        profile?.languages?.join(", ") ?? "",
                        keyword,
                        booleanSearch
                      ),
                    }}
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "row", // Show buttons side by side initially
                  gap: 2,
                  ml: 3,
                  alignItems: "center", // Align items in the center vertically
                }}
              >
                {!showContactDetails ? (
                  <>
                    <StyledButton
                      variant="contained"
                      startIcon={<Phone />}
                      onClick={handleViewContactDetails}
                      sx={{ textTransform: "none", p: 1, fontSize: 12 }}
                    >
                      View Contact Details
                    </StyledButton>

                    {/* <Typography
                      key={profile.id}
                      variant="contained"
                      startIcon={<Message />}
                      onClick={() => handleOpenChat(profile.id)}
                      sx={{
                        color: "white",
                        fontWeight: "regular",
                        fontSize: 12,
                        cursor: "pointer",
                        bgcolor: "#38b538",
                        p: 1,
                        borderRadius: 2,
                        textDecoration: "none", // To remove the underline
                        ":hover": { bgcolor: "#14A114FF" },
                      }}
                    >
                      Message {profile?.name}
                    </Typography> */}
                  </>
                ) : (
                  <>
                    {/* Show contact details (phone and email) in the same place */}
                    <Box sx={{ display: "flex", gap: 1 }}>
                      {/* Contact details section */}
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography sx={{ color: "green", fontWeight: "bold" }}>
                          {profile.phone}
                        </Typography>
                        <CopyToClipboard
                          text={profile.phone}
                          onCopy={() => handleCopy("Phone number copied")}
                        >
                          <IconButton sx={{ ml: 1 }}>
                            <CopyAll />
                          </IconButton>
                        </CopyToClipboard>

                        <Typography sx={{ color: "green", fontWeight: "bold" }}>
                          {profile.email}
                        </Typography>
                        <CopyToClipboard
                          text={profile.email}
                          onCopy={() => handleCopy("Email id copied")}
                        >
                          <IconButton sx={{ ml: 1 }}>
                            <CopyAll />
                          </IconButton>
                        </CopyToClipboard>
                      </Box>

                      {/* Separate div for the Message button */}
                      <div></div>
                    </Box>
                  </>
                )}
              </Box>

              {isModalOpen && (
                <Modal open={isModalOpen} onClose={handleCloseModal}>
                  {currentId && (
                    <CandidateChatModal
                      currentId={currentId}
                      onClose={handleCloseModal}
                    />
                  )}
                </Modal>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                mt={1}
                sx={{ ml: 3 }}
              >
                No credits will be used for viewing phone number
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          bgcolor="#f5f5f5"
        >
          <Typography variant="body2">
            {profile?.profileViewCount} Profile Views
          </Typography>
          <Typography variant="body2">
            Active <span style={{ marginLeft: 5 }}>{profile.updatedAt}</span>
          </Typography>
        </Box>
      </StyledCard>
      <Snackbar
        open={copySuccess}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={copiedText}
      />
    </>
  );
};

export default CandidateProfileCard;
