import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import subscribeList from "../../../styles/subscribeList";
import {
  getAllSubsciptions,
  upgradePlanApi,
  contactSales,
} from "../../../api/subscriptions";
import { toast, ToastContainer } from "react-toastify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import StillNotSure from "./StillNotSure";
import SubscriptionsCard from "./SubscriptionsCard";

function SubScriptionPage() {
  const [subscriptionData, setsubscriptionData] = useState();
  const [openDialog, setOpenDialog] = useState(false); // State for dialog visibility
  const [selectedPlan, setSelectedPlan] = useState(null); // To store the selected plan
  const [subscriptionId, setSubscriptionId] = useState();
  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId");

  const handleBuyNow = async (tier, id) => {
    setSelectedPlan(tier); // Set the selected plan for confirmation
    setSubscriptionId(id);
    setOpenDialog(true); // Open the confirmation dialog
  };

  const navigate = useNavigate();

  const handleConfirmPayment = async () => {
    try {
      const formValues = {
        newTier: selectedPlan,
        organizationId: orgId,
        subscriptionId: subscriptionId,
      };
      const response = await upgradePlanApi(token, formValues);
      if (response) {
        toast.success("Payment Successful! Plan upgraded.");
        setOpenDialog(false); // Close dialog after payment
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Payment Failed! Please try again.");
    }
  };

  const handleContactSales = async () => {
    try {
      const response = await contactSales(token);
      if (response) {
        toast.success("Thank you for contacting we will get back to you soon");
        setOpenDialog(false); // Close dialog after payment
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Error Contacting Sales!.");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog without doing anything
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        if (orgId) {
          const response = await getAllSubsciptions(orgId, token);
          setsubscriptionData(response?.data?.customSubscriptions);
        } else {
          throw new Error("No organization id");
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchSubscriptions();
  }, [orgId, token]);

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Box sx={{ ...subscribeList.parentBox }}>
      <Typography
        onClick={handleBack}
        gutterBottom
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          ml:'20px',
          mt:'20px',
          mb: "15px",
          color: "grey", // Adjust color for better visibility
          "&:hover": {
            color: "primary.dark", // Hover effect
          },
        }}
      >
        <ArrowBackIcon sx={{ marginRight: "4px" }} />
      </Typography>
      <Box sx={{ ...subscribeList.mainTitleBox }}>
        <Typography
          sx={{ color: "red", ...subscribeList.contentText, fontSize: 25 }}
        >
          Job Posting
        </Typography>
        <Typography sx={{ ...subscribeList.contentText, fontSize: 30 }}>
          Attract Candidates
        </Typography>
        <Typography sx={{ ...subscribeList.contentText }}>
          with quick and easy plans on India's 1st video based job site
        </Typography>
      </Box>

      <SubscriptionsCard
        subscriptionData={subscriptionData}
        handleBuyNow={handleBuyNow}
        handleContactSales={handleContactSales}
      />
      <StillNotSure handleContactSales={handleContactSales} />
      {/* Confirm Payment Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} handleContactSales>
        <DialogTitle>Confirm Payment</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to upgrade your plan to
            <span style={{ marginLeft: 3, color: "red" }}>
              {selectedPlan} ?
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              bgcolor: "red",
              color: "white",
              borderRadius: "2rem",
              textTransform: "none",
              ":hover": {
                bgcolor: "red",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmPayment}
            sx={{
              bgcolor: "green",
              color: "white",
              borderRadius: "2rem",
              textTransform: "none",
              ":hover": {
                bgcolor: "green",
                color: "white",
              },
            }}
          >
            Confirm Payment
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        theme="colored"
      />
    </Box>
  );
}

export default SubScriptionPage;
