import React from "react";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@mui/material";
import { Instagram, Facebook, Twitter, YouTube } from "@mui/icons-material";
import VacanziLogo from "../../../assets/icons/logos/vacanzi.png";

const EmployerFooter = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleScrollToTop = () => {
    window.scrollBy({
      top: -500, // Scroll up by 100 pixels
      behavior: "smooth", // For smooth scrolling
    });
  };
  
  return (
    <Box
      sx={{ backgroundColor: "#162340", color: "#fff", py: 4, width: "100vw" }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={4}
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: "center",
                mb: isSmallScreen ? 2 : 2,
              }}
            >
              <Avatar
                src={VacanziLogo}
                alt="Vacanzi Logo"
                sx={{
                  width: 66,
                  height: 66,
                  mr: isSmallScreen ? 0 : 1,
                  mb: isSmallScreen ? 1 : 0,
                }}
              />
              <Typography
                variant="h2"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  display: "flex",
                  color: "#FFF",
                  textDecoration: "none",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                Vacanzi
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: 2, ml: 0 }}
            >
              <Link href="#" color="inherit" aria-label="Instagram">
                <Instagram sx={{ color: "#d62976" }} />
              </Link>
              <Link href="#" color="inherit" aria-label="Facebook">
                <Facebook sx={{ color: "#1877F2" }} />
              </Link>
              <Link href="#" color="inherit" aria-label="Twitter">
                <Twitter sx={{ color: "#1DA1F2" }} />
              </Link>
              <Link href="#" color="inherit" aria-label="YouTube">
                <YouTube sx={{ color: "#FF0000" }} />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
              }}
            >
              About Us
            </Typography>
            {/* <Link
              href="#"
              color="inherit"
              sx={{
                display: "block",
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Careers
            </Link> */}
            <Link
              href="/about-vacanzi"
              color="inherit"
              sx={{
                display: "block",
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              About Vacanzi
            </Link>
            <Link
              href="/employerSignIn"
              color="inherit"
              sx={{
                display: "block",
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Employer Home
            </Link>

            {/* <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left' ,textDecoration: 'none',fontFamily:'IBM Plex Sans'}}>
                            Credit
                        </Link> */}
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
              }}
            >
              For Employers
            </Typography>
            <Link
              color="inherit"
              sx={{
                display: "block",
                cursor:'pointer',
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
              onClick={handleScrollToTop} // Trigger the scroll on click
            >
              FAQ
            </Link>
            <Link
              href="/privacy-policy"
              color="inherit"
              sx={{
                display: "block",
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Privacy Policy
            </Link>
            <Link
              href="/payment-policy"
              color="inherit"
              sx={{
                display: "block",
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Payment Policy
            </Link>
            <Link
              href="/terms-&-cons"
              color="inherit"
              sx={{
                display: "block",
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                textDecoration: "none",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Terms & Conditions
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Contact Us
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
              }}
            >
              No.10, Ramamurthy Nagar, Dodda Banaswadi, Bangalore - 560043
            </Typography>
            {/* <Typography variant="body2" sx={{ mb: 1, textAlign: isSmallScreen ? 'center' : 'left' }}>
                            +91 89042 71342 / +91 79758 49598
                        </Typography> */}
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
              }}
            >
              sales@vacanzi.com
            </Typography>
            <Typography
              variant="body2"
              sx={{
                textAlign: isSmallScreen ? "center" : "left",
                fontFamily: "IBM Plex Sans",
              }}
            >
              info@vacanzi.com
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ borderTop: "1px solid #444", mt: 4, pt: 2 }}>
          <Typography
            variant="body2"
            align="center"
            sx={{ mb: 2, fontFamily: "IBM Plex Sans" }}
          >
            ©2025 Vacanzi Rights Reserved
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default EmployerFooter;
