import React from 'react';
import {

    Typography,
    Button,

    Box,
    TextField,
    Modal,
} from '@mui/material';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const WorkExpierenceModal = ({ open,handleClose,handleEditChange, selectedExperience, handleSave}) => {

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ ...modalStyle }}>
                <Typography variant="h6" mb={2}>
                    Edit Work Experience
                </Typography>
                <TextField
                    fullWidth
                    label="Company"
                    name="company"
                    value={selectedExperience.company}
                    onChange={handleEditChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Designation"
                    name="designation"
                    value={selectedExperience.designation}
                    onChange={handleEditChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Job Role"
                    name="jobRole"
                    value={selectedExperience.jobRole.join(', ')}
                    onChange={handleEditChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Industry"
                    name="industry"
                    value={selectedExperience.industry}
                    onChange={handleEditChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="CTC"
                    name="ctc"
                    value={selectedExperience.ctc}
                    onChange={handleEditChange}
                    margin="normal"
                />
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save
                </Button>
            </Box>
        </Modal>
    )
}

export default WorkExpierenceModal

