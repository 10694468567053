import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import OTPPage from "../../../components/OTPBox/OTPPage";
import EmployerLoginSignUp from "../../../components/EmployerComponents/EmployerLoginSignup/EmployerLoginSignUp";
import OTPModal from "../../../components/OTPBox/OTPModal";

import {
  signupEmployer,
  loginEmployer,
  verifySignupOtp,
  verifyOtp,
  resendOtp,
} from "../../../api/employerAuth";
import { Box } from "@mui/system";
// import ResponsiveAppBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

const initialErrors = {
  loginEmail: "",
  loginPassword: "",
  signupName: "",
  signupEmail: "",
  signupPhone: "",
  signupCompany: "",
  otp: "",
};

export default function EmployerSignIn() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({
    loginEmail: "",
    loginPassword: "",
    signupName: "",
    signupEmail: "",
    signupPhone: "",
    signupCompany: "",
    otp: "",
  });
  const [errors, setErrors] = useState(initialErrors);
  // const [showOtpBox, setShowOtpBox] = useState(false);
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    let countdown;
    if (showOtpModal) {
      countdown =
        timer > 0
          ? setInterval(() => setTimer((prev) => prev - 1), 1000)
          : (setCanResend(true), setTimer(30));
    } else {
      setTimer(30);
      setCanResend(false);
    }
    return () => clearInterval(countdown);
  }, [showOtpModal, timer]);

  const validateField = (name, value) => {
    const validationRules = {
      loginEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      signupEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      loginPassword: /.+/,
      signupName: /.+/,
      signupCompany: /.+/,
    };
    const errorMessages = {
      loginEmail: "Invalid email format",
      signupEmail: "Invalid email format",
      loginPassword: "Password is required",
      signupName: "Full Name is required",
      signupCompany: "Company Name is required",
    };
    return !value?.match(validationRules[name]) ? errorMessages[name] : "";
  };

  const handleFieldChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, value),
    }));
  };

  const validateForm = (fields) => {
    const newErrors = fields.reduce((acc, field) => {
      acc[field] = validateField(field, formData[field]);
      return acc;
    }, {});
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleLoginSubmit = async () => {
    const fields = ["loginEmail", "loginPassword"];
    if (validateForm(fields)) {
      try {
        const loginData = await loginEmployer(
          formData.loginEmail,
          formData.loginPassword
        );
        setFormData((prev) => ({ ...prev, signupPhone: loginData.phone }));
        setShowOtpModal(true);
      } catch (error) {
        console.error("Login error:", error);
        setErrors((prev) => ({ ...prev, loginEmail: "Invalid credentials" }));
      }
    }
  };

  const handleSignUpSubmit = async () => {
    const fields = ["signupName", "signupEmail", "signupPhone"];

    if (validateForm(fields)) {
      try {
        const signupData = await signupEmployer(
          formData.signupName,
          formData.signupEmail,
          formData.signupPhone,
          formData.signupCompany
        );

        setFormData((prev) => ({ ...prev, otp: signupData.otp }));
        setShowOtpModal(true);
      } catch (error) {
        console.error("Signup error:", error);
        setErrors((prev) => ({
          ...prev,
          signupEmail: "Signup failed. Please try again.",
        }));
      }
    }
  };

  const handleOtpSubmit = async () => {
    if (formData.otp.length === 4) {
      try {
        if (value === 0) {
          // Login OTP verification
          const otpData = await verifyOtp(formData.loginEmail, formData.otp);
          const { organizationId, role, token } = otpData;
          const recruiterId = otpData.recruiter._id;
          localStorage.setItem("recruiterId", recruiterId);
          localStorage.setItem("organizationId", organizationId);
          localStorage.setItem("role", role);
          localStorage.setItem("token", token);

          navigate("/search-candidates");
        } else {
          // Signup OTP verification
          const otpData = await verifySignupOtp(
            formData.signupPhone,
            formData.otp
          );
          const { role, token } = otpData;

          localStorage.setItem("role", role);
          localStorage.setItem("token", token);

          navigate("/employerRegistration", {
            state: {
              signupEmail: formData.signupEmail,
              signupName: formData.signupName,
              signupPhone: formData.signupPhone,
            },
          });
        }
      } catch (error) {
        console.error("OTP verification error:", error);
        setErrors((prev) => ({ ...prev, otp: "Invalid OTP" }));
      }
    } else {
      setErrors((prev) => ({ ...prev, otp: "OTP must be 4 digits" }));
    }
  };

  const handleResendOtp = async () => {
    try {
      await resendOtp(formData.signupPhone);
      setCanResend(false);
      setTimer(30);
    } catch (error) {
      console.error("Resend OTP error:", error);
    }
  };

  return (
    <>
      <NewNavBar />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: 0,
          padding: 0,
          width: "100%", // Ensure it takes full width
          maxWidth: "100vw", // Prevent horizontal overflow
          overflowX: "hidden", // Hide horizontal overflow
        }}
      >
        {!showOtpModal ? (
          <>
            <EmployerLoginSignUp
              handleFieldChange={handleFieldChange}
              errors={errors}
              value={value}
              loginEmail={formData.loginEmail}
              loginPassword={formData.loginPassword}
              handleLoginSubmit={handleLoginSubmit}
              handleSignupSubmit={handleSignUpSubmit}
              setValue={setValue}
              signupEmail={formData.signupEmail}
              signupName={formData.signupName}
              signupCompany={formData.signupCompany}
              signupPhone={formData.signupPhone}
            />
          </>
        ) : (
          // <OTPPage
          //   handleBack={() => setShowOtpBox(false)}
          //   loginPhone={formData.loginPhone}
          //   signupPhone={formData.signupPhone}
          //   signupEmail={formData.signupEmail}
          //   handleFieldChange={handleFieldChange}
          //   handleResendOtp={handleResendOtp}
          //   canResend={canResend}
          //   timer={timer}
          //   otp={formData.otp}
          //   errors={errors}
          //   handleOtpSubmit={handleOtpSubmit}
          // />
          <OTPModal
            phone={formData.signupPhone || formData.loginPhone}
            email={formData.signupEmail || formData.loginEmail}
            open={showOtpModal}
            handleClose={() => setShowOtpModal(false)}
            handleBack={() => setShowOtpModal(false)}
            handleFieldChange={handleFieldChange}
            handleResendOtp={handleResendOtp}
            handleLoginSubmit={handleLoginSubmit}
            canResend={canResend}
            timer={timer}
            otp={formData.otp}
            errors={errors}
            handleOtpSubmit={handleOtpSubmit}
          />
        )}
      </Box>
    </>
  );
}
