import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Avatar,
  Typography,
  IconButton,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import baseURL from "../../../api/baseUrl";
import { getUserIdFromToken } from "../../../utils/userAuth";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getProfilePercentageApi } from "../../../api/jobseeker";

const JobSeekerPersonalDetails = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState(Array(4).fill("")); // OTP state
  const inputRefs = useRef([]);
  const [userData, setUserData] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [skills, setSkills] = useState([]);
  const [profilePercentage, setProfilePercentage] = useState();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    totalExpInMonths: "",
    totalExpInYears: "",
    noticePeriod: "",
    currentLocation: "",
    profileImage: "",
    skills: "",
  });

  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userId = await getUserIdFromToken();
      try {
        const response = await fetch(`${baseURL}/jobseeker/${userId}`);
        const data = await response.json();
        setOtpVerified(data.user.emailVerified);
        setUserData(data.user);
        console.log("userData", data.user); // Logs updated data, not state

        // Set formData for both displaying and editing
        setFormData({
          fullName: data.user.fullName||"",
          email: data.user.email||'',
          phone: data.user.phone||'',
          profileImage: data.user.profileImage||'',
          totalExpInMonths: data.user.totalExpInMonths||'',
          totalExpInYears: data.user.totalExpInYears||'',
          noticePeriod: data.user.noticePeriod||'',
          skills: data.user.skills||'',
          dob: data.user.dob||'',
          gender: data.user.gender||'',
          currentLocation: data.user.currentLocation||'',
        });

        // If a profile image exists, set it in the state
        if (data.user.profileImageUrl) {
          setProfileImage(data.user.profileImageUrl);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserDetails();
  }, []);

  const formatDate = (date) => {
    if (!date) return "";
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString("en-US");
  };

  const skillOptions = [
    "JavaScript",
    "React.Js",
    "React Native",
    "Node.Js",
    "Python",
    "Java",
    "C++",
    "SQL",
  ];
  const handleSkillsChange = (event) => {
    const { value } = event.target;
    setSkills(value); // Set the selected skills as an array

    // Update formData to always store skills as an array
    setFormData((prev) => ({
      ...prev,
      skills: value, // Ensure skills is directly assigned as an array
    }));
  };

  // This method will be called when you upload a new image
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Set the image preview immediately
      const imageURL = URL.createObjectURL(file);
      setProfileImage(imageURL); // Instantly update the preview

      // Set the form data for the file
      setFormData((prevData) => ({
        ...prevData,
        profileImage: file, // Save the file object to form data
      }));
    }
  };

  const handleEdit = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken");
    const formDataToSubmit = new FormData();

    // Append form data
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }

    // Append profile image if it exists
    if (profileImage instanceof File) {
      formDataToSubmit.append("profileImage", profileImage);
    }

    // Append skills[] values if it's an array
    if (Array.isArray(formData?.skills)) {
      formData.skills.forEach((skill) => {
        formDataToSubmit.append("skills[]", skill); // This ensures skills are sent as "skills[]:React.Js"
      });
    }

    try {
      const response = await fetch(`${baseURL}/updateUser`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formDataToSubmit,
      });

      if (response.ok) {
        const updatedData = await response.json();
        setUserData(updatedData.user);
        setOpenModal(false);
        toast.success("Profile updated successfully");
      } else {
        console.error("Failed to update user data");
        toast.error("Failed to update profile");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error updating profile");
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    // Allow only numeric input
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if a digit is entered
      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }

    // Handle backspace
    if (e.key === "Backspace" && !value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  const handleVerifyOtp = async () => {
    const otpString = otp.join("");
    try {
      const response = await fetch("https://vacanzi.com/api/verifyEmailOtp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: formData.email, otp: otpString }),
      });

      const data = await response.json();
      if (response.ok) {
        setOtpVerified(true);
        toast.success("OTP verified successfully!");
      } else {
        toast.error(data.message || "Invalid OTP");
      }
    } catch (error) {
      toast.error("Error verifying OTP");
    }
  };

  const handleSendOtp = async () => {
    try {
      const response = await fetch("https://vacanzi.com/api/emailOtp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: formData.email }),
      });

      const data = await response.json();
      if (response.ok) {
        setOtpSent(true);
        toast.success("OTP sent successfully!");
      } else {
        toast.error(data.message || "Failed to send OTP");
      }
    } catch (error) {
      toast.error("Error sending OTP");
    }
  };

  const handleEditClick = (educationId) => {
    console.log("educationId", educationId);
  };

  //

  useEffect(() => {
    const getProfilePercentage = async () => {
      try {
        const response = await getProfilePercentageApi(userId, token);
        console.log("response", response);
        setProfilePercentage(response.profilePercentage);
      } catch (error) {
        console.log(error.message);
      }
    };
    getProfilePercentage();
  }, [token, userId]);

  const getProgressColor = (percentage) => {
    if (percentage <= 30) {
      return "#ff4444"; // Red for 0-30%
    } else if (percentage <= 70) {
      return "#ffbb33"; // Orange for 31-70%
    } else {
      return "#00C851"; // Green for 71-100%
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex", // Set row direction
          flexDirection: "row",
          justifyContent: "space-between", // Add spacing between items
          flexWrap: "wrap", // Ensure responsiveness on smaller screens
          gap: 2, // Optional: Add gap between the papers
          margin: "20px auto", // Center the container
        }}
      >
        <Paper
          sx={{
            padding: { xs: "15px", sm: "20px", md: "30px" },
            minWidth: { xs: "100%", sm: "80%", md: "765px" },
            borderRadius: "15px",
            maxWidth: "100%",
            margin: "20px auto", // Centered Paper with margin
          }}
          variant="outlined"
          elevation={0}
        >
          <Grid container spacing={3} alignItems="center">
            {/* Avatar Section */}
            <Grid item xs={12} sm={3} md={2} textAlign="center">
              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  width: 80,
                  height: 80,
                }}
              >
                {/* Circular Progress Bar */}
                <CircularProgress
                  variant="determinate" // Use "determinate" for a specific progress value
                  value={profilePercentage} // Set the progress value
                  size={92} // Slightly larger than the Avatar to create a border effect
                  thickness={3} // Thickness of the progress bar
                  sx={{
                    position: "absolute",
                    top: -6,
                    right: 0,
                    left: -6.6,
                    transform: "translate(-50%, -50%)",
                    color: getProgressColor(profilePercentage), // Customize the progress bar color
                  }}
                />

                {/* Avatar */}
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    margin: "0 auto",
                    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                    position: "relative", // Ensure Avatar is above the progress bar
                  }}
                  src={profileImage || userData?.profileImage}
                >
                  {!profileImage && formData.fullName.charAt(0)}
                </Avatar>
                <Typography
                  sx={{
                    color: `${getProgressColor(profilePercentage)}`,
                    fontFamily: "IBM plex sans",
                    fontWeight: "bold",
                    mt: 1,
                    fontSize: "20px",
                    ml: 2,
                  }}
                >
                  {profilePercentage}%
                </Typography>
              </Box>
            </Grid>

            {/* User Information */}
            <Grid item xs={12} sm={9} md={10}>
              <Box display="flex" flexDirection="column">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  mb={1}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", marginRight: "8px" }}
                  >
                    {formData.fullName}
                  </Typography>
                  <Tooltip title="Edit Profile">
                    <IconButton onClick={handleEdit} sx={{ marginLeft: "4px" }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="flex"
                  alignItems="center"
                >
                  <BusinessCenterIcon sx={{ mr: 1 }} />
                  {userData?.experiences[0]?.designation ||
                    "No Designation"} at{" "}
                  {userData?.experiences[0]?.company || "No Company"}
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="flex"
                  alignItems="center"
                  mt={1}
                >
                  <LocationOnIcon sx={{ mr: 1 }} />
                  {formData.currentLocation || "Location not available"}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Additional Fields Section */}
          <Box mt={4}>
            <Grid container spacing={2}>
              {["phone", "email", "dob", "gender", "currentLocation"].map(
                (field, index) => (
                  <Grid item xs={12} sm={4} md={6} key={index}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      gutterBottom
                    >
                      {
                        // Custom field labels
                        field === "email"
                          ? "Email ID"
                          : field === "dob"
                          ? "Date of Birth"
                          : field === "currentLocation"
                          ? "Current Location"
                          : field.charAt(0).toUpperCase() + field.slice(1)
                      }
                    </Typography>
                    <Typography variant="body1">
                      {field === "email" && formData[field] ? (
                        <>
                          {formData[field]}
                          {otpVerified && (
                            <CheckCircleIcon sx={{ ml: 1, color: "green" }} />
                          )}
                        </>
                      ) : field === "phone" ? (
                        `${formData[field] || "Add Phone"}`
                      ) : field === "dob" ? (
                        formData[field] ? (
                          formatDate(formData[field])
                        ) : (
                          "Add Date of Birth"
                        )
                      ) : field === "gender" ? (
                        formData[field] ? (
                          formData[field].charAt(0).toUpperCase() +
                          formData[field].slice(1)
                        ) : (
                          "Add Gender"
                        )
                      ) : (
                        formData[field] || `Add ${field}`
                      )}
                    </Typography>
                    {field === "email" && !otpVerified && !otpSent && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSendOtp}
                        sx={{ mt: 1, width: "100%" }}
                      >
                        Verify Email
                      </Button>
                    )}
                    {field === "email" && otpSent && !otpVerified && (
                      <Box mt={2}>
                        <Typography
                          variant="subtitle2"
                          sx={{ mb: 1, color: "text.secondary" }}
                        >
                          Enter OTP sent to your email:
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          gap={1.5}
                          mt={1}
                        >
                          {otp.map((digit, idx) => (
                            <TextField
                              key={idx}
                              value={digit}
                              onChange={(e) => handleOtpChange(e, idx)}
                              inputProps={{ maxLength: 1 }}
                              variant="outlined"
                              sx={{
                                width: 50,
                                height: 50,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "10px",
                                  "& input": {
                                    textAlign: "center",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  },
                                  "& fieldset": {
                                    borderColor: "#ccc",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#423bfa",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#423bfa",
                                  },
                                },
                              }}
                            />
                          ))}
                        </Box>
                        <Box mt={2} textAlign="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleVerifyOtp}
                            sx={{
                              borderRadius: "20px",
                              textTransform: "none",
                              fontWeight: "bold",
                              padding: "8px 24px",
                              backgroundColor: "#423bfa",
                              "&:hover": {
                                backgroundColor: "#362fd9",
                              },
                            }}
                            startIcon={<CheckCircleIcon />}
                          >
                            Verify OTP
                          </Button>
                        </Box>
                        <Box mt={1} textAlign="center">
                          <Typography variant="body2" color="textSecondary">
                            Didn’t receive the OTP?{" "}
                            <Button
                              variant="text"
                              onClick={handleSendOtp}
                              sx={{
                                textTransform: "none",
                                color: "#423bfa",
                                fontWeight: "bold",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            >
                              Resend OTP
                            </Button>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>
      <Paper
        sx={{
          padding: "20px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "15px",
          marginTop: 1,
        }}
        onClick={handleEdit}
        variant="outlined"
        elevation={3}
      >
        <Typography sx={{ fontWeight: "bold", color: "gray" }}>
          Total Year of Experience:{" "}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "18px", ml: 4 }}>
            {formData.totalExpInYears}.{formData.totalExpInMonths}
          </Typography>
          <ArrowForwardIosIcon sx={{ cursor: "pointer" }} />
        </Box>
      </Paper>

      <Paper
        sx={{
          padding: "20px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "15px",
          marginTop: 1,
        }}
        onClick={handleEdit}
        variant="outlined"
        elevation={3}
      >
        <Typography sx={{ fontWeight: "bold", color: "gray" }}>
          Notice Period:
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography sx={{ fontWeight: "bold", ml: 2 }}>
            {formData.noticePeriod}
          </Typography>
          <ArrowForwardIosIcon sx={{ cursor: "pointer" }} />
        </Box>
      </Paper>
      <Box sx={{ backgroundColor: "#f9f9f9", marginTop: 1 }}>
        <Paper
          onClick={handleEdit}
          sx={{
            padding: "20px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column", // Ensure the content is arranged in columns
            borderRadius: "15px",
          }}
          variant="outlined"
          elevation={3}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "gray",
                marginBottom: 1,
                fontSize: "16px",
              }}
            >
              Skills:
            </Typography>
            <EditIcon
              sx={{ cursor: "pointer", color: "grey" }}
              onClick={() =>
                handleEditClick("skills", userData?.skills, userData?._id)
              }
            />
          </Box>

          {/* Container for skills and the edit icon */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center", // Align items on the same line
              gap: 2,
              flexWrap: "wrap", // Wrap skills to the next line if necessary
            }}
          >
            {userData?.skills?.length > 0 ? (
              userData.skills.map((skill, index) => (
                <Chip
                  key={index}
                  label={skill}
                  sx={{
                    borderRadius: 5,
                    backgroundColor: "#e0e0e0",
                    color: "black",
                    fontSize: "0.875rem",
                    padding: "4px 8px",
                  }}
                />
              ))
            ) : (
              <Box
                sx={{ display: "flex", flexWrap: "wrap", gap: 1, marginTop: 2 }}
              >
                {formData?.skills &&
                  formData.skills.map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill}
                      sx={{
                        borderRadius: 5,
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontSize: "0.875rem",
                        padding: "4px 8px",
                      }}
                    />
                  ))}
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="edit-personal-details-dialog"
      >
        <DialogTitle id="edit-personal-details-dialog">
          <b>Edit Personal Details</b>
        </DialogTitle>
        <DialogContent dividers>
          {Object.keys(formData)
            .filter((key) => key !== "profileImage") // Exclude the profileImage key
            .map((key) => (
              <TextField
                key={key}
                label={
                  key === "dob"
                    ? "Date of Birth"
                    : key
                        .split(/(?=[A-Z])/)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                }
                fullWidth
                name={key}
                value={formData[key]}
                onChange={handleInputChange}
                variant="outlined"
                margin="normal"
                type={key === "dob" ? "date" : "text"}
                InputLabelProps={key === "dob" ? { shrink: true } : {}}
                sx={{
                  backgroundColor: "#f9f9f9", // Add subtle background for input fields
                  borderRadius: "5px",
                }}
                // Placeholder for validation
                // error={!!errors[key]}
                // helperText={errors[key]}
              />
            ))}
          <FormControl fullWidth margin="normal">
            <InputLabel id="skills-label">Skills</InputLabel>
            <Select
              labelId="skills-label"
              multiple
              value={skills} // Set the value to the selected skills
              onChange={handleSkillsChange} // Handle the change for multiple values
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              variant="outlined"
            >
              {skillOptions.map((skill) => (
                <MenuItem key={skill} value={skill}>
                  {skill}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              component="label"
              sx={{ mb: 2 }}
            >
              Upload Profile Image
              <input type="file" hidden onChange={handleImageUpload} />
            </Button>
            {profileImage ? (
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Preview:
                </Typography>
                <Avatar
                  sx={{ width: 100, height: 100, margin: "0 auto" }}
                  src={profileImage}
                />
              </Box>
            ) : null}
          </Box>
        </DialogContent>

        <DialogActions sx={{ padding: 2 }}>
          <Button
            sx={{
              borderRadius: "2rem",
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={handleModalClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              borderRadius: "2rem",
              textTransform: "none",
              background: "#423bfa",
              fontWeight: "bold",
            }}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JobSeekerPersonalDetails;
