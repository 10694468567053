import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Typography,
  Container,
  Grid,
  
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import { jwtDecode } from "jwt-decode";
import { getSecretKey } from "../../../api/jobPostAPI";

const EmployerProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCompanyDetails, setShowCompanyDetails] = useState(false);
  const [userData, setUserData] = useState({}); // Initialize as empty object
  const [securityKey, setSecurityKey] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserData(decodedToken); // Set user data from decoded token
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setSnackbarOpen(true);
  };

  const handleVerifyClick = () => {
    setShowCompanyDetails(true);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleGenerateSecurity = async () => {
    const token = localStorage.getItem("token");
    const recruiterId = localStorage.getItem("recruiterId");

    try {
      const response = await getSecretKey(token, recruiterId);
      setSecurityKey(response?.secretKey);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <EmployerDrawer>
      <Container>
        <Box sx={{ p: 3, bgcolor: "background.paper", boxShadow: 3 }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Profile
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              {isEditing ? (
                <Button
                  variant="contained"
                  onClick={handleSaveClick}
                  sx={{ bgcolor: "#423bfa", "&:hover": { bgcolor: "#423bfa" } }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleEditClick}
                  sx={{ bgcolor: "#423bfa",borderRadius:"20px",textTransform:"none", "&:hover": { bgcolor: "#423bfa" } }}
                >
                  Edit
                </Button>
              )}
            </Grid>
          </Grid>

          <Box mb={4}>
            {/* Basic Details Section */}
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Basic Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  disabled={!isEditing}
                  value={userData?.name || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  disabled={!isEditing}
                  value={userData?.email || ""}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={4}>
            {/* Mobile Section */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Mobile"
                  variant="outlined"
                  fullWidth
                  disabled={!isEditing}
                  value={userData?.mobile || "9776170950"}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={4}>
            {/* Security Key Section */}
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Security Key
            </Typography>
            <Typography
              component="legend"
              sx={{ fontSize: 16, display: "flex", alignItems: "center" }}
            >
              Security Key:{" "}
              {securityKey ? (
                <Typography sx={{ ml: 1, fontWeight: "bold", fontSize: 16 }}>
                  {securityKey}
                </Typography>
              ) : (
                <Button
                  sx={{
                    ml: 2,
                    borderRadius: "2rem",
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={handleGenerateSecurity}
                >
                  Generate Security Key
                </Button>
              )}
            </Typography>
          </Box>

          <Box mb={4}>
            {/* GST Details Section */}
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              GST Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="GST No."
                  variant="outlined"
                  fullWidth
                  disabled={!isEditing}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  variant="outlined"
                  onClick={handleVerifyClick}
                  disabled={!isEditing}
                  sx={{ textTransform: "none", alignSelf: "center" }}
                >
                  Verify
                </Button>
              </Grid>
            </Grid>

            {/* Show Company Details */}
            {showCompanyDetails && (
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  bgcolor: "background.default",
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Company Name:</strong> MILLION TALENTS INDIA PRIVATE
                  LIMITED
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Address:</strong> 10/1, V K PLAZA, RAMAMURTHY NAGAR
                  MAIN ROAD, BANASWADI, BENGALURU URBAN, Bengaluru Urban,
                  Karnataka 560043
                </Typography>
                <Typography variant="body2">
                  I verify my company details and understand that the invoices
                  would be generated using the same information.
                </Typography>
              </Box>
            )}
          </Box>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={
              showCompanyDetails
                ? "Company details verified"
                : "Profile updated successfully"
            }
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Box>
      
      </Container>
    </EmployerDrawer>
  );
};

export default EmployerProfile;
