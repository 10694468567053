import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Typography,
  IconButton,
  Box,
  Paper,
  Button,
  Menu,
  MenuItem,
  Container,
  Grid,
  Skeleton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import SaveIcon from "@mui/icons-material/Save";
import { useNavigate, useLocation } from "react-router-dom";
import CandidateProfileCard from "../../../components/AdvancedComponents/CandidateSearchLists/CandidateProfileCard";
import CandidateSearchFilters from "../../../components/AdvancedComponents/CandidateSearchLists/CandidateSearchFilter";
import CustomPagination from "../../../components/AdvancedComponents/CustomPagination";
import baseURL from "../../../api/baseUrl";
import SelectJobDialog from "../../../components/AdvancedComponents/SelectJobDialog";
import { toast } from "react-toastify";
import { sendInviteApi } from "../../../api/sendInvite";
import { useContext } from "react";
import { SearchContext } from "../../../context/SearchResults";
import { addSavedSearchApi } from "../../../api/search";
import { getUnlockedCandidatesApi } from "../../../api/JobSeekerDetailsApi";

const CandidateSearchList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { profiles, setProfiles } = useContext(SearchContext);
  const [loading, setLoading] = useState(false); // Add loading state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [openSelectJob, setOpenSelectJob] = useState(false);
  const [allData, setAllData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [inviteData, setInviteData] = useState({
    userId: [],
    jobId: "",
  });
  const [age, setAge] = useState({
    minAge: "",
    maxAge: "",
  });

  const [displayedProfiles, setDisplayedProfiles] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const token = localStorage.getItem("token");
  // filters functions

  const handleAgeChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleCheckboxChange = (userId) => {
    setInviteData((prevData) => {
      const isChecked = prevData.userId.includes(userId);
      return {
        ...prevData,
        userId: isChecked
          ? prevData.userId.filter((id) => id !== userId)
          : [...prevData.userId, userId],
      };
    });
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setIsAllSelected(isChecked);
    if (isChecked) {
      const displayedProfileUserIds = displayedProfiles.map(
        (profile) => profile.id
      );
      setInviteData((prevData) => ({
        ...prevData,
        userId: [...new Set([...prevData.userId, ...displayedProfileUserIds])],
      }));
    } else {
      const displayedProfileUserIds = displayedProfiles.map(
        (profile) => profile.id
      );
      setInviteData((prevData) => ({
        ...prevData,
        userId: prevData.userId.filter(
          (id) => !displayedProfileUserIds.includes(id)
        ),
      }));
    }
  };

  useEffect(() => {
    if (displayedProfiles?.length > 0) {
      const allDisplaySelected = displayedProfiles.every((profile) =>
        inviteData.userId.includes(profile.id)
      );
      setIsAllSelected(allDisplaySelected);
    }
  }, [inviteData, displayedProfiles]);

  const handleJobTypeChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      prefferedJobType: value, //
    }));
  };

  const handleSelectJob = (event) => {
    const { value } = event.target;
    setInviteData((prevData) => {
      return {
        ...prevData,
        jobId: value,
      };
    });
  };

  const handleOpenDialog = () => {
    if (inviteData.userId.length === 0) {
      toast.error("Select one Candidate to Continue ");
    } else {
      setOpenSelectJob(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenSelectJob(false);
    setInviteData({
      userId: [],
      jobId: "",
    });
    setIsAllSelected();
  };

  const sendInvite = useCallback(async () => {
    try {
      const invitationData = {
        userIds: inviteData?.userId,
        jobId: inviteData?.jobId,
      };
      if (!inviteData.jobId) {
        toast.error("Choose a job role for Invitation");
      } else {
        const response = await sendInviteApi(token, invitationData);

        if (response?.invites) {
          toast.success(response.message);
          handleCloseDialog();
          setIsAllSelected(false);
        }
      }
    } catch (error) {
      console.log(error.message);
      handleCloseDialog();
      toast.error(error.message);
    }
  }, [inviteData, token]);

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const experienceType = query.get("experienceType");
  const keyword = query.get("keyword") || "";
  const currentLocation = useMemo(() => {
    return query.get("currentLocation")
      ? query.get("currentLocation").split(",")
      : [];
  }, [query]);
  const preferredLocation = useMemo(() => {
    return query.get("preferredLocation")
      ? query.get("preferredLocation").split(",")
      : [];
  }, [query]);
  const skills = useMemo(()=>{
    return query.get('skills')?query.get('skills').split(","):[]
  },[query])

  const minExperience = query.get("totalExpMin") || "";
  const maxExperience = query.get("totalExpMax") || "";
  const minSalary = query.get("minSalary") || "";
  const maxSalary = query.get("maxSalary") || "";
  const industry = useMemo(() => {
    return query.get("industry") ? query.get("industry").split(",") : [];
  }, [query]);
  const booleanSearch = query.get("booleanSearch");
  const minAge = query.get("minAge");
  const maxAge = query.get("maxAge");
  const jobType = query.get("jobType");
  const preferredJobType = query.get("preferredJobType");
  const gender = query.get('gender')
  const shouldntHave =   useMemo(()=>{
   return query.get('shouldntHave')?query.get('shouldntHave'):[]
  },[query]) 


 
  const [formData, setFormData] = useState({
    minimumEducation: "",
    activeIn: "",
    minExperience: minExperience,
    maxExperience: maxExperience,
    minSalary: minSalary,
    maxSalary: maxSalary,
    skills:Array.isArray(skills)?skills:[],
    preferredLocation: Array.isArray(preferredLocation)
      ? preferredLocation
      : [],
    currentLocation: Array.isArray(currentLocation) ? currentLocation : [],
    industry: Array.isArray(industry) ? industry : [],
    booleanSearch: booleanSearch,
    minAge: minAge,
    maxAge: maxAge,
    jobType: jobType,
    prefferedJobType: preferredJobType,
    experienceType: experienceType,
    gender:gender,
    shouldntHave:shouldntHave?shouldntHave:""
  });
 
  const orgId = localStorage.getItem("organizationId");
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric", // Full year (e.g., "2024")
      month: "long", // Full month name (e.g., "December")
      day: "numeric", // Numeric day (e.g., "20")n
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatYear = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric", // Only year
    };
    return date.toLocaleDateString("en-US", options);
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const route = location.pathname;

  const isUnloackedCandidateRoute = route.includes("unlock-candidates");
  const isCandidateSearchListRoute = route.includes("candidate-searchList");

  const fetchProfiles = useCallback(async () => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      const response = await fetch(
        `${baseURL}/rc/jobseekers?experienceType=${experienceType}&keyword=${keyword}&currentLocation=${formData.currentLocation}&preferredLocation=${formData.preferredLocation}&totalExpMin=${formData.minExperience}&totalExpMax=${formData.maxExperience}&minSalary=${formData.minSalary}&maxSalary=${formData.maxSalary}&page=${currentPage}&industry=${formData.industry}&booleanSearch=${booleanSearch}&minAge=${formData.minAge}&maxAge=${formData.maxAge}&jobType=${jobType}&prefferedJobType=${formData.prefferedJobType}&skills=${skills}&gender=${gender}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "Application/json",
          },
        }
      );
      const data = await response.json();
      setAllData(data);

      const transformedProfiles = data?.jobseekers?.map((profile) => ({
        id: profile._id,
        name: profile.fullName,
        phone: profile.phone,
        email: profile.email,
        profileImage: profile.profileImage,
        experience: profile.totalExpInYears,
        location: profile.currentLocation,
        workExperience: profile.experiences.map((item) => ({
          company: item.company,
          designation: item.designation,
          industry: item.industry,
          jobRole: [],
          technologiesUsed: [],
          startDate: formatYear(item.startDate),
          endDate: formatYear(item.endDate),
        })),

        industries: [],
        departments: [],
        education: profile.educations.map((item) => ({
          degree: item.degree,
          degreeType: item.degreeType,
          institution: item.institution,
          startDate: formatYear(item.startDate),
          endDate: formatYear(item.endDate),
        })),

        skills: profile.skills || [],
        gender: profile.gender,
        age: calculateAge(profile.dob),
        preferredLocations: [profile.preferredLocation],
        profileViewCount: profile?.profileViewCount,
        candidatePreference: "",
        languages: profile.knownLanguage.map((item) => item),
        isSelected: false,
        videoCV: profile?.videoCV?.[0]?.url,
        resume: profile.resume.map((item) => item),
        updatedAt: formatDate(profile.updatedAt),
      }));
      setProfiles(transformedProfiles);
      setTotalPages(data?.pagination?.totalPages || 1);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching completes
    }
  }, [
    booleanSearch,
    currentPage,
    experienceType,
    formData.currentLocation,
    formData.industry,
    formData.maxAge,
    formData.minAge,
    formData.maxExperience,
    formData.minExperience,
    formData.maxSalary,
    formData.minSalary,
    formData.prefferedJobType,
    jobType,
    formData.preferredLocation,
    keyword,
    setProfiles,
    token,
    gender,
    skills,
  //  shouldntHave
  ]);

  

  const getUnloackedCandidates = useCallback(async () => {
    try {
      const response = await getUnlockedCandidatesApi(orgId, token);
      
      const transformedProfiles = response?.seenUsers?.map((profile) => ({
        id: profile._id,
        name: profile.fullName,
        phone: profile.phone,
        email: profile.email,
        profileImage: profile.profileImage,
        prefferedJobType:profile.prefferedJobTitle ? profile.prefferedJobTitle: profile.appliedPosition,
        experience: profile.totalExpInYears,
        location: profile.currentLocation,
        workExperience: profile.experiences.map((item) => ({
          company: item.company,
          designation: item.designation,
          industry: item.industry,
          jobRole: [],
          technologiesUsed: [],
          startDate: formatYear(item.startDate),
          endDate: formatYear(item.endDate),
        })),

        industries: [],
        departments: [],
        education: profile.educations.map((item) => ({
          degree: item.degree,
          degreeType: item.degreeType,
          institution: item.institution,
          startDate: formatYear(item.startDate),
          endDate: formatYear(item.endDate),
        })),

        skills: profile.skills || [],
        gender: profile.gender,
        age: calculateAge(profile.dob),
        preferredLocations: [profile.preferredLocation],
        profileViewCount: profile?.profileViewCount,
        candidatePreference: "",
        languages: profile.knownLanguage.map((item) => item),
        isSelected: false,
        videoCV: profile?.videoCV?.[0]?.url,
        resume: profile.resume.map((item) => item),
        updatedAt: formatDate(profile.updatedAt),
      }));
      setProfiles(transformedProfiles);
    } catch (error) {
      console.log(error.message);
    }
  }, [orgId, setProfiles, token]);

  useEffect(() => {
    if (isUnloackedCandidateRoute) {
      getUnloackedCandidates();
    } else if (isCandidateSearchListRoute) {
      fetchProfiles();
    }
  }, [
    currentPage,
    itemsPerPage,
    formData,
    isCandidateSearchListRoute,
    isUnloackedCandidateRoute,
    fetchProfiles,
    getUnloackedCandidates,
  ]);

  //  [
  //   experienceType,
  //   keyword,
  //   formData,
  //   token,
  //   currentLocation,
  //   preferredLocation,
  //   currentPage,
  //   itemsPerPage,
  //   setProfiles,
  //   booleanSearch,
  //   industry,
  //   maxAge,
  //   minAge,
  //   jobType,
  //   preferredJobType
  // ])

  useEffect(() => {
    localStorage.setItem("profiles", JSON.stringify(profiles));
  }, [profiles]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewDetails = (id) => {
    navigate(`/candidate-profile/${id}`);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      setInviteData({
        userId: [],
        jobId: "",
      });
    }
  };

  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const savedFormValues = {
    recruiterId: localStorage.getItem("recruiterId"),
    organizationId: localStorage.getItem("organizationId"),
    searchQuery: {
      experienceType: experienceType,
      keyword: keyword,
      currentLocation: currentLocation,
      preferredLocation: preferredLocation,
      industry: industry,
      minExperience: formData.minExperience,
      maxExperience: formData.maxExperience,
      minSalary: formData.minSalary,
      maxSalary: formData.maxSalary,
      minAge: formData.minAge,
      maxAge: formData.maxAge,
      jobType: formData.jobType,
      preferredJobType: formData.prefferedJobType,
      booleanSearch:formData.booleanSearch,
      shouldntHave:shouldntHave
    },
  };

  const handleSaveSearch = async () => {
    try {
      await addSavedSearchApi(token, savedFormValues);

      toast.success("Search Saved");
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleModifySearch = () => {
    // Construct the search data object
    const searchData = {
      searchQuery: {
        experienceType: experienceType,
        keyword: keyword,
        currentLocation: formData.currentLocation,
        preferredLocation: formData.preferredLocation,
        industry: formData.industry,
        minExperience: formData.minExperience,
        maxExperience: formData.maxExperience,
        minSalary: formData.minSalary,
        maxSalary: formData.maxSalary,
        minAge: formData.minAge,
        maxAge: formData.maxAge,
        jobType: formData.jobType,
        preferredJobType: formData.prefferedJobType,
        gender:formData.gender,
        
      },
    };

    // Save the search data to localStorage
    localStorage.setItem("savedSearchData", JSON.stringify(searchData));

    // Navigate to the SearchCandidate component
    navigate("/search-candidates");
  };
  
  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const slicedProfiles = profiles?.slice(startIndex, endIndex);

    setDisplayedProfiles(slicedProfiles);
  }, [profiles, currentPage, itemsPerPage]);

  return (
    <EmployerDrawer>
       {isUnloackedCandidateRoute && (
          <Box>
          <Typography
            onClick={handleBack}
            gutterBottom
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              mb: "15px",
              color: "grey", // Adjust color for better visibility
              "&:hover": {
                color: "primary.dark", // Hover effect
              },
            }}
          >
            <ArrowBackIcon sx={{ marginRight: "4px" }} />
          </Typography>
        </Box>
        )}
      <Container>
       
        {isCandidateSearchListRoute && (
          <Paper sx={{ padding: 2, backgroundColor: "#ffffff", boxShadow: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Header Section */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="body2">
                  <b>
                    {loading ? (
                      <Skeleton width={100} />
                    ) : (
                      allData?.pagination?.totalJobseekers
                    )}
                  </b>{" "}
                  profiles found for <b>{experienceType}</b>
                  <Typography
                    onClick={handleClick}
                    component="span"
                    sx={{
                      color: "green",
                      fontWeight: "bold",
                      marginLeft: 1,
                      display: "inline",
                      cursor: "pointer",
                    }}
                  >
                    View details
                  </Typography>
                  <IconButton onClick={handleClick}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </Typography>
              </Box>

              {/* Save Search and Modify Search Buttons */}
              <Box sx={{ display: "flex", gap: 2, marginLeft: "auto" }}>
                {" "}
                {/* Use flex to align buttons */}
                {/* Modify Search Button */}
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "20px",
                    textTransform: "none",
                    color: "#ffffff",
                    backgroundColor: "#423bfa", // Text color
                    borderColor: "#423bfa", // Border color
                    "&:hover": {
                      backgroundColor: "#5C58D8FF", // Hover background color
                      borderColor: "#423bfa", // Hover border color
                    },
                  }}
                  onClick={handleModifySearch} // Add your modify search handler
                >
                  Modify Search
                </Button>
                {/* Save Search Button */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#423bfa",
                    borderRadius: "20px",
                    textTransform: "none",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#5C58D8FF",
                    },
                  }}
                  onClick={handleSaveSearch} // Add your save search handler
                >
                  Save Search
                </Button>
              </Box>
            </Box>

            {/* Menu Section */}
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem onClick={handleClose}>
                Show results for: <b>{experienceType}</b>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                Keywords: <b>{keyword}</b>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                Locations: <b>{formData.currentLocation}</b>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                Min. Experience: <b>{formData.minExperience}</b>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                Max. Experience: <b>{formData.maxExperience}</b>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                Min. Salary: <b>{formData.minSalary}</b>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                Max. Salary: <b>{formData.maxSalary}</b>
              </MenuItem>
            </Menu>
          </Paper>
        )}
        <Grid
          container
          spacing={2}
          justifyContent={!isCandidateSearchListRoute ? "center" : "flex-start"}
        >
          {isCandidateSearchListRoute && (
            <Grid item xs={12} md={4}>
              <Typography variant="h6" fontWeight="bold" sx={{ padding: 3 }}>
                Filters (
                {
                  Object.values(formData).filter((value) =>
                    Array.isArray(value) ? value.length > 0 : value
                  ).length
                }
                )
              </Typography>

              <CandidateSearchFilters
                formData={formData}
                handleChange={handleChange}
                handleAgeChange={handleAgeChange}
                handleJobTypeChange={handleJobTypeChange}
                setFormData={setFormData}
                setAge={setAge}
                age={age}
                industry={formData.industry} // Pass industry from formData
                setIndustry={(newIndustry) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    industry: newIndustry,
                  }))
                }
              />
            </Grid>
          )}

          <Grid item xs={12} md={8}>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", padding: 2 }}
            >
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages} // Use totalPages from the API response
                  onPageChange={handlePageChange}
                  itemsPerPage={itemsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between", // Adjusted to space-between to align items properly
                alignItems: "center",
                gap: 2,
                background: "white",
                p: 2,
                mb: 3,
                borderBottom: "1px solid gray",
                borderRadius: "4px",
                position: "sticky",
                top: 55,
                zIndex: 10,
                borderTop: "1px solid #ddd",
              }}
            >
              {/* Left side: Select All Checkbox */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAllSelected} // Replace with your state variable
                      onChange={handleSelectAll} // Replace with your select all handler
                      color="primary"
                    />
                  }
                  label="Select All"
                  sx={{ fontFamily: "IBM plex sans", fontWeight: "bold" }}
                />
              </Box>

              {/* Right side: Selected Candidates Count and Send Invite Button */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography sx={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  {inviteData.userId.length} Candidate Selected
                </Typography>
                <Button
                 disabled = {inviteData.userId.length === 0}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    borderRadius: "20px",
                    backgroundColor: "#423BFA",
                    ":hover": { backgroundColor: "#423BFA" },
                  }}
                  onClick={handleOpenDialog}
                >
                  Send Invite
                </Button>
              </Box>
            </Box>

            {/* Loading Skeleton */}
            {loading ? (
              <Grid container spacing={2}>
                {Array.from({ length: itemsPerPage }).map((_, index) => (
                  <Grid item xs={24} md={12} key={index}>
                    <Skeleton variant="rectangular" height={300} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              displayedProfiles?.map((profile) => (
                <React.Fragment key={profile.id}>
                  <CandidateProfileCard
                    profile={profile}
                    onViewDetails={handleViewDetails}
                    handleCheckboxChange={handleCheckboxChange}
                    inviteData={inviteData}
                    keyword={keyword}
                    booleanSearch={booleanSearch}
                  />
                </React.Fragment>
              ))
            )}
          </Grid>
        </Grid>

        {/* Select Job Dialog */}
        <SelectJobDialog
          openSelectJob={openSelectJob}
          inviteData={inviteData}
          handleSelectJob={handleSelectJob}
          handleCloseDialog={handleCloseDialog}
          sendInvite={sendInvite}
        />

        {/* Toast Container for Notifications */}
      </Container>
    </EmployerDrawer>
  );
};

export default CandidateSearchList;
