import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Typography,
  Box,
 
} from '@mui/material';

import { ExpandMore } from '@mui/icons-material';





const AdditionalInfo = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4, backgroundColor: '#f7f7f7' }}>
      <Card sx={{ padding: 4, borderRadius: 2, boxShadow: 3, backgroundColor: 'white', width: '100%' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            Additional Information
          </Typography>

          {/* Accordion for Selected Plan */}
          <Accordion sx={{ marginBottom: 2 }}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                Selected Plan
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.6, color: 'gray' }}>
                The Premium Membership Plan includes 3 products: Resume Display, Jobs on Mail and SMS, and Recruiter Connection. The Basic Membership Plan includes only 1 product: Resume Display. We recommend the Premium Membership Plan as a comprehensive solution for advancing your job search. Recruitment on Vacanzi works as follows:
              </Typography>

              <Typography variant="body2" paragraph sx={{ lineHeight: 1.6, marginLeft: 2, color: 'gray' }}>
                <b>a.</b> Resume Display ensures that your profile ranks higher in search results when recruiters search for candidates like you.
              </Typography>
              <Typography variant="body2" paragraph sx={{ lineHeight: 1.6, marginLeft: 2, color: 'gray' }}>
                <b>b.</b> Jobs on Mail and SMS ensures that you receive the most relevant job opportunities and apply within 24 hours, giving you an edge over other candidates.
              </Typography>
              <Typography variant="body2" paragraph sx={{ lineHeight: 1.6, marginLeft: 2, color: 'gray' }}>
                <b>c.</b> Recruiter Connection allows you to directly contact and share your profile with active recruiters.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* Accordion for Delivery Information */}
          <Accordion sx={{ marginBottom: 2 }}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                Delivery Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.6, color: 'gray' }}>
                <b>Resume Display:</b> After payment, you will receive an email within 24 hours requesting your latest resume. The service will be processed within 6 working days once we receive your updated resume. If no response is received within 7 days, we will use your last updated Vacanzi resume. You will receive an email to review and approve your new profile to activate your service. If you do not review the profile within 7 days, it will be deemed approved. Your profile will be featured in Vacanzi's recruiter database, and we will refresh it weekly for added visibility.
              </Typography>

              <Typography variant="body1" paragraph sx={{ lineHeight: 1.6, color: 'gray' }}>
                <b>Jobs on Mail and SMS:</b> After payment, you will receive an email within 24 hours requesting your latest resume. A Vacanzi Expert will contact you to discuss your job preferences. After this, you will start receiving handpicked job opportunities via email and SMS every Tuesday and Friday. Services will be processed within 6 working days after we receive your resume. If no response is received within 7 days, we will use your last updated resume. You will receive one call from a Vacanzi Expert within 6 working days, and we will attempt to contact you up to 3 times. If we do not hear from you after the 3rd attempt, we will initiate the activation of the 4U service and consider it approved.
              </Typography>

              <Typography variant="body1" paragraph sx={{ lineHeight: 1.6, color: 'gray' }}>
                <b>Recruiter Connection:</b> You will receive 2 Recruiter Connection credits every month, which you can use to send personalized messages to recruiters. One credit equals one message. Credits are non-refundable and valid for 6 months. Please note that you cannot send a message to the same recruiter within 30 days. If a recruiter does not view your message within 15 days, you will receive a one-time credit refund.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* Accordion for Cancellation */}
          <Accordion sx={{ marginBottom: 2 }}>
            <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel3a-content" id="panel3a-header">
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                Cancellation
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.6, color: 'gray' }}>
                You can cancel your monthly Vacanzi subscription at any time through the Manage Subscriptions Page.
              </Typography>

              <Typography variant="body1" paragraph sx={{ lineHeight: 1.6, color: 'gray' }}>
                Alternatively, you can submit a cancellation request via email, 5 days before your service expires. Send the request to funsubscribe@vacanzi.com from your registered email ID. We will process your cancellation within 48 working hours, and your current subscription will remain unaffected until its expiry.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AdditionalInfo;
