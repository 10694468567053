import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  TextField,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import searchCandidates from "../../styles/searchCandidatesStyles";
import WorkDetails from "./WorkDetails";

const internalCss = {
  marginRight: 2,
  fontWeight: "bold",
  textTransform: "none",
  borderRadius: 10,
  border: "1px solid black",
  alignItems: "center",
  marginTop: { xs: 2, sm: 0 },
  "&:hover": {
    backgroundColor: "#423bfa",
    color: "white",
    border: "1px solid #423bfa",
  },
};

const ageDummy = Array.from({ length: 60 - 18 + 1 }, (_, index) => ({
  id: index + 18,
  value: index + 18 < 60 ? (index + 18).toString() : "60+",
}));

const AdditionalDetails = ({
  setAge,
  handleJobType,
  handlePrefferedJobType,
  jobType,
  prefferedJobType,
  gender,
  setGender,
  initialMaxAge,
  initialMinAge,
}) => {
  // Map initial ages to options in ageDummy
  
  // Initialize minAge and maxAge with the correct options
  const [minAge, setMinAge] = useState()
  const [maxAge, setMaxAge] = useState()

  const initialMinAgeOption = Number(initialMinAge)
  const initialMaxAgeOption = Number(initialMaxAge)



  // Handle min age change
  const handleMinAge = (event, value) => {
    setMinAge(value);
    setAge((prev) => ({ ...prev, minAge: value })); // Update parent state
  };

  // Handle max age change
  const handleMaxAge = (event, value) => {
    setMaxAge(value);
    setAge((prev) => ({ ...prev, maxAge: value })); // Update parent state
  };

  return (
    <Paper
      elevation={0}
      sx={{
        mt: 2, // Top margin
        mb: 2, // Bottom margin
        overflow: "hidden", // To ensure proper border-radius effect
        borderRadius: "8px", // Optional for rounded corners
      }}
    >
      <Accordion
        defaultExpanded
        sx={{
          boxShadow: "none", // Remove default Accordion shadow
          borderRadius: "inherit", // Match Accordion to Paper's borderRadius
          pt: 2,
          pb: 4,
          "&:before": {
            display: "none", // Remove the default Accordion divider line
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Additional Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Typography sx={{ fontWeight: "regular", mt: 1, fontSize: 14 }}>
              Gender
            </Typography>
            <Box sx={searchCandidates.flexboxCenter}>
              <Button
                variant="outlined"
                sx={{
                  ...(gender === "other"
                    ? {
                        ...internalCss,
                        backgroundColor: "#423bfa",
                        color: "white",
                      }
                    : { ...internalCss, color: "#423bfa" }),
                }}
                onClick={() => setGender("other")}
              >
                Other
              </Button>
              <Button
                sx={{
                  ...(gender === "male"
                    ? {
                        ...internalCss,
                        backgroundColor: "#423bfa",
                        color: "white",
                      }
                    : { ...internalCss, color: "#423bfa" }),
                }}
                onClick={() => setGender("male")}
              >
                Male{" "}
              </Button>
              <Button
                sx={{
                  ...(gender === "female"
                    ? {
                        ...internalCss,
                        backgroundColor: "#423bfa",
                        color: "white",
                      }
                    : { ...internalCss, color: "#423bfa" }),
                }}
                onClick={() => setGender("female")}
              >
                Female
              </Button>
            </Box>
            <Box>
              <Typography
                sx={{ fontWeight: "regular", mt: 1, fontSize: 14, mb: 1 }}
              >
                Candidate Age
              </Typography>
            </Box>
            <Box>
              <Box sx={{ ...searchCandidates.flexboxCenter, gap: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Autocomplete
                    size="small"
                    sx={{ width: 200 }}
                    options={ageDummy.map((option) => option.value)}
                    value={minAge?minAge:initialMinAgeOption}
                    onChange={handleMinAge}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: 12 },
                        }}
                        placeholder="Min"
                        variant="outlined"
                        sx={{ marginBottom: 2 }}
                      />
                    )}
                  />
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Autocomplete
                    size="small"
                    sx={{ width: 200 }}
                    options={ageDummy.map((option) => option.value)}
                    value={maxAge?maxAge:initialMaxAgeOption}
                    onChange={handleMaxAge}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: 12 },
                        }}
                        placeholder="Max"
                        variant="outlined"
                        sx={{ marginBottom: 2 }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Typography
                sx={{ fontWeight: "regular", mt: 1, mb: 1, fontSize: 14 }}
              >
                Work Details
              </Typography>
              <WorkDetails
                handleJobType={handleJobType}
                handlePrefferedJobType={handlePrefferedJobType}
                jobType={jobType}
                prefferedJobType={prefferedJobType}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{ borderColor: "#ddd" }} /> {/* Bottom Divider */}
    </Paper>
  );
};

export default AdditionalDetails;