import React, { useEffect } from "react";
import { Box, Card, CardContent, Typography, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useRef } from "react";

const Topcompanies = ({ companiesDetails, onIndustrySelect,selectedIndustry }) => {
  const cardRef = useRef()

// logic for scrolling the cards

  const scrollContainer = (direction) => {
    const card = cardRef.current
    if (direction === "left") {
      card.scrollBy({ left: -200, behavior: "smooth" });
    } else {
      card.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  
  const handleIndustryClick = (industry) => {
    onIndustrySelect(industry);
    if (onIndustrySelect) {
      onIndustrySelect(industry); 
    }
  };

// logic to show usere which card is selected when industry selected from navbar

  useEffect(()=>{
    if(selectedIndustry && cardRef.current){
      const selectedIndex = companiesDetails.findIndex(industry=>industry.name === selectedIndustry)
      if(selectedIndex !== -1){
        const cardwidth = 180
        const margin = 8
        const scrollposition = selectedIndex *(cardwidth + margin)
        cardRef.current.scrollTo({left:scrollposition,behavior:'smooth'})
      }
    }
  },[selectedIndustry,companiesDetails])

  

  return (
    <Container sx={{ py: 4 }}>
      {/* Header */}
      <Typography variant="h5" align="left" sx={{ mb: 4, fontWeight: "bold" }}>
        Top Hiring Companies now
      </Typography>

      {/* Outer Wrapper */}
      <Box sx={{ position: "relative", width: "100%" }}>
        {/* Scrollable Container */}
        <Box
        ref={cardRef}
          sx={{
            display: "flex",
            overflowX: "scroll",
            scrollBehavior: "smooth",
            padding: "25px", // Reduced padding for better fit
            backgroundColor: "#e7e7f1",
            borderRadius: 5,
            "&::-webkit-scrollbar": { display: "none" }, // Hides scrollbar for WebKit-based browsers
            "-ms-overflow-style": "none", // Hides scrollbar for IE and Edge
            "scrollbar-width": "none", // Hides scrollbar for Firefox
          }}
          id="companies-scroll-container"
        >
          {companiesDetails.map((company, index) => (
            <Box
              key={index}
              sx={{ flexShrink: 0, width: 180, marginRight: 2 }}
              onClick={() => handleIndustryClick(company.name)} // Trigger industry selection
            >
              <Card
                elevation={0}
                sx={{
                  height: "100%",
                  borderRadius: 3,
                  cursor: "pointer", // Adds pointer cursor on hover
                  transition: "box-shadow 0.3s ease-in-out", // Smooth transition for shadow effect
                  "&:hover": {
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", // Shadow effect on hover
                  },
                  border: company.name === selectedIndustry ? "2px solid #3f51b5" : "none", // Highlight selected industry
                }}
              >
                <CardContent>
                  {/* Title with reduced font size and ellipsis for overflow */}
                  <Typography
                    variant="body2" // Adjusted variant for reduced font size
                    color="black"
                    fontWeight="bold"
                    fontFamily='IBM plex sans'
                    sx={{
                      mb: 1,
                      whiteSpace: "nowrap", // Prevent wrapping
                      overflow: "hidden", // Hide overflowed text
                      textOverflow: "ellipsis", // Show ellipsis when text overflows
                      width: "100%", // Make sure text takes up full width
                    }}
                  >
                    {company.name} {/* Assuming company is just a string */}
                  </Typography>
                  <Typography sx={{fontSize:'13px',color:"#423bfa",display:"flex",alignItems:"center",justifyContent:"space-between",fontFamily:"IBM plex sans",fontWeight:"bold"}}>
                    {company.count} Companies <ArrowForwardIosIcon sx={{fontSize:'15px'}}/>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>

        {/* Scroll Buttons */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: -10,
            transform: "translateY(-50%)",
            zIndex: 1,
            background: "white",
            borderRadius: "50%",
          }}
        >
          <IconButton color="primary" onClick={() => scrollContainer("left")}>
            <NavigateBeforeIcon sx={{ color: "grey" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: -10,
            transform: "translateY(-50%)",
            zIndex: 1,
            background: "rgba(255, 255, 255, 0.7)",
            borderRadius: "50%",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <IconButton color="primary" onClick={() => scrollContainer("right")}>
            <NavigateNextIcon sx={{ color: "grey" }} />
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
};

export default Topcompanies;