import React, { useState, useEffect, Suspense } from "react";
import {
  Chip,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StarIcon from "@mui/icons-material/Star";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Footer from "../../../components/JobSeekerComponents/Footer/Footer";
import { jwtDecode } from "jwt-decode";
import baseURL from "../../../api/baseUrl";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { lazy } from "react";


const JobCardSkeleton = lazy(() => import("../../../utils/lazy/JobCardSkeleton"));


const JobsApplied = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) throw new Error("User not authenticated");

        const decodedToken = jwtDecode(authToken);
        const userId = decodedToken.id;

        const response = await fetch(`${baseURL}/jobs/${userId}/applied`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setJobs(data.appliedJobs || []); // Ensure jobs is an array even if data.appliedJobs is undefined
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAppliedJobs();
  }, []);

  const handlePlay = (jobId) => {
    setPlayingVideoId(playingVideoId === jobId ? null : jobId);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const postedDateConvert = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

   if (loading) {
     return (
       <Suspense fallback={<div>Loading...</div>}>
         <JobCardSkeleton />
       </Suspense>
     );
   }
 
   if (error) {
     return (
       <Typography variant="body1" color="error" sx={{ mt: 4 }}>
         {error}
       </Typography>
     );
   }

  return (
    <>
      <Box
        sx={{
          background: "linear-gradient(135deg, #F8F9FA, #CED4DA)",
          display: "flex",
          margin: 0,
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "20px",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        {jobs.length > 0 ? (
          <>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
                background: "#ffffff",
                mt: 3,
                borderRadius: "10px",
              }}
            >
              <Typography variant="h4" sx={{ fontFamily: "IBM plex sans" }}>
                {jobs.length} Applied Jobs
              </Typography>
            </Box> */}
            {jobs.map((job) => (
              <Box
                key={job.jobId?._id}
                sx={{
                  mt: 1,
                  p: 3,
                  background: "#ffffff",
                  borderRadius: "20px",
                  boxShadow: "0 6px 12px rgba(30, 10, 58, .04)",
                  marginBottom: 3,
                  padding: "24px 24px 20px",
                  transition: "box-shadow .2s linear",
                  fontFamily: "IBM Plex Sans, sans-serif",
                  position: "relative",
                  display: "flex",
                  gap: 3,
                  width: isMobile ? "90%" : "650px",
                  height: "auto",
                  "&:hover": {
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  },
                }}
              >
                {/* Left Side - Video Player */}
                <Box
                  sx={{
                    width: "160px",
                    height: "190px",
                    borderRadius: 4,
                    overflow: "hidden",
                    bgcolor: "black",
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  {playingVideoId !== job.jobId?._id ? (
                    <Box
                      component="img"
                      src={job.jobId?.jobThumbnailUrl}
                      alt="Video thumbnail"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlay(job.jobId?._id)}
                    />
                  ) : (
                    <Box
                      component="video"
                      src={job.jobId?.jobVideoUrl}
                      alt="Video"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      autoPlay
                      controls
                    />
                  )}
                  {playingVideoId !== job.jobId?._id && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "rgba(0,0,0,0.6)",
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlay(job.jobId?._id)}
                    >
                      <PlayArrowIcon sx={{ fontSize: 24, color: "white" }} />
                    </Box>
                  )}
                </Box>

                {/* Right Side - Job Content */}
                <Box sx={{ flex: 1 }}>
                  {/* Header Section */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/JobSeekerFilter/job/${job.jobId?._id}`)}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "IBM Plex Sans, sans-serif",
                          fontWeight: 600,
                          color: "#1A1A1A",
                          fontSize: "1.1rem",
                          mb: 0.5,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {job.jobId?.jobTitle}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mb: 0.5,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "IBM Plex Sans, sans-serif",
                            color: "#484848",
                            fontSize: "0.95rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {job.jobId?.organizationId?.organizationName}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <Typography
                            sx={{
                              bgcolor: "#FEF8E6",
                              color: "#B17D1B",
                              px: 0.8,
                              py: 0.2,
                              borderRadius: 1,
                              fontSize: "0.8rem",
                              display: "flex",
                              alignItems: "center",
                              fontFamily: "IBM Plex Sans, sans-serif",
                            }}
                          >
                            <StarIcon sx={{ fontSize: "0.9rem", mr: 0.3 }} />
                            3.5
                          </Typography>
                          <Typography
                            sx={{
                              color: "#666",
                              fontSize: "0.85rem",
                              fontFamily: "IBM Plex Sans, sans-serif",
                            }}
                          >
                            465 Reviews
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      component="img"
                      src={job.jobId?.organizationId?.companyLogo?.url}
                      sx={{
                        width: 45,
                        height: 45,
                        borderRadius: 1,
                        flexShrink: 0,
                        objectFit: "cover",
                      }}
                    />
                  </Box>

                  {/* Details Section */}
                  <Box sx={{ display: "flex", gap: 3, mb: 2 }}>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <WorkOutlineOutlinedIcon
                        sx={{ color: "#666", fontSize: "1.1rem" }}
                      />
                      <Typography
                        sx={{
                          color: "#484848",
                          fontSize: "0.9rem",
                          fontFamily: "IBM Plex Sans, sans-serif",
                        }}
                      >
                        {job.jobId?.requiredExperience
                          ? job.jobId?.requiredExperience
                          : "Not Mentioned"}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: "#484848",
                        fontSize: "0.9rem",
                        fontFamily: "IBM Plex Sans, sans-serif",
                      }}
                    >
                      ₹ {job.jobId?.ctc ? job.jobId?.ctc : "Not Disclosed"}
                    </Typography>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <LocationOnOutlinedIcon
                        sx={{ color: "#666", fontSize: "1.1rem" }}
                      />
                      <Typography
                        sx={{
                          color: "#484848",
                          fontSize: "0.9rem",
                          fontFamily: "IBM Plex Sans, sans-serif",
                        }}
                      >
                        {job.jobId?.jobLocation}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Role Description */}
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.8 }}>
                    <DescriptionOutlinedIcon
                      sx={{ color: "#666", fontSize: "1.1rem" }}
                    />
                    <Typography
                      sx={{
                        color: "#484848",
                        fontSize: "0.9rem",
                        fontFamily: "IBM Plex Sans, sans-serif",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        maxWidth: "100%",
                      }}
                    >
                      Role: {truncateText(job.jobId?.jobRole || "", 50)}
                    </Typography>
                  </Box>

                  {/* Tags Section */}
                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 1 }}
                  >
                    {job.jobId?.skillPreferences?.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        sx={{
                          bgcolor: "#F2F2F2",
                          color: "#484848",
                          fontSize: "0.8rem",
                          height: 24,
                          fontFamily: "IBM Plex Sans, sans-serif",
                          "&:hover": {
                            bgcolor: "#E8E8E8",
                          },
                        }}
                      />
                    ))}
                  </Box>

                  {/* Footer Section */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 0.5,
                      }}
                    >
                      <AccessTimeIcon
                        sx={{ color: "#666", fontSize: "0.9rem" }}
                      />
                      <Typography
                        sx={{
                          color: "#666",
                          fontSize: "0.85rem",
                          fontFamily: "IBM Plex Sans, sans-serif",
                        }}
                      >
                        Posted on {postedDateConvert(job.jobId?.postDate)}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#666",
                          fontSize: "0.85rem",
                          fontFamily: "IBM Plex Sans, sans-serif",
                          marginLeft: "20px",
                        }}
                      >
                        Applied on {postedDateConvert(job?.appliedAt)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Typography variant="h5" component="h2" mt={4} mb={2}>
              No applied jobs found
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={4}>
              You haven't applied to any jobs yet. Start exploring and apply to
              jobs that interest you!
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#423bfa",
                borderRadius: "20px",
                textTransform: "none",
              }}
              onClick={() => navigate("/findJobs")}
            >
              Find Job
            </Button>
          </Box>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default JobsApplied;
