import React, { useState } from "react";
import {
  TextField,
  FormControl,
  Switch,
  Button,
  Grid,
  Typography,
  Paper,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {
  departmentOptions,
  experienceOptions,
  industryOptions,
  jobTypeOptions,
  keySkills,
  pgEducations,
  salaryOptions,
  ugEducations,
} from "./jobPostStaticData";
import { Add, Remove } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { saveJobAsDraftApi } from "../../../api/jobPostAPI";
import { toast, ToastContainer } from "react-toastify";
import jobPostSchema from "./jobPostValidation";
import { z } from "zod";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";

const EmployerJobPostPage = () => {
  const [formData, setFormData] = useState({
    organizationId: localStorage.getItem("organizationId"),
    jobTitle: "",
    secretKey: "",
    jobRole: "",
    jobLocation: "",
    educationRequired: "",
    experienceRequired: "",
    ctc: "",
    department: "",
    industry: "",
    interviewLocation: {
      address: {
        addressLine1: "",
        addressLine2: "",
        landmark: "",
        city: "",
        postalCode: "",
      },
    },
    jobType: "",
    noOfPostionsOpen: 1,
    skillPreferences: [],
    walkIn: false,
    revealSalary: false,
    startDate: null,
    endDate: null,
  });

  const [newSkill, setNewSkill] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [responseMessage, setResponseMessage] = useState();


  // Handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle address field changes
  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      interviewLocation: {
        ...prevData.interviewLocation,
        address: {
          ...prevData.interviewLocation.address,
          [name]: value,
        },
      },
    }));
  };

  // Handle skills changes
  const handleSkillsChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      skillPreferences: newValue,
    }));
  };

  // Add a new skill
  const handleAddSkill = () => {
    if (newSkill && !formData.skillPreferences.includes(newSkill)) {
      setFormData((prevData) => ({
        ...prevData,
        skillPreferences: [...prevData.skillPreferences, newSkill],
      }));
      setNewSkill("");
    }
  };

  // Remove a skill
  const handleRemoveSkill = (skillToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      skillPreferences: prevData.skillPreferences.filter(
        (skill) => skill !== skillToRemove
      ),
    }));
  };

  // Handle autocomplete changes
  const handleAutocompleteChange = (name) => (event, newValue) => {
    setFormData({ ...formData, [name]: newValue });
  };

  // Handle switch changes
  const handleSwitchChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.checked });
  };

  // Handle start and end date changes
  const handleStartDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      startDate: e.target.value,
    }));
  };

  const handleEndDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      endDate: e.target.value,
    }));
  };

  // Validate the form
  const validateForm = () => {
    try {
      // Validate the form data against the schema
      jobPostSchema.parse(formData);
      setFormErrors({});
      setIsFormValid(true); // Form is valid
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const errors = {};
        error.errors.forEach(({ path, message }) => {
          errors[path[0]] = message;
        });
        setFormErrors(errors);
        console.log("Validation errors:", errors); // Log validation errors
      }
      setIsFormValid(false); // Form is invalid
      return false;
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    console.log("Form submitted. Validating form...");
    if (validateForm()) {
      console.log("Form is valid. Opening dialog...");
      setOpenDialog(true); // Open the key dialog if the form is valid
    } else {
      console.log("Form is invalid. Errors:", formErrors);
    }
  };

  // Handle key submission
  const handleSecuityKeySubmit = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await saveJobAsDraftApi(token, formData);

      console.log("status", response.status);

      // Check if the response contains a message
      if (response.message) {
        // Store the response message in state
        setResponseMessage(response.message);

        // Show the response message in a toast
        toast.success(response.message);

        // If the response is successful, reset the form data
        if (response.message === "Job post saved as draft successfully") {
          setFormData({
            organizationId: localStorage.getItem("organizationId"),
            jobTitle: "",
            secretKey: "",
            jobRole: "",
            jobLocation: "",
            educationRequired: "",
            experienceRequired: "",
            ctc: "",
            department: "",
            industry: "",
            interviewLocation: {
              address: {
                addressLine1: "",
                addressLine2: "",
                landmark: "",
                city: "",
                postalCode: "",
              },
            },
            jobType: "",
            noOfPostionsOpen: 1,
            skillPreferences: [],
            walkIn: false,
            revealSalary: false,
            startDate: null,
            endDate: null,
          });
        }

        // Close the dialog
        setOpenDialog(false);
      } else {
        // Handle cases where the response does not contain a message
        setResponseMessage("No message received from the server.");
        toast("No message received from the server.");
      }
    } catch (error) {
      console.error(error);

      // Handle API errors
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Store the error message in state
        setResponseMessage(error.response.data.message);

        // Show the error message in a toast
        toast.error(error.response.data.message);
      } else {
        // Handle unexpected errors
        setResponseMessage("An unexpected error occurred. Please try again.");
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  console.log("formdata", formData);
  console.log(isFormValid)
  console.log(responseMessage)
  return (
    <EmployerDrawer>
      <div
        style={{
          padding: "5px",
        }}
      >
        <CssBaseline />
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
            gap: 1,
            cursor: "pointer",
          }}
          onClick={() => navigate("/employerJobListings")}
        >
          <ArrowBackIcon sx={{ color: "blue" }} />
          <Typography sx={{ color: "blue", fontSize: 12, fontWeight: "bold" }}>
            Go Back
          </Typography>
        </Box> */}
        <Paper
          elevation={0}
          style={{
            padding: "20px",
            maxWidth: "90%",
            margin: "20px auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: "bold",
              marginBottom: 3,
              fontSize: 24,
              opacity: 0.8,
            }}
          >
            Post a Job
          </Typography>

          {/* Form Fields */}
          <Grid container spacing={2}>
            {/* Job Title */}
            <Grid item xs={12} sm={6}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Position Name
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Position Name"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                required
                size="small"
                sx={{ marginTop: 1, backgroundColor: "#ffffff" }}
                error={!!formErrors.jobTitle}
                helperText={formErrors.jobTitle}
              />
            </Grid>

            {/* Job Location */}
            <Grid item xs={12} sm={6}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Job City
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Job City"
                name="jobLocation"
                value={formData.jobLocation}
                onChange={handleChange}
                required
                size="small"
                sx={{ marginTop: 1, backgroundColor: "#ffffff" }}
                error={!!formErrors.jobLocation}
                helperText={formErrors.jobLocation}
              />
            </Grid>

            {/* Role Description */}
            <Grid item xs={12}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Role Description
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Role Description"
                name="jobRole"
                value={formData.jobRole}
                onChange={handleChange}
                multiline
                rows={3}
                required
                size="small"
                sx={{ marginTop: 1, backgroundColor: "#ffffff" }}
                error={!!formErrors.jobRole}
                helperText={formErrors.jobRole}
              />
            </Grid>

            {/* Education */}
            <Grid item xs={12} sm={6}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Education
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Autocomplete
                options={[...ugEducations, ...pgEducations]}
                getOptionLabel={(option) => option}
                value={formData.educationRequired}
                onChange={handleAutocompleteChange("educationRequired")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Education"
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ marginTop: 1, backgroundColor: "#ffffff" }}
                    error={!!formErrors.educationRequired}
                    helperText={formErrors.educationRequired}
                  />
                )}
              />
            </Grid>

            {/* Experience */}
            <Grid item xs={12} sm={6}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Experience
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Autocomplete
                options={experienceOptions}
                value={formData.experienceRequired}
                onChange={handleAutocompleteChange("experienceRequired")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Experience"
                    required
                    size="small"
                    sx={{ marginTop: 1, backgroundColor: "#ffffff" }}
                    error={!!formErrors.experienceRequired}
                    helperText={formErrors.experienceRequired}
                  />
                )}
              />
            </Grid>

            {/* CTC Range */}
            <Grid item xs={12} sm={6}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                CTC Range
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Autocomplete
                options={salaryOptions}
                value={formData.ctc}
                onChange={handleAutocompleteChange("ctc")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="CTC Range"
                    required
                    size="small"
                    sx={{ backgroundColor: "#ffffff" }}
                    error={!!formErrors.ctc}
                    helperText={formErrors.ctc}
                  />
                )}
              />
            </Grid>

            {/* Department */}
            <Grid item xs={12} sm={6}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Department
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Autocomplete
                options={departmentOptions}
                value={formData.department}
                onChange={handleAutocompleteChange("department")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Department"
                    required
                    size="small"
                    sx={{ backgroundColor: "#ffffff" }}
                    error={!!formErrors.department}
                    helperText={formErrors.department}
                  />
                )}
              />
            </Grid>

            {/* Industry */}
            <Grid item xs={12} sm={6}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Industry
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Autocomplete
                options={industryOptions}
                value={formData.industry}
                onChange={handleAutocompleteChange("industry")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Industry"
                    required
                    size="small"
                    sx={{ backgroundColor: "#ffffff" }}
                    error={!!formErrors.industry}
                    helperText={formErrors.industry}
                  />
                )}
              />
            </Grid>

            {/* Job Type */}
            <Grid item xs={12} sm={6}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Job Type
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Autocomplete
                options={jobTypeOptions}
                value={formData.jobType}
                onChange={handleAutocompleteChange("jobType")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Job Type"
                    required
                    size="small"
                    sx={{ backgroundColor: "#ffffff" }}
                    error={!!formErrors.jobType}
                    helperText={formErrors.jobType}
                  />
                )}
              />
            </Grid>

            {/* Number of Open Positions */}
            <Grid item xs={12} sm={6}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                No of Open Positions
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                placeholder="Number of Open Positions"
                name="noOfPostionsOpen"
                type="number"
                value={formData.noOfPostionsOpen}
                onChange={handleChange}
                required
                size="small"
                sx={{ marginTop: 1, backgroundColor: "#ffffff" }}
                error={!!formErrors.noOfPostionsOpen}
                helperText={formErrors.noOfPostionsOpen}
              />
            </Grid>

            {/* Key Skills Required */}
            <Grid item xs={12} sm={6}>
              <Typography
                component="legend"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Key Skills Required
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: 0.5 }}
                >
                  *
                </Typography>
              </Typography>
              <Autocomplete
                multiple
                options={keySkills}
                value={formData.skillPreferences || []}
                onChange={handleSkillsChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select or add skills"
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ marginTop: 1, backgroundColor: "#ffffff" }}
                    error={!!formErrors.skillPreferences}
                    helperText={formErrors.skillPreferences}
                  />
                )}
                renderOption={(props, option, state) => (
                  <li {...props}>
                    {option}
                    <IconButton
                      onClick={() => handleRemoveSkill(option)}
                      size="small"
                      sx={{ marginLeft: "auto" }}
                    >
                      <Remove fontSize="small" />
                    </IconButton>
                  </li>
                )}
              />

              {/* Add skill functionality */}
              <Grid
                container
                spacing={1}
                sx={{
                  marginTop: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Grid item sm={8}>
                  <TextField
                    fullWidth
                    placeholder="Add a skill"
                    value={newSkill}
                    onChange={(e) => setNewSkill(e.target.value)}
                    size="small"
                    sx={{ backgroundColor: "#ffffff" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <IconButton
                    onClick={handleAddSkill}
                    color="primary"
                    sx={{
                      bgcolor: "#423bfa",
                      color: "#fff",
                      ":hover": { bgcolor: "#4CAF50", color: "#fff" },
                    }}
                  >
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            {/* Walk In Switch */}
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <Typography
                  component="legend"
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Walk In
                  <Typography
                    component="span"
                    sx={{ color: "red", marginLeft: 0.5 }}
                  ></Typography>
                </Typography>
                <Switch
                  checked={formData.walkIn}
                  onChange={handleSwitchChange("walkIn")}
                  name="walkIn"
                />
              </FormControl>
            </Grid>

            {/* Walk In Address Fields */}
            {formData.walkIn && (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography
                    component="legend"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Address Line 1
                    <Typography
                      component="span"
                      sx={{ color: "red", marginLeft: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Address Line 1"
                    name="addressLine1"
                    value={formData.interviewLocation.address.addressLine1}
                    onChange={handleAddressChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    component="legend"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Address Line 2
                    <Typography
                      component="span"
                      sx={{ color: "red", marginLeft: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Address line 2"
                    name="addressLine2"
                    value={formData.interviewLocation.address.addressLine2}
                    onChange={handleAddressChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    component="legend"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Landmark
                    <Typography
                      component="span"
                      sx={{ color: "red", marginLeft: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Landmark"
                    name="landmark"
                    value={formData.interviewLocation.address.landmark}
                    onChange={handleAddressChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    component="legend"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    City
                    <Typography
                      component="span"
                      sx={{ color: "red", marginLeft: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="City"
                    name="city"
                    value={formData.interviewLocation.address.city}
                    onChange={handleAddressChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    component="legend"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    PinCode
                    <Typography
                      component="span"
                      sx={{ color: "red", marginLeft: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    fullWidth
                    label="Pincode"
                    name="postalCode"
                    value={formData.interviewLocation.address.postalCode}
                    onChange={handleAddressChange}
                    size="small"
                  />
                </Grid>
              </>
            )}

            {/* Reveal Salary Switch */}
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <Typography
                  component="legend"
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Reveal Salary
                  <Typography
                    component="span"
                    sx={{ color: "red", marginLeft: 0.5 }}
                  ></Typography>
                </Typography>
                <Switch
                  checked={formData.revealSalary}
                  onChange={handleSwitchChange("revealSalary")}
                  name="revealSalary"
                />
              </FormControl>
            </Grid>

            {/* Start Date */}
            <Grid item xs={12} md={4}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold" }}
              >
                Start Date
              </Typography>
              <TextField
                fullWidth
                type="date"
                variant="outlined"
                value={formData.startDate || ""}
                onChange={handleStartDateChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* End Date */}
            <Grid item xs={12} md={4} sx={{ marginBottom: 2 }}>
              <Typography
                component="legend"
                variant="bold"
                sx={{ fontWeight: "bold" }}
              >
                End Date
              </Typography>
              <TextField
                fullWidth
                type="date"
                variant="outlined"
                value={formData.endDate || ""}
                onChange={handleEndDateChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: "#423BFA",
                  padding: "8px",
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "#4CAF50",
                  },
                }}
                onClick={handleSubmit}
              >
                Post Job
              </Button>
            </Grid>
          </Grid>

          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
            theme="colored"
          />

          {/* Key Dialog */}
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            sx={{ borderRadius: "20px" }}
          >
            <DialogTitle sx={{ fontFamily: "IBM plex sans" }}>
              Security Check
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ fontFamily: "IBM plex sans" }}>
                Please enter the security key to post this job.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                placeholder="Enter Security Key"
                type="password"
                fullWidth
                variant="outlined"
                name="secretKey"
                value={formData.secretKey}
                onChange={handleChange}
                sx={{ mt: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  backgroundColor: "#FF0000FF",
                  color: "#ffffff",
                  borderRadius: "20px",
                  ":hover": {
                    backgroundColor: "#FF0000FF",
                  },
                }}
                onClick={() => setOpenDialog(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSecuityKeySubmit}
                color="primary"
                variant="contained"
                sx={{ backgroundColor: "#423bfa", borderRadius: "20px" }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    </EmployerDrawer>
  );
};

export default EmployerJobPostPage;
