import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { paymentInitiateApi } from "../../api/payment";

const PaymentDialog = ({ openPayDialog, handlePayMenuClose }) => {
  const [amount, setAmount] = useState(""); // To store the custom amount

  const handleAmountChange = (event) => {
    setAmount(event.target.value); // Update the amount as the user types
  };

  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId");

  const handleContinuePayment = async () => {
    try {
      const formValues = {
        amount: amount * 100,
        organizationId: orgId,
        redirectUrl: "http://localhost:3000/paymentVerify",
      };

      const response = await paymentInitiateApi(token, formValues);
      console.log("Response from API:", response);
      if (response) {
        localStorage.setItem(
          "merchantTransactionId",
          response?.merchantTransactionId
        );
      }

      if (response?.paymentUrl) {
        window.location.href = response.paymentUrl;
      } else {
        console.error("Payment URL not found in the response");
      }
    } catch (error) {
      console.error("Error during payment initiation:", error);
    }
  };
  const handlePresetAmountClick = (presetAmount) => {
    setAmount(presetAmount); // Set the selected preset amount to the input field
  };

  return (
    <div>
      <Dialog open={openPayDialog} onClose={handlePayMenuClose}>
        <DialogTitle>Enter Amount</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {[500, 1000, 2000, 5000, 10000].map((presetAmount) => (
              <Grid item xs={6} sm={4} key={presetAmount}>
                <Card
                  sx={{
                    color: "white",
                    borderRadius: "10px",
                    cursor: "pointer",
                    backgroundColor: "#AC05A3FF",
                    border: "1px solid #ddd",
                    "&:hover": { backgroundColor: "#8B0685FF" },
                  }}
                  onClick={() => handlePresetAmountClick(presetAmount)}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ fontSize: 25 }}
                    >
                      {presetAmount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <TextField
            autoFocus
            margin="dense"
            type="number"
            fullWidth
            variant="outlined"
            value={amount}
            onChange={handleAmountChange}
            inputProps={{ min: "1" }}
            sx={{ marginTop: "20px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlePayMenuClose}
            color="primary"
            variant="contained"
            sx={{ bgcolor: "red" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleContinuePayment}
            color="primary"
            variant="contained"
          >
            Continue Payment
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentDialog;
