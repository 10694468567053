import React, { useEffect, useState } from "react";
import { Box, Grid, Container, CardMedia, Card } from "@mui/material"; // Using Grid from Material UI
// import NavBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
import JobSeekerPersonalDetails from "../../../components/JobSeekerComponents/JobSeekerProfileDetailComponent/JobSeekerPesonalDetail";
import WorkExperience from "../../../components/JobSeekerComponents/JobSeekerProfileDetailComponent/WorkExperience";
import JobSeekerEducationProfile from "../../../components/JobSeekerComponents/JobSeekerProfileDetailComponent/EducationProfile";
import bikeVideo from "../../../assets/videos/bikevideo.mp4";
import { getUserIdFromToken } from "../../../utils/userAuth";
import baseURL from "../../../api/baseUrl";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

const JobSeekerProfileDetail = () => {
  const [videoCV, setVideoCV] = useState();

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userId = await getUserIdFromToken();
      try {
        const response = await fetch(`${baseURL}/jobseeker/${userId}`);
        const data = await response.json();
        setVideoCV(data.user?.videoCV?.[0]?.url);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <>
      <NewNavBar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Center content horizontally
          alignItems: "flex-start", // Align items at the top
          minHeight: "100vh", // Ensure full viewport height
          padding: { xs: "10px", sm: "20px", md: "50px" }, // Responsive padding
          backgroundColor: "rgb(244, 245, 247)",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {/* Left Side: Video CV Card */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center", // Center the card horizontally
              }}
            >
              <Card
                sx={{
                  width: { xs: "100%", sm: "80%", md: "100%" }, // Responsive width
                  borderRadius: 4,
                  mt: { xs: 2, sm: 4, md: 7 }, // Responsive margin-top
                  height: { xs: "30vh", sm: "40vh", md: "75vh" }, // Responsive height
                }}
              >
                <CardMedia
                  component="video"
                  src={videoCV || bikeVideo}
                  controls
                  loop
                  alt={bikeVideo}
                  sx={{
                    borderRadius: 4,
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  autoPlay
                />
              </Card>
            </Grid>

            {/* Right Side: Personal Details, Work Experience, and Education Profile */}
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                }}
              >
                {/* Personal Details */}
                <Box sx={{ width: "100%" }}>
                  <JobSeekerPersonalDetails />
                </Box>

                {/* Work Experience */}
                <Box sx={{ width: "100%" }}>
                  <WorkExperience />
                </Box>

                {/* Education Profile */}
                <Box sx={{ width: "100%" }}>
                  <JobSeekerEducationProfile />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default JobSeekerProfileDetail;