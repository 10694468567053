import baseURL from "./baseUrl";


export const deleteOrganization = async(orgId,token)=>{
    try{
      const response = await fetch(`${baseURL}/organization/orgDelete/${orgId}`,{
        method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      })
      if (!response.ok) {
        throw new Error("network response was not ok");
      }
      const data = await response.json();
      return data;
    }
    catch(error){
        console.log(error.message)
    }
}