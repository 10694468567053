import React from 'react';
import { Box, Skeleton } from '@mui/material';

const FilterSkeleton = () => {
  return (
    <Box
      sx={{
        width: '250px', // Fixed width for the filter container
        height: '300px', // Fixed height for the filter container
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        padding: '16px',
      }}
    >
      {/* Shimmer Effect Styles */}
      <style>
        {`
          @keyframes shimmer {
            0% { background-position: -200% 0; }
            100% { background-position: 200% 0; }
          }

          .shimmer {
            background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
            background-size: 200% 100%;
            animation: shimmer 1.5s infinite;
          }
        `}
      </style>

      {/* Accordion Summary Skeleton */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 0,
          minHeight: '40px',
          marginBottom: '16px',
        }}
      >
        <Skeleton
          variant="text"
          width="60%"
          height={24}
          className="shimmer"
          sx={{ bgcolor: '#f0f0f0' }}
        />
        <Skeleton
          variant="circular"
          width={24}
          height={24}
          className="shimmer"
          sx={{ bgcolor: '#f0f0f0' }}
        />
      </Box>

      {/* Accordion Details Skeleton */}
      <Box sx={{ padding: '0' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {[...Array(3)].map((_, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Skeleton
                variant="circular"
                width={24}
                height={24}
                className="shimmer"
                sx={{ bgcolor: '#f0f0f0' }}
              />
              <Skeleton
                variant="text"
                width="80%"
                height={20}
                className="shimmer"
                sx={{ bgcolor: '#f0f0f0' }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default FilterSkeleton;