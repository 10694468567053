import React from "react";
// import NavBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
import { Button, Typography } from "@mui/material";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Box} from "@mui/system";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

const JobSeekerInvoice = () => {
  const downloadInvoice = async () => {
    const invoiceElement = document.getElementById("invoice");

    // Render HTML content to canvas
    const canvas = await html2canvas(invoiceElement, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");

    // Create jsPDF instance
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = 210; // A4 width in mm
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio

    // Calculate how many pages we need
    let yPosition = 0;
    const pageHeight = 297; // A4 page height in mm

    // If the content exceeds the page height, split it into pages
    while (yPosition < pdfHeight) {
      pdf.addImage(imgData, "PNG", 0, yPosition, pdfWidth, pdfHeight);
      yPosition += pageHeight;

      // Add a new page if there's more content
      if (yPosition < pdfHeight) {
        pdf.addPage();
      }
    }

    // Save the PDF
    pdf.save("Invoice.pdf");
  };

  return (
    <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:'center'}}>
      <NewNavBar />
      <Typography variant="h4" style={{ margin: "20px 0" }}>
        Job Seeker Invoice
      </Typography>
      <Button onClick={downloadInvoice} style={{ marginBottom: "20px",backgroundColor:"#423BFA",borderRadius:'20px',textTransform:"none" }} variant="contained">
        Download Invoice
      </Button>

      {/* Invoice content */}
      <div
        id="invoice"
        style={{ width: "800px", padding: "20px", backgroundColor: "white", }}
      >
        <div>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{ marginLeft: "5.85pt", borderCollapse: "col	lapse" }}
          >
            <tr>
              <td
                colSpan="7"
                style={{
                  width: "800.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "0.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              ></td>
            </tr>
            <tr style={{ height: "50.3pt" }}>
              <td
                colSpan="7"
                style={{
                  width: "554.65pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center	",
                  }}
                >
                  <img
                    src="/vacanziLogo.png"
                    alt=""
                    style={{ width: 110, height: 110 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        margin: "1.5pt 0.15pt 0pt 1.05pt",
                        textAlign: "center",
                        fontSize: "14pt",
                      }}
                    >
                      <strong>
                        <span style={{ fontFamily: "Cambria" }}>Info</span>
                      </strong>
                      <strong>
                        <span
                          style={{
                            fontFamily: "Cambria",
                            letterSpacing: "-0.25pt",
                          }}
                        >
                          {" "}
                        </span>
                      </strong>
                      <strong>
                        <span style={{ fontFamily: "Cambria" }}>Edge</span>
                      </strong>
                      <strong>
                        <span
                          style={{
                            fontFamily: "Cambria",
                            letterSpacing: "-0.25pt",
                          }}
                        >
                          {" "}
                        </span>
                      </strong>
                      <strong>
                        <span style={{ fontFamily: "Cambria" }}>(India)</span>
                      </strong>
                      <strong>
                        <span
                          style={{
                            fontFamily: "Cambria",
                            letterSpacing: "-0.25pt",
                          }}
                        >
                          {" "}
                          Ltd
                        </span>
                      </strong>
                    </p>
                    <p
                      style={{
                        marginTop: "2.85pt",
                        marginLeft: "1.05pt",
                        marginBottom: "0pt",
                        textAlign: "center",
                        fontSize: "14pt",
                      }}
                    >
                      <strong>Tax </strong>
                      <strong>
                        <span style={{ letterSpacing: "-0.1pt" }}>Invoice</span>
                      </strong>
                    </p>

                    <p
                      style={{
                        marginTop: "1.7pt",
                        marginLeft: "1.05pt",
                        marginBottom: "0pt",
                        textAlign: "center",
                        fontSize: "9pt",
                      }}
                    >
                      <strong>ORIGINAL FOR </strong>
                      <strong>
                        <span style={{ letterSpacing: "-0.1pt" }}>
                          RECIPIENT
                        </span>
                      </strong>
                    </p>
                  </Box>
                  <img
                    src="/qrdummy.png"
                    alt=""
                    style={{
                      width: 100,
                      height: 100,
                      marginRight: "20px",
                      visibility: "hidden",
                    }}
                  />
                </Box>
              </td>
            </tr>
            <tr style={{ height: "12.65pt" }}>
              <td
                colSpan="7"
                style={{
                  width: "554.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1.3pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <strong>
                    <u>&#xa0;</u>
                  </strong>
                  <strong>
                    <u>IRN: </u>
                  </strong>
                  <strong>
                    <u>
                      <span style={{ letterSpacing: "-0.1pt" }}></span>
                    </u>
                  </strong>
                </p>
              </td>
            </tr>
            <tr style={{ height: "12.2pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "186.38pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "60.7pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <strong>Customer </strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.1pt" }}>Details</span>
                  </strong>
                </p>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "147.75pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "0.8pt",
                    marginBottom: "0pt",
                    textAlign: "center",
                    fontSize: "8pt",
                  }}
                >
                  <strong>
                    <span style={{ letterSpacing: "-0.1pt" }}>Invoice</span>
                  </strong>
                </p>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "2pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "55.15pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <strong>Billing /Supplier </strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.1pt" }}>Information</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr style={{ height: "133.35pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "186.38pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0.55pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    textAlign: "justify",
                    fontSize: "8pt",
                  }}
                >
                  <strong>
                    <span style={{ color: "#31cd31" }}>Bill-to/Sell-to </span>
                  </strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.1pt", color: "#31cd31" }}>
                      Customer
                    </span>
                  </strong>
                </p>
                <p
                  style={{
                    margin: "1.75pt 31.1pt 0pt 1.9pt",
                    textAlign: "justify",
                    lineHeight: "120%",
                    fontSize: "8pt",
                  }}
                >
                  Legal<span style={{ letterSpacing: "-0.1pt" }}> </span>Name
                  :Million Talents India Pvt. Ltd.
                  <span style={{ letterSpacing: "-0.1pt" }}> </span> Trade Name:
                  Million Talents India Pvt.Ltd.
                  <span style={{ letterSpacing: "-0.3pt" }}> </span> Address :
                  10/1,V K Plaza, BANGALORE-560043,India
                </p>

                <p
                  style={{
                    marginTop: "1.85pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.1pt" }}></span>
                </p>
                <p
                  style={{
                    margin: "1.8pt 67.75pt 0pt 1.9pt",
                    lineHeight: "120%",
                    fontSize: "8pt",
                  }}
                >
                  Receipient<span style={{ letterSpacing: "-0.6pt" }}> </span>
                  place:BANGALORE
                  <span style={{ letterSpacing: "-0.55pt" }}> </span>
                  Place of Supply: 29
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    lineHeight: "9.15pt",
                  }}
                >
                  <span style={{ fontSize: "8pt" }}>
                    Receipient State code - 29
                  </span>
                </p>
                <p
                  style={{
                    margin: "0.45pt 67.75pt 0pt 1.9pt",
                    lineHeight: "11pt",
                  }}
                >
                  <span style={{ fontSize: "8pt" }}>
                    P.A.N. No. - AAICM5579P
                  </span>
                  <span style={{ fontSize: "8pt" }}>
                    {" "}
                    GSTIN:29AAICM5579P1ZD
                  </span>
                </p>
              </td>
              <td
                style={{
                  width: "147.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0.55pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  Version 1.03
                </p>
                <p
                  style={{
                    margin: "1.75pt 8.8pt 0pt 4.1pt",
                    lineHeight: "120%",
                    fontSize: "8pt",
                  }}
                >
                  Document No. NK09I0725/013171 Document Date 26-Jul-2024
                </p>
                <p
                  style={{
                    margin: "0pt 62.65pt 0pt 4.1pt",
                    lineHeight: "120%",
                    fontSize: "8pt",
                  }}
                >
                  Supply Type B2B Document Type INV
                </p>
                <p
                  style={{
                    marginTop: "1.65pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  {/* Empty paragraph */}
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  Invoice Category Part Payment
                </p>
              </td>
              <td
                colSpan={4}
                style={{
                  width: "218.52pt",
                  borderRight: "0.75pt solid",
                  borderLeft: "1pt solid",
                  borderBottom: "0.75pt solid",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0.55pt 142.95pt 0pt 4.1pt",
                    textIndent: "-2.25pt",
                    lineHeight: "119%",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.6pt" }}>
                    {" "}
                    B-8,Sector 132,Noida
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0.05pt",
                    marginLeft: "4.1pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.1pt" }}>NOIDA-201304</span>
                </p>
                <p
                  style={{
                    margin: "1.8pt",
                    textIndent: "2.2pt",
                    lineHeight: "120%",
                    fontSize: "8pt",
                  }}
                >
                  <span>Uttar Pradesh,India</span>
                </p>
                <p
                  style={{
                    margin: "0pt 113.9pt 0pt 4.1pt",
                    lineHeight: "120%",
                    fontSize: "8pt",
                  }}
                >
                  Supplier Place : Noida <span style={{}}> </span>
                  Supplier State Code: 09
                  <span style={{ letterSpacing: "-0.5pt" }}>
                    PAN No: AAACI1838D{" "}
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "4.1pt",
                    marginBottom: "0pt",
                    lineHeight: "9.15pt",
                  }}
                >
                  <span style={{ fontSize: "8pt" }}>GSTN No : </span>
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.1pt" }}>
                    09AAACI1838D1ZU
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "1.7pt",
                    marginLeft: "4.1pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  CIN No :{" "}
                  <span style={{ letterSpacing: "-0.1pt" }}>
                    L74899DL1995PLC068021
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "1.75pt",
                    marginLeft: "4.1pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  Salesperson: Ponnanna K M{" "}
                </p>
              </td>
            </tr>

            <tr />

            <tr style={{ height: "19pt" }}>
              <td
                style={{
                  width: "21.12pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "0.05pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    lineHeight: "9.1pt",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "8pt", letterSpacing: "-0.2pt" }}>
                      S.No
                    </span>
                  </strong>
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    lineHeight: "8.85pt",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "8pt", letterSpacing: "-0.5pt" }}>
                      .
                    </span>
                  </strong>
                </p>
              </td>
              <td
                colSpan="5"
                style={{
                  width: "466.4pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "0.05pt",
                    marginLeft: "4.1pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <strong>HSN Code and</strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.1pt" }}>Description</span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "0.05pt",
                    marginLeft: "2pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <strong>
                    <span style={{ letterSpacing: "-0.2pt" }}>Rate</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr style={{ height: "11.55pt" }}>
              <td
                style={{
                  width: "21.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginRight: "0.95pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    lineHeight: "9.2pt",
                  }}
                >
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.5pt" }}>
                    1
                  </span>
                </p>
              </td>
              <td
                colSpan="5"
                style={{
                  width: "466.4pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "4.25pt",
                    marginBottom: "0pt",
                    lineHeight: "9.75pt",
                  }}
                >
                  <span style={{ fontSize: "8.5pt" }}>
                    998439-Other On-Line Contents Not Elsewhere
                  </span>
                  <span style={{ fontSize: "8.5pt", letterSpacing: "-0.1pt" }}>
                    Covered
                  </span>
                </p>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginRight: "1pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    lineHeight: "9.2pt",
                  }}
                >
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.1pt" }}>
                    1000007.97
                  </span>
                </p>
              </td>
            </tr>

            <tr style={{ height: "10.5pt" }}>
              <td
                colSpan="4"
                rowSpan="8"
                style={{
                  width: "397.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  verticalAlign: "top",
                  marginTop: "50px",
                }}
              ></td>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0.5pt",
                    marginLeft: "1.85pt",
                    marginBottom: "0pt",
                    lineHeight: "8.95pt",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "8pt" }}>Item total</span>
                  </strong>
                  <strong>
                    <span style={{ fontSize: "8pt", letterSpacing: "-0.1pt" }}>
                      Amount
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginRight: "1pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.1pt" }}>1000007.97</span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "10pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "1.85pt",
                    marginBottom: "0pt",
                    lineHeight: "9pt",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "8pt" }}>GST</span>
                  </strong>
                  <strong>
                    <span style={{ fontSize: "8pt", letterSpacing: "-0.2pt" }}>
                      Rate
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginRight: "0.95pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    lineHeight: "9pt",
                  }}
                >
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.25pt" }}>
                    18%
                  </span>
                </p>
              </td>
            </tr>

            <tr style={{ height: "9pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "1.85pt",
                    marginBottom: "0pt",
                    lineHeight: "8pt",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "8pt", letterSpacing: "-0.2pt" }}>
                      IGST
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginRight: "0.95pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    lineHeight: "8pt",
                  }}
                >
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.1pt" }}>
                    180001.45
                  </span>
                </p>
              </td>
            </tr>

            <tr style={{ height: "10pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "1.85pt",
                    marginBottom: "0pt",
                    lineHeight: "9pt",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "8pt", letterSpacing: "-0.2pt" }}>
                      CGST
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginRight: "0.95pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    lineHeight: "9pt",
                  }}
                >
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.2pt" }}>
                    0.00
                  </span>
                </p>
              </td>
            </tr>

            <tr style={{ height: "10pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "1.85pt",
                    marginBottom: "0pt",
                    lineHeight: "9pt",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "8pt", letterSpacing: "-0.2pt" }}>
                      SGST
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginRight: "0.95pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    lineHeight: "9pt",
                  }}
                >
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.2pt" }}>
                    0.00
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "17.4pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{ margin: "0pt 11.1pt 0pt 1.85pt", lineHeight: "9pt" }}
                >
                  <strong>
                    <span style={{ fontSize: "8pt" }}>Gross</span>
                  </strong>
                  <strong>
                    <span style={{ fontSize: "8pt", letterSpacing: "-0.6pt" }}>
                      {" "}
                    </span>
                  </strong>
                  <strong>
                    <span style={{ fontSize: "8pt" }}>
                      Amount/Total Invoice Value
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginRight: "1pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.1pt" }}>1,180,009.42</span>
                </p>
              </td>
            </tr>

            <tr style={{ height: "10.25pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "1.85pt",
                    marginBottom: "0pt",
                    lineHeight: "8.65pt",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "8pt" }}>Payable</span>
                  </strong>
                  <strong>
                    <span style={{ fontSize: "8pt", letterSpacing: "-0.25pt" }}>
                      in
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginRight: "0.95pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    lineHeight: "8.65pt",
                  }}
                >
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.25pt" }}>
                    INR
                  </span>
                </p>
              </td>
            </tr>
          </table>
          <Box>
            <p
              style={{
                marginTop: "3.15pt",
                marginLeft: "12.95pt",
                marginBottom: "0pt",
                textIndent: "-5.3pt",
                fontSize: "8pt",
              }}
            >
              *
              <span
                style={{
                  width: "2.19pt",
                  font: "7pt Times New Roman",
                  display: "inline-block",
                }}
              ></span>
              Total In Words **** Eleven Lakh Eighty Thousand Nine Rupees and
              Forty Two Paise only Whether Tax is payable on reverse charge :
              No.
            </p>
          </Box>
          <Box marginTop={2}>
            <p
              style={{
                marginTop: "3.15pt",
                marginLeft: "12.95pt",
                marginBottom: "0pt",
                textIndent: "-5.3pt",
                fontSize: "8pt",
              }}
            >
              *
              <span
                style={{
                  width: "2.19pt",
                  font: "7pt Times New Roman",
                  display: "inline-block",
                }}
              >
                {" "}
              </span>
              This invoice is recognised subject to realization of{" "}
              <span style={{ letterSpacing: "-0.1pt" }}>Payment.</span>
            </p>

            <p
              style={{
                marginTop: "1.8pt",
                marginLeft: "12.95pt",
                marginBottom: "0pt",
                textIndent: "-5.3pt",
              }}
            >
              <span style={{ fontSize: "8pt" }}>*</span>
              <span
                style={{
                  width: "2.19pt",
                  font: "7pt Times New Roman",
                  display: "inline-block",
                }}
              >
                {" "}
              </span>
              <span style={{ fontSize: "8pt" }}>
                Refer Terms and Conditions at{" "}
              </span>
              <a href="vacaziUrl" style={{ textDecoration: "none" }}>
                <u>
                  <span
                    style={{
                      fontSize: "8pt",
                      letterSpacing: "-0.1pt",
                      color: "#0000ff",
                    }}
                  >
                    vacanziUrl
                  </span>
                </u>
              </a>
            </p>
            <p
              style={{
                marginTop: "1pt",
                marginLeft: "12.95pt",
                marginBottom: "0pt",
                textIndent: "-5.3pt",
                fontSize: "8pt",
              }}
            >
              *
              <span
                style={{
                  width: "2.19pt",
                  font: "7pt Times New Roman",
                  display: "inline-block",
                }}
              >
                {" "}
              </span>
              All disputes subject to Bangalore Jurisdiction{" "}
              <span style={{ letterSpacing: "-0.1pt" }}>only.</span>
            </p>
          </Box>
        </div>

        <div>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{ marginLeft: "5.85pt", borderCollapse: "col	lapse" }}
          >
            <tr>
              <td
                colSpan="7"
                style={{
                  width: "800.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "0.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              ></td>
            </tr>
            <tr style={{ height: "50.3pt" }}>
              <td
                colSpan="7"
                style={{
                  width: "554.65pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center	",
                  }}
                >
                  <img
                    src="/vacanziLogo.png"
                    alt=""
                    style={{ width: 110, height: 110 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        margin: "1.5pt 0.15pt 0pt 1.05pt",
                        textAlign: "center",
                        fontSize: "14pt",
                      }}
                    >
                      <strong>
                        <span style={{ fontFamily: "Cambria" }}>Info</span>
                      </strong>
                      <strong>
                        <span
                          style={{
                            fontFamily: "Cambria",
                            letterSpacing: "-0.25pt",
                          }}
                        >
                          {" "}
                        </span>
                      </strong>
                      <strong>
                        <span style={{ fontFamily: "Cambria" }}>Edge</span>
                      </strong>
                      <strong>
                        <span
                          style={{
                            fontFamily: "Cambria",
                            letterSpacing: "-0.25pt",
                          }}
                        >
                          {" "}
                        </span>
                      </strong>
                      <strong>
                        <span style={{ fontFamily: "Cambria" }}>(India)</span>
                      </strong>
                      <strong>
                        <span
                          style={{
                            fontFamily: "Cambria",
                            letterSpacing: "-0.25pt",
                          }}
                        >
                          {" "}
                          Ltd
                        </span>
                      </strong>
                    </p>
                    <p
                      style={{
                        marginTop: "2.85pt",
                        marginLeft: "1.05pt",
                        marginBottom: "0pt",
                        textAlign: "center",
                        fontSize: "14pt",
                      }}
                    >
                      <strong>
                        <span style={{ letterSpacing: "-0.1pt" }}>Anexure</span>
                      </strong>
                    </p>
                  </Box>
                  <img
                    src="/qrdummy.png"
                    alt=""
                    style={{ width: 100, height: 100, marginRight: "20px",visibility:'hidden' }}
                  />
                </Box>
              </td>
            </tr>

            <tr style={{ height: "12.2pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "186.38pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "60.7pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <strong>Customer </strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.1pt" }}>Details</span>
                  </strong>
                </p>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "147.75pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "0.8pt",
                    marginBottom: "0pt",
                    textAlign: "center",
                    fontSize: "8pt",
                  }}
                >
                  <strong>
                    <span style={{ letterSpacing: "-0.1pt" }}>Invoice</span>
                  </strong>
                </p>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "2pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "55.15pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <strong>Billing /Supplier </strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.1pt" }}>Information</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr style={{ height: "133.35pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "186.38pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0.55pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    textAlign: "justify",
                    fontSize: "8pt",
                  }}
                >
                  <strong>
                    <span style={{ color: "#31cd31" }}>Bill-to/Sell-to </span>
                  </strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.1pt", color: "#31cd31" }}>
                      Customer
                    </span>
                  </strong>
                </p>
                <p
                  style={{
                    margin: "1.75pt",
                    textAlign: "justify",
                    lineHeight: "120%",
                    fontSize: "8pt",
                  }}
                >
                  <span> Legal Name:Million Talents India Pvt. Ltd. </span>
                  <br />
                  <span style={{ letterSpacing: "-0.1pt" }}> </span> Trade Name:
                  Million Talents India Pvt.Ltd.
                </p>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "186.38pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "0.55pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    textAlign: "justify",
                    fontSize: "8pt",
                  }}
                ></p>
                <p
                  style={{
                    margin: "1.75pt ",
                    textAlign: "justify",
                    lineHeight: "80%",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.1pt" }}>
                    Document No:123455{" "}
                  </span>
                  <p style={{ letterSpacing: "-0.1pt" }}>Document No:123455 </p>
                </p>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "186.38pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "1.75pt 31.1pt 0pt 1.9pt",
                    textAlign: "justify",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.3pt" }}>
                    {" "}
                    10/1,V K Plaza pvt. ltd
                  </span>
                </p>

                <p
                  style={{
                    marginTop: "1.85pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.1pt" }}>India</span>
                </p>
                <p
                  style={{
                    margin: "1.8pt 67.75pt 0pt 1.9pt",
                    lineHeight: "120%",
                    fontSize: "8pt",
                  }}
                >
                  Receipient<span style={{ letterSpacing: "-0.6pt" }}> </span>
                  place
                  <span style={{ letterSpacing: "-0.55pt" }}> </span>:
                  <span style={{ letterSpacing: "-0.55pt" }}> </span>
                  BANGALORE Place of Supply: 29
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    lineHeight: "9.15pt",
                  }}
                >
                  <span style={{ fontSize: "8pt" }}>
                    Receipient State code -{" "}
                  </span>
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.25pt" }}>
                    29
                  </span>
                </p>
                <p
                  style={{
                    margin: "0.45pt 67.75pt 0pt 1.9pt",
                    lineHeight: "11pt",
                  }}
                >
                  <span style={{ fontSize: "8pt" }}>
                    P.A.N. No. - AAICM5579P GSTIN
                  </span>
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.6pt" }}>
                    {" "}
                  </span>
                  <span style={{ fontSize: "8pt" }}>:-</span>
                  <span style={{ fontSize: "8pt", letterSpacing: "-0.55pt" }}>
                    {" "}
                  </span>
                  <span style={{ fontSize: "8pt" }}>29AAICM5579P1ZD</span>
                </p>
              </td>
            </tr>

            <tr />
            <tr style={{ height: "12.85pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <strong>Sr </strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.25pt" }}>no</span>
                  </strong>
                </p>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "0.8pt",
                    marginBottom: "0pt",
                    textAlign: "center",
                    fontSize: "8pt",
                  }}
                >
                  <strong>Description of </strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.1pt" }}>Services</span>
                  </strong>
                </p>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <strong>Start </strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.2pt" }}>Date</span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "2pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  <strong>End </strong>
                  <strong>
                    <span style={{ letterSpacing: "-0.2pt" }}>Date</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginRight: "0.95pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.5pt" }}>1</span>
                </p>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "1.85pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  CV access for Consultant{" "}
                  <span style={{ letterSpacing: "-0.1pt" }}>300000</span>
                </p>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  25-Jul-<span style={{ letterSpacing: "-0.2pt" }}>2024</span>
                </p>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "2pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  24-Jul-<span style={{ letterSpacing: "-0.2pt" }}>2025</span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginRight: "0.95pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.5pt" }}>2</span>
                </p>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "1.85pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  CV access for Consultant{" "}
                  <span style={{ letterSpacing: "-0.1pt" }}>300000</span>
                </p>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  25-Jul-<span style={{ letterSpacing: "-0.2pt" }}>2024</span>
                </p>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "2pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  24-Jul-<span style={{ letterSpacing: "-0.2pt" }}>2025</span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginRight: "0.95pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.5pt" }}>3</span>
                </p>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "1.85pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  CV access for Consultant{" "}
                  <span style={{ letterSpacing: "-0.1pt" }}>300000</span>
                </p>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  25-Jul-<span style={{ letterSpacing: "-0.2pt" }}>2024</span>
                </p>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "2pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  24-Jul-<span style={{ letterSpacing: "-0.2pt" }}>2025</span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginRight: "0.95pt",
                    marginBottom: "0pt",
                    textAlign: "right",
                    fontSize: "8pt",
                  }}
                >
                  <span style={{ letterSpacing: "-0.5pt" }}>4</span>
                </p>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "1.85pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  Resdex Consultant Premium Subscription 1 Year (1 xl download
                  =2 CV access){" "}
                  <span style={{ letterSpacing: "-0.1pt" }}>300000</span>
                </p>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "1.9pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  25-Jul-<span style={{ letterSpacing: "-0.2pt" }}>2024</span>
                </p>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    marginTop: "1pt",
                    marginLeft: "2pt",
                    marginBottom: "0pt",
                    fontSize: "8pt",
                  }}
                >
                  24-Jul-<span style={{ letterSpacing: "-0.2pt" }}>2025</span>
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>

      {/* 2nd table */}
    </div>
  );
};

export default JobSeekerInvoice;
