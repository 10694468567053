import React from "react";
import { Box, IconButton, Card, CardMedia, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";

const OrgCarousel = ({videos}) => {
  const [startIndex, setStartIndex] = React.useState(0);
  const navigate = useNavigate()

  const handlePrev = () => {
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setStartIndex((prev) => Math.min(prev + 1, 2)); // Assuming 3 items are visible at a time
  };

  return (
    <Box sx={{ width: 314, position: "relative", overflow: "hidden", p: 1 }}>
      <Typography
        sx={{ fontFamily: "IBM plex sans", fontWeight: "bold", mb: 1 }}
      >
        Previously Posted Jobs
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 1,
          transition: "transform 0.3s ease-in-out",
          transform: `translateX(-${startIndex * (100 / 3)}%)`,
          borderRadius: 0,
        }}
      >
        {videos?.map((video) => (
          <Card
            key={video.id}
            sx={{
              minWidth: `calc(100% / 2.7 - 8px)`, // Each slide takes 1/3 of the carousel width
              borderRadius: 2,
              cursor: "pointer",
              flexShrink: 0,
              backgroundColor: "#EAFBF4FF",
              width: 80,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
             onClick={()=>navigate(`/JobSeekerFilter/job/${video.id}`)}
          >
            <CardMedia
              component="video"
              image={video?.jobVideoUrl}
              alt="No Job video found for this"
              sx={{
                borderRadius: 2,
                height: 130,
                width: "100%",
                objectFit: "cover",
              }}
            />
            <Typography
              sx={{
                backgroundColor: "#EAFBF4FF",
                fontFamily: "IBM plex sans",
                fontSize: 12,
                px: 2,
              }}
            >
              {video?.jobTitle}
            </Typography>
          </Card>
        ))}
      </Box>

      {/* Left Navigation Button */}
      <IconButton
        onClick={handlePrev}
        disabled={startIndex === 0}
        sx={{
          position: "absolute",
          left: 8,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <ChevronLeftIcon />
      </IconButton>

      {/* Right Navigation Button */}
      <IconButton
        onClick={handleNext}
        disabled={startIndex >= 2} // Assuming 3 items are visible at a time
        sx={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default OrgCarousel;