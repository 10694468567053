import React from "react";
import { Box, Container, Divider, Typography, Button } from "@mui/material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";

const StillNotSure = ({ handleContactSales }) => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: "#483D8B",
        mt: 5,
        py: 6,
        borderRadius: "40px",
        boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          mb: 5,
          color: "#fff",
          fontSize: { xs: "1.8rem", md: "2.5rem" },
        }}
      >
        Still Not Sure?
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 4,
          px: { xs: 2, md: 5 },
        }}
      >
        {/* Contact Details Section */}
        <Box
          sx={{
            textAlign: "center",
            flex: 1,
          }}
        >
          <ContactPhoneIcon sx={{ fontSize: "4rem", color: "#fff", mb: 2 }} />
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: "#fff",
                mb: 1,
              }}
            >
              Connect with Our Experts
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                color: "#e0e0e0",
                mb: 0.5,
              }}
            >
              (Mon - Sat, 10 AM - 7 PM)
            </Typography>
            <Typography
              sx={{ fontSize: "1rem", fontWeight: 500, color: "#e0e0e0" }}
            >
              For Technical Issues: <b>+91-123456789</b>
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                color: "#e0e0e0",
                mt: 1,
              }}
            >
              For Buying Assistance:
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                color: "#e0e0e0",
              }}
            >
              <b>+91-123456789</b>, <b>+91-987654321</b>
            </Typography>
          </Box>
        </Box>

        {/* Divider */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderColor: "#fff",
            borderWidth: 1,
          }}
        />

        {/* Expert Outreach Section */}
        <Box
          sx={{
            textAlign: "center",
            flex: 1,
          }}
        >
          <PhoneInTalkIcon sx={{ fontSize: "4rem", color: "#fff", mb: 2 }} />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: "#fff",
              mb: 2,
            }}
          >
            Let Our Experts Reach Out to You!
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 3,
              flexWrap: "wrap",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={handleContactSales}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "1rem",
                px: 4,
                py: 1.5,
                backgroundColor: "#00aaff",
                "&:hover": { backgroundColor: "#008fcc" },
                "&:focus": { outline: "3px solid #80dfff" },
              }}
            >
              Request Free Demo
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default StillNotSure;
