import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import manageUserstyles from "../../../styles/manageUsersStyles";
import { editUser, getUsersOfOrg } from "../../../api/manageUsers";

const TextFieldStyles = {
  fontSize: "small",
  "& .MuiInputBase-input": {
    fontSize: "small",
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "0.8rem",
  },
};

const EditUser = ({
  openEditDialogBox,
  handleCloseDialogBox,
  filteredUsers,
  userId,
  fetchUsers,
}) => {
  const [formDetails, setFormDetails] = useState({
    rcName: "",
    rcEmail: "",
    password: "",
    role: "",
    reportingManagerId: "",
    rcPhone: "",
    permissions: {
      reviewCandidates: false,
      jobPosting: false,
      databaseSearch: false,
    },
  });

  const [reportingManagers, setReportingManagers] = useState([]);
  const token = localStorage.getItem("token");
  const selectedUser = filteredUsers.find((user) => user._id === userId);

  useEffect(() => {
    if (selectedUser) {
      setFormDetails({
        rcName: selectedUser.rcName || "",
        rcEmail: selectedUser.rcEmail || "",
        password: "",
        role: selectedUser.role || "",
        reportingManagerId: selectedUser.reportingManagerId || "",
        rcPhone: selectedUser.rcPhone || "",
        permissions: {
          reviewCandidates: selectedUser.permissions?.reviewCandidates || false,
          jobPosting: selectedUser.permissions?.jobPosting || false,
          databaseSearch: selectedUser.permissions?.databaseSearch || false,
        },
      });
    }
  }, [userId, selectedUser]); // Trigger the effect when userId or selectedUser changes

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormDetails((prevData) => ({
      ...prevData,
      permissions: {
        ...prevData.permissions,
        [name]: checked,
      },
    }));
  };
  useEffect(() => {
    const getReportingManagers = async () => {
      try {
        const response = await getUsersOfOrg(
          localStorage.getItem("organizationId"),
          localStorage.getItem("token")
        );
        setReportingManagers(response.recruiterAdmins);
      } catch (error) {
        console.log(error.message);
      }
    };
    getReportingManagers();
  }, []);

  const editExistingUser = async () => {
    try {
      await editUser(userId, token, formDetails);

      fetchUsers();

      handleCloseDialogBox();
    } catch (error) {
      console.log(error.messsage);
    }
  };

  return (
    <Dialog open={openEditDialogBox} onClose={handleCloseDialogBox}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pr: 4,
        }}
      >
        <DialogTitle>Edit User</DialogTitle>
        <CloseIcon
          sx={{ opacity: 0.6, color: "gray", cursor: "pointer" }}
          onClick={handleCloseDialogBox}
        />
      </Box>

      <DialogContent>
        {/* Check if selectedUser exists and map over its details */}
        {selectedUser && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography component="legend" sx={{ fontSize: 12 }}>
                Name
              </Typography>
              <TextField
                fullWidth
                required
                variant="outlined"
                size="small"
                name="rcName"
                placeholder="Enter the name of the user"
                value={formDetails.rcName}
                onChange={handleInputChange}
                sx={TextFieldStyles}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography component="legend" sx={{ fontSize: 12 }}>
                Work Email Id
              </Typography>
              <TextField
                fullWidth
                required
                variant="outlined"
                size="small"
                name="rcEmail"
                placeholder="Enter the work email ID of the user"
                value={formDetails.rcEmail}
                onChange={handleInputChange}
                sx={TextFieldStyles}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography component="legend" sx={{ fontSize: 12 }}>
                Mobile Number
              </Typography>
              <TextField
                fullWidth
                required
                variant="outlined"
                size="small"
                name="rcPhone"
                placeholder="Enter the mobile number of the user"
                value={formDetails.rcPhone}
                onChange={handleInputChange}
                sx={TextFieldStyles}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography component="legend" sx={{ fontSize: 12 }}>
                Password
              </Typography>
              <TextField
                fullWidth
                required
                variant="outlined"
                type="password"
                size="small"
                name="password"
                placeholder="Enter the password"
                value={formDetails.password}
                onChange={handleInputChange}
                sx={TextFieldStyles}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography component="legend" sx={{ fontSize: 12 }}>
                Role
              </Typography>
              <Select
                labelId="role-select-label"
                id="role-select"
                name="role"
                value={formDetails.role}
                size="small"
                onChange={handleInputChange}
                sx={manageUserstyles.select}
              >
                <MenuItem value="recruiter">Recruiter</MenuItem>
                <MenuItem value="manager">Manager</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography component="legend" sx={{ fontSize: 12 }}>
                Reporting Manager
              </Typography>
              <Select
                labelId="manager-select-label"
                id="manager-select"
                name="reportingManagerId"
                value={formDetails.reportingManagerId}
                size="small"
                onChange={handleInputChange}
                sx={manageUserstyles.select}
              >
                {reportingManagers.map((manager) => (
                  <MenuItem key={manager._id} value={manager._id}>
                    {manager.rcName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", marginTop: 2, fontSize: "small" }}
              >
                Permissions
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formDetails.permissions.jobPosting}
                    onChange={handleCheckboxChange}
                    name="jobPosting"
                    color="primary"
                  />
                }
                label="Job Posting"
                sx={manageUserstyles.checkBox}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formDetails.permissions.invite}
                    onChange={handleCheckboxChange}
                    name="invite"
                    color="primary"
                  />
                }
                label="Invites"
                sx={manageUserstyles.checkBox}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formDetails.permissions.databaseSearch}
                    onChange={handleCheckboxChange}
                    name="databaseSearch"
                    color="primary"
                  />
                }
                label="Database Search"
                sx={manageUserstyles.checkBox}
              />
            </Grid>
            <Grid item xs={12} sm={12} marginTop={2}>
              <DialogActions>
                <Button
                  color="primary"
                  sx={manageUserstyles.submitButton}
                  onClick={editExistingUser}
                >
                  Update
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default EditUser;
