import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Pagination,
  CircularProgress,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import savedSearchStyles from "../../../styles/savedSearcheStyles";
import { getSavedSearchApi } from "../../../api/search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const SavedSearch = () => {
  const navigate = useNavigate();
  const [savedSearch, setSavedSearch] = useState([]); // To store the list of saved searches
  const [searchCount, setSearchCount] = useState(10); // Number of results per page
  const [totalPages, setTotalPages] = useState(1); // Total pages for pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [loading, setLoading] = useState(true); // Loading state

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSelectSearchCount = (e) => {
    setSearchCount(e.target.value);
    setCurrentPage(1); // Reset to first page when changing the search count
  };

  const orgId = localStorage.getItem("organizationId");
  const token = localStorage.getItem("token");

  // Fetch saved searches
  useEffect(() => {
    const fetchSavedSearches = async () => {
      const formvalues = {
        recruiterId: localStorage.getItem("recruiterId"),
        organizationId: orgId,
      };
      try {
        setLoading(true); // Set loading to true before fetching
        if (orgId && token) {
          const response = await getSavedSearchApi(token, formvalues);
          setSavedSearch(response.data); // Set the saved searches
          setTotalPages(Math.ceil(response.data.length / searchCount)); // Calculate total pages
        }
      } catch (error) {
        console.error("Error fetching saved searches:", error.message);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
    fetchSavedSearches();
  }, [orgId, token, searchCount]);

  console.log("saved search", savedSearch);

  // Function to handle "Fill Search" button click
  const handleFillSearch = useCallback(
    (searchData) => {
      localStorage.setItem("savedSearchData", JSON.stringify(searchData));
      navigate("/search-candidates");
    },
    [navigate]
  );

  // Function to handle "Search Candidates" button click
  const handleSearchFromSaved = useCallback(
    (searchData) => {
      const keywords = searchData?.searchQuery?.keyword || "";
      const currentLocation = searchData?.searchQuery?.currentLocation || "";
      const preferredLocation =
        searchData?.searchQuery?.preferredLocation || "";
      const industry = searchData?.searchQuery?.industry || "";
      const experienceType =
        searchData?.searchQuery?.experienceType || "experienced";

      // Build the query parameters
      const queryParams = new URLSearchParams({
        keyword: keywords,
        currentLocation: currentLocation,
        preferredLocation: preferredLocation,
        industry: industry,
        experienceType: experienceType,
        booleanSearch: false, // Add this if needed
        minimumEducation: "", // Add this if needed
        activeIn: "", // Add this if needed
        totalExpMin: "", // Add this if needed
        totalExpMax: "", // Add this if needed
        minSalary: "", // Add this if needed
        maxSalary: "", // Add this if needed
        minAge: "", // Add this if needed
        maxAge: "", // Add this if needed
        jobType: "", // Add this if needed
        preferredJobType: "", // Add this if needed
      }).toString();

      // Navigate to the candidate search page
      navigate(`/candidate-searchList?${queryParams}`);
    },
    [navigate]
  );

  // Slice the saved searches for pagination
  const displayedRows = savedSearch?.slice(
    (currentPage - 1) * searchCount,
    currentPage * searchCount
  );

  // Show loading spinner while fetching data
  if (loading) {
    return (
      <EmployerDrawer>
        <Box sx={{ ...savedSearchStyles.parentBox, textAlign: "center" }}>
          <CircularProgress size={60} sx={{ mb: 2 }} />
        </Box>
      </EmployerDrawer>
    );
  }

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <EmployerDrawer>
      <Box>
        <Typography
          onClick={handleBack}
          gutterBottom
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            mb: "5px",
            color: "grey", // Adjust color for better visibility
            "&:hover": {
              color: "primary.dark", // Hover effect
            },
          }}
        >
          <ArrowBackIcon sx={{ marginRight: "4px" }} />
        </Typography>
      </Box>
      <Box sx={{ ...savedSearchStyles.parentBox }}>
        {/* Table for displaying saved searches */}
        <Box sx={{ ...savedSearchStyles.tableParent }}>
          <Box sx={{ ...savedSearchStyles.tableActionParent }}>
            <Box sx={{ ...savedSearchStyles.pageFilterBox }}>
              <Typography>Showing</Typography>
              <FormControl sx={{ width: 100 }}>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ height: 40, bgcolor: "white" }}
                  value={searchCount}
                  onChange={handleSelectSearchCount}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
              <Typography>Results</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </Box>

          {/* Table with saved search data */}
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "8px",
              overflow: "hidden",
              maxHeight: "600px",
              width: "100%",
              "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar
              scrollbarWidth: "none", // Hide scrollbar for Firefox
              msOverflowStyle: "none", // Hide scrollbar for IE and Edge
            }}
          >
            <Table
              sx={{
                ...savedSearchStyles.table,
                fontFamily: "IBM Plex Sans, sans-serif", // Set font family
                minWidth: "800px", // Increase table width
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      ...savedSearchStyles.tableHeader,
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Search Query
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      ...savedSearchStyles.tableHeader,
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Fill Search
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      ...savedSearchStyles.tableHeader,
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Search Candidates
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedRows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": { backgroundColor: "#f5f5f5" }, // Add hover effect
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        ...savedSearchStyles.tablecell,
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: "14px",
                      }}
                    >
                      {Object.entries(row?.searchQuery || {})
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(" | ")}
                    </TableCell>

                    {/* Fill Search Cell */}
                    <TableCell
                      align="left"
                      sx={{
                        ...savedSearchStyles.tablecell,
                        color: "blue",
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      <Typography onClick={() => handleFillSearch(row)}>
                        Fill Search
                      </Typography>
                    </TableCell>

                    {/* Search Candidates Cell */}
                    <TableCell
                      align="left"
                      sx={{
                        ...savedSearchStyles.tablecell,
                        color: "green",
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      <Typography onClick={() => handleSearchFromSaved(row)}>
                        Search Candidates
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </EmployerDrawer>
  );
};

export default SavedSearch;
