import baseURL from './baseUrl';

// Function to handle employer signup
export const signupEmployer = async (rcName, rcEmail, rcPhone) => {
  try {
    const response = await fetch(`${baseURL}/recruiterAdmin/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ rcName, rcEmail, rcPhone }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

// Function to handle employer login
export const loginEmployer = async (rcEmail, password) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        
      },
      body: JSON.stringify({ rcEmail, password }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

// Function to handle OTP verification for signup
export const verifySignupOtp = async (rcPhone, otp) => {
  try {
    const response = await fetch(`${baseURL}/recruiterAdmin/verify_otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ rcPhone, otp }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


// Function to handle OTP verification
export const verifyOtp = async (rcEmail, otp) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/verify_otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ rcEmail, otp }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

// Function to handle OTP resend
export const resendOtp = async (phone) => {
  try {
    const response = await fetch(`${baseURL}/resend-otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const logoutApi = async (formValues) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/logOut`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
        
      },
      body:JSON.stringify(formValues)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const getloggedHours = async (token, recruiterId) => {
  try {
    const response = await fetch(`${baseURL}/recruiter/loggedHours/${recruiterId}`, { // Append recruiterId to the URL
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const forgotPasswordApi = async(token,formValues)=>{
  try {
    const response = await fetch(`${baseURL}/recruiter/forgotPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization":`Bearer ${token}`,

      },
      body: JSON.stringify(formValues),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
export const resetPasswordApi = async(token,formValues)=>{
  try {
    const response = await fetch(`${baseURL}/recruiter/rec/resetPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization":`Bearer ${token}`,

      },
      body: JSON.stringify(formValues),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}



