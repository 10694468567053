import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import { Box, Typography, IconButton, Button } from "@mui/material";
import CompanyDetails from "./CompanyForms/CompanyDetails";
import AccountDetails from "./CompanyForms/AccountDetails";
import KYCComplainsDetails from "./CompanyForms/KYCComplainsDetails";
import { editOrgLogoApi, getOrgDetails } from "../../../api/companyProfile";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const CompanyProfile = () => {
  const [orgData, setOrgData] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openKYCDialog, setOpenKYCDialog] = useState(false);
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [logo, setLogo] = useState(""); // State for company logo
  const [previewLogo, setPreviewLogo] = useState(""); // State for previewing the selected logo
  const fileInputRef = useRef(null); // Ref for the file input element
  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
 
    const navigate = useNavigate();
  
  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        if (orgId) {
          const companyDetails = await getOrgDetails(orgId, token);
          setOrgData(companyDetails);
          setLogo(companyDetails?.logo || ""); // Set the logo from API response
        } else {
          throw new Error("No organization id");
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchOrgDetails();
  }, [orgId, token]);

  // Handle logo edit
  const handleLogoEdit = () => {
    fileInputRef.current.click(); // Trigger the file input dialog
  };
 console.log(logo)
  // Handle logo upload
  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewLogo(reader.result); // Set the preview logo to the uploaded image
        setIsPreviewModalOpen(true); // Open the preview modal
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  // Handle logo update
  const handleUpdateLogo = async () => {
    if (previewLogo) {
      try {
        const file = fileInputRef.current.files[0]; // Get the selected file
        const formData = new FormData();
        formData.append("companyLogo", file); // Use the key "companyLogo"
  
        const response = await editOrgLogoApi(orgId, token, formData);
  
        console.log("Logo update response:", response);
  
        // Fetch the updated organization details
        const updatedOrgDetails = await getOrgDetails(orgId, token);
        setOrgData(updatedOrgDetails); // Update the orgData state
  
        setLogo(updatedOrgDetails?.companyLogo?.url || ""); // Update the logo in the UI
        setPreviewLogo(""); // Clear the preview
        setIsPreviewModalOpen(false); // Close the preview modal
      } catch (error) {
        console.error("Failed to update logo:", error.message);
      }
    }
  };

  // account details state,functions
  const handleEdit = () => {
    setOpenDialog(true); //open dialog box
  };
  const handleCloseDialog = () => {
    setOpenDialog(false); //close dialog box
  };

  const handleSaveDialog = () => {
    setOpenDialog(false);
  };

  // company details state,functions
  const handleCompanyEdit = () => {
    setOpenCompanyDialog(true); //edit company details
  };
  const handleCompanyDialogClose = () => {
    setOpenCompanyDialog(false); //close company details
  };

  const handleSaveCompanyDetails = () => {
    setOpenCompanyDialog(false);
  };

  // KYC details states,functions
  const handleKYCEdit = () => {
    setOpenKYCDialog(true); //open KYC Edit
  };

  const handleKYCDialogClose = () => {
    setOpenKYCDialog(false); // close KYC edit
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  
  return (
    <EmployerDrawer>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "starts",
        justifyContent: "start",
        mb: 2,
      }}
    >
       <Typography
        onClick={handleBack}
        gutterBottom
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          mb: "15px",
          color: "grey", // Adjust color for better visibility
          "&:hover": {
            color: "primary.dark", // Hover effect
          },
        }}
      >
        <ArrowBackIcon sx={{ marginRight: "4px" }} />
      </Typography>
      <Typography variant="h6" sx={{ opacity: 0.6 }}>
        Company Profile
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">{orgData?.organizationName}</Typography>
        <Box
          sx={{
            position: "relative",
            cursor: "pointer",
            mr: 3,
            "&:hover .edit-icon": {
              opacity: 1,
            },
          }}
        >
          <img
            src={orgData?.companyLogo?.url} // Use the uploaded logo or a default logo
            alt="Company Logo"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
          <IconButton
            className="edit-icon"
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              opacity: 0,
              transition: "opacity 0.3s",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 1)",
              },
            }}
            onClick={handleLogoEdit} // Trigger file input on icon click
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {/* Hidden file input */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }} // Hide the file input
        onChange={handleLogoUpload}
      />
      {/* Preview Modal */}
      {isPreviewModalOpen && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            backdropFilter: "blur(5px)", // Blur effect
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1300, // Ensure it's above everything
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "24px",
              textAlign: "center",
              maxWidth: "300px",
             
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 ,fontFamily:"IBM plex sans"}}>
              Preview
            </Typography>
            <img
              src={previewLogo}
              alt="Preview Logo"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
            <Box sx={{ mt: 3, display: "flex", justifyContent: "center", gap: 2 }}>
              <Button variant="contained" onClick={() => setIsPreviewModalOpen(false)} sx={{backgroundColor:"#FD0A02FF",textTransform:"none",borderRadius:"20px"}}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleUpdateLogo} sx={{backgroundColor:"#423bfa",textTransform:"none",borderRadius:"20px"}}>
                Upload Logo
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
    <Box sx={{ gap: 2 }}>
      <AccountDetails
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleSaveDialog={handleSaveDialog}
        handleEdit={handleEdit}
        orgData={orgData}
      />
      <CompanyDetails
        openCompanyDialog={openCompanyDialog}
        handleCompanyDialogClose={handleCompanyDialogClose}
        handleSaveCompanyDetails={handleSaveCompanyDetails}
        handleCompanyEdit={handleCompanyEdit}
        orgData={orgData}
      />
      <KYCComplainsDetails
        openKYCDialog={openKYCDialog}
        handleKYCDialogClose={handleKYCDialogClose}
        handleSaveDialog={handleSaveDialog}
        handleKYCEdit={handleKYCEdit}
        orgData={orgData}
      />
    </Box>
  </EmployerDrawer>
  );
};

export default CompanyProfile;