import { Box, Typography, styled } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation,Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { allTestimonialsApi } from "../../api/jobseeker";
import { useEffect } from "react";
import { useState } from "react";

const StyledSwiper = styled(Swiper)({
  padding: "8px",
  "& .swiper-button-next, & .swiper-button-prev": {
    color: "white",
    width: "40px",
    "&::after": {
      display: "none",
    },
  },
});

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState();
  useEffect(() => {
    const getAllTestimonials = async () => {
      try {
        const response = await allTestimonialsApi();
        setTestimonials(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    getAllTestimonials();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#EAFBF4FF", width: "100vw" }}>
      <Typography
        variant="h3"
        sx={{
          fontFamily: "IBM Plex Sans",
          textAlign: "center",
          padding: "20px",
          fontWeight: "bold",
          color: "#03030CFF",
        }}
      >
        Testimonials
      </Typography>
      <Box sx={{ py: 6, px: 4 }}>
        <StyledSwiper
          modules={[Navigation,Autoplay]}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          slidesPerView={3}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          spaceBetween={30}
        >
          {testimonials?.testimonials?.map((user, index) => (
            <SwiperSlide key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gridTemplateColumns: "1fr",
                  gap: 2,
                  textAlign: "center",
                  borderRadius: "10px",
                  boxShadow: "0 0 10px 0px cadetblue",
                  backgroundColor: "white",
                  height: "200px",
                }}
              >
                <Box sx={{ px: 4, py: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: "IBM Plex Sans", fontSize: 17 }}
                  >
                    {user.testimonial}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    px: 2,
                    pb: 2,
                  }}
                >
                  <img
                    src="/slider7.png"
                    alt=""
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  />
                  <Box
                    sx={{
                      ml: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                    }}
                  >
                    <Typography
                      variant="body1"
                      textAlign={"start"}
                      sx={{ fontFamily: "IBM Plex Sans", fontWeight: "bold" }}
                    >
                      {user?.userId?.fullName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontFamily: "IBM Plex Sans" }}
                    >
                      {user.designation}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          ))}

          <Box
            className="swiper-button-prev"
            sx={{
              right: 20,
              zIndex: 10,
              color: "black",
              "&::after": { display: "none" },
            }}
          >
            <ArrowBackIosIcon sx={{ color: "blue", ml: 3, opacity: 0.6 }} />
          </Box>
          <Box
            className="swiper-button-next"
            sx={{
              right: 20,
              zIndex: 10,
              color: "black",
              "&::after": { display: "none" },
            }}
          >
            <ArrowForwardIosIcon sx={{ color: "blue", opacity: 0.6 }} />
          </Box>
        </StyledSwiper>
      </Box>
    </Box>
  );
};

export default Testimonials;
