import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Avatar, Button } from "@mui/material";
import baseURL from "../../../api/baseUrl";
import { useNavigate } from "react-router-dom";

const JobSeekerTopCompanies = () => {
  const [companies, setCompanies] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTopCompanies = async () => {
      try {
        const response = await fetch(`${baseURL}/org/getFeaturedOrg`);
        const data = await response.json();
        setCompanies(data.data);
      } catch (error) {
        console.log("Error fetching top companies", error);
      }
    };

    fetchTopCompanies();
  }, []);

  const RoleCard = ({ organizationName, url, industry, onClick }) => (
    <Paper
      elevation={0}
      onClick={onClick} // Pass onClick to the Paper component
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "8px",
        padding: 2,
        height: "300px",
        width: "220px",
        border: "2px solid #EAF1F5",
        borderRadius: 4,
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "translateY(-8px)",
          boxShadow: "0 12px 24px rgba(0, 0, 0, 0.15)",
        },
        backgroundColor: "#fff",
        cursor: "pointer",
      }}
    >
      <Avatar
        alt={organizationName}
        src={url}
        sx={{
          width: 80,
          height: 80,
          mb: 2,
        }}
      />
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        textAlign="center"
        sx={{
          flexShrink: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          mb: 1,
        }}
      >
        {organizationName}
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        sx={{
          flexShrink: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          mb: 1,
        }}
      >
        {industry}
      </Typography>

      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: "#18181b",
          borderRadius: "0.7rem",
          padding: "0.375rem 0.75rem",
          
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#18181b",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            textTransform: "none",
            color: "#ffffff",
          }}
        >
          View Jobs
        </Typography>
      </Button>
    </Paper>
  );

  // Function to navigate to the company page
  const goToCompanyPage = (companyId) => {
    console.log("companyId", companyId);

    navigate(`/organizationOverview?companyId=${companyId}`); // Pass companyId as a query parameter
  };

  return (
    <Box sx={{ padding: { xs: 2, sm: 4 }, backgroundColor: "#F9FAFB" }}>
      <Typography
        variant="h4"
        fontWeight="bold"
        textAlign="center"
        mb={4}
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem" }, letterSpacing: "0.5px" }}
      >
        Featured Top Companies
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 3,
            animation: "scroll 20s linear infinite",
            "&:hover": { animationPlayState: "paused" },
          }}
        >
          {companies.concat(companies).map((company, index) => (
            <RoleCard
              key={`${company._id}-${index}`}
              onClick={() => goToCompanyPage(company?._id)} // Ensure the correct key is passed
              organizationName={company.organizationName}
              url={company.companyLogo?.url}
              industry={company.industry}
            />
          ))}
        </Box>
      </Box>

      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
      `}</style>
    </Box>
  );
};

export default JobSeekerTopCompanies;
