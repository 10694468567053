import { useEffect, useCallback } from "react";
import "./index.css"; // Import the CSS file
import Routes from "./routes/routes";
import { messaging } from "./utils/firebaseConfig";
import { getToken } from "firebase/messaging";
import baseURL from "./api/baseUrl";
import { ToastContainer } from "react-toastify";
function App() {
  // Memoize the requestPermission function to prevent re-creation on each render
  const requestPermission = useCallback(async () => {
    try {
      // Check if permission has already been asked
      const permissionStatus = localStorage.getItem("notificationPermission");

      if (permissionStatus === null) {
        // If permission hasn't been asked yet, request permission
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
          alert("Permission Granted");

          // Store permission status in localStorage
          localStorage.setItem("notificationPermission", "granted");

          // Get FCM token
          const token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FCM_SECRET,
          });

          if (token) {
            console.log("Token Generated:", token);

            const userId = localStorage.getItem('userId');
            if (userId) {
              await sendTokenToBackend(userId, token);
            } else {
              console.log("No userId found in localStorage.");
            }
          } else {
            console.log("No registration token available.");
          }
        } else if (permission === "denied") {
          alert("User Denied the Notification Permission");
          localStorage.setItem("notificationPermission", "denied");
        }
      } else {
        console.log("Notification permission already granted or denied.");
      }
    } catch (error) {
      console.error("Failed to request notification permission", error);
    }
  }, []); // Empty dependency array means `requestPermission` won't change

  // Function to send the token to your backend
  async function sendTokenToBackend(userId, token) {
    try {
      const response = await fetch(`${baseURL}/update-token`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, fcmToken: token }), // Include userId and token in the request
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to send token to backend: ${response.status} ${response.statusText} - ${errorText}`);
      }

      console.log('Token sent to backend successfully.');
    } catch (error) {
      console.error('Error sending token to backend:', error);
    }
  }

  // Use `useEffect` to trigger the permission request on mount
  useEffect(() => {
    requestPermission();
  }, [requestPermission]); // Now, `requestPermission` is stable due to useCallback

  return (
    <div style={{width:"100vw",margin:0,padding:0}}>
      <Routes />

      <ToastContainer
  position="top-right"
  autoClose={1000}
  hideProgressBar={false}
  closeOnClick
  closeButton={true}
  pauseOnHover
  draggable
  theme="colored"
/>
    </div>
  );
}

export default App;
