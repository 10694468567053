import React, { useEffect, useState, lazy, Suspense } from "react";
import { Box, Typography } from "@mui/material";
// import ResponsiveAppBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
// import CompanyTitleCard from "../../../components/JobSeekerComponents/JobPostOverView/CompanyTitleCard";
// import CompanyVideoCard from "../../../components/JobSeekerComponents/JobPostOverView/CompanyVideoCard";
import CompanyVideoCarousel from "../../../components/JobSeekerComponents/JobPostOverView/CompanyVideoCarousel";
// import CompanyDescription from "../../../components/JobSeekerComponents/JobPostOverView/CompanyDescription";

import { getUserIdFromToken } from "../../../utils/userAuth";
import baseURL from "../../../api/baseUrl";
import { toast } from "react-toastify"; // Import toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import { getJobsByOrgApi } from "../../../api/jobseeker";
import TitleCardSkeleton from "../../../utils/lazy/TitleCardSkeleton";
import VideoCardSkeleton from "../../../utils/lazy/VideoCardSkeleton";
import DescriptionBox from "../../../utils/lazy/DescriptionBox";
import NewNavBar from "../../../components/JobSeekerComponents/NavBar/NewNavbar";

const CompanyTitleCard = lazy(() =>
  import(
    "../../../components/JobSeekerComponents/JobPostOverView/CompanyTitleCard"
  )
);
const CompanyVideoCard = lazy(() =>
  import(
    "../../../components/JobSeekerComponents/JobPostOverView/CompanyVideoCard"
  )
);
const CompanyDescription = lazy(() =>
  import(
    "../../../components/JobSeekerComponents/JobPostOverView/CompanyDescription"
  )
);

const CompanyOverView = ({ job }) => {

  const [userId, setUserId] = useState(null);
  const [isApplied, setIsApplied] = useState(false);
  const [orgJobs, setOrgJobs] = useState();
  const [selectedJob, setSelectedJob] = useState();
  const [selectedVideoCv, setSelectedVideoCv] = useState(null);
  const [selectedResume, setSelectedResume] = useState(null);
  const userIds  = localStorage.getItem('userId')
  console.log('userId',userIds)
  const fetchUserId = () => {
    const storedUserId = getUserIdFromToken(); // Adjust this to how you fetch the userId
    setUserId(storedUserId);
  };

  useEffect(() => {
    const fetchJobsByOrganization = async () => {
      const orgId = job?.organizationId?._id; // Extract orgId from job
      //   console.log("orgId", orgId);

      if (!orgId) {
        console.error("Organization ID is missing");
        return; // Early return if orgId is missing
      }

      try {
        const response = await getJobsByOrgApi(orgId); // Pass orgId to the API function
        // console.log("Jobs fetched:", response);
        setOrgJobs(response);
      } catch (error) {
        console.error("Failed to fetch jobs:", error.message);
      }
    };

    fetchJobsByOrganization();
  }, [job]);

  useEffect(() => {
    fetchUserId();

    if (job?.appliedCandidates && userId) {
      const appliedCandidates = job.appliedCandidates; // Array of applied candidate IDs
      const hasApplied = appliedCandidates.includes(userId); // Check if userId exists in the array
      setIsApplied(hasApplied); // Update the state to reflect if the user has applied
    }
  }, [userId, job]);



  if (!job) return <Typography variant="h6">Job not found</Typography>;

  // Function to handle video selection from the carousel
  const handleVideoSelect = (video) => {
    // Find the corresponding job from orgJobs

    setSelectedJob(orgJobs?.find((job) => job._id === video.id));
  };

  const fetchUserData = async () => {
    const userId = getUserIdFromToken();
    const authToken = localStorage.getItem("authToken");

    const response = await fetch(`${baseURL}/jobseeker/${userId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    console.log("response is okay", response.ok);

    const data = await response.json();
    console.log("data of user", data);
    return data;
  };

  const handleApplyJob = async (selectedVideoCv, selectedResume) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        toast.error("User not authenticated");
        return;
      }
  
      const userdata = await fetchUserData(); // Fetch user data
      const applicationData = {
        jobId: job._id,
        expectedCtc: userdata.user.expectedCtc,
        videoCV: selectedVideoCv,
        resume: selectedResume,
        intro: userdata.user.description,
      };
  
      const response = await fetch(`${baseURL}/jobs/${job._id}/apply`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(applicationData),
      });
  
      const data = await response.json();
      console.log("apply jobs data", data);
  
      if (!response.ok) {
        throw new Error("Failed to apply for the job");
      }
  
      toast.success("Job application successful!");
    } catch (error) {
      console.error("Error applying for the job:", error);
      toast.error("Error applying for the job");
    }
  };
  console.log('selectedjob',job)


  return (
    <>
      <NewNavBar />
      <Box
        sx={{
          p: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
          background: "linear-gradient(135deg, #F8F9FA, #CED4DA)",
          fontFamily: "IBM plex sans",
          padding: 10,
        }}
      >
        {/* Left Side: Title, Video, Video Carousel */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Suspense fallback={<TitleCardSkeleton />}>
            <CompanyTitleCard job={job} />
          </Suspense>

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start", // Align items to the top
              justifyContent: "space-between",
              gap: 3,
            }}
          >
            {/* Video Card */}
            <Box sx={{ flex: 1 }}>
              <Suspense fallback={<VideoCardSkeleton />}>
                <CompanyVideoCard
                  video={selectedJob ? selectedJob : job}
                  job={job}
                />
              </Suspense>
            </Box>

      {/* Description */}
      <Box sx={{ flex: 1 }}>
        <Suspense fallback = {<DescriptionBox/>}>
        <CompanyDescription
          job={selectedJob ? selectedJob : job}
          isApplied={isApplied}
          orgJobs={orgJobs}
          selectedVideoCv={selectedVideoCv}
          setSelectedVideoCv={setSelectedVideoCv}
          selectedResume={selectedResume}
          setSelectedResume={setSelectedResume}
          handleApplyJob={handleApplyJob}

        />
        </Suspense>
       
      </Box>
    </Box>

          {/* Video Carousel */}
          <CompanyVideoCarousel
            videos={orgJobs?.map((job) => ({
              id: job?._id,
              jobVideoUrl: job?.jobVideoUrl,
              jobTitle: job?.jobTitle,
            }))}
            onVideoSelect={handleVideoSelect}
            job={job}
            orgJobs={orgJobs}
          />
        </Box>
      </Box>
    </>
  );
};

export default CompanyOverView;
