import React from 'react';
import { Box, Paper, Typography, Button, Divider, Tooltip } from '@mui/material';
import { WarningAmber, LocationOn, Event, Person, Visibility, Work, Business, AssignmentInd } from '@mui/icons-material';

const JobCard = ({ job }) => {
  return (
    <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#ffffff' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Job Details */}
        <Box sx={{ flexGrow: 1, mb: { xs: 2, sm: 0 } }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {job?.title}
          </Typography>
          {job?.company && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 1 }}>
              <Business color="action" />
              <Typography variant="body2" color="textSecondary">
                {job?.company}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              gap: 1,
              mt: 1,
            }}
          >
            <Tooltip title="Job Location">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <LocationOn color="action" />
                <Typography variant="body2" color="textSecondary">
                  {job?.location}
                </Typography>
              </Box>
            </Tooltip>

            <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', sm: 'block' } }} />

            <Tooltip title="Posting Date">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Event color="action" />
                <Typography variant="body2" color="textSecondary">
                  Posted on: {job?.postedOn}
                </Typography>
              </Box>
            </Tooltip>

            <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', sm: 'block' } }} />

            <Tooltip title="Posted By">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Person color="action" />
                <Typography variant="body2" color="textSecondary">
                  {job?.postedBy}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>

        {/* Metrics and Actions */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: 2,
            mt: { xs: 2, sm: 0 },
          }}
        >
          {/* Applications */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Work color="action" />
            <Typography fontWeight="bold" variant="body2" color="textSecondary">
              {job?.actionPending}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Total Applications: {job?.applicationCount}
            </Typography>
          </Box>

          <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', sm: 'block' } }} />

          {/* Views */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Visibility color="action" />
            <Typography fontWeight="bold" variant="body2" color="textSecondary">
              {job?.allCandidates}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Total Views: {job?.viewCount}
            </Typography>
          </Box>

          {/* Plan Button */}
          {job?.planRequired === 'unpaid' && (
            <Button
              variant="outlined"
              size="small"
              fontWeight="bold"
              sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
            >
              Select Plan
            </Button>
          )}

          {/* Status */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body2" color="textSecondary">
              Status:
            </Typography>
            <Typography fontWeight="bold" variant="body2" color="textSecondary">
              {job?.status}
            </Typography>
          </Box>

          {/* Role */}
          {job?.role && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <AssignmentInd color="action" />
              <Typography variant="body2" color="textSecondary">
                Role: {job?.role}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Warning Section */}
      {job?.planRequired === 'unpaid' && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 2,
            padding: 1,
            backgroundColor: '#fff8e1',
            borderRadius: 1,
          }}
        >
          <WarningAmber sx={{ color: '#ff9800', marginRight: 1 }} />
          <Typography variant="body2" color="textSecondary">
            Select plan and finish job posting to start receiving candidates
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default JobCard;
