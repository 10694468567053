import baseURL from "./baseUrl";
import { getUserIdFromToken } from "../utils/userAuth";

export const getJobSeekerDetails = async () => {
  const userId = await getUserIdFromToken();
  try {
    const response = await fetch(`${baseURL}/jobseeker/${userId}`);
    
    if (!response.ok) {
     
      throw new Error('Failed to fetch job seeker details');
    }

    const data = await response.json();
    console.log("Job Seeker details:", data.user);
    
    return data.user;  
  } catch (error) {
    console.error("Error fetching job seeker details:", error);
    return null;  
  }
};


// export const getViewedJoseeker = async (orgId, token) => {
//   try {
//     const response = await fetch(
//       `${baseURL}/organization/${orgId}/getallrecruiters`,
//       {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     if (!response.ok) {
//       throw new Error("network response was not ok");
//     }
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.log(error.message);
//     throw error;
//   }
// };



export const getUnlockedCandidatesApi = async (orgId, token) => {
  try {
    const response = await fetch(
      `${baseURL}/organization/unlockedCandidates/${orgId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};