import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography, Box, Container } from "@mui/material";
import { jobSeekerVerifyPaymentApi } from "../../api/payment";
import baseURL from "../../api/baseUrl";

const JobSeekerPaymentVerification = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    const fetchWallet=async()=>{
        try{
            const response = await  fetch(`${baseURL}/getWallet/${userId}`)
            const data = await response.json();
            console.log("fetching wallets",data);


        }catch(error){
            console.log("error in fetching wallet",error);
        }
    }
  
    const verifyPayment = async () => {


      const merchantTransactionId = localStorage.getItem(
        "merchantTransactionId"
      );
      console.log("merchant id",merchantTransactionId);

      if (!merchantTransactionId) {
        console.error("No transaction ID found in local storage");
        navigate("/jobseekerpayment-failed");
        return;
      }

      try {
        const response = await  jobSeekerVerifyPaymentApi(token, merchantTransactionId);
        console.log("payment response",response)
        
        setTimeout(() => {
          if (response?.payment?.status === "success") {
            navigate("/jobseekerpayment-successful");
          } else {
            navigate("/jobseekerpayment-failed", {
              state: { errorMessage: response?.message || "Payment failed" },
            });
          }
        }, 3000);
      } catch (error) {
        console.error("Error verifying payment:", error);
        navigate("/jobseekerpayment-failed", {
          state: { errorMessage: error.message },
        });
      }
    };
    fetchWallet();

    verifyPayment();
  }, [navigate, token]);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress size={60} sx={{ mb: 2 }} />
        <Typography variant="h5" component="h1" gutterBottom>
          Verifying your payment
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Please wait while we process your transaction...
        </Typography>
      </Box>
    </Container>
  );
};

export default JobSeekerPaymentVerification;
