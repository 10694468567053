import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,

  Container,
 
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

const BenefitsTable = () => {
  const benefits = [
    {
      feature:
        "Free Profile competes with 7 Crore profiles while Paid Profile competes with 50,000 profiles only",
      gold: true,
      platinum: true,
    },
    {
      feature:
        "Rank Higher in Search results - Automatic refresh boost for paid profiles",
      gold: true,
      platinum: true,
    },
    {
      feature: "Highlighted Profile in Naukri Database",
      gold: true,
      platinum: true,
    },
    {
      feature: "Get Priority Access to jobs – within 24 hrs of being posted",
      gold: false,
      platinum: true,
    },
    {
      feature: "Receive profile based jobs on SMS - Max 3 per day",
      gold: false,
      platinum: true,
    },
    {
      feature: "Expand job search to openings posted on company websites",
      gold: false,
      platinum: true,
    },
    {
      feature: "Send Direct Message to any recruiter on Naukri",
      gold: false,
      platinum: true,
    },
    {
      feature: "Exclusive access to recruiters having confidential jobs",
      gold: false,
      platinum: true,
    },
    {
      feature: "Guaranteed Jobs / Interview Calls",
      gold: false,
      platinum: true,
    },
  ];

  const renderIcon = (isAvailable) =>
    isAvailable ? (
      <CheckCircleOutlineIcon sx={{ color: "green", fontSize: 24 }} />
    ) : (
      <CancelIcon sx={{ fontSize: 24 }} color="disabled" />
    );

  return (
    <Container sx={{ marginTop: 4 }}>
    

      <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 3 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f5f5f5",
                  color: "#333",
                  borderBottom: "2px solid #ddd",
                  fontSize: "1rem",
                  padding: "12px 16px",
                }}
              >
                Feature
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f5f5f5",
                  color: "#333",
                  borderBottom: "2px solid #ddd",
                  fontSize: "1rem",
                  padding: "12px 16px",
                }}
              >
                Gold
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f5f5f5",
                  color: "#333",
                  borderBottom: "2px solid #ddd",
                  fontSize: "1rem",
                  padding: "12px 16px",
                }}
              >
                Platinum
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {benefits.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:hover": {
                    backgroundColor: "#f9f9f9",
                    cursor: "pointer",
                  },
                  backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
                }}
              >
                <TableCell
                  sx={{
                    padding: "16px",
                    fontSize: "0.9rem",
                    color: "#555",
                  }}
                >
                  {row.feature}
                </TableCell>
                <TableCell align="center">{renderIcon(row.gold)}</TableCell>
                <TableCell align="center">{renderIcon(row.platinum)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default BenefitsTable;
