import React, { useEffect, useState, useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmployerJobSeekerChatWindow from "../../../components/EmployerComponents/EmployerJobSeekerChat/EmployerJobSeekerChatWindow";
import EmployerJobSeekerChatList from "../../../components/EmployerComponents/EmployerJobSeekerChat/EmployerJobSeekerChatList";
import { getChats, getChatById } from "../../../api/chat";

const EmployerJobSeekerSupport = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageSent, setMessageSent] = useState(false);

  // Fetch chat list (memoized function)
  const fetchChatList = useCallback(async () => {
    try {
      const response = await getChats();
      setChatList(response?.chats || []);
    } catch (error) {
      console.error("Failed to fetch chat list:", error.message);
    }
  }, []);

  // Fetch selected chat (memoized function)
  const fetchSelectedChat = useCallback(async () => {
    if (!selectedChatId) return;
    try {
      const chatData = await getChatById(selectedChatId);
      setSelectedChat(chatData);
    } catch (error) {
      console.error("Failed to fetch chat details:", error.message);
    }
  }, [selectedChatId]);

  // Fetch chat list on mount or when messageSent changes
  useEffect(() => {
    fetchChatList();
  }, [fetchChatList, messageSent]);

  // Poll for new messages only when a chat is selected
  useEffect(() => {
    if (!selectedChatId) return;

    fetchSelectedChat();
    const interval = setInterval(fetchSelectedChat, 5000);

    return () => clearInterval(interval); // Cleanup interval
  }, [selectedChatId, fetchSelectedChat]);

  // Handle chat selection
  const handleChatSelection = async (chatId) => {
    setSelectedChatId(chatId);
    setIsOpen(true);
    await fetchSelectedChat(chatId);
  };

  // Accordion toggle handler
  const handleAccordionChange = (_, expanded) => {
    setIsOpen(expanded);
    if (!expanded) {
      setSelectedChatId(null);
      setSelectedChat(null);
    }
  };

  // Handle new message
  const handleNewMessage = (newMessage) => {
    setSelectedChat((prevChat) => ({
      ...prevChat,
      messages: [...(prevChat?.messages || []), newMessage],
    }));
    setMessageSent((prev) => !prev);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        zIndex: 1000,
        width: {
          xs: "50%", // For extra small screens (mobile)
          sm: "40%", // For small screens (tablet)
          md: "30%", // For medium screens (small desktops)
          lg: "20%", // For large screens (large desktops)
          xl:"20%",
        },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Accordion
        expanded={isOpen}
        onChange={handleAccordionChange}
        sx={{
          borderRadius: 2,
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: "#1976d2",
            color: "#fff",
            borderRadius: "8px 8px 0 0",
            padding: "0px 6px",
            "&:hover": { backgroundColor: "#1565c0" },
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Messaging
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
            backgroundColor: "#f9f9f9",
            borderRadius: "0 0 8px 8px",
            overflow: "hidden",
            height: "300px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: 1,
              overflowY: "auto",
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
            }}
          >
            <EmployerJobSeekerChatList
              chatList={chatList}
              onChatSelect={handleChatSelection}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      {selectedChat && (
        <Box
          sx={{
            position: "absolute",
            right: "400px",
            width: "450px",
            backgroundColor: "#fff",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            height: "400px",
          }}
        >
          <EmployerJobSeekerChatWindow
            chatData={selectedChat}
            onMessageSent={handleNewMessage}
            handleAccordionChange={handleAccordionChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default EmployerJobSeekerSupport;
