import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Chip, Button } from "@mui/material";
import VideoShortsScroll from "./VideoShortsScroll";
import { getTopIndustryMasters } from "../../../api/common";
import baseURL from "../../../api/baseUrl";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForward";


const JobSeekerTopIndustries = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null); // Default to null initially
  const [videoLinks, setVideoLinks] = useState([]);
  const [industryData, setIndustryData] = useState([]);

  useEffect(() => {
    const fetchTopIndustryMasters = async () => {
      try {
        const response = await getTopIndustryMasters();
        if (response) {
          // Extract names from the response array
          const subItems = response.flatMap((resItem) =>
            resItem.items.flatMap((item) => item.name)
          );
          const allCategories = subItems.filter(Boolean);
          setCategories(allCategories);

          // Set the first category as the default selection
          if (allCategories.length > 0) {
            setSelectedCategory(allCategories[0]);
            fetchVideosByIndustry(allCategories[0]); // Fetch videos for the first category
          }
        }
      } catch (error) {
        console.error("Error fetching top industry masters:", error.message);
      }
    };

    fetchTopIndustryMasters();
  }, []);

  const fetchVideosByIndustry = async (industry) => {
    try {
      const response = await fetch(`${baseURL}/dashboard/jobs-by-industry?industry=${industry}`);
      const data = await response.json();
      // Update video links with API response
      setVideoLinks(data.data || []);
      setIndustryData(data?.data);
    } catch (error) {
      console.error("Error fetching videos by industry:", error);
    }
  };

  const handleFilterChange = (category) => {
    setSelectedCategory(category);
    fetchVideosByIndustry(category); // Fetch videos for the selected category
  };

  const allVideos = videoLinks?.map((link) => link.jobVideoUrl);
  const thumbNails = videoLinks?.map((link) => link.jobThumbnailUrl)

  return (
    <Box sx={{ padding: { xs: 2, sm: 4 }, backgroundColor: "#ffffff", mt: 6 }}>
      <Typography
        variant="h3"
        fontWeight="bold"
        textAlign="center"
        mb={4}
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem" }, letterSpacing: "0.5px" }}
      >
        Top Companies
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          justifyContent: "center",
          mb: 4,
          paddingInline: { xs: 0, md: 20 },
          alignItems: "center",
        }}
      >
        {categories.map((category) => (
          <Chip
            key={category}
            label={category}
            clickable
            onClick={() => handleFilterChange(category)}
            sx={{
              fontSize: "16px",
              border: "1px solid #EAF1F5",
              backgroundColor:
                selectedCategory === category ? "#979EC2" : "transparent",
              color: selectedCategory === category ? "#fff" : "#000",
              "&:hover": {
                backgroundColor: "#eaeaf5",
                color: "#000",
              },
            }}
          />
        ))}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", paddingInline: 20 }}>
        <VideoShortsScroll videos={allVideos} thumbNails={thumbNails} industryData={industryData} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant='outlined'
          onClick={() => navigate('/top-hiring-companies')}
          sx={{
            color: "#ffffff",
            padding: "0.375rem 0.75rem",
            fontSize: '1rem',
            borderRadius: 3,
            backgroundColor: "#18181b",
            textTransform: 'none',
            borderColor: '#0e0593',
            '&:hover': {
              backgroundColor: '#21867a',
              color: 'white',
            },
          }}
        >
          View All Companies
        </Button>
      </Box>
    </Box>
  );
};

export default JobSeekerTopIndustries;
