import React from "react";
import { Card, CardMedia, Typography, Box } from "@mui/material";

const JobSeekerVideo = ({data}) => {
  return (
        <Card
        sx={{
            width: { xs: "100%", sm: 314 }, // Full width on extra small screens, 314px on small and larger screens
            borderRadius: 4,
            position: "relative",
            height: { xs: 400, sm: 500 }, // 300px height on extra small screens, 400px on small and larger screens
          }}
        >
      {data?.videoCV ? (
            <CardMedia
            controls
              component="video"
              image={data?.videoCV.map((cv)=>cv.url)}
              loop
              alt={data?.videoCV.map((cv)=>cv.thumbnail)}
              sx={{
                borderRadius: 4,
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
              autoPlay
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                backgroundColor: "#f0f0f0",
                borderRadius: 4,
              }}
            >
              <Typography variant="body1" color="textSecondary">
                No video found for this job
              </Typography>
            </Box>
          )}
    
          <Box
            sx={{
              position: "absolute",
              bottom: 16,
              left: 16,
              color: "white",
              fontWeight: "bold",
              p: 1,
              borderRadius: 2,
            }}
          >
            {/* <Typography
            //   sx={
            //     video?.jobVideoUrl
            //       ? { color: "white", fontWeight: "bold" }
            //       : { color: "black", fontWeight: "bold" }
            //   }
            >
              dummy Developer
            </Typography> */}
          </Box>
        </Card>
  )
}

export default JobSeekerVideo