import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Menu,
  Container,
  MenuItem,
  IconButton,
  TextField,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import { toast, ToastContainer } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { allUsers, deleteRecruiter } from "../../../api/manageUsers";
import EditUser from "./EditUser";

const MemberTable = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null); // Track the selected user ID
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [manageUsers, setManageUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [openEditDialogBox, setOpenEditDialogBox] = useState(false);
  const [userId, setUserId] = useState("");

  const token = localStorage.getItem("token");
  const orgId = localStorage.getItem("organizationId");

  const fetchUsers = useCallback(async () => {
    try {
      if (orgId) {
        const manageUserData = await allUsers(orgId, token);
        setManageUsers(manageUserData.recruiters);
        setFilteredUsers(manageUserData.recruiters); // Initialize filtered users
      } else {
        throw new Error("No organization id");
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [orgId, token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Dialog functions
  const handleOpenDialogBox = (userId) => {
    setOpenEditDialogBox(true);
    setUserId(userId);
  };

  const handleCloseDialogBox = () => {
    setOpenEditDialogBox(false);
  };

  const handleMenuOpen = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId); // Set the selected user ID
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserId(null); // Reset the selected user ID
  };

  const handleDeleteRecruiter = async (userId) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this recruiter?"
      );
      if (confirmDelete) {
        await deleteRecruiter(userId, token);
        toast.success("Recruiter deleted successfully");
        fetchUsers(); // Refetch the updated list of users
      }
    } catch (error) {
      console.error("Failed to delete recruiter:", error);
      toast.error(error.message || "An error occurred while deleting the recruiter.");
    }
  };

  // Filter logic
  useEffect(() => {
    const filtered = manageUsers.filter((user) => {
      const matchesSearch =
        user.rcName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.rcEmail.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesStatus =
        statusFilter === "" ||
        (statusFilter === "Active" && !user.deleted) ||
        (statusFilter === "Inactive" && user.deleted);
      return matchesSearch && matchesStatus;
    });
    setFilteredUsers(filtered);
  }, [searchQuery, statusFilter, manageUsers]);

  return (
    <Container
      sx={{
        padding: 2,
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        background: "#fff",
      }}
    >
      {/* Search and Filter */}
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mb={2}
        gap={2}
      >
        {/* Search Input */}
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search by name or email"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: "30%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Status Filter */}
        <FormControl size="small" variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            label="Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Table */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Name & Email</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Member Role</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Permissions</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Status</Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((member, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        width: 36,
                        height: 36,
                        borderRadius: "50%",
                        backgroundColor: "#f5f5f5",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 2,
                      }}
                    >
                      <Typography variant="body2" fontWeight="bold">
                        {member.rcName[0].toUpperCase()}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1">{member.rcName}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {member.rcEmail}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>{member.role}</TableCell>
                <TableCell>
                  {Object.keys(member.permissions)
                    .filter((key) => member.permissions[key])
                    .join(", ")}
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor: !member.deleted ? "#e8f5e9" : "#fbe9e7",
                      color: !member.deleted ? "#4caf50" : "#d32f2f",
                      borderRadius: "16px",
                      padding: "4px 12px",
                      display: "inline-block",
                    }}
                  >
                    {!member.deleted ? "Active" : "Inactive"}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={(event) => handleMenuOpen(event, member._id)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            handleOpenDialogBox(selectedUserId); // Open the edit dialog for the selected user
          }}
        >
          <EditIcon fontSize="small" sx={{ marginRight: 1 }} />
          Edit Member
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            handleDeleteRecruiter(selectedUserId); // Delete the selected user
          }}
          sx={{ color: "red" }}
        >
          <DeleteIcon fontSize="small" sx={{ marginRight: 1 }} />
          Delete Member
        </MenuItem>
      </Menu>

      <EditUser
        openEditDialogBox={openEditDialogBox}
        handleCloseDialogBox={handleCloseDialogBox}
        userId={userId}
        filteredUsers={filteredUsers}
        fetchUsers={fetchUsers}
      />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        theme="colored"
      />
    </Container>
  );
};

export default MemberTable;