import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { Box, Chip, Switch } from "@mui/material";
import { Typography } from "@mui/material";
import { getTopCitiesApi } from "../../api/common";
import { debounce } from "lodash"; // For debouncing

export default function CheckBoxSelect({ onCurrentCity, onPrefferedCity ,  initialSelectedCities = [],
  initialPreferredCities = [],}) {
  // States for Current City
  const [selectedCities, setSelectedCities] = useState(initialSelectedCities);
  const [currentInputValue, setCurrentInputValue] = useState("");
  const [currentTopCities, setCurrentTopCities] = useState([]);
  const [isTopCity, setIsTopCity] = useState(true); // State for the slider

  // States for Preferred City
  const [prefferedCity, setPrefferedCity] = useState(initialPreferredCities);
  const [preferredInputValue, setPreferredInputValue] = useState("");
  const [preferredTopCities, setPreferredTopCities] = useState([]);
  const [openPrefferedCity, setOpenPrefferedCity] = useState(false);

 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setSelectedCities(initialSelectedCities);
  }, [initialSelectedCities]);

  useEffect(() => {
    setPrefferedCity(initialPreferredCities);
  }, [initialPreferredCities]);
  
  useEffect(() => {
    if (isTopCity) {
      getTopCities(true, "current"); 
    }
  }, [isTopCity]);


  const fetchCities = debounce(async (query, isTopCity = false, type) => {
    setLoading(true);
    setError(null);

    try {
      const response = await getTopCitiesApi(query, isTopCity);
      if (type === "current") {
        setCurrentTopCities(response.cities); 
      } else if (type === "preferred") {
        setPreferredTopCities(response.cities); 
      }
    } catch (error) {
      console.error("Error fetching cities:", error.message);
      setError("Failed to fetch cities. Please try again.");
    } finally {
      setLoading(false);
    }
  }, 300); // 300ms debounce delay

  // Handle input change for the Current City TextField
  const handleCurrentInputChange = (event) => {
    const newInputValue = event.target.value;
    setCurrentInputValue(newInputValue);

    if (newInputValue) {
      fetchCities(newInputValue, isTopCity, "current");
    } else {
      fetchCities("", isTopCity, "current"); 
    }
  };

  // Handle input change for the Preferred City TextField
  const handlePreferredInputChange = (event) => {
    const newInputValue = event.target.value;
    setPreferredInputValue(newInputValue);

    if (newInputValue) {
      fetchCities(newInputValue, isTopCity, "preferred"); 
    } else {
      fetchCities("", isTopCity, "preferred"); 
    }
  };

  // Fetch top cities (initial load or when input is empty)
  const getTopCities = async (isTopCity = false, type) => {
    setLoading(true);
    setError(null);

    try {
      const response = await getTopCitiesApi("", isTopCity); // Fetch top cities
      if (type === "current") {
        setCurrentTopCities(response.cities); // Set cities for Current City
      } else if (type === "preferred") {
        setPreferredTopCities(response.cities); // Set cities for Preferred City
      }
    } catch (error) {
      console.error("Error fetching top cities:", error.message);
      setError("Failed to fetch top cities. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle city selection for Current City
  const handleCitySelect = (city) => {
    if (!selectedCities.includes(city)) {
      const updatedCities = [...selectedCities, city];
      setSelectedCities(updatedCities);
      onCurrentCity(updatedCities); // Pass the selected values to the parent
    }
    setCurrentInputValue(""); // Clear the input after selection
  };

  // Handle city removal for Current City
  const handleCityRemove = (city) => {
    const updatedCities = selectedCities.filter((c) => c !== city);
    setSelectedCities(updatedCities);
    onCurrentCity(updatedCities); // Pass the updated values to the parent
  };

  // Handle preferred city selection
  const handlePrefferedCitySelect = (city) => {
    if (!prefferedCity.includes(city)) {
      const updatedCities = [...prefferedCity, city];
      setPrefferedCity(updatedCities);
      onPrefferedCity(updatedCities); // Pass the selected values to the parent
    }
    setPreferredInputValue(""); // Clear the input after selection
  };

  // Handle preferred city removal
  const handlePrefferedCityRemove = (city) => {
    const updatedCities = prefferedCity.filter((c) => c !== city);
    setPrefferedCity(updatedCities);
    onPrefferedCity(updatedCities); // Pass the updated values to the parent
  };

  const handleOpenPrefferedCity = () => {
    setOpenPrefferedCity(true);
  };

  // Handle slider toggle
  const handleSliderChange = (event) => {
    setIsTopCity(event.target.checked);
  };

  // Handle boolean switch toggle


  const fontStyles = {
    fontSize: 13,
    fontWeight: "regular",
  };

  return (
    <>
      <Box>
      <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 1, // Add margin-top for spacing
          }}
        >
          <Typography component="legend" sx={fontStyles} gutterBottom>
            Current city
          </Typography>
          {/* Boolean Switch */}
          <span style={{ fontSize: 12 }}>
            <Switch
              checked={isTopCity}
              onChange={handleSliderChange}
              size="small"
            />
            Top Cities {`${isTopCity ? "On" : "Off"}`}
          </span>
        </Box>
        <TextField
          size="small"
          fullWidth
          placeholder="Type to search city"
          variant="outlined"
          value={currentInputValue}
          onChange={handleCurrentInputChange}
          error={!!error}
          helperText={error}
          InputProps={{
            startAdornment: selectedCities.map((city) => (
              <Chip
                key={city}
                label={city}
                onDelete={() => handleCityRemove(city)}
                sx={{ m: 0.5 }}
              />
            )),
          }}
        />
  
        {currentInputValue && (
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 1,
              mt: 1,
              maxHeight: 150,
              overflowY: "auto",
            }}
          >
            {currentTopCities.map((city) => (
              <Typography
                key={city.name}
                sx={{
                  p: 1,
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f5f5f5" },
                }}
                onClick={() => handleCitySelect(city.name)}
              >
                {loading?('Fetching cities...'):(`${city.name}` )}
              </Typography>
            ))}
          </Box>
        )}
      </Box>

      <Box>
        <Typography
          sx={{ color: "blue", fontSize: 12, m: 1, cursor: "pointer" }}
          onClick={handleOpenPrefferedCity}
        >
          Change Preffered Location
        </Typography>
      </Box>

      {openPrefferedCity && (
        <Box>
          <Typography sx={{ fontSize: 13, fontWeight: "regular", my: 1 }}>
            Preffered City
          </Typography>
          {/* Preferred City Section */}
          <TextField
            size="small"
            fullWidth
            placeholder="Type to search city"
            variant="outlined"
            value={preferredInputValue}
            onChange={handlePreferredInputChange}
            error={!!error}
            helperText={error}
            InputProps={{
              startAdornment: prefferedCity.map((city) => (
                <Chip
                  key={city}
                  label={city}
                  onDelete={() => handlePrefferedCityRemove(city)}
                  sx={{ m: 0.5 }}
                />
              )),
            }}
          />
          {/* Display suggestions for Preferred City */}
          {preferredInputValue && (
            <Box
              sx={{
                border: "1px solid #ccc",
                borderRadius: 1,
                mt: 1,
                maxHeight: 150,
                overflowY: "auto",
              }}
            >
              {preferredTopCities.map((city) => (
                <Typography
                  key={city.name}
                  sx={{
                    p: 1,
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                  }}
                  onClick={() => handlePrefferedCitySelect(city.name)}
                >
                  {loading?('Fetching cities...'):(`${city.name}` )}
                 
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      )}
    </>
  );
}