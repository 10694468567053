import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIos";
import { getTrendingJobs } from "../../../api/common";

const RoleCard = ({ title, hiring, logos, organizations, onClick }) => {
  return (
    <Paper
      elevation={0}
      onClick={onClick} // Add onClick here
      sx={{
        border: "1px solid #DFDFE7FF",
        padding: "24px",
        margin: "10px",
        borderRadius: 2,
        cursor: "pointer",
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
        },
        flex: "0 0 auto",
        width: "240px",
        height: "170px",
      }}
    >
        <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb:2,
        }}
      >
        {logos.map((logo, index) => (
          <img
            key={index}
            src={logo.url}
            alt={`Company Logo ${index + 1}`}
            style={{ width: "50px", height: "50px", borderRadius: 2 }}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography sx={{ fontFamily: "poppins", fontWeight: "bold" }}>
          {title}
        </Typography>
        <ArrowForwardIosIcon sx={{ fontSize: 17, opacity: 0.6 }} />
      </Box>
    
      <Box>
        <Typography
          sx={{
            fontFamily: "poppins",
            fontWeight: "regular",
            fontSize: 14,
            opacity: 0.5,
          }}
        >
          {hiring}
        </Typography>
        <Typography
          sx={{
            fontFamily: "poppins",
            fontWeight: "regular",
            fontSize: 14,
            opacity: 0.5,
          }}
        >
          {organizations}
        </Typography>
      </Box>
    
    </Paper>
  );
};


const JobSeekerTrendingJobs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [trendingJobs, setTrendingJobs] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrendingJobs = async () => {
      try {
        const response = await getTrendingJobs();
        setTrendingJobs(response?.data || []);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchTrendingJobs();
  }, []);

  const nextSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % Math.ceil(trendingJobs.length / 5)
    );
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + Math.ceil(trendingJobs.length / 5)) %
        Math.ceil(trendingJobs.length / 5)
    );
  };

  // Handle job click to navigate to job details
  const handleJobClick = (jobId) => {
    console.log("Navigating to job:", jobId); // Debugging
    if (jobId) {
      navigate(`/JobSeekerFilter/job/${jobId}`);
    } else {
      console.error("Job ID is undefined");
    }
  };
  

  const visibleRoles = isMobile ? 1 : 5;

  return (
    <Box
      sx={{
        padding: 3,
        position: "relative",
        minHeight: "50vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        textAlign="center"
        color="#0e0593"
      >
        Trending Job Roles on Vacanzi
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Button
          onClick={prevSlide}
          sx={{
            position: "absolute",
            left: 0,
            zIndex: 2,
            backgroundColor: "rgba(255,255,255,0.7)",
            "&:hover": { backgroundColor: "rgba(255,255,255,0.9)" },
          }}
        >
          <ArrowBackIosNewIcon />
        </Button>

        <Box
          sx={{
            display: "flex",
            transition: "transform 0.5s ease",
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {[...Array(Math.ceil(trendingJobs.length / visibleRoles))].map(
            (_, pageIndex) => (
              <Box key={pageIndex} sx={{ display: "flex", flexShrink: 0 }}>
                {trendingJobs
                  .slice(
                    pageIndex * visibleRoles,
                    (pageIndex + 1) * visibleRoles
                  )
                  .map((job, index) => (
                    <RoleCard
                    key={index}
                    onClick={() => handleJobClick(job?.jobId)} // Pass onClick correctly
                    title={job.trendingJob}
                    hiring={`${job.totalPositions} positions open`}
                    logos={job.organizationLogos}
                    organizations={`${job.noOfOrganizations} organization(s) hiring!`}
                  />
                  
                  ))}
              </Box>
            )
          )}
        </Box>

        <Button
          onClick={nextSlide}
          sx={{
            position: "absolute",
            right: 0,
            zIndex: 2,
            backgroundColor: "rgba(255,255,255,0.7)",
            "&:hover": { backgroundColor: "rgba(255,255,255,0.9)" },
          }}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
        <Button
          endIcon={<ArrowForwardIosIcon sx={{fontSize:"12px"}}/>}
          variant="outlined"
          sx={{
            color: "#ffffff",
            padding: "0.375rem 0.75rem",
            backgroundColor: "#18181b",
            fontWeight: "bold",
            borderRadius: "0.7rem",
            textTransform:"none",
            borderColor: "#18181b",
            "&:hover": {
              backgroundColor: "#21867a",
              color: "white",
            },
          }}
          onClick={()=>navigate('/findJobs')}
        >
          View All
        </Button>
      </Box>
    </Box>
  );
};

export default JobSeekerTrendingJobs;
