import { Box, Typography, List, ListItem } from "@mui/material";
import React from "react";
// import ResponsiveAppBar from "../NavBar/NavBar";
import NewNavBar from "../NavBar/NewNavbar";

const JobSeekerAboutUs = () => {
  return (
    <>
      <NewNavBar />
      <Box
        sx={{
          maxWidth: "800px",
          margin: "0 auto",
          padding: "2rem 1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3, // Creates consistent spacing between elements
        }}
      >
        {/* Title */}
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "#007BFF",
            width: "100%",
          }}
        >
          About Us: Vacanzi
        </Typography>

        {/* Introduction */}
        <Typography
          variant="h6"
          component="h1"
          sx={{
            fontWeight: "bold",
            color: "#423bfa",
            textAlign: "left", // Left-aligning the header
          }}
        >
          A New Era of Job Hunting Begins with Vacanzi
        </Typography>
        <Typography
          variant="body1"
          sx={{
            
            color: "#555",
            textAlign: "justify", // Justify the text for readability
          }}
        >
          Welcome to Vacanzi, the revolutionary job-seeking app that’s changing
          the way India connects with career opportunities. Built for the new
          generation, Vacanzi redefines how job seekers and employers interact,
          breaking barriers with innovative video and voice profiles. Vacanzi
          puts your personality, attitude, and communication skills at the
          center of the hiring process.
        </Typography>

        {/* India’s Fastest-Growing Job Search Engine */}
        <Typography
          variant="h6"
          component="h1"
          sx={{
            fontWeight: "bold",
            color: "#423bfa",
            textAlign: "left", // Left-aligning the header
          }}
        >
          India’s Fastest-Growing Job Search Engine
        </Typography>
        <Typography
          variant="body1"
          sx={{
            
            color: "#555",
            textAlign: "justify",
          }}
        >
          Record Break, In just 30 days, over half a million job seekers have
          joined the Vacanzi, making it India’s fastest-growing job search
          engine. This incredible milestone reflects the growing need for
          smarter, faster, and more engaging ways to bridge the gap between job
          seekers and employers.
        </Typography>

        {/* Built for the New Generation */}
        <Typography
          variant="h6"
          component="h1"
          sx={{
            fontWeight: "bold",
            color: "#423bfa",
            textAlign: "left", // Left-aligning the header
          }}
        >
          Built for the New Generation
        </Typography>
        <Typography
          variant="body1"
          sx={{
            
            color: "#555",
            textAlign: "justify",
          }}
        >
          Vacanzi transforms job searching into an empowering experience:
        </Typography>
        <List
          sx={{
            
            width: "100%",
          }}
        >
          <ListItem
            sx={{
              
              color: "#555",
              textAlign: "justify", // Justify the text for readability
             
            }}
          >
            Showcase the Real You: Upload a quick 30-second video or voice
            profile to demonstrate your confidence, communication skills, and
            potential..
          </ListItem>
          <ListItem
            sx={{
              
              color: "#555",
              textAlign: "justify", // Justify the text for readability
             
            }}
          >
            Interactive Job Listings: Explore opportunities in engaging video
            shorts, saving you time and making your search more effective.
          </ListItem>
          <ListItem
            sx={{
              
              color: "#555",
              textAlign: "justify", // Justify the text for readability
             
            }}
          >
            Instant Applications: Apply for your dream role in seconds and
            connect directly with top recruiters—without unnecessary steps.
          </ListItem>
        </List>

        {/* Why Vacanzi */}
        <Typography
          variant="h6"
          component="h1"
          sx={{
            fontWeight: "bold",
            color: "#423bfa",
            textAlign: "left", // Left-aligning the header
          }}
        >
          Why Vacanzi?
        </Typography>
        <List
          sx={{
            pl: 0,
            mb: 3,
            listStylePosition: "inside",
            width: "100%",
          }}
        >
          <ListItem
            sx={{
              listStyleType: '"• "',
              ml: 0,
              color: "#2D3748",
              fontSize: { xs: "1rem", md: "1.1rem" },
              
              lineHeight: 1.6,
            }}
          >
            <Typography variant="body1" component="span">
              <b>Save Time & Showcase the Real You</b> with Vacanzi.
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              listStyleType: '"• "',
              ml: 0,
              color: "#4A5568",
              fontSize: { xs: "1rem", md: "1.1rem" },
              
              lineHeight: 1.6,
            }}
          >
            <Typography sx={{
              
              color: "#555",
              textAlign: "justify", // Justify the text for readability
            }}>
              Job searching doesn’t have to be long and tedious. Vacanzi lets
              you stand out with video and voice profiles, allowing you to
              showcase your personality and communication skills without the
              hassle of form-filling.
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              listStyleType: '"• "',
              ml: 0,
              color: "#1A202C",
              fontSize: { xs: "1rem", md: "1.1rem" },
              lineHeight: 1.6,
            }}
          >
            <Typography sx={{
              
              color: "#555",
              textAlign: "justify", // Justify the text for readability
            }}>
              <b>The Truth About Job Rejections:</b> Did you know that 75% of
              employers reject candidates based on appearance, attitude,
              communication, and presentation? With Vacanzi, employers can see
              the real you, assessing whether you're a true fit for the job
              beyond the resume.
            </Typography>
          </ListItem>
        </List>

        {/* How Vacanzi Saves You Time */}
        <Typography
          variant="h6"
          component="h1"
          sx={{
            fontWeight: "bold",
            color: "#423bfa",
            textAlign: "left", // Left-aligning the header
          }}
        >
          How Vacanzi Saves You Time
        </Typography>
        <List
          sx={{
            
            listStylePosition: "inside",
            width: "100%",
          }}
        >
          <ListItem
            sx={{
              
              color: "#555",
              textAlign: "justify", // Justify the text for readability
            }}
          >
            Skip the Paperwork: Upload a 30-second video or voice profile to
            show your confidence, communication, and professional attitude..
          </ListItem>
          <ListItem
            sx={{
              
              color: "#555",
              textAlign: "justify", // Justify the text for readability
             
            }}
          >
            Showcase your Personality : Employers get to see your personality
            and presentation skills, helping you stand out.
          </ListItem>
          <ListItem
            sx={{
              
              color: "#555",
              textAlign: "justify", // Justify the text for readability
             
            }}
          >
            Interview with the Right Fit: Only candidates who match the job
            requirements are invited for interviews, saving you time and
            effort..
          </ListItem>
          <ListItem
            sx={{
              
              color: "#555",
              textAlign: "justify", // Justify the text for readability
             
            }}
          >
            Save Time on Travel: Vacanzi ensures that only the best-matched
            candidates get interview invites, eliminating unnecessary travel.
          </ListItem>
          <ListItem
            sx={{
              
              color: "#555",
              textAlign: "justify", // Justify the text for readability
             
            }}
          >
            Join the Future of Job Search Vacanzi is all about finding the right
            job for you, faster. Upload your video or voice profile today and
            let employers see your true potential.
          </ListItem>
        </List>

        {/* Join the Future of Job Search */}
        <Typography
          variant="h6"
          component="h1"
          sx={{
            fontWeight: "bold",
            color: "#423bfa",
            textAlign: "left", // Left-aligning the header
          }}
        >
          Join the Future of Job Search
        </Typography>
        <Typography
          variant="body1"
          sx={{
            
            color: "#555",
            textAlign: "justify",
          }}
        >
          Vacanzi is all about finding the right job for you, faster. Upload
          your video or voice profile today and let employers see your true
          potential.
        </Typography>
      </Box>
    </>
  );
};

export default JobSeekerAboutUs;
