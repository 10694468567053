import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const jobTypes = [
  { value: "fullTime", label: "Full Time" },
  { value: "partTime", label: "Part Time" },
  { value: "freelance", label: "Freelance" },
  { value: "contract", label: "Contract" },
  { value: "internship", label: "Internship" },
  { value: "remote", label: "Remote" },
];

const JobTypeFilter = ({ formData, handleJobTypeChange }) => {
  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#666", opacity: 0.7 }} />}
        sx={{
          padding: "8px 0",
          minHeight: "40px",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "IBM Plex Sans, sans-serif",
            color: "#333",
          }}
        >
         Preffered Job Type
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "0 0 16px 0", backgroundColor: "transparent" }}
      >
        <FormControl component="fieldset" fullWidth>
          <RadioGroup value={formData.prefferedJobType} onChange={(e)=>handleJobTypeChange(e.target.value)}>
            {jobTypes.map((type) => (
              <FormControlLabel
                key={type.value}
                value={type.value}
                control={
                  <Radio
                    checked={formData.prefferedJobType === type.value}
                    sx={{
                      color: "#1967d2",
                      "&.Mui-checked": {
                        color: "#1967d2",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontFamily: "IBM Plex Sans, sans-serif",
                      fontWeight: formData.prefferedJobType === type.value ? "bold" : "normal",
                      color: "#333",
                      fontSize: "14px",
                    }}
                  >
                    {type.label}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default JobTypeFilter;