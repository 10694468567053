import baseUrl from "./baseUrl";


export const fetchOrgSupport = async () => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('User not authenticated');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}/getOrgTicket`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetch returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }


        // Map the new response data to match the required fields

        return response.json();

    } catch (error) {
        console.error('Error fetching Support details:', error);
        throw error;
    }
};


// Assuming this is your API call function
export const fetchOrgSupportById = async (supportId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        const response = await fetch(`${baseUrl}/OrgTicket/${supportId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });



        if (!response.ok) {
            console.warn("Token may be invalid or session expired.");
            return null;
        }

        const data = await response.json(); // assuming JSON response

        return data; // Extract messages from the response


    } catch (error) {
        console.error("Error fetching Support details:", error);
        throw error;
    }
};

export const employerSendMessage = async (payload, supportId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}/replyOrgTicket/${supportId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set header for JSON
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        // Check if the response status is not OK (e.g., 200–299)
        if (!response.ok) {
            const errorData = await response.json(); // Parse the error response body
            throw new Error(`Failed to send message: ${errorData.message || response.statusText}`);
        }

        const data = await response.json(); // Parse the JSON response        

        if (data.message === 'Reply sent successfully') {
            return data?.recruiterMessage
                ; // Return relevant data
        } else {
            throw new Error(`Failed to send message: ${data.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error during sending message:', error);

        // Add response-specific error handling if needed
        throw error; // Propagate error to the calling function
    }
};
