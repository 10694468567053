import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Divider,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import signupVideo from "../../../assets/videos/videoplayback2.mp4";
import NonRegisteredNavBar from "../../../components/NavBarNonRegistered/NonRegisteredNavBar";
import JobSeekerCaseZero from "../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseZero/JobSeekerCaseZero";
import JobSeekerCaseTwo from "../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseTwo/JobSeekerCaseTwo";
// import JobSeekerCaseThree from "../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseThree/JobSeekerCaseThree";
import JobSeekerCaseFour from "../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseFour/JobSeekerCaseFour";
// import JobSeekerCaseFive from "../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseFive/JobSeekerCaseFive";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import baseUrl from "../../../api/baseUrl";
import { toast, ToastContainer } from "react-toastify";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#423BFA" : "#308fe8",
  },
}));

export default function Job() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const phone = location.state?.loginPhone || "";

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: phone,
    highestEducation: "",
    dob: "",
    currentCompany: "",
    dateOfJoining: "",
    dateOfExit: null,
    noticePeriod: "",
    servingNoticePeriod: true,
    gender: "",
    totalExp: 0,
    videoCV: "",
    audioCV: "",
    resume: "",

    degreeType: "",
    institution: "",
    industry: "",
    startDate: "",
    fieldOfStudy: "",
    currentlyWorking: true,
    endDate: "",
    grade: "",
    jobRole: [],
    experienceType: "",
    expectedCtc: "",
    totalExpInYears: 0,
    totalExpInMonths: 0,
    currentCtc: "",
    skills: [],
    description: "",
    currentDesignation: "",
    pursuingEducation: false,
    currentlyPursuing: "NA",
    profileImage: null,
    knownLanguage: [],
    preferredJobType: [], // Initialize as array
    preferredShift: [], // Initialize as array
    preferredWorkplace: [], // Initialize as array
    preferredEmploymentType: "",
    preferredLocation: "",
    currentLocation: "",
    graduationYear: null,
    hasWorkExperience: true,
    preferredJobTitle: "",
  });

  const steps = [
    "Basic Details",
    "",
    "Job Details",
    "",
    "Profile Photo",
    "Resume Upload",
  ];

  const handleNext = () => {
    if (activeStep === 0 && !formData.hasWorkExperience) {
      setActiveStep(4);
    } else if (activeStep === 0 && formData.hasWorkExperience) {
      setActiveStep(2);
    } else if (activeStep === 2 && formData.hasWorkExperience) {
      setActiveStep(4);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 4) {
      setCompleted(true);
    }
  };
  const handleBack = () => {
    if (activeStep === 3 && !formData.hasWorkExperience) {
      setActiveStep(0); // Go back to step 0
    } else if (activeStep === 3 && formData.hasWorkExperience) {
      setActiveStep(2); // Skip back to step 2
    } else if (activeStep === 4 && !formData.hasWorkExperience) {
      setActiveStep(0);
    } else if (activeStep === 4 && formData.hasWorkExperience) {
      setActiveStep(2);
    } else if (activeStep === 0) {
      setActiveStep(0); // Stay at step 0
    } else if (activeStep === 2) {
      setActiveStep(0); // Stay at step 0
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1); // Decrement step normally
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    // If the value is an array (for Autocomplete), update state directly
    if (Array.isArray(value)) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      // Handle other types like string values for Select
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleLanguageChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      knownLanguage: newValue, // Directly set the new value (array of selected languages)
    }));
  };

  const handleGenderChange = (gender) => {
    setFormData({
      ...formData,
      gender: gender,
    });
  };

  const handleJobRoleChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experiences: [
        {
          ...prevFormData.experiences[0],
          jobRole: newValue,
        },
      ],
    }));
  };

  const handleExperienceChange = (experience) => {
    handleChange({
      target: {
        name: "hasWorkExperience",
        value: experience,
      },
    });

    handleChange({
      target: {
        name: "experienceType",
        value: experience ? "experienced" : "fresher",
      },
    });
  };

  const handlePursuingEducationChange = (value) => {
    handleChange({
      target: {
        name: "pursuingEducation",
        value: value,
      },
    });
  };

  const handleCurrentlyPursuingChange = (value) => {
    handleChange({
      target: {
        name: "currentlyPursuing",
        value: value,
        experienceType: formData.hasWorkExperience ? "Expierenced" : "Fresher",
      },
    });
  };

  const handleIndustryChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      industry: newValue,
    }));
  };

  const handleCurrentlyWorkingChange = (workingStatus) => {
    handleChange({
      target: {
        name: "currentlyWorking",
        value: workingStatus,
      },
    });
  };

  const handleSkillsChange = (event, newValue) => {
    // Update the formData with the selected skills
    setFormData((prevFormData) => ({
      ...prevFormData,
      skills: newValue, // skills is now just an array of strings
    }));
  };

  const handleProceed = () => {
    setCompleted(false);
    setActiveStep(0);
    setFormData({
      fullName: "",
      email: "",
      phone: phone,
      highestEducation: "",
      dob: "",
      currentCompany: "",
      dateOfJoining: "",
      dateOfExit: null,
      noticePeriod: "",
      gender: "male",
      // totalExp: 0,
      totalExpInMonths: 0,
      currentCtc: "",
      videoCV: "",
      audioCV: "",
      resume: "",
      skills: [],
      description: "",
      currentDesignation: "",
      pursuingEducation: false,
      currentlyPursuing: "NA",
      currentlyWorking: true,
      profileImage: null,
      preferredJobType: [], // Initialize as array
      preferredShift: [], // Initialize as array
      preferredWorkplace: [], // Initialize as array
      preferredEmploymentType: "",
      graduationYear: null,
      hasWorkExperience: true,
      preferredJobTitle: "",
    });
    navigate("/JobSeekerFilter");
  };

  useEffect(() => {
    const registerUser = async () => {
      try {
        const registerUserUrl = `${baseUrl}/js_register`;
        const registerPayload = {
          phone: formData.phone,
          email: formData.email,
          fullName: formData.fullName,
        };

        const registerResponse = await fetch(registerUserUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(registerPayload),
        });

        if (!registerResponse.ok) {
          throw new Error("Error Registering User");
        }
        const registerData = await registerResponse.json();
        toast.success("User registered successfully");

        console.log("User registered successfully:", registerData);

        // Assuming the response contains a token, save it to localStorage
        if (registerData.token) {
          localStorage.setItem("authToken", registerData.token);
          localStorage.setItem("userId", registerData.user._id);
        }
      } catch (error) {
        console.error("Error registering user:", error);
      }
    };

    // Call registerUser only when activeStep === 4
    if (activeStep === 4) {
      registerUser();
    }
  }, [activeStep, formData.phone, formData.email, formData.fullName]);

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken"); // Retrieve authToken from localStorage
    if (!authToken) {
      console.error("No auth token found in localStorage");
      return;
    }

    try {
      const apiEndpoint = `${baseUrl}/registerJsDetails`;
      const formDataToSend = new FormData();

      // Append form data
      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (Array.isArray(value)) {
          value.forEach((item) => formDataToSend.append(`${key}[]`, item));
        } else {
          formDataToSend.append(key, value);
        }
      });

      // Send POST request
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formDataToSend,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Form submitted successfully:", data);
   
        setCompleted(true); // Mark as completed
        setActiveStep(steps.length); // Move to the next step (or final step)
        toast.success("Profile created successfully!")
      } else {
        throw new Error("Failed to create profile");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    
      toast.error("Failed to create profile")

    }
  };


  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <JobSeekerCaseZero
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
            handleGenderChange={handleGenderChange}
            handleExperienceChange={handleExperienceChange}
            handleCurrentlyPursuingChange={handleCurrentlyPursuingChange}
            handlePursuingEducationChange={handlePursuingEducationChange}
          />
        );
      case 2: // Skip case 1
        return (
          <JobSeekerCaseTwo
            steps={steps}
            formData={formData}
            setFormData={setFormData}
            handleSkillsChange={handleSkillsChange}
            handleLanguageChange={handleLanguageChange}
            handleJobRoleChange={handleJobRoleChange}
            handleIndustryChange={handleIndustryChange}
            handleChange={handleChange}
            handleCurrentlyWorkingChange={handleCurrentlyWorkingChange}
          />
        );
      // case 3:
      //   return (
      //     <JobSeekerCaseThree
      //       formData={formData}
      //       handleCurrentlyPursuingChange={handleCurrentlyPursuingChange}
      //       handlePursuingEducationChange={handlePursuingEducationChange}
      //       handleChange={handleChange}
      //     />
      //   );
      case 4:
        return (
          <JobSeekerCaseFour
            formData={formData}
            handleChange={handleChange}
            handleNext={handleNext}
          />
        );
      // case 5:
      //   return (
      //     <ThemeProvider theme={theme}>
      //       <JobSeekerCaseFive
      //         formData={formData}
      //         handleChange={handleChange}
      //         setFormData={setFormData}
      //       />
      //     </ThemeProvider>
      //   );
      default:
        return "Unknown step";
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#e5e7eb",
        minHeight: "100vh",
        mb: "20px",
      }}
    >
      <NonRegisteredNavBar />
      <Container maxWidth="800px" sx={{ marginTop: "4rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            {completed ? (
              <Grid
                sx={{ backgroundColor: "#ffffff", padding: 2, borderRadius: 4 }}
              >
                <CardContent align="center">
                  <Typography variant="h5" gutterBottom>
                    ✨ Congratulations! ✨
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Your profile is successfully created.
                  </Typography>
                  <Box mt={4}>
                    <Button
                      sx={{
                        backgroundColor: "#4CAF50",
                        padding: "10px",
                        borderRadius: 2,
                        "&:hover": {
                          backgroundColor: "#4CAF50",
                        },
                      }}
                      type="submit"
                      fullWidth
                      onClick={handleProceed}
                    >
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="subtitle2"
                        color="white"
                      >
                        Proceed
                      </Typography>
                    </Button>
                  </Box>
                </CardContent>
              </Grid>
            ) : (
              <Grid
                sx={{ backgroundColor: "#ffffff", padding: 2, borderRadius: 4 }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                      <KeyboardBackspaceIcon
                        disabled={activeStep === steps + 1}
                        onClick={handleBack}
                        sx={{ mr: 2, color: activeStep === 0 ? "grey" : "" }}
                      />
                      {steps[activeStep]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right", mb: 2 }}>
                    <Box sx={{ width: "100%" }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={(activeStep / (steps.length - 1)) * 100}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Divider />
                {renderStepContent(activeStep)}
                <Divider />
                {/* {activeStep === 4 && (
                  <Box mt={2}>
                    <Button
                      sx={{
                        backgroundColor: "transparent",
                        padding: "8px",
                        borderRadius: 2,
                        border: "1px solid #423BFA",
                        "&:hover": {
                          backgroundColor: "#4CAF50",
                          borderColor: "#4CAF50",
                        },
                      }}
                      type="submit"
                      fullWidth
                      onClick={handleNext}
                    >
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="subtitle2"
                        color="#423BFA"
                      >
                        Skip
                      </Typography>
                    </Button>
                  </Box>
                )} */}
                <Box mt={4}>
                  <Button
                    sx={{
                      backgroundColor: "#423BFA",
                      padding: "8px",
                      borderRadius: 2,
                      "&:hover": {
                        backgroundColor: "#4CAF50",
                      },
                    }}
                    type="submit"
                    fullWidth
                    onClick={activeStep === 4 ? handleSubmit : handleNext}
                  >
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      color="white"
                    >
                      {activeStep === 4 ? "Submit" : "Next"}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1,
            }}
          >
            <video
              autoPlay
              loop
              muted
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "1rem",
              }}
            >
              <source src={signupVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        theme="colored"
      />
    
    </Box>
  );
}
