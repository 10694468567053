import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const baseURL = "https://vacanzi.com/api";

const CandidateChatModal = ({ currentId, onClose }) => {
  const [chatId, setChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const recruiterId = localStorage.getItem("recruiterId");

  // Reference to scroll to the latest message
  const messagesEndRef = useRef(null);

  // Fetch chat details or create a new one
  useEffect(() => {
    const fetchChatDetails = async () => {
      try {
        const response = await fetch(`${baseURL}/chat/get/chatId`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userIds: [currentId, recruiterId] }),
        });
        const data = await response.json();
        if (data.chatId || (data.chat && data.chat._id)) {
          setChatId(data.chatId || data.chat._id);
          fetchChatMessages(data.chatId || data.chat._id);
        } else {
          setMessages([]);
        }
      } catch (error) {
        console.error("Error fetching chat details:", error.message);
      }
    };

    fetchChatDetails();
  }, [currentId, recruiterId, chatId]);

  // Fetch chat messages by chatId
  const fetchChatMessages = async (chatId) => {
    try {
      const response = await fetch(`${baseURL}/chat/${chatId}`);
      const data = await response.json();
      setMessages(data || []);
    } catch (error) {
      console.error("Error fetching chat messages:", error.message);
    }
  };

  // Send message
  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const payload = {
      senderId: recruiterId,
      receiverId: currentId,
      senderModel: "Recruiter",
      receiverModel: "User",
      message: input,
    };

    try {
      const response = await fetch(`${baseURL}/chat/sendMessage`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();

        // Update chatId if not already set
        if (!chatId) {
          setChatId(data.chat._id);
        }

        // Update messages with the new message
        setMessages((prev) => [
          ...prev,
          {
            ...data.message,
            senderModel: "Recruiter",
            timestamp: new Date(data.message.timestamp),
          },
        ]);

        setInput("");
      } else {
        console.error("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error.message);
    }
  };

  // Scroll to the latest message when messages are updated
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Box
      sx={{
        width: 400,
        margin: "50px auto",
        padding: 2,
        backgroundColor: "white",
        borderRadius: 2,
        boxShadow: 3,
        display: "flex",
        flexDirection: "column",
        height: 500,
        position: "relative", // Ensures proper positioning of the close button
      }}
    >
      {/* Close button */}
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "gray",
          "&:hover": {
            color: "black",
          },
        }}
      >
        <CloseIcon />
      </IconButton>

      {/* Chat Header */}
      <Typography variant="h6" sx={{ marginBottom: 2, textAlign: "center" }}>
        Chat
      </Typography>

      {/* Messages Container */}
      <Box sx={{ flexGrow: 1, overflowY: "auto", marginBottom: 2 }}>
        {chatId ? (
          messages.length > 0 ? (
            messages.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent:
                    msg?.senderModel === "Recruiter" ||
                    msg?.sender?.userModel === "Recruiter"
                      ? "flex-end"
                      : "flex-start",
                  marginBottom: 1,
                }}
              >
                <Box
                  sx={{
                    maxWidth: "70%",
                    padding: "8px 12px",
                    borderRadius: 1,
                    backgroundColor:
                      msg?.senderModel === "Recruiter" ||
                      msg.sender.userModel === "Recruiter"
                        ? "#e3f2fd"
                        : "#f5f5f5",
                    boxShadow:
                      msg?.senderModel === "Recruiter" ||
                      msg.sender.userModel === "Recruiter"
                        ? "0 2px 5px rgba(0, 0, 0, 0.1)"
                        : "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      color:
                        msg?.senderModel === "Recruiter" ||
                        msg?.sender.userModel === "Recruiter"
                          ? "#000"
                          : "#333",
                      textAlign: "left",
                    }}
                  >
                    {msg.message}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 10,
                      color: "gray",
                      textAlign:
                        msg?.senderModel === "Recruiter" ||
                        msg.sender.userModel === "Recruiter"
                          ? "right"
                          : "left",
                      marginTop: 0.5,
                    }}
                  >
                    {new Date(msg.timestamp).toLocaleTimeString()}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography>No messages yet.</Typography>
          )
        ) : (
          <Typography>No conversation found.</Typography>
        )}
        {/* Scroll to the bottom after the messages */}
        <div ref={messagesEndRef} />
      </Box>

      {/* Input and Send Button */}
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <Button
          sx={{
            borderRadius: "2rem",
            textTransform: "none",
            backgroundColor: "#423bfa",
          }}
          variant="contained"
          onClick={handleSendMessage}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default CandidateChatModal;
