import React, { useState, useRef, useEffect } from 'react';
import { Box, Avatar, Typography, IconButton, Container, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserIdFromToken } from '../../../utils/userAuth';
import baseURL from '../../../api/baseUrl';
import ProfileActivity from '../../ProfileActiviyCount/ProfileActivity';

const UpdateJSProfile = ({ toggleDrawer, profileData }) => {
  const [otpSent, setOtpSent] = useState(false); 
  const [otpVerified, setOtpVerified] = useState(false); 
  const [otp, setOtp] = useState(Array(6).fill(''));
  const inputRefs = useRef([]);

  const experiences = profileData?.experiences || [];
  const currentExperience = experiences[0] || {};

  useEffect(() => {
    const fetchUserDetail = async () => {
      const userId = getUserIdFromToken();
      try {
        const response = await fetch(`${baseURL}/jobseeker/${userId}`);
        const data = await response.json();
        console.log("user data:", data);
        setOtpVerified(data.user.emailVerified);
      } catch (error) {
        toast.error('Error fetching user details');
      }
    };
    fetchUserDetail();
  }, []);

  const handleSendOtp = async () => {
    try {
      const response = await fetch('https://vacanzi.com/api/emailOtp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: profileData.email }),
      });

      const data = await response.json();
      if (response.ok) {
        setOtpSent(true);
        toast.success('OTP sent successfully!');
      } else {
        toast.error(data.message || 'Failed to send OTP');
      }
    } catch (error) {
      toast.error('Error sending OTP');
    }
  };

  const handleVerifyOtp = async () => {
    const otpString = otp.join('');
    try {
      const response = await fetch('https://vacanzi.com/api/verifyEmailOtp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: profileData.email, otp: otpString }),
      });

      const data = await response.json();
      if (response.ok) {
        setOtpVerified(true);
        toast.success('OTP verified successfully!');
      } else {
        toast.error(data.message || 'Invalid OTP');
      }
    } catch (error) {
      toast.error('Error verifying OTP');
    }
  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <>
    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: { xs: 2, md: 3 } }}>
      {/* Profile and OTP Section */}
      <Box sx={{ position: 'relative', width: { xs: '100%', sm: 300, md: 300 }, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 3, textAlign: 'center', p: 3, mb: 2 }}>
        {/* Edit Button */}
        <IconButton onClick={() => toggleDrawer(true)} sx={{ position: 'absolute', top: 8, right: 8, bgcolor: 'background.paper', borderRadius: '50%', boxShadow: 2 }}>
          <EditIcon />
        </IconButton>

        {/* Avatar */}
        <Avatar sx={{ width: 80, height: 80, margin: 'auto' }}>{profileData.fullName ? profileData.fullName.charAt(0).toUpperCase() : 'P'}</Avatar>

        {/* Profile Details */}
        <Typography variant="h6" sx={{ mt: 2 }}>{profileData.fullName || 'Full Name'}</Typography>
        <Typography variant="body2" color="textSecondary">{profileData.phone || 'Phone Number'}</Typography>
        
        {/* Experience & Location */}
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <WorkIcon sx={{ mr: 1 }} />
          <Typography>{currentExperience?.designation || 'Designation'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <LocationOnIcon sx={{ mr: 1 }} />
          <Typography>{currentExperience?.location || 'Location'}</Typography>
        </Box>

        {/* Email Status */}
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <EmailIcon sx={{ mr: 1 }} />
          <Typography>{profileData.email || 'Email Address'}</Typography>
          {otpVerified ? (
            <Box sx={{ ml: 2, width: 20, height: 20, borderRadius: '50%', bgcolor: 'green', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography sx={{ color: 'white', fontSize: 14 }}>✔</Typography>
            </Box>
          ) : (
            <Box sx={{ ml: 2, width: 20, height: 20, borderRadius: '50%', bgcolor: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography sx={{ color: 'white', fontSize: 14 }}>✘</Typography>
            </Box>
          )}
        </Box>

        {/* OTP Section */}
        {!otpVerified && !otpSent && <Button variant="outlined" color="primary" onClick={handleSendOtp} sx={{ mt: 2 }}>Verify Email</Button>}
        {otpSent && !otpVerified && (
          <Box sx={{ mt: 2 }}>
            <Typography>Enter OTP:</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  maxLength={1}
                  ref={(el) => (inputRefs.current[index] = el)}
                  style={{ width: 40, height: 40, textAlign: 'center', margin: '0 5px', fontSize: 18, border: '1px solid #ccc', borderRadius: 5 }}
                />
              ))}
            </Box>
            <Button variant="contained" color="primary" onClick={handleVerifyOtp} sx={{ mt: 2 }}>Verify OTP</Button>
          </Box>
        )}
       
      </Box>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar closeOnClick />
     
     
    </Container>
    <ProfileActivity />
    </>
  );
};

export default UpdateJSProfile;
