import React, { useEffect } from "react";
// import ResponsiveAppBar from "../../components/JobSeekerComponents/NavBar/NavBar";
// import HowItWorks from '../../components/JobSeekerComponents/HowItWorks/HowItWorks';
// import SearchCategories from '../../components//JobSeekerComponents/Catogories/SearchCategories';
// import LatestJobOpenings from '../../components/JobSeekerComponents/LatestJobs/LatestJobs';
// import FeaturedJobs from '../../components/JobSeekerComponents/FeaturedJobs/FeaturedJobs';
import FeaturedEmployers from "../../components/JobSeekerComponents/FeaturedEmployers/FeaturedEmployers";
import Footer from "../../components/JobSeekerComponents/Footer/Footer";
import JobSeekerJobSearch from "../../components/JobSeekerComponents/JobSeekerSearch/jobSeekerJobSearch";
import JobSeekerTrendingJobs from "../../components/JobSeekerComponents/JobSeekerTrendingJobs/JobSeekerTrendingJobs";
// import JobSeekerGetTheApp from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerGetTheApp";
// import DiscoverJobs from '../../components/JobSeekerComponents/JobSeekerHomePageBanners/DiscoverJobsBanner';
// import JobSeekerUpComingEvents from '../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerUpComingEvents';
// import JobSeekerInterViewPrep from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerIntreviewPrep";
import JobSeekerTopCompanies from "../../components/JobSeekerComponents/JobSeekerTopCompanies/JobSeekerTopCompanies";
import WhyVacanzi from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/WhyVacanzi";
import JobSeekerTopIndustries from "../../components/JobSeekerComponents/JobSeekerIndustries/JobSeekerTopIndustries";
// import ImageCarousel from '../../components/LoginPageComponents/ImageCorousel';
import JobSeekerImageCarousel from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerImageCarousel";
import VacanziCounselors from "../../components/LoginPageComponents/VacanziCounselors";
import { useScroll } from "../../context/ScrollContext";
import { useLocation, useNavigate } from "react-router-dom";
import NewNavBar from "../../components/JobSeekerComponents/NavBar/NewNavbar";
import Faq from "../../components/LoginPageComponents/Faq";
import JobSeekerBottom from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerBottom";
import Advertisements from "../../components/JobSeekerComponents/JobSeekerHomePageBanners/Advertisements";


const Home = () => {
  const { whyVacanziRef, scrollToWhyVacanzi } = useScroll();
  const location = useLocation();
  const navigate = useNavigate();

  

  useEffect(() => {
    if (location.state?.scrollToWhyVacanzi) {
      scrollToWhyVacanzi();
      // Clear the state after scrolling
      navigate(".", { replace: true, state: {} });
    }
  }, [location, scrollToWhyVacanzi, navigate]);


  // const whyVacanziRef = useRef(null);
  // useEffect(() => {
  //   window.scrollTo(0, 0); // Scroll to the top of the page
  // }, []);
  // const scrollToWhyVacanzi = () => {
  //   whyVacanziRef.current.scrollIntoView({ behavior: 'smooth' });
  // };

  return (
    <>
      <NewNavBar />
      <JobSeekerJobSearch />
      <Advertisements/>
      <JobSeekerTopIndustries />
      {/* <HowItWorks /> */}
      {/* <SearchCategories /> */}
      <JobSeekerTrendingJobs />
      {/* <LatestJobOpenings /> */}
      <div ref={whyVacanziRef}>
        <WhyVacanzi />
      </div>
      <JobSeekerImageCarousel />
      {/* <FeaturedJobs /> */}
      <JobSeekerTopCompanies />
      <FeaturedEmployers />
      {/* <JobSeekerUpComingEvents /> */}
      <VacanziCounselors />
      {/* <DiscoverJobs /> */}
      {/* <JobSeekerInterViewPrep /> */}
      {/* <JobSeekerGetTheApp /> */}
      <JobSeekerBottom/>
      <Faq/>
      <Footer />
    </>
  );
};

export default Home;
