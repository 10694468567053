import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UGqualificaton from "./UGqualificaton";
import { Box } from "@mui/system";
import searchCandidates from "../../styles/searchCandidatesStyles";
import SpecificUGqualification from "./SpecificUGqualification";
import PGqualification from "./PGqualification";
import SpecificPGqualification from "./SpecificPGqualification";

const internalCss = {
  marginRight: 2,
  fontWeight: "bold",
  textTransform: "none",
  borderRadius: 10,
  border: "1px solid black",
  alignItems: "center",
  marginTop: { xs: 2, sm: 0 },
  "&:hover": {
    backgroundColor: "#423bfa",
    color: "white",
    border: "1px solid #423bfa",
  },
};

const EducationDetails = () => {
  const [selectUGQualification, setSelectUGQualification] = useState("Any UG");
  const [selectPGQualification, setSelectPGQualification] = useState("");
  return (
    <Paper
      elevation={0}
      sx={{
        mt: 2, // Top margin
        mb: 2, // Bottom margin
        overflow: "hidden", // To ensure proper border-radius effect
        borderRadius: "8px", // Optional for rounded corners
      }}
    >
      <Accordion
      defaultExpanded
        sx={{
          boxShadow: "none", // Remove default Accordion shadow
          borderRadius: "inherit", // Match Accordion to Paper's borderRadius
          pt: 2,
          pb: 4,
          "&:before": {
            display: "none", // Remove the default Accordion divider line
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Education Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontSize: 14, mb: 1, fontWeight: 'bold' }}>
            Any UG Qualification
          </Typography>
          <Box sx={searchCandidates.flexboxCenter}>
            <Button
              variant="outlined"
              sx={{
                ...(selectUGQualification === "Any UG"
                  ? {
                    ...internalCss,
                    backgroundColor: "#423bfa",
                    color: "white",
                  }
                  : { ...internalCss, color: "#423bfa" }),
              }}
              onClick={() => setSelectUGQualification("Any UG")}
            >
              Any UG
            </Button>
            <Button
              sx={{
                ...(selectUGQualification === "Specific UG"
                  ? {
                    ...internalCss,
                    backgroundColor: "#423bfa",
                    color: "white",
                  }
                  : { ...internalCss, color: "#423bfa" }),
              }}
              onClick={() => setSelectUGQualification("Specific UG")}
            >
              Specific UG
            </Button>
            <Button
              sx={{
                ...(selectUGQualification === "No UG"
                  ? {
                    ...internalCss,
                    backgroundColor: "#423bfa",
                    color: "white",
                  }
                  : { ...internalCss, color: "#423bfa" }),
              }}
              onClick={() => setSelectUGQualification("No UG")}
            >
              No UG
            </Button>
          </Box>
          {selectUGQualification === "Any UG" && <UGqualificaton />}
          {selectUGQualification === "Specific UG" && (
            <SpecificUGqualification />
          )}
          {selectUGQualification === "No UG" && (
            <Typography sx={{ fontSize: 12, mt: 1 }}>
              No UG qualification - Candidates who have only completed 10th or
              12th but are not pursuing/ have pursued graduation will appear in
              the resul
            </Typography>
          )}
          {selectUGQualification === "No UG" ? (
            <></>
          ) : (
            <Box>
              <Typography sx={{ fontSize: 14, mb: 1, fontWeight: 'bold' }}>
                Any PG Qualification
              </Typography>
              <Box sx={searchCandidates.flexboxCenter}>
                <Button
                  variant="outlined"
                  sx={{
                    ...(selectPGQualification === "Any PG"
                      ? {
                        ...internalCss,
                        backgroundColor: "#423bfa",
                        color: "white",
                      }
                      : { ...internalCss, color: "#423bfa" }),
                  }}
                  onClick={() => setSelectPGQualification("Any PG")}
                >
                  Any PG
                </Button>
                <Button
                  sx={{
                    ...(selectPGQualification === "Specific PG"
                      ? {
                        ...internalCss,
                        backgroundColor: "#423bfa",
                        color: "white",
                      }
                      : { ...internalCss, color: "#423bfa" }),
                  }}
                  onClick={() => setSelectPGQualification("Specific PG")}
                >
                  Specific PG
                </Button>
                <Button
                  sx={{
                    ...(selectPGQualification === "No PG"
                      ? {
                        ...internalCss,
                        backgroundColor: "#423bfa",
                        color: "white",
                      }
                      : { ...internalCss, color: "#423bfa" }),
                  }}
                  onClick={() => setSelectPGQualification("No PG")}
                >
                  No PG{" "}
                </Button>
              </Box>

              {selectPGQualification === "Any PG" && <PGqualification />}
              {selectPGQualification === "Specific PG" && (
                <SpecificPGqualification />
              )}
              {selectPGQualification === "No PG" && (
                <Typography sx={{ fontSize: 12, mt: 1 }}>
                  No UG qualification - Candidates who have only completed 10th
                  or 12th but are not pursuing/ have pursued graduation will
                  appear in the resul
                </Typography>
              )}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      <Divider sx={{ borderColor: "#ddd" }} /> {/* Bottom Divider */}
    </Paper>
  );
};

export default EducationDetails;
