import React from "react";
import {
  Box,
  Typography,
  Divider,
  Chip,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  IconButton,
} from "@mui/material";
import {
  Person as PersonIcon,
  LocationOn as LocationOnIcon,
  Work as WorkIcon,
  School as SchoolIcon,
  //   Star as StarIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  //   Description as DescriptionIcon,
  //   VerifiedUser as VerifiedUserIcon,
  //   Cake as CakeIcon,
  //   Wc as GenderIcon,
} from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";

// Assume this data comes from props or some other data source

const JobSeekerDetails = ({ data }) => {
  const formatYear = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric", // Only year
    };
    return date.toLocaleDateString("en-US", options);
  };

  function calculateDuration(startDateStr, endDateStr) {
    // Ensure the dates are provided
    if (!startDateStr || !endDateStr) {
      return "Dates are required";
    }

    // Parse the ISO 8601 date strings into Date objects
    const start = new Date(startDateStr);
    const end = new Date(endDateStr);

    // Check if the dates are valid
    if (isNaN(start) || isNaN(end)) {
      return "Invalid date format";
    }

    // Ensure the end date is after the start date
    if (end < start) {
      return "End date must be after start date";
    }

    // Calculate the difference in months and years
    let months = (end.getFullYear() - start.getFullYear()) * 12;
    months -= start.getMonth();
    months += end.getMonth();

    // Calculate years and remaining months
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;

    // Format the result
    let duration = "";
    if (years > 0) {
      duration += `${years} ${years === 1 ? "year" : "years"}`;
    }
    if (remainingMonths > 0) {
      if (duration) duration += " ";
      duration += `${remainingMonths} ${
        remainingMonths === 1 ? "month" : "months"
      }`;
    }

    return duration || "0 months"; // Handle case where duration is 0
  }

  return (
    <Box
      sx={{
        backgroundColor: "#F7F7F7FF",
        borderRadius: 4,
        p: 3,
        width: "700px",
        height: "500px", // Fixed height
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        position: "relative",
        overflowY: "auto", // Enable vertical scrolling
        "&::-webkit-scrollbar": {
          width: "0.4em", // Hide scrollbar for Chrome, Safari, and Opera
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "transparent", // Hide scrollbar thumb
        },
      }}
    >
      {/* Job Seeker Name and Title */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Avatar src={data?.profileImage} sx={{ width: 80, height: 80 }}>
          <PersonIcon sx={{ fontSize: 40 }} />
        </Avatar>
        <Box>
          <Typography
            variant="h4"
            component="div"
            sx={{ fontWeight: "bold", fontFamily: "IBM Plex Sans" }}
          >
            {data?.fullName}
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{ fontFamily: "IBM Plex Sans" }}
          >
            {data?.experiences.map((exp) => exp.designation)}
          </Typography>
          {/* <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontFamily: "IBM Plex Sans", display: "flex", alignItems: "center", gap: 1 }}
          >
            <StarIcon sx={{ fontSize: 16, color: "#FFD700" }} /> {jobSeekerData.rating} ({jobSeekerData.totalReviews} Reviews)
          </Typography> */}
        </Box>
      </Box>

      {/* Job Seeker Details */}
      <Box sx={{ mt: 3 }}>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            fontFamily: "IBM Plex Sans",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <LocationOnIcon sx={{ opacity: 0.7 }} /> {data?.currentLocation}
        </Typography>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            mt: 1,
            fontFamily: "IBM Plex Sans",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <WorkIcon sx={{ opacity: 0.7 }} /> {data?.industry} -{" "}
          {data?.experiences?.map((exp) => exp.designation)}
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

      {/* Work Experience */}
      <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "bold", mt: 2, fontFamily: "IBM Plex Sans" }}
      >
        Work Experience
      </Typography>
      <List>
        {data?.experiences.map((exp, index) => (
          <ListItem key={index} alignItems="flex-start">
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText
              primary={exp?.designation}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.primary"
                    sx={{ fontFamily: "IBM Plex Sans" }}
                  >
                    {exp.company}
                  </Typography>
                  {` — ${formatYear(exp.startDate)} - ${
                    exp.endDate ? formatYear(exp.endDate) : "Present"
                  }`}{" "}
                  (
                  {`${
                    exp.endDate
                      ? calculateDuration(exp?.startDate, exp?.endDate)
                      : ""
                  }`}
                  )
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>

      {/* Education */}
      <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "bold", mt: 2, fontFamily: "IBM Plex Sans" }}
      >
        Education
      </Typography>
      <List>
        {data?.educations.map((edu, index) => (
          <ListItem key={index} alignItems="flex-start">
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText
              primary={edu.degree}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.primary"
                    sx={{ fontFamily: "IBM Plex Sans" }}
                  >
                    {edu.institution ? edu.institution : ""},
                    {edu.placeOfEducation ? edu.placeOfEducation : ""}
                  </Typography>
                  {` — ${formatYear(
                    edu.startDate ? edu.startDate : ""
                  )}-${formatYear(edu.endDate ? edu.endDate : "Present")}`}{" "}
                  <br />
                  {`${edu.grade ? edu.grade : ""}`}
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>

      {/* Skills */}
      <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "bold", mt: 2, fontFamily: "IBM Plex Sans" }}
      >
        Skills
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
        {data?.skills.map((skill, index) => (
          <Chip key={index} label={skill} />
        ))}
      </Box>

      {/* Preferred Job Locations */}
      <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "bold", mt: 3, fontFamily: "IBM Plex Sans" }}
      >
        Preferred Job Locations
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
        {data?.preferredJobLocations?.map((location, index) => (
          <Chip key={index} label={location} icon={<LocationOnIcon />} />
        ))}
      </Box>
      <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "bold", mt: 3, fontFamily: "IBM Plex Sans" }}
      >
        Languages Known
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
        {data?.knownLanguage.map((lang, index) => (
          <Chip key={index} label={lang} />
        ))}
      </Box>

      {/* Contact Details */}
      <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "bold", mt: 3, fontFamily: "IBM Plex Sans" }}
      >
        Contact Details
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary={data?.email} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary={data?.phone} />
        </ListItem>
      </List>

      {/* Assets and Documents */}
      <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "bold", mt: 3, fontFamily: "IBM Plex Sans" }}
      >
        Assets and Documents
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {data.resume.map((doc, index) => (
          <Box sx={{ ml: 1 }} key={index}>
            <a href={doc?.url} download>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  sx={{
                    color: "#02FF4EFF",
                    mb: 1,
                    backgroundColor: "#3A3A3AFF",
                    "&:hover": {
                      backgroundColor: "#625E5EFF",
                    },
                  }}
                >
                  <DownloadIcon fontSize="medium" sx={{ fontSize: 17 }} />
                </IconButton>
              </Box>
            </a>

            <Box>
              <iframe
                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                  doc?.url
                )}&embedded=true`}
                title="Contract PDF"
                style={{
                  width: 100, // Ensure the iframe takes up the full card width
                  height: 100, // Fill the container height (250px)
                  border: "none",
                  borderRadius: 3, // Remove default iframe borders
                }}
              />
            </Box>
          </Box>
        ))}
      </Grid>

      {/* Personal Details */}
      {/* <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "bold", mt: 3, fontFamily: "IBM Plex Sans" }}
      >
        Personal Details
      </Typography> */}
      {/* <List>
        <ListItem>
          <ListItemIcon>
            <CakeIcon />
          </ListItemIcon>
          <ListItemText primary={`Date of Birth: ${jobSeekerData.personalDetails.dateOfBirth}`} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <GenderIcon />
          </ListItemIcon>
          <ListItemText primary={`Gender: ${jobSeekerData.personalDetails.gender}`} />
        </ListItem>
      </List> */}

      {/* Call to Action */}
      {/* <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          sx={{
            borderColor: "#423bfa",
            color: "white",
            bgcolor: "#423bfa",
            textTransform: "none",
            fontFamily: "IBM Plex Sans",
            borderRadius: "20px",
            px: 4,
          }}
          onClick={() => window.open(jobSeekerData.profileUrl, "_blank")}
        >
          View Full Profile
        </Button>
      </Box> */}

      {/* Dialog for displaying documents */}
      {/* <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>{dialogContent.title}</DialogTitle>
        <DialogContent>
          <iframe src={dialogContent.url} width="100%" height="500px" />
        </DialogContent>
      </Dialog> */}
    </Box>
  );
};

export default JobSeekerDetails;
